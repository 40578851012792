import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { AddUserGroupService } from '../add-user-group/add-user-group.service';
import { Customer } from '../customer';
import { DialogBodyComponent } from '../dialog-body/dialog-body.component';
import { ManageUsersService } from '../manage-users/manage-users.service';
import { Menu } from '../menu';
import { User } from '../user';
import { MediaMatcher } from '@angular/cdk/layout';
// import { addUserGroupMenuTableService } from '../add-user-group/add-user-group.service';

@Component({
  selector: 'app-add-user-group',
  templateUrl: './add-user-group.component.html',
  styleUrls: ['./add-user-group.component.css']
})

export class AddUserGroupComponent implements OnInit, OnDestroy {
  //To be fetched using company service call
  //To be fetched using company service call
  items = Array.from({length: 100000}).map((_, i) => `Item #${i}`);
  companyList: Customer[] = [];
  userGroupForm: FormGroup;
  submitted = false;
  groupId: string;

  menuAccessArray: FormArray;
  // menuInformationData = [
  //   { "menuId": "O_1001", "menuName": "Order Management", "AccessFlag": true },
  //   { "menuId": "F_1002", "menuName": "Forecast Management", "AccessFlag": false },
  //   { "menuId": "P_2001", "menuName": "Prices and Index", "AccessFlag": false },
  //   { "menuId": "PR_3001", "menuName": "Products", "AccessFlag": true },
  //   { "menuId": "R_2001", "menuName": "Reports", "AccessFlag": true }
  // ];
  menuData:any;

  // menuData:any = [
  //   { name: 'Price/Quantities - Price',  actions: [], isSelected: false, id: 1 },
  //   { name: 'Price/Quantities - Purchase Plan',  actions: [], isSelected: false, id: 2 },
  //   { name: 'Price/Quantities - Actual',  actions: [], isSelected: false, id: 3 },
  //   { name: 'Products - Distribution Program',  actions: [], isSelected: false, id: 4 },
  //   { name: 'Products - Bitumen Usage',  actions: [], isSelected: false, id: 5 },
  //   { name: 'Products - Ordering Procedures', actions: [], isSelected: false, id: 6 },
  //   { name: 'Products - Product Information',  actions: [], isSelected: false, id: 7 },
    
  //   ];

    // userData: any = [
    //   // { name: 'Kapil Sharma ', EmailID: 'kapil.sharma@lntinfotech.com' },   
        
    //   { FirstName: 'Kapil ', LastName: 'Sharma ', EmailID: 'kapil.sharma@lntinfotech.com' },  
    //   { FirstName: 'Shweta  ', LastName:'Kadav ', EmailID: 'Shweta.Kadav@lntinfotech.com' },  
    //   { FirstName: 'Rahul ', LastName: 'Saini ', EmailID: 'Rahul.Saini@lntinfotech.com' },  
    //   { FirstName: 'Sayali ', LastName: 'Chavan ', EmailID: 'Sayali.Chavan@lntinfotech.com' },  
    //   { FirstName: 'Sonal ', LastName: 'Surve ', EmailID: 'Sonal.Surve@lntinfotech.com' },  
    //   { FirstName: 'Shikha ', LastName: 'Singh ', EmailID: 'Shikha.Singh@lntinfotech.com' },  
    //   { FirstName: 'Shubhi ', LastName: 'Chaunhan ', EmailID: 'Shubhi.Chaunhan@lntinfotech.com' },
    //   { FirstName: 'Varad ', LastName: 'Mayekar ', EmailID: 'Varad.sharma@lntinfotech.com' },    
    //   { FirstName: 'Divyansh ', LastName: 'Singh ', EmailID: 'Divyansh.Singh@lntinfotech.com' },
    //   { FirstName: 'Vinita ', LastName: 'Modi ', EmailID: 'Vinita.Modi@lntinfotech.com' }, 

		//  	];

  // public displayedColumns = ['MenuName', 'actions' ];

  // Uncomment for new menu tree structure
  public displayedColumns = ['expandCollapseIcon', 'actions', 'MenuName'];

  // public dataSource = new MatTableDataSource<Menu>();
  public displayedColumns1 = ['FirstName', 'EmailID' ];
  // public dataSource1 = new MatTableDataSource<User>();

  public myFirstTableData: MatTableDataSource<Menu> = new MatTableDataSource<Menu>();
  public mySecondTableData: MatTableDataSource<User> = new MatTableDataSource<User>();
  @ViewChild('firstTableSort', {static: true}) public firstTableSort: MatSort;
  @ViewChild('secondTableSort', {static: true}) public secondTableSort: MatSort;

  // @ViewChild(MatSort, {static: true}) sort: MatSort;
  customerID: string;
  //  @ViewChild(MatSort, {static: true}) sort1: MatSort;
  isRequestCompleted: boolean;  
  userInfo: any;
	isLoggedInUserCustomerAdmin: boolean;
  countryCode: any;
	mobileQuery: MediaQueryList;
	private _mobileQueryListener: () => void;
  isAllMenusSelected: boolean;

  // Uncomment for new menu tree structure
  isAllMenusCollapsed: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private manageUsersService: ManageUsersService,
    private router: Router,
    private dialog: MatDialog,    
    private addUserGroupService: AddUserGroupService,
    private sharedService: AppService,
    private cdRef: ChangeDetectorRef,
		private media: MediaMatcher) {
      this.userInfo = this.sharedService.getLoginResponse();
      const navigation = this.router.getCurrentNavigation();
      if (navigation) {
        const state = navigation.extras.state as {
          userGroupID: string,
          customerID: string
        };
        if (state && state.userGroupID) {
          this.groupId = state.userGroupID;
          this.customerID = state.customerID;
        }
      }
      //this.createUserGroupForm();
		if (this.userInfo && this.userInfo.RoleID === "A") {
			this.isLoggedInUserCustomerAdmin = true;
    }
    this.countryCode = this.userInfo.CountryCode;            
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => cdRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener); 
  }

 
  // createUserGroupForm() {
  //   this.userGroupForm = this.formBuilder.group({
  //     companyName: [null, [Validators.required]],
  //     userGroupName: ['', Validators.required],
  //     menuAccess: this.menuAccessArray
  //   })
  // }  

  ngOnInit() {
    // this.companyName = ['Ab Asphalt AX', 'AB Rafael', 'Baltic Tank Oy', 'Neste Corporation', 'Super Asphalt Oy'];
    
    // this.dataSource.data = this.menuData; 
    // this.dataSource1.data = this.userData; 

   /*  this.getUsers();
    this.getMenu(); */

    this.getUserDetails();

    /* this.dataSource1.sort = this.sort1;
     this.dataSource.sort = this.sort; */
    

    this.userGroupForm = this.formBuilder.group({
      companyName: ['', Validators.required],      
      UserGroupName: ['', Validators.required],
      userGroupID: [''],      
      createdBy: ['']      
    });

    this.userGroupForm.controls['createdBy'].setValue(this.userInfo ? (this.userInfo.FirstName + " "+ this.userInfo.LastName) : '');	
    
    // this.userGroupForm = new FormGroup({
    //   companyName: new FormControl(''),
    //   userGroupName: new FormControl(''),
    //   menus: new FormArray(this.menuInformationData.map(item => {
    //     const group = this.initMenuData();
    //     group.patchValue(item);
    //     return group;
    //   }))
    // });

  }

  ngAfterViewInit(): void {
    // this.dataSource.sort = this.sort;
    
    this.myFirstTableData.sort = this.firstTableSort;
    this.mySecondTableData.sort = this.secondTableSort;
    this.cdRef.detectChanges();	
		this.myFirstTableData.sortingDataAccessor = (data, sortHeaderId) => {
			if (data[sortHeaderId]) {
				return data[sortHeaderId].toLocaleLowerCase()
			}
			return null;
		};	
		this.mySecondTableData.sortingDataAccessor = (data, sortHeaderId) => {
			if (data[sortHeaderId]) {
				return data[sortHeaderId].toLocaleLowerCase()
			}
			return null;
		};

  }  

	ngOnDestroy(): void {
	  this.mobileQuery.removeListener(this._mobileQueryListener);
	}

  get f() { return this.userGroupForm.controls; }

  saveUserDetails() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.userGroupForm.invalid) {
          return;
      }

      if (this.groupId) {
        this.addUserGroupService.updateUserGroup(this.userGroupForm.value, this.myFirstTableData.data).subscribe(
				res => {
          this.saveStatusDialog(res);
					// this.openSaveUserGroupDialog('User Group Details Updated Successfully', null, true);
				});

      } else {
        this.addUserGroupService.createAddUserGroup(this.userGroupForm.value, this.myFirstTableData.data).subscribe(
				res => {
          this.saveStatusDialog(res);
					// this.openSaveUserGroupDialog('User Group Details Saved Successfully', null, true);
				});
      }
      
  }

  /* plusGreen(element: any) {

    for(let menu of this.myFirstTableData.data) {

      if (menu['id'] === element.id) {
        element.AccessFlag = true;
      }
       
    }
  }

    minusGrey(element: any) {

      for(let menu of this.myFirstTableData.data) {
  
        if (menu['id'] === element.id) {
          element.AccessFlag = false;
        }
         
      }
  } */

  addSelectedMenu(element: any) {
    for (let menu of this.myFirstTableData.data) {
      if (menu['id'] === element.id) {
        element.AccessFlag = true;
      }
    }
  }

  removeSelectedMenu(element: any) {
    for (let menu of this.myFirstTableData.data) {
      if (menu['id'] === element.id) {
        element.AccessFlag = false;
      }
    }
  }

  initMenuData() {
    return new FormGroup({
      menuId: new FormControl(''),
      menuName: new FormControl(''),
      AccessFlag: new FormControl('')
    });
  }

  addMenu() {
    const control = <FormArray>this.userGroupForm.get('menus');
    control.push(this.initMenuData());
  }

  // get formControls() { return this.userGroupForm.controls; }

  // addMenuAccess(): void {
  //   this.menuAccessArray = this.userGroupForm.get('menuAccess') as FormArray;
  //   //this.menuAccessArray.push(this.createmenuAccessData());
  // } 
  public deleteMenu(index) {
    const control = <FormArray>this.userGroupForm.get('menus');
    control.removeAt(index);
  }

  getUsers() {
		this.addUserGroupService.getUsers('', this.countryCode, '')
			.subscribe(
				res => {
					const users = 'Users';
					if (res && res[users] && res[users].length) {
            this.mySecondTableData.data = res[users];
            

					}
				});
  }
  
  getMenu() {
		this.addUserGroupService.getMenu(this.countryCode)
			.subscribe(
				res => {
					const menu = 'Menus';
					if (res && res[menu] && res[menu].length) {
						this.myFirstTableData.data = res[menu];
					}
				});			

}






getUserDetails() {
  const requestArray = [];
  this.isRequestCompleted = false;

  const request1 = this.addUserGroupService.getCustomerNames(this.countryCode);
  requestArray.push(request1);  

  const request2 = this.manageUsersService.getUsers('', this.countryCode, '');
  requestArray.push(request2);

  const request3 =  this.addUserGroupService.getMenu(this.countryCode);
  requestArray.push(request3);

  if (this.groupId) {
    const request4 = this.addUserGroupService.getUserGroupsByGroupId(this.groupId, this.countryCode);
    requestArray.push(request4);
  }

  forkJoin(requestArray).subscribe(results => {
    var menuMasterList = [], userMasterList = [];
    if (results && results.length) {
      if (results[0] && results[0]['Customers'] && results[0]['Customers'].length) {
        this.companyList = results[0]['Customers'];
      }

      if (results[1] && results[1]['Users'] && results[1]['Users'].length) {
        userMasterList = results[1]['Users'];
        this.mySecondTableData.data = userMasterList;
      }

      if (results[2] && results[2]['Menus'] && results[2]['Menus'].length) {
        /* for (var menu of results[2].Menus) {
          menu['AccessFlag'] = false;
          menuMasterList.push(menu);
        } */

        // menuMasterList = results[2].Menus;

        // Uncomment for new menu tree structure
        /* let menuList = [];

        let menu1 = {};
        menu1['MenuId'] = '1';
        menu1['ParentMenuId'] = '';
        menu1['MenuName'] = 'Order management';
        menu1['isHidden'] = false;
        menu1['isExpanded'] = true;
        menu1['AccessFlag'] = false;
        menuList.push(menu1);

        let menu2 = {};
        menu2['MenuId'] = '2';
        menu2['ParentMenuId'] = '1';
        menu2['MenuName'] = 'Rolling 4 week';
        menu2['isHidden'] = false;
        menu2['isExpanded'] = false;
        menu2['AccessFlag'] = false;
        menuList.push(menu2);

        let menu3 = {};
        menu3['MenuId'] = '3';
        menu3['ParentMenuId'] = '1';
        menu3['MenuName'] = 'Monthly forecast entry';
        menu3['isHidden'] = false;
        menu3['isExpanded'] = false;
        menu3['AccessFlag'] = false;
        menuList.push(menu3);

        let menu4 = {};
        menu4['MenuId'] = '4';
        menu4['ParentMenuId'] = '1';
        menu4['MenuName'] = 'Forecast status';
        menu4['isHidden'] = false;
        menu4['isExpanded'] = false;
        menu4['AccessFlag'] = false;
        menuList.push(menu4);

        let menu5 = {};
        menu5['MenuId'] = '5';
        menu5['ParentMenuId'] = '';
        menu5['MenuName'] = 'Product information';
        menu5['isHidden'] = false;
        menu5['isExpanded'] = true;
        menu5['AccessFlag'] = false;
        menuList.push(menu5);

        let menu6 = {};
        menu6['MenuId'] = '6';
        menu6['ParentMenuId'] = '';
        menu6['MenuName'] = 'My company';
        menu6['isHidden'] = false;
        menu6['isExpanded'] = true;
        menu6['AccessFlag'] = false;
        menuList.push(menu6);

        let menu7 = {};
        menu7['MenuId'] = '7';
        menu7['ParentMenuId'] = '6';
        menu7['MenuName'] = 'Add delivery site';
        menu7['isHidden'] = false;
        menu7['isExpanded'] = false;
        menu7['AccessFlag'] = false;
        menuList.push(menu7);

        let menu8 = {};
        menu8['MenuId'] = '8';
        menu8['ParentMenuId'] = '6';
        menu8['MenuName'] = 'Modify delivery site';
        menu8['isHidden'] = false;
        menu8['isExpanded'] = false;
        menu8['AccessFlag'] = false;
        menuList.push(menu8);

        let menu9 = {};
        menu9['MenuId'] = '9';
        menu9['ParentMenuId'] = '';
        menu9['MenuName'] = 'Reports';
        menu9['isHidden'] = false;
        menu9['isExpanded'] = true;
        menu9['AccessFlag'] = false;
        menuList.push(menu9);

        let menu10 = {};
        menu10['MenuId'] = '10';
        menu10['ParentMenuId'] = '9';
        menu10['MenuName'] = 'Monthly reports';
        menu10['isHidden'] = false;
        menu10['isExpanded'] = false;
        menu10['AccessFlag'] = false;
        menuList.push(menu10);

        let menu11 = {};
        menu11['MenuId'] = '11';
        menu11['ParentMenuId'] = '9';
        menu11['MenuName'] = 'Weekly reports';
        menu11['isHidden'] = false;
        menu11['isExpanded'] = false;
        menu11['AccessFlag'] = false;
        menuList.push(menu11);

        let menu12 = {};
        menu12['MenuId'] = '12';
        menu12['ParentMenuId'] = '9';
        menu12['MenuName'] = 'Shipped deliveries';
        menu12['isHidden'] = false;
        menu12['isExpanded'] = false;
        menu12['AccessFlag'] = false;
        menuList.push(menu12);

        this.myFirstTableData.data = menuList; */

        menuMasterList = this.getMenuListToBeDisplayedInTable(results[2]['Menus']);
        
        this.myFirstTableData.data = menuMasterList;
      }


      if (results[3] && results[3]['UsersGroups'] && results[3]['UsersGroups'].length && results[3]['UsersGroups'][0]) {

        var menuList = [], userList = [];

        if (results[3]['UsersGroups'][0].Users && results[3]['UsersGroups'][0].Users.length) {
          this.mySecondTableData.data = results[3]['UsersGroups'][0].Users;
        }

        /* for (var menuMaster of menuMasterList) {

            for (var menu of results[3].UsersGroups[0].Menus) {
              if (menuMaster['MenuId'] === menu['MenuId']) {

                menuMaster['AccessFlag'] = true;
                menuList.push(menuMaster);
              } 
              else {
                menuList.push(menuMaster);
              }
            }

        } */

        /* for (var menuMaster of menuMasterList) {
          for (var menu of results[3].UsersGroups[0].Menus) {
            if (menuMaster['MenuId'] === menu['MenuId']) {
              menu['MenuName'] = menuMaster.MenuName;
              menuList.push(menu);  
            }
          }
      } */

        if (results[3]['UsersGroups'][0].Menus && results[3]['UsersGroups'][0].Menus.length) {
          for (var menuMaster of menuMasterList) {
            for (var menu of results[3]['UsersGroups'][0].Menus) {
              if (menuMaster['MenuId'] === menu['MenuId']) {
                menuMaster['AccessFlag'] = menu['AccessFlag'];
                menuList.push(menuMaster);
              }
            }
          }
        }
        
        if (results[3]['UsersGroups'][0].Users && results[3]['UsersGroups'][0].Users.length) {
          for (var userMaster of userMasterList) {
            for (var user of results[3]['UsersGroups'][0].Users) {
              if (userMaster['UserID'] === user['UserId']) {
                userList.push(userMaster);
              }
            }
          }
        }

        this.isRequestCompleted = true;
        this.myFirstTableData.data = menuList;
        this.mySecondTableData.data = userList;
        this.userGroupForm.patchValue(results[3]['UsersGroups'][0]);
        this.userGroupForm.controls['companyName'].setValue(this.customerID ? this.customerID.toString() : '');
        this.userGroupForm.controls['userGroupID'].setValue(results[3]['UsersGroups'][0]['UserGroupID']);
        if (this.groupId) {
          this.userGroupForm.controls['companyName'].disable();
          this.userGroupForm.controls['UserGroupName'].disable();
        }
      } else if (this.isLoggedInUserCustomerAdmin) {
        this.userGroupForm.controls['companyName'].setValue(this.userInfo ? this.userInfo.CustomerID : '');
      }
  }
});

}


cancelUserDetails() {
  this.router.navigate(["app/home"]);
}

onSubmit() {
    /* this.submitted = true;
		if (!this.userGroupForm.valid) {
			return false;
		}

		this.addUserGroupService.createAddUserGroup(this.addUserGroupService.value)
			.subscribe(
				res => {
					this.openSaveUserDialog();
				}); */
  }

  openSaveUserGroupDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber

      },
      height: '39%',
      width: (this.mobileQuery.matches ? '50%' : '25%'),
    });
  }

  saveStatusDialog(response: any) {
    if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
      this.reset();
      this.openSaveUserGroupDialog('User Group Details Saved Successfully', null, true, 'add-user-group-success');
    } else if (response['Description'] === "User_Group_Name and Customer_ID Combination already exist") {
      this.openSaveUserGroupDialog('', '', false, 'add-user-group-fail-exist');
    }
    else if (response && response['ResponseCode'] === '500' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR', 'add-user-group-fail') {
      this.openSaveUserGroupDialog('Failed to Save User Group Details', '', false, 'add-user-group-fail');
    }

    else {
      this.openSaveUserGroupDialog('Failed to Save User Group Details', '', false, 'add-user-group-fail');
    }
  }

  reset() {
    this.submitted = false;
    this.userGroupForm.reset();
    this.presetFormValues();
    this.resetAllSelectedMenus();
  }

  presetFormValues() {
    this.userGroupForm.controls['companyName'].setValue('');
    this.resetMenuList();
  }

  resetMenuList() {
    for (let menu of this.myFirstTableData.data) {
      menu['AccessFlag'] = false;
    }
  }

  getStringWithoutSpaces(stringWithSpaces: string): string {
    let stringWithoutSpaces = '';
    stringWithoutSpaces = stringWithSpaces.replace(/\s/g, '');
    return stringWithoutSpaces;
  }

  selectAllMenusToSave() {
    for (let menuObject of this.myFirstTableData.data) {
      menuObject['AccessFlag'] = !this.isAllMenusSelected;
    }
  }

  resetAllSelectedMenus() {
    this.isAllMenusSelected = false;
    for (let menuObject of this.myFirstTableData.data) {
      menuObject['AccessFlag'] = false;
    }
  }

  // Uncomment for new menu tree structure
  expandCollapseMenu(row: any) {
    for (let menuObject of this.myFirstTableData.data) {
      if (menuObject && row && menuObject['ParentMenuId'] && row['MenuId'] === menuObject['ParentMenuId']) {
        menuObject['isHidden'] = !menuObject['isHidden'];
      }
      if (menuObject && row && !menuObject['ParentMenuId'] && row['MenuId'] === menuObject['MenuId']) {
        row['isExpanded'] = !row['isExpanded'];
      }
    }
  }

  selectUnselectMenu(row: any) {
    for (let menuObject of this.myFirstTableData.data) {
      if (menuObject && row && !row['ParentMenuId'] && row['MenuId'] === menuObject['ParentMenuId']) {
        menuObject['AccessFlag'] = !row['AccessFlag'];
      }
      if (menuObject && row && row['ParentMenuId'] && !row['AccessFlag'] && row['ParentMenuId'] === menuObject['MenuId']) {
        menuObject['AccessFlag'] = !row['AccessFlag'];
      }
    }
  }

  hasChildren(menuId: string): boolean {
    let parentHasChildren: boolean;
    for (let menuObject of this.myFirstTableData.data) {
      if (menuId && menuObject && menuObject['ParentMenuId'] === menuId) {
        parentHasChildren = true;
        break;
      }
    }
    return parentHasChildren;
  }

  expandCollapseAllMenus() {
    this.isAllMenusCollapsed = !this.isAllMenusCollapsed;
    for (let menuObject of this.myFirstTableData.data) {
      if (menuObject && menuObject['ParentMenuId']) {
        menuObject['isHidden'] = this.isAllMenusCollapsed;
      }
      if (menuObject && !menuObject['ParentMenuId']) {
        menuObject['isExpanded'] = !this.isAllMenusCollapsed;
      }
    }
  }

  getMenuListToBeDisplayedInTable(menus: any): any {
    let menuMasterList = [];
    for (var menu of menus) {
      let menuObject = {};
      menuObject['MenuId'] = menu['MenuId'];
      menuObject['ParentMenuId'] = menu['Parent_Menu_Id'];
      menuObject['MenuName'] = menu['MenuName'];
      menuObject['isHidden'] = false;
      menuObject['isExpanded'] = !menu['Parent_Menu_Id'];
      menuObject['AccessFlag'] = false;
      menuMasterList.push(menuObject);
    }

    return menuMasterList;

    // menuMasterList = results[2].Menus;

    // Uncomment for new menu tree structure
    /* let menuList = [];

    let menu1 = {};
    menu1['MenuId'] = '1';
    menu1['ParentMenuId'] = '';
    menu1['MenuName'] = 'Order management';
    menu1['isHidden'] = false;
    menu1['isExpanded'] = true;
    menu1['AccessFlag'] = false;
    menuList.push(menu1);

    let menu2 = {};
    menu2['MenuId'] = '2';
    menu2['ParentMenuId'] = '1';
    menu2['MenuName'] = 'Rolling 4 week';
    menu2['isHidden'] = false;
    menu2['isExpanded'] = false;
    menu2['AccessFlag'] = false;
    menuList.push(menu2);

    let menu3 = {};
    menu3['MenuId'] = '3';
    menu3['ParentMenuId'] = '1';
    menu3['MenuName'] = 'Monthly forecast entry';
    menu3['isHidden'] = false;
    menu3['isExpanded'] = false;
    menu3['AccessFlag'] = false;
    menuList.push(menu3);

    let menu4 = {};
    menu4['MenuId'] = '4';
    menu4['ParentMenuId'] = '1';
    menu4['MenuName'] = 'Forecast status';
    menu4['isHidden'] = false;
    menu4['isExpanded'] = false;
    menu4['AccessFlag'] = false;
    menuList.push(menu4);

    let menu5 = {};
    menu5['MenuId'] = '5';
    menu5['ParentMenuId'] = '';
    menu5['MenuName'] = 'Product information';
    menu5['isHidden'] = false;
    menu5['isExpanded'] = true;
    menu5['AccessFlag'] = false;
    menuList.push(menu5);

    let menu6 = {};
    menu6['MenuId'] = '6';
    menu6['ParentMenuId'] = '';
    menu6['MenuName'] = 'My company';
    menu6['isHidden'] = false;
    menu6['isExpanded'] = true;
    menu6['AccessFlag'] = false;
    menuList.push(menu6);

    let menu7 = {};
    menu7['MenuId'] = '7';
    menu7['ParentMenuId'] = '6';
    menu7['MenuName'] = 'Add delivery site';
    menu7['isHidden'] = false;
    menu7['isExpanded'] = false;
    menu7['AccessFlag'] = false;
    menuList.push(menu7);

    let menu8 = {};
    menu8['MenuId'] = '8';
    menu8['ParentMenuId'] = '6';
    menu8['MenuName'] = 'Modify delivery site';
    menu8['isHidden'] = false;
    menu8['isExpanded'] = false;
    menu8['AccessFlag'] = false;
    menuList.push(menu8);

    let menu9 = {};
    menu9['MenuId'] = '9';
    menu9['ParentMenuId'] = '';
    menu9['MenuName'] = 'Reports';
    menu9['isHidden'] = false;
    menu9['isExpanded'] = true;
    menu9['AccessFlag'] = false;
    menuList.push(menu9);

    let menu10 = {};
    menu10['MenuId'] = '10';
    menu10['ParentMenuId'] = '9';
    menu10['MenuName'] = 'Monthly reports';
    menu10['isHidden'] = false;
    menu10['isExpanded'] = false;
    menu10['AccessFlag'] = false;
    menuList.push(menu10);

    let menu11 = {};
    menu11['MenuId'] = '11';
    menu11['ParentMenuId'] = '9';
    menu11['MenuName'] = 'Weekly reports';
    menu11['isHidden'] = false;
    menu11['isExpanded'] = false;
    menu11['AccessFlag'] = false;
    menuList.push(menu11);

    let menu12 = {};
    menu12['MenuId'] = '12';
    menu12['ParentMenuId'] = '9';
    menu12['MenuName'] = 'Shipped deliveries';
    menu12['isHidden'] = false;
    menu12['isExpanded'] = false;
    menu12['AccessFlag'] = false;
    menuList.push(menu12);

    this.myFirstTableData.data = menuList; */
  }

}





