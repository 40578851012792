import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-forecast-snapshot-details',
  templateUrl: './forecast-snapshot-details.component.html',
  styleUrls: ['./forecast-snapshot-details.component.css']
})
export class ForecastSnapshotDetailsComponent implements OnInit {
  snapshotDetails: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  dataSource: any;
  // displayedColumns: any = ['CustomerName', 'DeliverySiteName', 'DepotName', 'ProductName', 'WeekNumber', 'ForecastQuantity', 'FIRST_SNAPSHOT_QUANTITY', 'ActualQuantity', 'Difference'];
  displayedColumns: any = ['CUSTOMER_NAME', 'DELIVERY_SITE', 'DEPOT_NAME', 'PRODUCT_NAME', 'PLANNED_QUANTITY', 'WEEK', 'SNAPSHOT_WEEK', 'SNAP_Number', 'FORECAST_ACCURACY'];
  actualQuantity: string;
  countryCode: string;
  isLoggedInUserPortalAdmin: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ForecastSnapshotDetailsComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher) {
    if (data) {
      this.snapshotDetails = data.snapshotDetails || this.snapshotDetails;
      this.actualQuantity = data.actualQuantity || this.actualQuantity;
      this.countryCode = data.countryCode || this.countryCode;
      this.isLoggedInUserPortalAdmin = data.isLoggedInUserPortalAdmin || this.isLoggedInUserPortalAdmin;
    }
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.snapshotDetails);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

}