import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import * as XLSX from 'xlsx';
import { environment } from '../../../environments/environment';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class PendingForecastService {
  d
  apiUrl = environment.apiUrl;
  private pWeek: any;
  private pCustomerName: any;
  private pDelSite: any;
  private pCustomerId: any;
  private pData: any;

  constructor(private httpClient: HttpClient,
    private sharedService: AppService) { }

  setPWeek(pWeek) {
    this.pWeek = pWeek;
  }
  getPWeek() {
    return this.pWeek;
  }
  setPCustomerName(pCustomerName) {
    this.pCustomerName = pCustomerName;
  }
  getPCustomerName() {
    return this.pCustomerName;
  }
  setPCustomerId(pCustomerId) {
    this.pCustomerId = pCustomerId;
  }
  getPCustomerId() {
    return this.pCustomerId;
  }
  setPDelSite(pDelSite) {
    this.pDelSite = pDelSite;
  }
  getPDelSite() {
    return this.pDelSite;
  }
  setPData(pData) {
    this.pData = pData;
  }
  getPData() {
    return this.pData;
  }



  getWeeklyForecastStatus(CustomerId: any, DeliverySite: any, Status: any, Week: any, currentYear: any, countryCode: string) {
    let defaulter_url = this.apiUrl + '/getForecast';
    return this.httpClient
      .get(defaulter_url, {
        params: {
          CustomerId: CustomerId,
          DeliverySite: DeliverySite,
          Status: Status,
          Week: Week,
          Year: currentYear,
          CountryCode: countryCode
        }
      });

  }

  GetMonthlyForecastStatus(CustomerId: any, DeliverySite: any, Status: any, currentYear: any, countryCode: string) {
    let defaulter_url = this.apiUrl + '/GetMonthlyForecastStatus';
    return this.httpClient
      .get(defaulter_url, {
        params: {
          CustomerId: CustomerId,
          DeliverySite: DeliverySite,
          Year: currentYear,
          CountryCode: countryCode
        }
      });

  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json),
        { header: ["CustomerName", "DeliverySite", "SubscriberEmail", "Week", "Status"] });

      const workbook: XLSX.WorkBook = { Sheets: { 'Data in excel': worksheet }, SheetNames: ['Data in excel'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }
  public exportAsExcelFileMonthly(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json),
        { header: ["CustomerName", "DeliverySite", "SubscriberEmail", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total"] });

      const workbook: XLSX.WorkBook = { Sheets: { 'Data in excel': worksheet }, SheetNames: ['Data in excel'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const ForecastStatus: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(ForecastStatus, fileName + this.sharedService.getCurrentDateTime().getTime() + EXCEL_EXTENSION);
  }

  sendEmailNotification(emailTo: string, emailBody: string, emailSubject: string) {
    let url = this.apiUrl + '/CommonNotificationRestOSBService';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      })
    };
    let postData = JSON.stringify(
      {
        "EmailNotification": {
          "To": emailTo,
          "Body": emailBody,
          "Subject": emailSubject
        }
      }
    );
    return this.httpClient.post(url, postData, httpOptions).pipe();
  }

  getCustomerNames(countryCode) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }

  // For SWEDEN and NORWAY
  getDeliverySiteForForecastStatus(UserID: string, CountryCode: any, MappingStatus: any, CustomerID: any) {
    let delSiteUrl = this.apiUrl + '/GetDeliverySiteForUser';

    return this.httpClient
      .get(delSiteUrl, {
        params: {
          UserID: UserID,
          CountryCode: CountryCode,
          MappingStatus: MappingStatus,
          CustomerID: CustomerID
        }
      });
  }

  GetMonthlyForecastStatusForUser(customerId: string, deliverySite: string, year: any, countryCode: any, userId: any) {
    let url = this.apiUrl + '/GetMonthlyForecastStatusForUser';
    return this.httpClient
      .get(url, {
        params: {
          CustomerID: customerId,
          DeliverySite: deliverySite,
          UserID: userId,
          Year: year,
          CountryCode: countryCode,
        }
      });
  }

  getWeeklyForecastStatusForUser(CustomerId: any, DeliverySite: any, Week: any, currentYear: any, countryCode: string, UserId: string, status: string) {
    let url = this.apiUrl + '/GetWeeklyForecastStatusForUser';
    return this.httpClient
      .get(url, {
        params: {
          customerId: CustomerId,
          userId: UserId,
          deliverySite: DeliverySite,
          Week: Week,
          year: currentYear,
          CountryCode: countryCode,
          Status: status
        }
      });

  }

}