
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { User } from '../user';


@Injectable({ providedIn: 'root' })
export class BitumenIndexAdminService {
  apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({ 
      // 'Access-Control-Allow-Origin': '*', 
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*' ,
      // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization',
      // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    })
  };


  constructor(
     private httpClient: HttpClient) { }

    // getBitumenIndex(month: string, country?: string, Year?: string) {
      getBitumenIndex() {
        const serviceUrl = this.apiUrl + '/GetBituminIndex';
        return this.httpClient
            .get(serviceUrl, {
                params: {
                  // month: userID,
                  // Country: country,
                  // Year: Year
                }
            });
    }


    addBitumenIndex(bitumenIndex: any) {

      let url = this.apiUrl + '/AddBitumenIndex';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        })
      };
      let postData = JSON.stringify(
          // {          
             
          //     "Index_2000": bitumenIndex.Index_2000,
          //     "Month": bitumenIndex.month,
          //     "Index_2010": bitumenIndex.Index_2010,
          //     "Index_2020": bitumenIndex.Index_2020 ? bitumenIndex.Index_2020 : "",
          //     "Index_2030": bitumenIndex.Index_2030 ? bitumenIndex.Index_2030 : "",
          //      "Year": bitumenIndex.Year,
          //      "Index_2015": bitumenIndex.Index_2015,
          //      "Index_2025": bitumenIndex.Index_2025 ? bitumenIndex.Index_2025 : "",
          //      "Index_1995": bitumenIndex.Index_1995,
          //      "Index_2005": bitumenIndex.Index_2005,
          // }

          {
            "Month": bitumenIndex.month,
            "Year": bitumenIndex.Year,
            "Index_2030": bitumenIndex.Index_2030 ? bitumenIndex.Index_2030 : "",
            "Index_2025": bitumenIndex.Index_2025 ? bitumenIndex.Index_2025 : "",
            "Index_2020": bitumenIndex.Index_2020 ? bitumenIndex.Index_2020 : "",
            "Index_2015": bitumenIndex.Index_2015,
            "Index_2010": bitumenIndex.Index_2010,
            "Index_2005": bitumenIndex.Index_2005,
            "Index_2000": bitumenIndex.Index_2000,
            "Index_1995": bitumenIndex.Index_1995,
            "CreatedBy": bitumenIndex.CreatedBy,
            "CreatedOn": bitumenIndex.CreatedOn,
            "UpdatedBy": bitumenIndex.UpdatedBy,
            "UpdatedOn": bitumenIndex.UpdatedOn,
         }
      );
           
      return this.httpClient.post(url, postData, httpOptions).pipe();
    }


    updateBitumenIndex(bitumenIndex: any) {
      let url = this.apiUrl + '/UpdateBitumenIndex';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        })
      };
      let postData = JSON.stringify(
          {
            "Month": bitumenIndex.month,
            "Year": bitumenIndex.Year,
            "Index_2030": bitumenIndex.Index_2030 ? bitumenIndex.Index_2030 : "",
            "Index_2025": bitumenIndex.Index_2025 ? bitumenIndex.Index_2025 : "",
            "Index_2020": bitumenIndex.Index_2020 ? bitumenIndex.Index_2020 : "",
            "Index_2015": bitumenIndex.Index_2015,
            "Index_2010": bitumenIndex.Index_2010,
            "Index_2005": bitumenIndex.Index_2005,
            "Index_2000": bitumenIndex.Index_2000,
            "Index_1995": bitumenIndex.Index_1995,
            "CreatedBy": bitumenIndex.CreatedBy,
            "CreatedOn": bitumenIndex.CreatedOn,
            "UpdatedBy": bitumenIndex.UpdatedBy,
            "UpdatedOn": bitumenIndex.UpdatedOn,
         }
         /* {
          "Index_2000":  	"15"	,
          "Month":  	"JAN"	,
          "Index_2010":  	"23"	,
          "Index_2020":  	"40"	,
          "Index_2030":  	""	,
          "Year":  	"2020"	,
          "Index_2015":  	"52"	,
          "Index_2025":  	""	,
          "Index_1995":  	"10"	,
          "Index_2005":  	"20"
          } */
      );           
      return this.httpClient.post(url, postData, httpOptions).pipe();
    }


}
