import {
  Component,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-order-entry-validations',
  templateUrl: './order-entry-validations.component.html',
  styleUrls: ['./order-entry-validations.component.css']
})
export class OrderEntryValidationsComponent {

  messages: any;
  footerMessage: string;
  hideOkButton: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<OrderEntryValidationsComponent>) {
    if (data) {
      this.messages = data.messages;
      this.footerMessage = data.footerMessage;
      this.hideOkButton = data.hideOkButton;
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

}
