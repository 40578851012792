import { MediaMatcher } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, MatDialog, MatPaginator, MatSort, MatTableDataSource, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSearchService } from '../global-search/global-search.service';
import { AppService } from '../shared-modules/services/shared.service';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YY',
  },
  display: {
    dateInput: 'DD-MMM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
}
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class SettingsComponent implements OnInit {

  public dataSource = new MatTableDataSource();
  @ViewChild('sort', { static: true }) public sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  public displayedColumns = ['Type', 'Description', 'Actions'];
  userInfo: any;
  loggedInUserName: string;
  isRequestCompleted: boolean;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  submitted: boolean;
  type: string;
  description: any;
  globalSearchText: string;
  showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;
  fieldType: string;
  settingsData: any = [];
  multiLineTextEmails: any = [];
  savedMultiLineTextEmails: any = [];

  constructor(private sharedService: AppService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private media: MediaMatcher,
    private translate: TranslateService,
    private globalSearchService: GlobalSearchService,
    private dateAdapter: DateAdapter<any>) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cd.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  async ngOnInit() {
    try {
      await this.getSettingsData();
    }
    catch (e) {
    }

    this.translate.get(['HOME.itemsPerPageLabel'])
      .subscribe(data => {
        this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
      });
    this.translate.get(['HOME.selectedLanguageForDatePicker'])
      .subscribe(data => {
        this.dateAdapter.setLocale(data['HOME.selectedLanguageForDatePicker']);
      });

    this.subscribeToGlobalSearchServiceSearchTerm();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.globalSearchText = '';
  }

  save() {
    this.submitted = true;
    if (this.type) {
      this.updateSettingsData();
    } else {
      this.showStatusDialog('Please select type', null, false, 'settings-save-required-type');
      // this.showStatusDialog('Failed to save settings details', null, false, 'settings-save-fail');
    }
  }

  reset() {
    this.submitted = false;
    this.type = '';
    this.description = '';
    this.multiLineTextEmails = [];
    this.savedMultiLineTextEmails = [];
    this.fieldType = undefined;
  }

  editRecord(rowData: any) {
    this.type = rowData['Type'];
    this.fieldType = rowData['FieldType'];

    if (this.fieldType === 'calendar') {
      this.description = new Date(rowData['Description']);
    } else if (this.fieldType === 'text' || this.fieldType === 'textarea' || this.fieldType === 'email') {
      this.description = rowData['Description'];
    } else if (this.fieldType === 'multi-line-text-email') {
      this.multiLineTextEmails = rowData['Description'].split(',');
      this.savedMultiLineTextEmails = JSON.parse(JSON.stringify(this.multiLineTextEmails));
    } else if (this.fieldType === 'checkbox') {
      this.description = (rowData['Description'] === "TRUE") ? true : false;
    } else if (this.fieldType === 'radio') {
      this.description = (rowData['Description'] === "TRUE") ? "YES" : "NO";
    }
  }

  getSettingsData() {

    this.settingsData = [];

    let settingObject1: any = {};
    settingObject1['Type'] = 'Contact us';
    settingObject1['Description'] = 'contact-us@nynas.com';
    settingObject1['FieldType'] = 'email';
    this.settingsData.push(settingObject1);

    let settingObject2: any = {};
    settingObject2['Type'] = 'Banner message';
    settingObject2['Description'] = 'This is a test banner message';
    settingObject2['FieldType'] = 'text';
    this.settingsData.push(settingObject2);

    let settingObject3: any = {};
    settingObject3['Type'] = 'Add delivery site email';
    settingObject3['Description'] = 'test@abc.com,pqrs@xyz.com,test@123.com';
    settingObject3['FieldType'] = 'multi-line-text-email';
    this.settingsData.push(settingObject3);

    let settingObject4: any = {};
    settingObject4['Type'] = 'Update delivery site email';
    settingObject4['Description'] = 'abc@123.com';
    settingObject4['FieldType'] = 'multi-line-text-email';
    this.settingsData.push(settingObject4);

    let settingObject5: any = {};
    settingObject5['Type'] = 'Order full date';
    settingObject5['Description'] = '18-MAR-22';
    settingObject5['FieldType'] = 'calendar';
    this.settingsData.push(settingObject5);

    let settingObject6: any = {};
    settingObject6['Type'] = 'Allow new orders';
    settingObject6['Description'] = 'FALSE';
    settingObject6['FieldType'] = 'checkbox';
    this.settingsData.push(settingObject6);

    let settingObject7: any = {};
    settingObject7['Type'] = 'Display banner message';
    settingObject7['Description'] = 'TRUE';
    settingObject7['FieldType'] = 'radio';
    this.settingsData.push(settingObject7);

    let settingObject8: any = {};
    settingObject8['Type'] = 'Product documentation page note';
    settingObject8['Description'] = 'If you cannot find the product documentation you are looking for, please contact your Nynas representative or contact us on info.suomi@nynas.com and we will get back to you.';
    settingObject8['FieldType'] = 'textarea';
    this.settingsData.push(settingObject8);

    this.dataSource = new MatTableDataSource(this.settingsData);
    this.dataSource.sort = this.sort;
    this.isRequestCompleted = true;
  }

  showStatusDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,
      },
      height: 'auto',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.cd.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.cd.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      // this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }

  updateSettingsData() {
    let isAllDataValid: boolean = true;
    for (let settingsDataObject of this.settingsData) {
      if (this.type === settingsDataObject['Type']) {
        if (this.fieldType === 'calendar') {
          let datePipe = new DatePipe("en-US");
          settingsDataObject['Description'] = (this.description ? datePipe.transform(new Date(this.description), 'dd-MMM-yy').toLocaleUpperCase() : '');
        } else if (this.fieldType === 'multi-line-text-email') {
          let nonEmptySavedMultiLineTextEmails: any = [];
          for (let savedMultiLineTextEmail of this.savedMultiLineTextEmails) {
            if (savedMultiLineTextEmail) {
              nonEmptySavedMultiLineTextEmails.push(savedMultiLineTextEmail);
              isAllDataValid = isAllDataValid && !this.validateEmailId(savedMultiLineTextEmail);
            }
          }
          if (isAllDataValid) {
            settingsDataObject['Description'] = nonEmptySavedMultiLineTextEmails.toString();
          }
        } else if (this.fieldType === 'checkbox') {
          settingsDataObject['Description'] = (this.description ? "TRUE" : "FALSE");
        } else if (this.fieldType === 'radio') {
          settingsDataObject['Description'] = ((this.description === "YES") ? "TRUE" : "FALSE");
        } else if (this.fieldType === 'email') {
          isAllDataValid = !this.validateEmailId(this.description);
          if (isAllDataValid) {
            settingsDataObject['Description'] = this.description;
          }
        } else {
          settingsDataObject['Description'] = this.description;
        }
        break;
      }
    }
    if (isAllDataValid) {
      this.showStatusDialog('Settings details updated successfully', null, true, 'settings-save-success');
      this.reset();
    }
  }

  validateEmailId(emailId: string): boolean {
    let isEmailIdInvalid = false;
    if (emailId) {
      const regularExpressionForEmailId = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isEmailIdInvalid = isEmailIdInvalid || !(regularExpressionForEmailId.test(String(emailId).toLowerCase()));
    }
    return isEmailIdInvalid;
  }

  removeField(index: number) {
    if (index !== -1) {
      this.multiLineTextEmails.splice(index, 1);
      this.savedMultiLineTextEmails.splice(index, 1);
      if (this.savedMultiLineTextEmails && this.savedMultiLineTextEmails.length === 0) {
        this.addField(0);
      }
    }
  }

  addField(index: number) {
    if (index !== -1) {
      let emptyEmailId: string;
      this.multiLineTextEmails.push(emptyEmailId);
      this.savedMultiLineTextEmails.push(emptyEmailId);
    }
  }

  getYesNoForBooleanFields(value: string): string {
    if (value === "TRUE") {
      return "Yes";
    }
    return "No";
  }

  getTranslatedDate(dateInString: string): string {
    let translatedDate: string = '';
    let separator: string = '-';
    if (dateInString) {
      let dateArray = dateInString.split('-');
      if (dateArray && dateArray.length && dateArray[0] && dateArray[1] && dateArray[2]) {
        let date = dateArray[0] ? dateArray[0] : '';
        let monthShortName = dateArray[1] ? dateArray[1].toUpperCase() : '';
        this.translate.get(['HOME.' + monthShortName])
          .subscribe(data => {
            monthShortName = (data['HOME.' + monthShortName]).toUpperCase();
          });
        let year = dateArray[2] ? dateArray[2] : '';
        if (date && monthShortName && year) {
          translatedDate = date + separator + monthShortName + separator + year;
        }
      }

    }
    return translatedDate;
  }

}
