import { MediaMatcher } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateAdapter, MatDialog, MatSort, MatStepper, MatTableDataSource, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { Country } from 'src/app/user-management/country';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import { AddEditDeliverySiteService } from './add-edit-delivery-site.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YY',
  },
  display: {
    dateInput: 'DD-MMM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
}

@Component({
  selector: 'app-add-edit-delivery-site',
  templateUrl: './add-edit-delivery-site.component.html',
  styleUrls: ['./add-edit-delivery-site.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AddEditDeliverySiteComponent implements OnInit {

  isLinear = true;
  formInformationGroup: FormGroup;
  formAddressGroup: FormGroup;
  formNotificationGroup: FormGroup;
  formSummaryGroup: FormGroup;
  customerList: any[];
  countryCode: string;
  userInfo: any;
  customerId: string;
  customerName: string;
  isLoggedInUserPortalAdmin: boolean;
  isLoggedInUserAdmin: boolean;
  role: string;
  userId: string;
  loggedInUserName: string;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  incotermsList: any;
  countryList: Country[] = [];
  enableFormControl: string;
  isEditDeliverySite: boolean;
  dataSource: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayTable: boolean;
  displayedColumns = ["DepotName", "ProductName", "Status"];
  submittedFlagObject: any;
  dateOfToday: Date;
  @ViewChild('stepper', { static: true }) private stepper: MatStepper;
  selectedDeliverySite: any;
  showInvalidPostalCodeMessage: boolean;
  plantTypes: any;
  showInvalidExpectedDeliveryDateMessage: any;
  oldDeliverySite: string;
  selectedLanguageCode: string = 'EN';

  constructor(private fb: FormBuilder,
    private addEditDeliverySiteService: AddEditDeliverySiteService,
    private sharedService: AppService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService) {
    this.userInfo = this.sharedService.getLoginResponse();
    if (this.userInfo) {
      this.countryCode = (this.userInfo.CountryCode ? this.userInfo.CountryCode : '');
      this.customerId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
      this.customerName = (this.userInfo.CustomerName ? this.userInfo.CustomerName : '');
      this.isLoggedInUserPortalAdmin = (this.userInfo.RoleID === 'PA');
      this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
      this.role = (this.userInfo ? this.userInfo.RoleID : '');
      this.userId = (this.userInfo ? this.userInfo.UserID : '');
      this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }
    this.dateOfToday = this.sharedService.getCurrentDateTime();
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as {
        selectedDeliverySite: any
      };
      if (state && state.selectedDeliverySite) {
        this.selectedDeliverySite = state.selectedDeliverySite;
        this.isEditDeliverySite = true;
      }
    }
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  async ngOnInit(): Promise<void> {

    if (this.isEditDeliverySite) {
      this.createFormForEditDeliverySite();
    } else {
      this.createFormForAddDeliverySite();
    }

    this.initialiseSubmittedFlagObject();

    try {
      await this.getCustomerList();
    }
    catch (e) {
    }

    try {
      await this.getIncotermsList();
    }
    catch (e) {
    }

    try {
      await this.getCountryList();
    }
    catch (e) {
    }

    try {
      await this.getPlantTypes();
    }
    catch (e) {
    }

    if (this.isEditDeliverySite) {
      this.changedSelectedCustomer();
      this.loadSelectedDeliverySiteDetails();
    } else {
      this.populateDefaultDataForCreateNewDeliverySite();
    }

    // Translate date selected in date picker
    this.translate.get(['HOME.selectedLanguageForDatePicker'])
      .subscribe(data => {
        this.dateAdapter.setLocale(data['HOME.selectedLanguageForDatePicker']);
      });

    this.addValidationOnBillingReferenceField();  

    // Get language code for the country flag selected
    this.translate.get(['HOME.languageCode'])
      .subscribe(data => {
        this.selectedLanguageCode = (data['HOME.languageCode']);
      });
  }

  ngAfterViewInit() {
    if (this.isEditDeliverySite) {
      this.moveStepperToIndex(this.stepper._steps.length - 1); // Move stepper to Summary step on editing an existing delivery site
    }
  }

  async getCustomerList() {
    this.customerList = [];
    return new Promise<void>((resolve, reject) => {
      this.addEditDeliverySiteService.getCustomerList(this.countryCode).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            this.customerList = response["Customers"];

            // Set logged in user's customer name by default in the customer dropdown
            this.formInformationGroup.controls['CustomerId'].setValue(this.customerId);

            // Set selected customer's abbreviation in the delivery site textbox, for Finland only
            if (this.countryCode === 'FIN') {
              this.formInformationGroup.controls['DeliverySite'].setValue(this.getCustomerAbbreviationById(this.customerId));
            }

            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  async validateDeliverySiteName(formSummaryGroup: any, notifyHaulier: string, sendForecastReminder: string, expectedFirstDeliveryDate: string, contactNumber: string) {
    return new Promise<void>((resolve, reject) => {
      this.addEditDeliverySiteService.validateDeliverySiteName(formSummaryGroup['CustomerId'], formSummaryGroup['Country'], formSummaryGroup['DeliverySite']).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            this.formSummaryGroup.controls['Mode'].setValue('U');
            this.addEditDeliverySiteService.updateDeliverySite(this.formSummaryGroup.value, notifyHaulier, sendForecastReminder, expectedFirstDeliveryDate, contactNumber, this.oldDeliverySite)
              .subscribe(
                (response: any) => {
                  if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
                    if (this.getUpdatedFieldsName(notifyHaulier, sendForecastReminder) && this.getUpdatedFieldsName(notifyHaulier, sendForecastReminder).length) {
                      this.saveStatusDialogForUpdateDeliverySite(response, 'DS_UPDATE', contactNumber, this.getUpdatedFieldsName(notifyHaulier, sendForecastReminder), sendForecastReminder);
                    } else {
                      this.showSuccessStatusDialog('Delivery Site Details Saved Successfully', null, true, 'add-delivery-site-save-success');
                    }
                    this.checkIfBillingReferenceNumberOrSendForecastReminderFlagAreUpdated(response, contactNumber, sendForecastReminder);
                  } else {
                    this.showErrorStatusDialog('Something went wrong. Please contact your Nynas representative.', '', false, 'SomethingwentwrongPleasecontactyourNynasrepresentative');
                  }
                });
            resolve();
          } else if (response['ResponseCode'] === '201' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'DELIVERY SITE WITH SAME NAME ALREADY EXISTS.') {
            this.showErrorStatusDialog('Delivery site already exists', '', false, 'add-delivery-site-save-fail-already-present');
            reject();
          } else {
            this.showErrorStatusDialog('Something went wrong. Please contact your Nynas representative.', '', false, 'SomethingwentwrongPleasecontactyourNynasrepresentative');
            reject();
          }
        });
    })
  }

  getIncotermsList() {
    this.incotermsList = [];
    return new Promise<void>((resolve, reject) => {
      this.addEditDeliverySiteService.fetchFreightHandlingDetails(this.countryCode).subscribe(
        response => {
          if (response["ResponseCode"] === "200" && response['FetchFreightHandlingDetailsCollection'] && response['FetchFreightHandlingDetailsCollection'].length) {
            this.incotermsList = response['FetchFreightHandlingDetailsCollection'];
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  async getCountryList() {
    this.countryList = [];
    return new Promise<void>((resolve, reject) => {
      this.addEditDeliverySiteService.getCountries().subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            response["Countries"].forEach((country: any) => {
              var countryCode: number = 0;
              if (country["CountryID"] === 'FIN') {
                countryCode = 358;
              } else if (country["CountryID"] === 'SWE') {
                countryCode = 46;
              } else if (country["CountryID"] === 'NOR') {
                countryCode = 47;
              } else if (country["CountryID"] === 'EST') {
                countryCode = 372;
              } else if (country["CountryID"] === 'DNK') {
                countryCode = 45;
              } else if (country["CountryID"] === 'GBR') {
                countryCode = 44;
              }
              this.countryList.push(new Country(country["CountryID"], country["CountryName"], countryCode));
            });
            this.updateCountryNameByCountryCode(this.countryCode);
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  createFormForAddDeliverySite() {
    this.formInformationGroup = this.fb.group({
      CustomerId: ['', Validators.required], // required
      DeliverySite: ['', [Validators.required, Validators.maxLength(32)]], // required
      BillingReference: ['', [Validators.nullValidator, Validators.maxLength(40)]],
      ExpectedFirstDeliveryDate: ['', this.dateValidator],
      PlantType: ['', Validators.nullValidator],
      Incoterms: ['', Validators.nullValidator],
      IncotermsDescription: ['', Validators.nullValidator],
      LoadingDepot: ['', Validators.nullValidator],
      JdeAddressNumber: ['', Validators.nullValidator],
      DepotAddressNumber: ['', Validators.nullValidator]
    }, {
      // validator: this.validateExpectedFirstDeliveryDate
    });

    this.formAddressGroup = this.fb.group({
      PrimaryContactName: ['', [Validators.nullValidator, Validators.maxLength(40)]],
      PrimaryContactEmailAddress: ['', [Validators.required, Validators.email, Validators.maxLength(256)]], // required
      PrimaryContactCountryCode: ['', Validators.nullValidator],
      ContactNumber: ['', [Validators.nullValidator, Validators.pattern('^[0-9]+$'), Validators.maxLength(20)]],
      AddressLine1: ['', [Validators.required, Validators.maxLength(40)]],
      AddressLine2: ['', [Validators.maxLength(40)]],
      DeliveryPostalCode: ['', [Validators.required, Validators.maxLength(12)]], // required
      City: ['', [Validators.required, Validators.maxLength(25)]], // required
      Country: ['', Validators.required], // required
      CountryName: ['', Validators.nullValidator],
      GPSCoordinates: ['', Validators.nullValidator]
    },
      // { validator: this.validateAddressLine1AndGPSCoordinatesFields }  // required
    );

    this.formNotificationGroup = this.fb.group({
      NotifyHaulier: ['', Validators.nullValidator],
      HaulierEmailId: ['', [Validators.nullValidator, Validators.email, Validators.maxLength(256)]],
      HaulierName: ['', [Validators.nullValidator, Validators.maxLength(40)]],
      CustomerTextInfo: ['', [Validators.maxLength(250)]],
      InternalTextInfo: ['', [Validators.maxLength(2000)]],
      SendForecastReminder: ['', Validators.nullValidator]
    },
      { validator: this.validateHaulierEmailId(this) }
    );

    this.formSummaryGroup = this.fb.group({
      CustomerId: ['', Validators.required],
      CustomerName: ['', Validators.nullValidator],
      DeliverySite: ['', [Validators.required, Validators.maxLength(32)]],
      BillingReference: ['', [Validators.nullValidator, Validators.maxLength(40)]],
      ExpectedFirstDeliveryDate: ['', this.dateValidator],
      PlantType: ['', Validators.nullValidator],
      Incoterms: ['', Validators.nullValidator],
      IncotermsDescription: ['', Validators.nullValidator],
      LoadingDepot: ['', Validators.nullValidator],
      JdeAddressNumber: ['', Validators.nullValidator],
      DepotAddressNumber: ['', Validators.nullValidator],
      PrimaryContactName: ['', [Validators.nullValidator, Validators.maxLength(40)]],
      PrimaryContactEmailAddress: ['', [Validators.required, Validators.email, Validators.maxLength(256)]],
      PrimaryContactCountryCode: ['', Validators.nullValidator],
      ContactNumber: ['', [Validators.nullValidator, Validators.pattern('^[0-9]+$'), Validators.maxLength(20)]],
      AddressLine1: ['', [Validators.required, Validators.maxLength(40)]],
      AddressLine2: ['', [Validators.maxLength(40)]],
      DeliveryPostalCode: ['', [Validators.required, Validators.maxLength(12)]],
      City: ['', [Validators.required, Validators.maxLength(25)]],
      Country: ['', Validators.required],
      CountryName: ['', Validators.nullValidator],
      GPSCoordinates: ['', Validators.nullValidator],
      NotifyHaulier: ['', Validators.nullValidator],
      HaulierEmailId: ['', [Validators.nullValidator, Validators.email, Validators.maxLength(256)]],
      HaulierName: ['', [Validators.nullValidator, Validators.maxLength(40)]],
      CustomerTextInfo: ['', [Validators.maxLength(250)]],
      InternalTextInfo: ['', [Validators.maxLength(2000)]],
      SendForecastReminder: ['', Validators.nullValidator],
      CreatedBy: ['', Validators.nullValidator],
      UpdatedBy: ['', Validators.nullValidator],
      Mode: ['', Validators.nullValidator]
    }, {
      validator: [
        // this.validateAddressLine1AndGPSCoordinatesFields, 
        this.validateHaulierEmailId(this),
        // this.validateExpectedFirstDeliveryDate
      ]
    });
  }

  createFormForEditDeliverySite() {
    this.formInformationGroup = this.fb.group({
      CustomerId: ['', Validators.nullValidator], // required
      DeliverySite: ['', [Validators.nullValidator]], // required
      BillingReference: ['', Validators.nullValidator],
      ExpectedFirstDeliveryDate: ['', Validators.nullValidator],
      PlantType: ['', Validators.nullValidator],
      Incoterms: ['', Validators.nullValidator],
      IncotermsDescription: ['', Validators.nullValidator],
      LoadingDepot: ['', Validators.nullValidator],
      JdeAddressNumber: ['', Validators.nullValidator],
      DepotAddressNumber: ['', Validators.nullValidator]
    },
      // { validator: this.validateExpectedFirstDeliveryDate }
    );

    this.formAddressGroup = this.fb.group({
      PrimaryContactName: ['', Validators.nullValidator],
      PrimaryContactEmailAddress: ['', [Validators.nullValidator, Validators.email]], // required
      PrimaryContactCountryCode: ['', Validators.nullValidator],
      ContactNumber: ['', Validators.nullValidator],
      AddressLine1: ['', [Validators.maxLength(200)]],
      AddressLine2: ['', [Validators.maxLength(200)]],
      DeliveryPostalCode: ['', Validators.nullValidator], // required
      City: ['', Validators.nullValidator], // required
      Country: ['', Validators.nullValidator], // required
      CountryName: ['', Validators.nullValidator],
      GPSCoordinates: ['', Validators.nullValidator]
    },
      // { validator: this.validateAddressLine1AndGPSCoordinatesFields }  // required
    );

    this.formNotificationGroup = this.fb.group({
      NotifyHaulier: ['', Validators.nullValidator],
      HaulierEmailId: ['', [Validators.nullValidator]],
      HaulierName: ['', Validators.nullValidator],
      CustomerTextInfo: ['', [Validators.maxLength(250)]],
      InternalTextInfo: ['', [Validators.maxLength(2000)]],
      SendForecastReminder: ['', Validators.nullValidator]
    },
      // { validator: this.validateHaulierEmailId(this) }
    );

    this.formSummaryGroup = this.fb.group({
      CustomerId: ['', Validators.required],
      CustomerName: ['', Validators.nullValidator],
      DeliverySite: ['', [Validators.required]],
      BillingReference: ['', [Validators.nullValidator, Validators.maxLength(40)]],
      ExpectedFirstDeliveryDate: ['', Validators.nullValidator],
      PlantType: ['', Validators.nullValidator],
      Incoterms: ['', Validators.nullValidator],
      IncotermsDescription: ['', Validators.nullValidator],
      LoadingDepot: ['', Validators.nullValidator],
      JdeAddressNumber: ['', Validators.nullValidator],
      DepotAddressNumber: ['', Validators.nullValidator],
      PrimaryContactName: ['', [Validators.nullValidator, Validators.maxLength(40)]],
      PrimaryContactEmailAddress: ['', [Validators.required, Validators.email, Validators.maxLength(256)]],
      PrimaryContactCountryCode: ['', Validators.nullValidator],
      ContactNumber: ['', [Validators.nullValidator, Validators.pattern('^[0-9]+$'), Validators.maxLength(20)]],
      AddressLine1: ['', [Validators.required, Validators.maxLength(40)]],
      AddressLine2: ['', [Validators.maxLength(40)]],
      DeliveryPostalCode: ['', [Validators.required, Validators.maxLength(12)]],
      City: ['', [Validators.required, Validators.maxLength(25)]],
      Country: ['', Validators.required],
      CountryName: ['', Validators.nullValidator],
      GPSCoordinates: ['', Validators.nullValidator],
      NotifyHaulier: ['', Validators.nullValidator],
      HaulierEmailId: ['', [Validators.nullValidator, Validators.email, Validators.maxLength(256)]],
      HaulierName: ['', [Validators.nullValidator, Validators.maxLength(40)]],
      CustomerTextInfo: ['', [Validators.maxLength(250)]],
      InternalTextInfo: ['', [Validators.maxLength(2000)]],
      SendForecastReminder: ['', Validators.nullValidator],
      CreatedBy: ['', Validators.nullValidator],
      UpdatedBy: ['', Validators.nullValidator],
      Mode: ['', Validators.nullValidator]
    }, {
      validator: [
        // this.validateAddressLine1AndGPSCoordinatesFields, 
        this.validateHaulierEmailId(this),
        // this.validateExpectedFirstDeliveryDate
      ]
    });
  }

  updateSummaryModel(currentStepName: string) {

    // Update Summary form model with data filled in respective step forms
    if (currentStepName === "Information") {
      // Information form
      this.formSummaryGroup.controls['CustomerId'].setValue(this.formInformationGroup.controls['CustomerId'].value);
      this.formSummaryGroup.controls['DeliverySite'].setValue(this.formInformationGroup.controls['DeliverySite'].value);
      this.formSummaryGroup.controls['BillingReference'].setValue(this.formInformationGroup.controls['BillingReference'].value);
      this.formSummaryGroup.controls['ExpectedFirstDeliveryDate'].setValue(this.formInformationGroup.controls['ExpectedFirstDeliveryDate'].value);
      
      // Commented below code, kept for future reference
      /* if (this.formInformationGroup.controls['ExpectedFirstDeliveryDate'].value && this.datePipe.transform(this.formInformationGroup.controls['ExpectedFirstDeliveryDate'].value, 'dd-MMM-yy', null, 'en-US')) {
        this.formSummaryGroup.controls['ExpectedFirstDeliveryDate'].setValue(this.datePipe.transform(this.formInformationGroup.controls['ExpectedFirstDeliveryDate'].value, 'dd-MMM-yy', null, 'en-US').toUpperCase());
      } */

      this.formSummaryGroup.controls['PlantType'].setValue(this.formInformationGroup.controls['PlantType'].value);
      this.formSummaryGroup.controls['Incoterms'].setValue(this.formInformationGroup.controls['Incoterms'].value);
      this.formSummaryGroup.controls['LoadingDepot'].setValue(this.formInformationGroup.controls['LoadingDepot'].value);
    }

    if (currentStepName === "Address") {
    // Address form
    this.formSummaryGroup.controls['PrimaryContactName'].setValue(this.formAddressGroup.controls['PrimaryContactName'].value);
    this.formSummaryGroup.controls['PrimaryContactEmailAddress'].setValue(this.formAddressGroup.controls['PrimaryContactEmailAddress'].value);
    this.formSummaryGroup.controls['PrimaryContactCountryCode'].setValue(this.formAddressGroup.controls['PrimaryContactCountryCode'].value);
    this.formSummaryGroup.controls['ContactNumber'].setValue(this.formAddressGroup.controls['ContactNumber'].value);
    this.formSummaryGroup.controls['AddressLine1'].setValue(this.formAddressGroup.controls['AddressLine1'].value);
    this.formSummaryGroup.controls['AddressLine2'].setValue(this.formAddressGroup.controls['AddressLine2'].value);
    this.formSummaryGroup.controls['DeliveryPostalCode'].setValue(this.formAddressGroup.controls['DeliveryPostalCode'].value);
    this.formSummaryGroup.controls['City'].setValue(this.formAddressGroup.controls['City'].value);
    this.formSummaryGroup.controls['Country'].setValue(this.formAddressGroup.controls['Country'].value);
    this.formSummaryGroup.controls['GPSCoordinates'].setValue(this.formAddressGroup.controls['GPSCoordinates'].value);
    }

    if (currentStepName === "Notification") {
    // Notification form
    this.formSummaryGroup.controls['NotifyHaulier'].setValue(this.formNotificationGroup.controls['NotifyHaulier'].value);
    this.formSummaryGroup.controls['HaulierEmailId'].setValue(this.formNotificationGroup.controls['HaulierEmailId'].value);
    this.formSummaryGroup.controls['HaulierName'].setValue(this.formNotificationGroup.controls['HaulierName'].value);
    this.formSummaryGroup.controls['CustomerTextInfo'].setValue(this.formNotificationGroup.controls['CustomerTextInfo'].value);
    this.formSummaryGroup.controls['InternalTextInfo'].setValue(this.formNotificationGroup.controls['InternalTextInfo'].value);
    this.formSummaryGroup.controls['SendForecastReminder'].setValue(this.formNotificationGroup.controls['SendForecastReminder'].value);
    }

    if (currentStepName === "Summary") {

      // Information form
      this.formInformationGroup.controls['CustomerId'].setValue(this.formSummaryGroup.controls['CustomerId'].value);
      this.formInformationGroup.controls['DeliverySite'].setValue(this.formSummaryGroup.controls['DeliverySite'].value);
      this.formInformationGroup.controls['BillingReference'].setValue(this.formSummaryGroup.controls['BillingReference'].value);
      this.formInformationGroup.controls['PlantType'].setValue(this.formSummaryGroup.controls['PlantType'].value);
      this.formInformationGroup.controls['Incoterms'].setValue(this.formSummaryGroup.controls['Incoterms'].value);
      this.formInformationGroup.controls['LoadingDepot'].setValue(this.formSummaryGroup.controls['LoadingDepot'].value);
      this.formInformationGroup.controls['ExpectedFirstDeliveryDate'].setValue(this.formSummaryGroup.controls['ExpectedFirstDeliveryDate'].value);

      // Address form
      this.formAddressGroup.controls['PrimaryContactName'].setValue(this.formSummaryGroup.controls['PrimaryContactName'].value);
      this.formAddressGroup.controls['PrimaryContactEmailAddress'].setValue(this.formSummaryGroup.controls['PrimaryContactEmailAddress'].value);
      this.formAddressGroup.controls['PrimaryContactCountryCode'].setValue(this.formSummaryGroup.controls['PrimaryContactCountryCode'].value);
      this.formAddressGroup.controls['ContactNumber'].setValue(this.formSummaryGroup.controls['ContactNumber'].value);
      this.formAddressGroup.controls['AddressLine1'].setValue(this.formSummaryGroup.controls['AddressLine1'].value);
      this.formAddressGroup.controls['AddressLine2'].setValue(this.formSummaryGroup.controls['AddressLine2'].value);
      this.formAddressGroup.controls['DeliveryPostalCode'].setValue(this.formSummaryGroup.controls['DeliveryPostalCode'].value);
      this.formAddressGroup.controls['City'].setValue(this.formSummaryGroup.controls['City'].value);
      this.formAddressGroup.controls['Country'].setValue(this.formSummaryGroup.controls['Country'].value);
      this.formAddressGroup.controls['GPSCoordinates'].setValue(this.formSummaryGroup.controls['GPSCoordinates'].value);

      // Notification form
      this.formNotificationGroup.controls['NotifyHaulier'].setValue(this.formSummaryGroup.controls['NotifyHaulier'].value);
      this.formNotificationGroup.controls['HaulierEmailId'].setValue(this.formSummaryGroup.controls['HaulierEmailId'].value);
      this.formNotificationGroup.controls['HaulierName'].setValue(this.formSummaryGroup.controls['HaulierName'].value);
      this.formNotificationGroup.controls['CustomerTextInfo'].setValue(this.formSummaryGroup.controls['CustomerTextInfo'].value);
      this.formNotificationGroup.controls['InternalTextInfo'].setValue(this.formSummaryGroup.controls['InternalTextInfo'].value);
      this.formNotificationGroup.controls['SendForecastReminder'].setValue(this.formSummaryGroup.controls['SendForecastReminder'].value);
    }
  }

  editField(controlName: string) {
    if (this.enableFormControl === 'ExpectedFirstDeliveryDate' && this.formSummaryGroup.controls['ExpectedFirstDeliveryDate'].value && this.datePipe.transform(this.formSummaryGroup.controls['ExpectedFirstDeliveryDate'].value, 'dd-MMM-yy', null, 'en-US')) {
      this.formSummaryGroup.controls['ExpectedFirstDeliveryDate'].setValue(this.datePipe.transform(this.formSummaryGroup.controls['ExpectedFirstDeliveryDate'].value, 'dd-MMM-yy', null, 'en-US').toUpperCase());
    }
    this.enableFormControl = controlName;
  }

  getCustomerNameById(customerId: string): string {
    for (var customer of this.customerList) {
      if (customer && customer.CustomerID === customerId) {
        return customer.CustomerName;
      }
    }
    return null;
  }

  getCountryPhoneCodeByCountryId(countryId: string): string {
    var countryCode: string = "";
    if (countryId === 'FIN') {
      countryCode = "+358";
    } else if (countryId === 'SWE') {
      countryCode = "+46";
    } else if (countryId === 'NOR') {
      countryCode = "+47";
    } else if (countryId === 'EST') {
      countryCode = "+372";
    } else if (countryId === 'DNK') {
      countryCode = "+45";
    } else if (countryId === 'GBR') {
      countryCode = "+44";
    }
    return countryCode;
  }

  getCountryNameById(countryId: string): string {
    let countryName: string = '';
    for (let country of this.countryList) {
      if (country && (country['id'] === countryId) && country['name']) {
        this.translate.get(['HOME.' + country.name])
          .subscribe(data => {
            countryName = (data['HOME.' + country.name]);
          });
        break;
      }
    }
    return countryName;
  }

  getYesNoForBooleanFields(value: string): string {
    if (value) {
      return "Yes";
    }
    return "No";
  }

  changedSelectedCustomer() {
    let selectedCustomerId: string = this.formInformationGroup.controls['CustomerId'].value;
    if (this.countryCode === 'FIN') {
      // Get customer abbreviation
      if (selectedCustomerId) {
        let customerNameAbbreviation = '';
        for (let customer of this.customerList) {
          if (customer['CustomerID'] === selectedCustomerId) {
            customerNameAbbreviation = customer['CustomerName_Prefix'];
            break;
          }
        }
        this.formInformationGroup.controls['DeliverySite'].setValue(customerNameAbbreviation);
      } else {
        this.formInformationGroup.controls['DeliverySite'].setValue('');
      }
    }
  }

  getSelectedProducts(): any {
    let selectedProducts: any = [];
    if (this.dataSource && this.dataSource.data && this.dataSource.data.length) {
      for (var product of this.dataSource.data) {
        if (product && product['isStatusActive']) {
          selectedProducts.push(product);
        }
      }
    }
    return selectedProducts;
  }

  navigateToNextPage(currentPageName: string) {
    if (currentPageName === "Information") {
      this.submittedFlagObject['Information'] = true;
      this.updateSummaryModel("Information");
    } else if (currentPageName === "Address") {
      this.submittedFlagObject['Address'] = true;
      this.updateSummaryModel("Address");
    } else if (currentPageName === "Notification") {
      this.submittedFlagObject['Notification'] = true;
      this.updateSummaryModel("Notification");
    } else if (currentPageName === "Summary") {
      this.submittedFlagObject['Summary'] = true;
      this.updateSummaryModel("Summary");
    }
  }

  navigateToPreviousPage(currentPageName: string) {
    if (currentPageName === "Address") {
      this.updateSummaryModel("Address");
    } else if (currentPageName === "Notification") {
      this.updateSummaryModel("Notification");
    } else if (currentPageName === "Summary") {
      this.updateSummaryModel("Summary");
    }
  }

  initialiseSubmittedFlagObject() {
    this.submittedFlagObject = {};
    this.submittedFlagObject['Information'] = false;
    this.submittedFlagObject['Address'] = false;
    this.submittedFlagObject['Notification'] = false;
    this.submittedFlagObject['Summary'] = false;
  }

  getCustomerAbbreviationById(customerID: string): string {
    let customerNameAbbreviation = '';
    for (let customer of this.customerList) {
      if (customer['CustomerID'] === customerID) {
        customerNameAbbreviation = customer['CustomerName_Prefix'];
        break;
      }
    }
    return customerNameAbbreviation;
  }

  validateAddressLine1AndGPSCoordinatesFields(formGroup: any): any {
    let addressLine1Field = formGroup.controls['AddressLine1'].value;
    let gpsCoordinatesField = formGroup.controls['GPSCoordinates'].value;
    return (!addressLine1Field && !gpsCoordinatesField) ? { addressLine1AndGPSCoordinatesAreEmpty: true } : null;
  }

  // Commented below code, kept for future reference
  /* validateHaulierEmailId(formGroup: any): any {
    let notifyHaulier = formGroup.controls['NotifyHaulier'].value;
    let haulierEmailId = formGroup.controls['HaulierEmailId'].value;
    return (notifyHaulier && !haulierEmailId) ? { haulierEmailIdIsEmpty: true } : null;
  } */

  validateHaulierEmailId(component: AddEditDeliverySiteComponent): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let notifyHaulier = control.get('NotifyHaulier').value;
      let haulierEmailId = control.get('HaulierEmailId').value;
      return ((notifyHaulier || (component.countryCode && component.countryCode === 'FIN')) && !haulierEmailId) ? { haulierEmailIdIsEmpty: true } : null;
    };
  }

  validateExpectedFirstDeliveryDateNew(): any {
    this.showInvalidExpectedDeliveryDateMessage = false;
    if (this.formSummaryGroup && this.formSummaryGroup.controls['ExpectedFirstDeliveryDate'] && this.formSummaryGroup.controls['ExpectedFirstDeliveryDate'].value) {
      let expectedFirstDeliveryDate = new Date(this.formSummaryGroup.controls['ExpectedFirstDeliveryDate'].value);
      let dateOfTodayWithZeroHours = new Date();
      dateOfTodayWithZeroHours.setHours(0, 0, 0, 0);
      console.log(((expectedFirstDeliveryDate && (expectedFirstDeliveryDate.getTime() < dateOfTodayWithZeroHours.getTime())) ? { expectedFirstDeliveryDateIsLessThanToday: true } : undefined));
      this.showInvalidExpectedDeliveryDateMessage = ((expectedFirstDeliveryDate && (expectedFirstDeliveryDate.getTime() < dateOfTodayWithZeroHours.getTime())) ? true : undefined);
      return null;
    }
    return null;
  }

  moveStepperToIndex(index: number) {
    for (let stepperIndex = 0; stepperIndex <= index; stepperIndex++) {
      this.stepper.selectedIndex = stepperIndex;
    }
  }

  async saveDeliverySiteDetails() {
    this.navigateToNextPage('Summary');
    if (this.formSummaryGroup.valid) {

      // Set values for fields not present in UI form
      if (this.formSummaryGroup.value['CustomerId']) {
        this.formSummaryGroup.controls['CustomerName'].setValue(this.getCustomerNameById(this.formSummaryGroup.value['CustomerId']));
      }
      this.formSummaryGroup.controls['CreatedBy'].setValue(this.loggedInUserName);
      this.formSummaryGroup.controls['UpdatedBy'].setValue(this.loggedInUserName);

      let notifyHaulier: string = (this.formSummaryGroup.value['NotifyHaulier'] ? 'TRUE' : 'FALSE');
      let sendForecastReminder: string = (this.formSummaryGroup.value['SendForecastReminder'] ? 'TRUE' : 'FALSE');
      var datePipe = new DatePipe("en-US");
      let expectedFirstDeliveryDate: string = (this.formSummaryGroup.value['ExpectedFirstDeliveryDate'] ? datePipe.transform(new Date(this.formSummaryGroup.value['ExpectedFirstDeliveryDate']), 'yyyy-MM-dd').toLocaleUpperCase() : '');

      // Set value for Plant type as blank while saving a delivery site
      /* let plantType: string;
      if (this.formSummaryGroup.value['PlantType'] === 'BLK') {
        plantType = '';
      } else {
        plantType = this.formSummaryGroup.value['PlantType'];
      } */

      // Save phone number with country code
      let contactNumber: string = '';
      if (this.formSummaryGroup.value['ContactNumber']) {
        contactNumber = (this.formSummaryGroup.value['PrimaryContactCountryCode'] + this.getCountryPhoneCodeByCountryId(this.formSummaryGroup.value['PrimaryContactCountryCode']) + ' ' + this.formSummaryGroup.value['ContactNumber']); // example, for Sweden, it will be: SWE+44 22222
      }

      /* console.log("contactNumber :: " + contactNumber);

      let response: any = {
        "ResponseCode": "200",
        "ResponseStatus": "Success",
        "Description": " Delivery Site: created successfully with JDEAddressNumber: 318481"
      };
      let jdeAddressNumber = this.getJDEAddressNumberFromResponse(response);
      this.showStatusDialog('Delivery Site creation requested successfully with Address number: XXXXXXXX', jdeAddressNumber, true, 'new-delivery-site-creation-success'); */

      // this.showStatusDialog('Delivery site created in portal but JDE address number is not being assigned from JDE end', null, false, 'delivery-site-jde-side-failure-heading');

      // Check if it is edit user or create new user
      if (this.selectedDeliverySite) {

        // Validate delivery site name, only if it is changed by user
        if (this.oldDeliverySite !== this.formSummaryGroup.value['DeliverySite']) {
          try {
            await this.validateDeliverySiteName(this.formSummaryGroup.value, notifyHaulier, sendForecastReminder, expectedFirstDeliveryDate, contactNumber);
          }
          catch (e) {
          }
        } else {
          this.formSummaryGroup.controls['Mode'].setValue('U');
          this.addEditDeliverySiteService.updateDeliverySite(this.formSummaryGroup.value, notifyHaulier, sendForecastReminder, expectedFirstDeliveryDate, contactNumber, this.oldDeliverySite)
            .subscribe(
              (response: any) => {
                if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
                  if (this.getUpdatedFieldsName(notifyHaulier, sendForecastReminder) && this.getUpdatedFieldsName(notifyHaulier, sendForecastReminder).length) {
                    this.saveStatusDialogForUpdateDeliverySite(response, 'DS_UPDATE', contactNumber, this.getUpdatedFieldsName(notifyHaulier, sendForecastReminder), sendForecastReminder);
                  } else {
                    this.showSuccessStatusDialog('Delivery Site Details Saved Successfully', null, true, 'add-delivery-site-save-success');
                  }
                  this.checkIfBillingReferenceNumberOrSendForecastReminderFlagAreUpdated(response, contactNumber, sendForecastReminder);
                } else {
                  this.showErrorStatusDialog('Something went wrong. Please contact your Nynas representative.', '', false, 'SomethingwentwrongPleasecontactyourNynasrepresentative');
                }
              });
        }
      } else {
        this.formSummaryGroup.controls['Mode'].setValue('N');
        this.addEditDeliverySiteService.addDeliverySite(this.formSummaryGroup.value, notifyHaulier, sendForecastReminder, expectedFirstDeliveryDate, contactNumber)
          /* .subscribe(
            (response: any) => {
              if (response) {
                if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
                  let jdeAddressNumber = this.getJDEAddressNumberFromResponse(response);
                  this.showStatusDialog('Delivery Site creation requested successfully with Address number: XXXXXXXX', jdeAddressNumber, true, 'new-delivery-site-creation-success');
                } else if (response['ResponseCode'] === '201' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'DELIVERY SITE ALREADY PRESENT') {
                  this.showStatusDialog('Delivery site already exists', '', false, 'add-delivery-site-save-fail-already-present');
                } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
                  this.showStatusDialog('Failed to Save Delivery Site Details', '', false, 'add-delivery-site-save-fail');
                } else {
                  this.showStatusDialog('Failed to Save Delivery Site Details', null, false, 'add-delivery-site-save-fail');
                }
              } else {
                this.showStatusDialog('Failed to Save Delivery Site Details', null, false, 'add-delivery-site-save-fail');
              }
            }); */
          .subscribe(
            (response: any) => {
              this.saveStatusDialogForAddDeliverySite(response, 'DS_CREATE', contactNumber, '', sendForecastReminder);
            });
      }

    } else {
      this.showErrorStatusDialog('There are items that require your attention, Please complete all fields marked with *', 'complete-required-fields', false, 'complete-required-fields');
    }
  }

  saveStatusDialogForAddDeliverySite(response: any, emailNotificationKey: string, contactNumber: string, fieldsChanged: string, sendReminderFlag: string) {
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        let jdeAddressNumber: any = this.getJDEAddressNumberFromResponse(response);

        if (jdeAddressNumber && !isNaN(jdeAddressNumber) && +jdeAddressNumber > 0) {
          // Send email to depot managers to update depot address number for Finland only
          if (this.countryCode === 'FIN') {
            this.sendEmailNotificationForAddDeliverySite(emailNotificationKey, contactNumber, fieldsChanged, sendReminderFlag, jdeAddressNumber);
          } else {
            this.showSuccessStatusDialog('Delivery Site creation requested successfully with Address number: XXXXXXXX', jdeAddressNumber, true, 'new-delivery-site-creation-success');
          }
        } else {
          this.showErrorStatusDialog('Something went wrong. Please contact your Nynas representative.', '', false, 'SomethingwentwrongPleasecontactyourNynasrepresentative');
        }

      } else if (response['ResponseCode'] === '201' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'DELIVERY SITE ALREADY PRESENT') {
        this.showErrorStatusDialog('Delivery site already exists', '', false, 'add-delivery-site-save-fail-already-present');
      } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.showErrorStatusDialog('Something went wrong. Please contact your Nynas representative.', '', false, 'SomethingwentwrongPleasecontactyourNynasrepresentative');
      } else {
        this.showErrorStatusDialog('Something went wrong. Please contact your Nynas representative.', '', false, 'SomethingwentwrongPleasecontactyourNynasrepresentative');
      }
    } else {
      this.showErrorStatusDialog('Something went wrong. Please contact your Nynas representative.', '', false, 'SomethingwentwrongPleasecontactyourNynasrepresentative');
    }
  }

  saveStatusDialogForUpdateDeliverySite(response: any, emailNotificationKey: string, contactNumber: string, fieldsChanged: string, sendReminderFlag: string) {
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        this.sendEmailNotificationForUpdateDeliverySite(emailNotificationKey, contactNumber, fieldsChanged, sendReminderFlag, this.formSummaryGroup.value['JdeAddressNumber']);
      } else {
        this.showErrorStatusDialog('Something went wrong. Please contact your Nynas representative.', '', false, 'SomethingwentwrongPleasecontactyourNynasrepresentative');
      }
    } else {
      this.showErrorStatusDialog('Something went wrong. Please contact your Nynas representative.', '', false, 'SomethingwentwrongPleasecontactyourNynasrepresentative');
    }
  }

  sendEmailNotificationForAddDeliverySite(key: string, contactNumber: string, fieldsChanged: string, sendReminderFlag: string, jdeAddressNumber: string): void {
    this.addEditDeliverySiteService.emailService(key, this.getCountryNameById(this.countryCode), this.formSummaryGroup.value, this.getContactNumberWithoutCountryCode(contactNumber), fieldsChanged, sendReminderFlag, jdeAddressNumber)
      .subscribe(
        (response: any) => {
          if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            this.dialog.closeAll();
            this.showSuccessStatusDialog('Delivery Site creation requested successfully with Address number: XXXXXXXX', jdeAddressNumber, true, 'new-delivery-site-creation-success');
          } else {
            this.showErrorStatusDialog('Something went wrong. Please contact your Nynas representative.', '', false, 'SomethingwentwrongPleasecontactyourNynasrepresentative');
          }
        });
  }

  sendEmailNotificationForUpdateDeliverySite(key: string, contactNumber: string, fieldsChanged: string, sendReminderFlag: string, jdeAddressNumber: string): void {
    this.addEditDeliverySiteService.emailService(key, this.getCountryNameById(this.countryCode), this.formSummaryGroup.value, this.getContactNumberWithoutCountryCode(contactNumber), fieldsChanged, sendReminderFlag, jdeAddressNumber)
      .subscribe(
        (response: any) => {
          if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            this.dialog.closeAll();
            this.showSuccessStatusDialog('Delivery Site Details Saved Successfully', null, true, 'add-delivery-site-save-success');
          } else {
            this.showErrorStatusDialog('Something went wrong. Please contact your Nynas representative.', '', false, 'SomethingwentwrongPleasecontactyourNynasrepresentative');
          }
        });
  }
  
  showErrorStatusDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,
      },
      height: 'auto',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  showSuccessStatusDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    const dialogRef = this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,
      },
      height: 'auto',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.resetForm();
      }
    });
  }

  updateCountryNameByCountryCode(countryCode: string): void {
    let countryName: string;
    for (let country of this.countryList) {
      if (country && country['id'] === countryCode) {
        countryName = country['name'];
        break;
      }
    }
    this.formAddressGroup.controls['CountryName'].setValue(countryName);
    this.formSummaryGroup.controls['CountryName'].setValue(countryName);
  }

  loadSelectedDeliverySiteDetails() {

    // Use old delivery site name, for update of delivery site name, since delivery site name is a primary key
    this.oldDeliverySite = this.selectedDeliverySite['DeliverySite'] ? this.selectedDeliverySite['DeliverySite'] : '';

    // Information form data load
    this.formInformationGroup.controls['CustomerId'].setValue(this.selectedDeliverySite['CustomerId']);
    this.formInformationGroup.controls['DeliverySite'].setValue(this.selectedDeliverySite['DeliverySite']);
    this.formInformationGroup.controls['BillingReference'].setValue(this.selectedDeliverySite['BillingReference']);
    this.formInformationGroup.controls['ExpectedFirstDeliveryDate'].setValue(this.selectedDeliverySite['ExpectedFirstDeliveryDate']);
    
    /* if (!this.selectedDeliverySite['MobilePlant'] || this.selectedDeliverySite['MobilePlant'] === '') {
      this.formInformationGroup.controls['PlantType'].setValue('BLK');
    } else {
      this.formInformationGroup.controls['PlantType'].setValue(this.selectedDeliverySite['MobilePlant']);
    } */

    if (this.selectedDeliverySite['MobilePlant']) {
      this.formInformationGroup.controls['PlantType'].setValue(this.selectedDeliverySite['MobilePlant']);
    } else {
      // Set Plant type as Stationary by default, if not selected
      this.formInformationGroup.controls['PlantType'].setValue('BLK');
    }
    
    this.formInformationGroup.controls['Incoterms'].setValue(this.selectedDeliverySite['FreightCode']);
    this.formInformationGroup.controls['IncotermsDescription'].setValue(this.selectedDeliverySite['FreightHandlingDescription']);
    this.formInformationGroup.controls['LoadingDepot'].setValue(this.selectedDeliverySite['LoadingDepot']);
    this.formInformationGroup.controls['JdeAddressNumber'].setValue(this.selectedDeliverySite['JdeAddressNumber']);
    this.formInformationGroup.controls['DepotAddressNumber'].setValue(this.selectedDeliverySite['DepotAddressNumber']);

    // Adddress form data load
    this.formAddressGroup.controls['AddressLine1'].setValue(this.selectedDeliverySite['AddressLine1']);
    this.formAddressGroup.controls['AddressLine2'].setValue(this.selectedDeliverySite['AddressLine2']);
    this.formAddressGroup.controls['GPSCoordinates'].setValue(this.selectedDeliverySite['GPSCoordinates']);
    this.formAddressGroup.controls['City'].setValue(this.selectedDeliverySite['City']);
    this.formAddressGroup.controls['DeliveryPostalCode'].setValue(this.selectedDeliverySite['DeliveryPostalCode']);
    this.formAddressGroup.controls['Country'].setValue(this.selectedDeliverySite['CountryId']);
    this.formAddressGroup.controls['PrimaryContactEmailAddress'].setValue(this.selectedDeliverySite['SubscriberEmailAddress']);
    this.formAddressGroup.controls['PrimaryContactName'].setValue(this.selectedDeliverySite['PrimaryContactName']);

    // Split country code and phone number
    let primaryContactWithCountryCodeForAddressForm = this.selectedDeliverySite['PrimaryContact'];	//if primaryContactWithCountryCode = 'EST123'
    if (primaryContactWithCountryCodeForAddressForm) {
      this.formAddressGroup.controls['PrimaryContactCountryCode'].setValue(primaryContactWithCountryCodeForAddressForm.substr(0, 3));	// set PrimaryContactCountryCode = 'EST'
      this.formAddressGroup.controls['ContactNumber'].setValue(primaryContactWithCountryCodeForAddressForm.substring(3));	// set PrimaryContact='123'
    } else {
      this.formAddressGroup.controls['PrimaryContactCountryCode'].setValue(this.countryCode);
    }

    // Notification form data load
    this.formNotificationGroup.controls['NotifyHaulier'].setValue(this.selectedDeliverySite['NotifyHaulier'] === "TRUE");
    this.formNotificationGroup.controls['HaulierName'].setValue(this.selectedDeliverySite['HaulierName']);
    this.formNotificationGroup.controls['HaulierEmailId'].setValue(this.selectedDeliverySite['HaulierEmailId']);
    this.formNotificationGroup.controls['SendForecastReminder'].setValue(this.selectedDeliverySite['SendReminderFlag'] === "TRUE");
    this.formNotificationGroup.controls['InternalTextInfo'].setValue(this.selectedDeliverySite['Information']);
    this.formNotificationGroup.controls['CustomerTextInfo'].setValue(this.selectedDeliverySite['CustomerTextInfo']);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Summary form data load

    // Section 1:
    this.formSummaryGroup.controls['CustomerId'].setValue(this.selectedDeliverySite['CustomerId']);
    this.formSummaryGroup.controls['DeliverySite'].setValue(this.selectedDeliverySite['DeliverySite']);
    this.formSummaryGroup.controls['BillingReference'].setValue(this.selectedDeliverySite['BillingReference']);
    this.formSummaryGroup.controls['ExpectedFirstDeliveryDate'].setValue(this.selectedDeliverySite['ExpectedFirstDeliveryDate']);

    if (this.selectedDeliverySite['MobilePlant']) {
      this.formSummaryGroup.controls['PlantType'].setValue(this.selectedDeliverySite['MobilePlant']);
    } else {
      // Set Plant type as Stationary by default, if not selected
      this.formSummaryGroup.controls['PlantType'].setValue('BLK');
    }
    this.formSummaryGroup.controls['Incoterms'].setValue(this.selectedDeliverySite['FreightCode']);
    this.formSummaryGroup.controls['IncotermsDescription'].setValue(this.selectedDeliverySite['FreightHandlingDescription']);
    this.formSummaryGroup.controls['LoadingDepot'].setValue(this.selectedDeliverySite['LoadingDepot']);
    this.formSummaryGroup.controls['JdeAddressNumber'].setValue(this.selectedDeliverySite['JdeAddressNumber']);
    this.formSummaryGroup.controls['DepotAddressNumber'].setValue(this.selectedDeliverySite['DepotAddressNumber']);

    // Section 2:
    this.formSummaryGroup.controls['AddressLine1'].setValue(this.selectedDeliverySite['AddressLine1']);
    this.formSummaryGroup.controls['AddressLine2'].setValue(this.selectedDeliverySite['AddressLine2']);
    this.formSummaryGroup.controls['GPSCoordinates'].setValue(this.selectedDeliverySite['GPSCoordinates']);
    this.formSummaryGroup.controls['City'].setValue(this.selectedDeliverySite['City']);
    this.formSummaryGroup.controls['DeliveryPostalCode'].setValue(this.selectedDeliverySite['DeliveryPostalCode']);
    this.formSummaryGroup.controls['Country'].setValue(this.selectedDeliverySite['CountryId']);
    this.formSummaryGroup.controls['PrimaryContactEmailAddress'].setValue(this.selectedDeliverySite['SubscriberEmailAddress']);
    this.formSummaryGroup.controls['PrimaryContactName'].setValue(this.selectedDeliverySite['PrimaryContactName']);

    // Split country code and phone number
    let primaryContactWithCountryCodeForSummaryForm = this.selectedDeliverySite['PrimaryContact'];	//if primaryContactWithCountryCode = 'EST123'
    if (primaryContactWithCountryCodeForSummaryForm) {
      this.formSummaryGroup.controls['PrimaryContactCountryCode'].setValue(primaryContactWithCountryCodeForSummaryForm.substr(0, 3));	// set PrimaryContactCountryCode = 'EST'
      this.formSummaryGroup.controls['ContactNumber'].setValue(primaryContactWithCountryCodeForSummaryForm.substring(3));	// set PrimaryContact='123'
    } else {
      this.formSummaryGroup.controls['PrimaryContactCountryCode'].setValue(this.countryCode);
    }

    // Section 3:
    this.formSummaryGroup.controls['NotifyHaulier'].setValue(this.selectedDeliverySite['NotifyHaulier'] === "TRUE");
    this.formSummaryGroup.controls['HaulierName'].setValue(this.selectedDeliverySite['HaulierName']);
    this.formSummaryGroup.controls['HaulierEmailId'].setValue(this.selectedDeliverySite['HaulierEmailId']);
    this.formSummaryGroup.controls['SendForecastReminder'].setValue(this.selectedDeliverySite['SendReminderFlag'] === "TRUE");
    this.formSummaryGroup.controls['InternalTextInfo'].setValue(this.selectedDeliverySite['Information']);
    this.formSummaryGroup.controls['CustomerTextInfo'].setValue(this.selectedDeliverySite['CustomerTextInfo']);
  }

  async validatePostalCode(updatedPostalCode: string) {
    this.showInvalidPostalCodeMessage = false;
    return new Promise<void>((resolve, reject) => {
      this.addEditDeliverySiteService.validatePostalCode(this.formInformationGroup.value['CustomerId'], this.countryCode, updatedPostalCode).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            resolve();
          }
          else {
            this.showInvalidPostalCodeMessage = true;
            reject();
          }
        });
    })
  }

  dateValidator(control: FormControl): { [s: string]: boolean } {
    // console.log('date :: ' + date);
    /* if (formGroup && formGroup.controls['ExpectedFirstDeliveryDate'] && formGroup.controls['ExpectedFirstDeliveryDate'].value) {
      let expectedFirstDeliveryDate = new Date(formGroup.controls['ExpectedFirstDeliveryDate'].value);
      let dateOfTodayWithZeroHours = new Date();
      dateOfTodayWithZeroHours.setHours(0, 0, 0, 0);
      console.log(((expectedFirstDeliveryDate && (expectedFirstDeliveryDate.getTime() < dateOfTodayWithZeroHours.getTime())) ? { expectedFirstDeliveryDateIsLessThanToday: true } : null));
      return ((expectedFirstDeliveryDate && (expectedFirstDeliveryDate.getTime() < dateOfTodayWithZeroHours.getTime())) ? { expectedFirstDeliveryDateIsLessThanToday: true } : null);
    }
    return null; */

    if (control.value) {
      const date = moment(control.value);
      const today = moment().startOf('day');
      if (date.isBefore(today)) {
        return { 'invalidDate': true }
      }
    }
    return null;

  }

  getPlantTypes() {
    this.plantTypes = [];
    return new Promise<void>((resolve, reject) => {
      this.addEditDeliverySiteService.getPlantTypeDetails(this.formInformationGroup.value['CustomerId'], this.countryCode, this.role).subscribe(
        response => {
          if (response["ResponseCode"] === "200" && response['PlantTypeDetailsCollection'] && response['PlantTypeDetailsCollection'].length) {
            this.plantTypes = response['PlantTypeDetailsCollection'];
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  getDateInCorrectFormat(date: string) {
    let formattedDate: string = '';
    if (date && this.datePipe.transform(date, 'dd-MMM-yy', null, 'en-US').toUpperCase()) {
      let dateInString: string = this.datePipe.transform(date, 'dd-MMM-yy', null, 'en-US').toUpperCase();
      let monthName: string = this.datePipe.transform(date, 'MMM', null, 'en-US').toUpperCase();
      let translatedMonthName: string;
      this.translate.get(['HOME.' + monthName])
        .subscribe(data => {
          translatedMonthName = ((data['HOME.' + monthName]) ? (data['HOME.' + monthName].toUpperCase()) : '');
        });
      formattedDate = dateInString.replace(monthName, translatedMonthName);
    }
    // return ((date && this.datePipe.transform(date, 'dd-MMM-yy', null, 'en-US').toUpperCase()) ? this.datePipe.transform(date, 'dd-MMM-yy', null, 'en-US').toUpperCase() : '');
    return formattedDate.toUpperCase();
  }

  getPlantTypeNameByCode(plantTypeCode: string): string {
    let plantTypeValue: string = '';
    if (this.plantTypes && this.plantTypes.length) {
      for (let plantType of this.plantTypes) {
        if (plantType.PlantTypeCode === plantTypeCode) {
          if (this.selectedLanguageCode === "EN") {
            plantTypeValue = plantType.PlantTypeDesc;
          } else {
            this.translate.get(['HOME.PlanttypeDescriptionFor_' + plantType.PlantTypeCode])
              .subscribe(data => {
                plantTypeValue = (data['HOME.PlanttypeDescriptionFor_' + plantType.PlantTypeCode]);
              });
          }
          break;
        }
      }
    }
    return plantTypeValue;
  }

  getJDEAddressNumberFromResponse(response: any): string {
    let jdeAddressNumber: string = '';
    if (response && response['Description']) {
      let description = response['Description'];

      // Get jde address number from response description, e.g., Delivery Site: created successfully with JDEAddressNumber: 318481
      jdeAddressNumber = description.substring((description.indexOf('JDEAddressNumber: ')) + 18);
    }
    return jdeAddressNumber;
  }

  getIncotermsDescriptionById(formValue: string): string {
    let incotermsDescription: string = '';
    if (formValue['FreightHandlingDescription'] || formValue['IncotermsDescription']) {
      incotermsDescription = (formValue['FreightHandlingDescription'] || formValue['IncotermsDescription']);
    } else {
      if (this.incotermsList && this.incotermsList.length) {
        for (let incoterms of this.incotermsList) {
          if (incoterms.FreightHandlingId === formValue['Incoterms']) {
            incotermsDescription = incoterms.FreightHandlingDesc2;
            break;
          }
        }
      }
    }
    return incotermsDescription;
  }
  
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

	resetForm() {
		this.initialiseSubmittedFlagObject();
		this.formInformationGroup.reset();
		this.formAddressGroup.reset();
		this.formNotificationGroup.reset();
		this.formSummaryGroup.reset();    
    this.createFormForAddDeliverySite();
    this.stepper.reset();
    this.moveStepperToIndex(0);
		this.populateDefaultDataForCreateNewDeliverySite();
    this.presetFormValues();
	}

	populateDefaultDataForCreateNewDeliverySite() {
		// Set logged in user name by default in the Primary email address textbox
    this.formAddressGroup.controls['PrimaryContactEmailAddress'].setValue(this.userId.toLowerCase());

    // Set logged in user's country name and country code in phone number by default
    this.formAddressGroup.controls['Country'].setValue(this.countryCode);
    this.formAddressGroup.controls['PrimaryContactCountryCode'].setValue(this.countryCode);
    
    // Set Plant type as Stationary by default
    this.formInformationGroup.controls['PlantType'].setValue('BLK');
    this.isEditDeliverySite = false;
    this.selectedDeliverySite = undefined;
	}

  presetFormValues() {
    // Set logged in user's customer name by default in the customer dropdown
    this.formInformationGroup.controls['CustomerId'].setValue(this.customerId);

    // Set selected customer's abbreviation in the delivery site textbox, for Finland only
    if (this.countryCode === 'FIN') {
      this.formInformationGroup.controls['DeliverySite'].setValue(this.getCustomerAbbreviationById(this.customerId));
    }

    // Set Country name as logged in user country by default
    this.updateCountryNameByCountryCode(this.countryCode);

    // Set Incoterms value as blank by default in the incoterms dropdown
    this.formInformationGroup.controls['Incoterms'].setValue("");
  }

  stepperSelectionChange(event: any) {
    if (event && (event['previouslySelectedIndex'] || event['previouslySelectedIndex'] === 0) && (event['selectedIndex'] || event['selectedIndex'] === 0)) {
      let stepName = this.getStepNameByIndex(event['previouslySelectedIndex'])
      this.updateSummaryModel(stepName);
    }
  }

  getStepNameByIndex(index: number): string {
    let stepNameList = ["Information", "Address", "Notification", "Summary"];
    let stepName: string;
    if (((index && index > 0) || index === 0) && stepNameList && stepNameList.length >= index) {
      stepName = stepNameList[index];
    }
    return stepName;
  }

  getUpdatedFieldsName(notifyHaulier: string, sendForecastReminder: string): string {
    let updatedFields = [];
    let existingDeliverySiteObject = this.selectedDeliverySite;
    let updatedDeliverySiteObject = this.formSummaryGroup.value;

    if (existingDeliverySiteObject['DeliverySite'] !== updatedDeliverySiteObject['DeliverySite']) {
      updatedFields.push(' Delivery site name');
    }
    if (existingDeliverySiteObject['BillingReference'] !== updatedDeliverySiteObject['BillingReference']) {
      updatedFields.push(' Billing reference');
    }
    if (existingDeliverySiteObject['LoadingDepot'] !== updatedDeliverySiteObject['LoadingDepot']) {
      updatedFields.push(' Loading depot');
    }
    if (existingDeliverySiteObject['AddressLine1'] !== updatedDeliverySiteObject['AddressLine1']) {
      updatedFields.push(' Address line 1 / GPS coordinates');
    }

    /* if (existingDeliverySiteObject['GPSCoordinates'] !== updatedDeliverySiteObject['GPSCoordinates']) {
      updatedFields.push(' GPS coordinates ');
    } */

    if (existingDeliverySiteObject['AddressLine2'] !== updatedDeliverySiteObject['AddressLine2']) {
      updatedFields.push(' Address line 2');
    }
    if (existingDeliverySiteObject['City'] !== updatedDeliverySiteObject['City']) {
      updatedFields.push(' City');
    }
    if (existingDeliverySiteObject['DeliveryPostalCode'] !== updatedDeliverySiteObject['DeliveryPostalCode']) {
      updatedFields.push(' Delivery postal code');
    }
    if (existingDeliverySiteObject['PrimaryContactName'] !== updatedDeliverySiteObject['PrimaryContactName']) {
      updatedFields.push(' Primary contact person name');
    }
    if (updatedDeliverySiteObject['ContactNumber'] && existingDeliverySiteObject['PrimaryContact'] !== (updatedDeliverySiteObject['PrimaryContactCountryCode'] + updatedDeliverySiteObject['ContactNumber'])) {
      updatedFields.push(' Contact number');
    }
    if (existingDeliverySiteObject['SubscriberEmailAddress'] !== updatedDeliverySiteObject['PrimaryContactEmailAddress']) {
      updatedFields.push(' Primary email address');
    }
    if ((existingDeliverySiteObject['NotifyHaulier'] !== notifyHaulier)) {
      updatedFields.push(' Notify haulier');
    }
    if (existingDeliverySiteObject['HaulierName'] !== updatedDeliverySiteObject['HaulierName']) {
      updatedFields.push(' Haulier name');
    }
    if (existingDeliverySiteObject['HaulierEmailId'] !== updatedDeliverySiteObject['HaulierEmailId']) {
      updatedFields.push(' Haulier email id');
    }
    if (existingDeliverySiteObject['CustomerTextInfo'] !== updatedDeliverySiteObject['CustomerTextInfo']) {
      updatedFields.push(' Additional text info');
    }
    if (existingDeliverySiteObject['Information'] !== updatedDeliverySiteObject['InternalTextInfo']) {
      updatedFields.push(' Internal text info');
    }

    return ((updatedFields && updatedFields.length) ? (updatedFields.toString() + ' ') : '');
  }

  getContactNumberWithoutCountryCode(contactNumber: string): string {
    let contactNumberWithoutCountryCode: string = '';
    if (contactNumber) {
      contactNumberWithoutCountryCode = contactNumber.substr(3); // To get +47 7987878991 from NOR+47 7987878991
    }
    return contactNumberWithoutCountryCode;
  }

  checkIfBillingReferenceNumberOrSendForecastReminderFlagAreUpdated(response: any, contactNumber: string, sendForecastReminder: string) {
    // If Billing reference number is changed then send email to Neste
    let existingDeliverySiteObject = this.selectedDeliverySite;
    let updatedDeliverySiteObject = this.formSummaryGroup.value;
    if (existingDeliverySiteObject['BillingReference'] !== updatedDeliverySiteObject['BillingReference']) {
      this.saveStatusDialogForUpdateDeliverySite(response, 'DS_BILLINGREFERENCENUMBER_CHANGE', contactNumber, '', null);
    }

    // If Send Forecast Reminder flag is updated then send email to respective users only
    if (existingDeliverySiteObject['SendReminderFlag'] !== sendForecastReminder) {
      this.saveStatusDialogForUpdateDeliverySite(response, 'DS_SEND_FORECAST_REMINDER', contactNumber, '', sendForecastReminder);
    }
  }

  addValidationOnBillingReferenceField() {
    if (this.countryCode === 'SWE' || this.countryCode === 'NOR') {
      if (!this.isEditDeliverySite) {
        this.formInformationGroup.controls['BillingReference'].setValidators([Validators.required, Validators.maxLength(40)]);
      }
      this.formSummaryGroup.controls['BillingReference'].setValidators([Validators.required, Validators.maxLength(40)]);
    } else {
      if (!this.isEditDeliverySite) {
        this.formInformationGroup.controls['BillingReference'].clearValidators();
        this.formInformationGroup.controls['BillingReference'].setValidators([Validators.maxLength(40)]);
      }
      this.formSummaryGroup.controls['BillingReference'].clearValidators();
      this.formSummaryGroup.controls['BillingReference'].setValidators([Validators.maxLength(40)]);
    }
    if (!this.isEditDeliverySite) {
      this.formInformationGroup.controls['BillingReference'].updateValueAndValidity();
    }
    this.formSummaryGroup.controls['BillingReference'].updateValueAndValidity();
  }

}

