import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { QualityCertificateComponent } from 'src/app/quality-certificate/quality-certificate.component';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { ProductInformationFilterPipe } from '../../shared-modules/pipes/product-information-filter.pipe';
import { DialogBodyComponent } from '../../user-management/dialog-body/dialog-body.component';
import { LanguageSelectionComponent } from '../language-selection/language-selection.component';
import { LanguageSelectionService } from '../language-selection/language-selection.service';
import { ProductDocumentationService } from "./product-documentation.service";

@Component({
  selector: 'app-product-documentation',
  templateUrl: './product-documentation.component.html',
  styleUrls: ['./product-documentation.component.css']
})
export class ProductDocumentationComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  productsInformationData: any;
  productsInformationDataCopy: any;
  productType: any = [];
  productSubType: any = [];
  depotLocation: any;
  productTypeToSearch: any;
  productSubTypeToSearch: any;
  depotLocationToSearch: any;
  displayIndex = 1;
  displayedColumns: string[] = ['product_name', 'Product_type', 'Product_category', 'productInformation', 'SAFETY_DATA', 'CE_MARK', 'PERFORMANCE_REPORT', 'qualityCertificate'];
  dataSource: any;
  displayTable: boolean;
  displayView: boolean = true;
  productData: any;
  userInfo: any;
  username: string;
  role: any;
  customerID: any;
  customerName: any;
  isLoggedInUserAdmin: boolean;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  All: string = 'All';
  countryCode: any;
  countryCodeMarket: string;
  countryMarketList: any;
  countryList: any;
  userId: string;
  isRequestCompleted: boolean;
  currentToggleMenu: string = 'product-catalogue';
  globalSearchText: string;
  selectedLanguage: string;
  contactUsEmailId: string;
  nynasCustomerName: string;
  bannerMessage: string;

  constructor(private productDocumentationService: ProductDocumentationService,
    private languageSelectionService: LanguageSelectionService,
    private dialog: MatDialog, private filterData: ProductInformationFilterPipe,
    private sharedService: AppService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private media: MediaMatcher
  ) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.username = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.role = (this.userInfo ? this.userInfo.RoleID : '');
    this.customerID = (this.userInfo ? this.userInfo.CustomerID : '');
    this.customerName = (this.userInfo ? this.userInfo.CustomerName : '');
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.countryCode = this.userInfo.CountryCode;
    this.userId = (this.userInfo ? this.userInfo.UserID : '');
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // Commented below code, moved to ngOnInit method
    /* this.translate.get(['HOME.selectedLanguageForDatePicker'])
      .subscribe(data => {
        this.selectedLanguage = data['HOME.selectedLanguageForDatePicker'];
      });
    this.getProductDocumentationPageNote(); */

  }

  async ngOnInit() {
    this.productSubType = ['All', 'Regular', 'Extra', 'Premium'];
    this.productType = ['All', 'Paving Bitumen', 'Adhesive Bitumen', 'Bituminous solutions and fluxed Bitumens'];
    this.productTypeToSearch = 'All';
    this.productSubTypeToSearch = 'All';
    this.productsInformationData = [];
    this.productsInformationDataCopy = this.productsInformationData;

    try {
      await this.getSelectedLanguageCode();
    }
    catch (e) {
    }

    try {
      await this.getProductDocumentationPageNote();
    }
    catch (e) {
    }

    try {
      await this.getCountriesMasterData();
    }
    catch (e) {
    }

    try {
      await this.getProductDropdownData();
    }
    catch (e) {
    }

    if (this.countryCode === 'GBR') {
      this.countryCodeMarket = this.countryCode;

      try {
        await this.fetchProductDetailsFromSaleforce();
      }
      catch (e) {
      }

    } else {
      try {
        await this.getUserDocumentCountry();
      }
      catch (e) {
      }
      try {
        await this.fetchProductDetailsFromSaleforce();
      }
      catch (e) {
      }
    }

    this.translate.get(['HOME.itemsPerPageLabel'])
      .subscribe(data => {
        this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  async toggleButtonClick() {
    this.globalSearchText = '';
    if (this.currentToggleMenu === 'product-catalogue') {
      try {
        await this.getCountriesMasterData();
      }
      catch (e) {
      }
  
      try {
        await this.getProductDropdownData();
      }
      catch (e) {
      }

      try {
        await this.getUserDocumentCountry();
      }
      catch (e) {
      }

      try {
        await this.fetchProductDetailsFromSaleforce();
      }
      catch (e) {
      }

    } else if (this.currentToggleMenu === 'quality-certificate') {
      try {
        await this.getProductDropdownData();
      }
      catch (e) {
      }

      try {
        await this.fetchFinlandQualityCertificatesFromSharepoint();
      }
      catch (e) {
      }
    }
  }

  async getUserDocumentCountry() {
    this.countryMarketList = [];
    return new Promise<void>((_resolve, _reject) => {
      this.productDocumentationService.getUserDocumentCountry(this.userId).subscribe(
        response => {
          if (response && response["ResponseCode"] === "200") {
            this.countryMarketList = response["Collection"];
            this.countryCodeMarket = this.countryCode;
          } else {
            let countryList = [];
            let countryObject = {};
            let loggedInUserCountryCode = this.countryCode;
            countryObject['COUNTRY_CODE'] = loggedInUserCountryCode;
            countryObject['COUNTRY_NAME'] = this.getCountryNameByCode(loggedInUserCountryCode);
            countryList.push(countryObject);
            this.countryMarketList = countryList;
            this.countryCodeMarket = loggedInUserCountryCode;
          }
          _resolve();
        });
    });
  }

  async getProductDropdownData() {
    return new Promise<void>((_resolve, _reject) => {
      this.productDocumentationService.getProductDropdownData()
        .subscribe(
          response => {
            if (response["ResponseCode"] == 200) {
              let productTypesWithAll = response['PRODUCT_TYPES'];
              productTypesWithAll.shift();
              this.productType = productTypesWithAll; // Remove word 'All' using shift method, for translation
              let prodSubTypeWithAll = response['PRODUCT_SUBTYPES'];
              prodSubTypeWithAll.shift();
              this.productSubType = prodSubTypeWithAll;
              this.productTypeToSearch = 'All';
              this.productSubTypeToSearch = 'All';
              _resolve();
            }
            else {
              _reject();
            }
          });
    });
  }

  async fetchProductDetailsFromSaleforce() {
    let productType = this.productTypeToSearch;
    this.dataSource = new MatTableDataSource([]);
    this.globalSearchText = '';
    return new Promise<void>((_resolve, _reject) => {
    this.productDocumentationService.fetchProductDetailsFromSaleforce(this.getCountryNameByCode(this.countryCodeMarket))
      .subscribe(
        response => {
          if (response["ResponseCode"] == 200) {
            this.productsInformationData = response['responseList'];
            this.productsInformationDataCopy = this.productsInformationData;
            let productList = [];
            for (let product of response['responseList']) {
              if ((productType === "All" || productType === product['Product_type'])) {
                productList.push(product);
              }
            }
            this.dataSource = new MatTableDataSource(productList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.displayTable = (productList && productList.length) ? true : false ;
            this.isRequestCompleted = true;
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

  async fetchFinlandQualityCertificatesFromSharepoint() {
    let productType = this.productTypeToSearch;
    this.dataSource = new MatTableDataSource([]);
    this.globalSearchText = '';
    return new Promise<void>((_resolve, _reject) => {
    this.productDocumentationService.getProductInformation(this.customerID, this.countryCode)
      .subscribe(
        response => {
          if (response["ResponseCode"] == 200) {
            this.productsInformationData = response['GetAllProductResponses'];
            this.productsInformationDataCopy = this.productsInformationData;
            let productList = [];
            for (let product of response['GetAllProductResponses']) {
              if ((productType === "All" || productType === product['Product_type'])) {
                let productObject = {};
                productObject['PRODUCT_ID'] = product['PRODUCT_ID'];
                productObject['Product_type'] = product['PRODUCT_TYPE'];
                productObject['Product_category'] = product['PRODUCT_SUB_TYPE'];
                productObject['product_name'] = product['PRODUCT_NAME'];
                productList.push(productObject);
              }
            }
            this.dataSource = new MatTableDataSource(productList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.displayTable = (productList && productList.length) ? true : false ;
            this.isRequestCompleted = true;
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

  checkDisplayFlag(value) {

    if (value / 6 < this.displayIndex) {
      return 'display-data';
    }
    else {
      return 'hide-data';
    }

  }
  displayMoreProducts(data) {
    this.displayIndex = this.displayIndex + 1;

  }


  downloadPdf(pdf) {
    let pdfData;
    // let filepath = "/Shared%20Documents/General/" + pdf
    let filepath = pdf;

    let sharepointData = {
      "Username": "API.Integration@nynas.onmicrosoft.com",
      "Password": "Nosa9144",
      "Domain": "nynas",
      // "SourceFolderPath" : "/sites/GroupCustomerPortal",
      "SourceFolderPath": "/sites/PortalOperationalDocuments",
      "FilePath": filepath.replace(/ /g, "%20")
    }


    this.productDocumentationService.getSharepointDoc(sharepointData)
      .subscribe(
        res => {
          if (res["ResponseCode"] == "200") {
            pdfData = res["EncodedFileData"]
            // console.log(pdfData)
            //pdf='%PDF-1.7%����115 0 obj<</Linearized 1/L ...';
            const byteArray = new Uint8Array(atob(pdfData).split('').map(char => char.charCodeAt(0)));
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            if (this.checkIfBrowserIsIE()) {
              window.navigator.msSaveOrOpenBlob(blob, 'data.pdf');
            } else {
              let fileUrl = window.URL.createObjectURL(blob);
              window.open(fileUrl);
            }

          } else {
            this.openDialog('Sorry! No file available.', null, false, 'no-file-available');

          }

        });


    // if (pdf != '') {
    //   const fileName = productName + '_' + pdfType;
    //   const pdfUrl = 'http://132.145.243.109/productInfo/' + pdf;
    //   window.open(pdfUrl, '_blank');
    //   // let Url='https://www.nynas.fi/Aspx/Products/'+pdf+'?PID='+(pIndex+3);
    //   // window.open(Url, '_blank');
    //   // alert('Downloading'+fileName+'from Url'+Url);
    //   //saveAs(pdfUrl,fileName);
    //   // Option one passing the Url
    // }
  }

  openDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,
      },
      height: '39%',
      width: (this.mobileQuery.matches ? '70%' : '25%'),
    });
  }

  getQualityCertificate(productId: string) {

    // this.downloadPdf(pIndex,pdf,productName,pdfType);
    // console.log("Product ",productToSearch);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      productId: productId
    };
    // dialogConfig.height = '22%';
    // dialogConfig.width = '22%';
    dialogConfig.height = (this.mobileQuery.matches ? '30%' : '22%');
    dialogConfig.width = (this.mobileQuery.matches ? '50%' : '22%');

    this.dialog.open(QualityCertificateComponent, dialogConfig);

  }

  checkIfBrowserIsIE(): boolean {
    var isBrowserIE = false;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) { // If Internet Explorer browser
      isBrowserIE = true;
    }
    return isBrowserIE;
  }

  getCountryNameByCode(countryCode: string): string {
    /* if (this.countryCode === 'GBR') {
      return 'United Kingdom';
    } */
    let countryName: string;
    if (this.countryList && this.countryList.length) {
      for (let country of this.countryList) {
        if (country['CountryID'] === countryCode) {
          countryName = country['CountryName'];
        }
      }
    }
    return countryName;
  }

  showLanguageSelectionPopUp(productDocumentRow: any, documentType: string) {
    if (this.countryCodeMarket === 'GBR') {
      this.getProductDocument(documentType, productDocumentRow['Product_id']);
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        productId: productDocumentRow['Product_id'],
        documentType: documentType,
        country: this.getCountryNameByCode(this.countryCodeMarket),
        countryCodeMarket: this.countryCodeMarket
      };
      dialogConfig.height = (this.mobileQuery.matches ? '30%' : 'auto');
      dialogConfig.width = (this.mobileQuery.matches ? '50%' : '22%');
      this.dialog.open(LanguageSelectionComponent, dialogConfig);
    }
  }

  getProductDocument(documentType: string, productId: string): void {
    let languageForUK = 'English';
    this.languageSelectionService.fetchProductDetailsFromSaleforce(this.getCountryNameByCode(this.countryCodeMarket), documentType, languageForUK, productId)
      .subscribe(
        response => {
          if (response && response['ResponseCode'] === '200' && response['Details'] && response['Details']['doc_List'] && response['Details']['doc_List']['url']) {
            this.showProductDocument(response['Details']['doc_List']['url']);
          } else {
            this.openDialog('No document found', '', false, 'no-document-found');
          }
        });
  }

  showProductDocument(url: string) {
    if (url) {
      window.open(url, "_blank");
    } else {
      this.openDialog('No document found', '', false, 'no-document-found');
    }
  }

  async getCountriesMasterData() {
    return new Promise<void>((_resolve, _reject) => {
      this.productDocumentationService.getAllCountries().subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            this.countryList = response["Countries"];
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

  changeCountryName(event: any) {
    this.isRequestCompleted = false;
    this.globalSearchText = '';
    this.dataSource = new MatTableDataSource([]);
    this.fetchProductDetailsFromSaleforce();
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }
  
  // Commented old code, kept for future reference
  /* getProductDocumentationPageNote() {
    switch (this.countryCode) {
      case 'FIN':
        this.contactUsEmailId = 'info.suomi@nynas.com';
        this.nynasCustomerName = 'Nynas Oy';
        break;
      case 'SWE':
        this.contactUsEmailId = 'sweden.portal@nynas.com';
        this.nynasCustomerName = 'Nynas AB';
        break;
      case 'NOR':
        this.contactUsEmailId = '';
        this.nynasCustomerName = 'Nynas AS';
        break;
      case 'GBR':
        this.contactUsEmailId = '';
        this.nynasCustomerName = 'Nynas';
        break;
      default:
        this.contactUsEmailId = '';
        this.nynasCustomerName = 'Nynas';
    }
  } */

  async getProductDocumentationPageNote() {
    let countryCode = (this.countryCode ? this.countryCode : '');
    let selectedLanguage = (this.selectedLanguage ? this.selectedLanguage : '');
    let key = (countryCode && selectedLanguage) ? (countryCode + '_' + selectedLanguage) : '';
    if (key) {
      return new Promise<void>((_resolve, _reject) => {
        this.productDocumentationService.getDisplayMessage(key).subscribe(
          response => {
            if (response && response['ResponseCode'] === '200') {
              this.bannerMessage = response['Message'];
              _resolve();
            }
            else {
              _reject();
            }
          });
      });
    }
  }

  async getSelectedLanguageCode() {
    return new Promise<void>((_resolve, _reject) => {
      this.translate.get(['HOME.languageCode'])
        .subscribe(data => {
          if (data) {
            this.selectedLanguage = data['HOME.languageCode'];  // Get language code of EN, FI, SE or NR, as per flag selected on page
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

}
