import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class DeliveryGuideAdminService {
  apiUrl= environment.apiUrl;
  httpOptions = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json'
      
    })

    
  };
  

  constructor( private httpClient: HttpClient) { }

  getDeliveryGuideAdmin(countryCode: string, isAvailable: string) {
    const serviceUrl = this.apiUrl + '/GetDeliveryGuide';
    return this.httpClient.get(serviceUrl, {
      params: {
        Country: countryCode,
        is_available: isAvailable
      }
    });
  }
 
  updateDeliveryGuide(deliveryGuideIndex: any,){
    let url = this.apiUrl + '/AddDeliveryGuide';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };

          /* {      
      "available_from": deliveryGuideIndex.FromDate,
      "available_to": deliveryGuideIndex.ToDate,  
    } */

    let postData = JSON.stringify(
      {
        "ProductId": deliveryGuideIndex.PRODUCT_ID,
        "DepotId": deliveryGuideIndex.DEPOT_ID,
        "CountryCode": deliveryGuideIndex.countryCode,
        "FromDate": deliveryGuideIndex.FROM_DATE,
        "ToDate": deliveryGuideIndex.TO_DATE,
      }
    );

    
    
    return this.httpClient.post(url, postData, httpOptions).pipe();
  





  }

}
