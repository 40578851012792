import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../user-management/user';
 
@Injectable({
    providedIn: 'root'
})
 
export class ContactUsAdminService {
 
    apiUrl= environment.apiUrl;
    httpOptions = {
        headers : new HttpHeaders({
          'Content-Type' : 'application/json'
 
        })
 
 
      };
 
    constructor(private httpClient: HttpClient) {
    }
 
 
    getContactUs (countryCode) {      
        let url = this.apiUrl + '/GetContactUs';
        return this.httpClient
            .get(url, {
                params: {
                    Country_Code: countryCode
 
                }
            });
    }
 
    createContactUs(user: any)
    {
        let url = this.apiUrl + '/CreateContactUs';
        const httpOptions = {
            /* headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }) */
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*' ,
                // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization',
                // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            })
        };
 
        let postData = JSON.stringify({
 
            "CONTACT_ID": user.Contact_ID,
            "COUNTRY_CODE": user.Country_Code,
            "CONTACT_NAME": user.Contact_Name,
            "CONTACT_IMAGE": user.Photo,
            "DESIGNATION": user.Designation,
            "EMAILID": user.Email_Id,
            "PHONE_NUMBER": user.Phone_Number,
            "CREATED_BY": user.CreatedBy,
            "UPDATED_BY": user.UpdatedBy,
            "CREATED_ON": user.CreatedOn, 
            "UPDATED_ON": user.UpdatedOn 
        });
        return this.httpClient.post<User>(url, postData, httpOptions).pipe();
 
 
    }
 
    getCountries() {
        let url = this.apiUrl + '/getCountry';
        return this.httpClient.get(url);
    }
 
    deleteContactUs(id: number){
        let url = this.apiUrl + '/DeleteContactUs';
        let httpParams = new HttpParams().set('ContactId', id.toString());
 
        let options = { params: httpParams };
 
        /* let postData = JSON.stringify({
            "ContactId": id,
        }) */
 
        return this.httpClient.delete(url, options ).pipe();
    }
 
}