import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PriceDataService {
  private customerId: any;
  private customerName: any;
  apiUrl = environment.apiUrl;
 private yearUserSelected: any;
  private currYear: any;

  constructor(private httpClient: HttpClient) { }

getCustomerNames(countryCode){
  let url= this.apiUrl + '/getCustomer';
  return this.httpClient.get(url, {
    params: {
      CountryCode: countryCode,
    }
  });
}
setCustomerName(customerId) {
  this.customerId = customerId;
}
getCustomerName() {
  return this.customerId;
}

setCustomerId(customerName) {
  this.customerName = customerName;
}
getCustomerId() {
  return this.customerName;
}

// setYear(yearUserSelected) {
//   this.yearUserSelected = yearUserSelected;
// }
// setCurrYear(currYear) {
//   this.currYear = currYear;
// }
// getCurrYear() {
//   return this.currYear;
// } 

  getAdhesivePrice(CustomerID: string, EffectiveDate: string, CountryCode: string) {
    const serviceUrl = this.apiUrl + '/GetAdhesiveProductPrice';
    return this.httpClient
      .get(serviceUrl, {
        params: {
          CustomerID: CustomerID,
          EffectiveDate: EffectiveDate,
          CountryCode: CountryCode,
        }
      });
  }

  getProductPrice(CustomerID: string, EffectiveDate: string, CountryCode: string) {
    const serviceUrl = this.apiUrl + '/GetProductPrice';
    return this.httpClient
      .get(serviceUrl, {
        params: {
          CustomerID: CustomerID, //267359
          EffectiveDate: EffectiveDate, //2020-03-01
          CountryCode: CountryCode

        }
      });
  }


getYear() {
  return this.yearUserSelected;
}

getMonthlyavgDetails(Type: string, Year: any){
  const serviceUrl = this.apiUrl + '/GetPriceIndexAndConversionRate';
  return this.httpClient
  .get(serviceUrl, {
    params: {
      Type: Type,
      Year: Year
     
    }
  })
 
 }

}
