import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UploadDocumentService {
  apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private httpClient: HttpClient) { }







    
  addProductMaster(form: any, countryCode: string) {
    let url = this.apiUrl + '/AddProductDocument';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };

    let postData: any;
    postData = JSON.stringify(
      {
        "ProductId": form.PRODUCT_ID,
        "CountryCode": countryCode,
        "ProductDatasheet": form.PRODUCT_INFO,
        "SafetyDatasheet": form.SAFETY_DATA,
        "CEMark": form.CE_MARK,
        "PerformanceReport": form.PERFORMANCE_REPORT,       
        "Year": "",
        "QualityCertificate": form.QualityCertificate,
        "CreatedBy": form.CreatedBy,
        "CreatedOn": form.CreatedOn,
        "UpdatedBy": form.UpdatedBy,
        "UpdatedOn": form.UpdatedOn
      }
    );
    return this.httpClient.post(url, postData, httpOptions).pipe();
  }

  addQualityCertificate (form: any, countryCode: string) {
    let url = this.apiUrl + '/AddProductDocument';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };

    let postData: any;
    postData = JSON.stringify(
      {
        "ProductId": form.PRODUCT_ID,
        "CountryCode": countryCode,
        "ProductDatasheet": form.PRODUCT_INFO,
        "SafetyDatasheet": form.SAFETY_DATA,
        "CEMark": form.CE_MARK,
        "PerformanceReport": form.PERFORMANCE_REPORT,       
        "Year": form.Year,
        "QualityCertificate": form.QualityCertificate,
        "CreatedBy": form.CreatedBy,
        "CreatedOn": form.CreatedOn,
        "UpdatedBy": form.UpdatedBy,
        "UpdatedOn": form.UpdatedOn
      }
    );
    return this.httpClient.post(url, postData, httpOptions).pipe();
  }

  getProductInformation(CustomerID, countryCode) {
    const serviceUrl = this.apiUrl + '/GetAllProduct';

    return this.httpClient
      .get(serviceUrl, {
        params: {
          Status: 'ACTIVE',
          Country: countryCode,
          CustomerID: CustomerID
        }
      });
  }

  getSharepointDoc(data) {
    let postData = JSON.stringify(data)
    try {
      let productSharepointUrl = this.apiUrl + '/ReadFileDataFromSharepointService';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(productSharepointUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }
  }

  
  addNynasUpdate(form: any, countryCode) {
    let url = this.apiUrl + '/UpdateNynasUpdates';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };

    let postData: any;
    postData = JSON.stringify(
      {
        "Date": form.NYNAS_UPDATE_DATE,
        "Type": form.TYPE,
        "Title": form.TITLE,
        "CountryCode" : countryCode,
        "Message": form.MESSAGE,
        "Link": form.DOUCUMENT_URL, 
        "StorageType": form.STORAGE_TYPE,      
        "CreatedBy": form.CreatedBy,
        "CreatedOn": form.CreatedOn,
        "UpdatedBy": form.UpdatedBy,
        "UpdatedOn": form.UpdatedOn
      }
    );
    return this.httpClient.post(url, postData, httpOptions).pipe();
  }


  /* deleteNynasUpdate(data) {
    let url = this.apiUrl + '/DeleteNynasUpdates';
    let postData: any;
    postData = JSON.stringify(data)
     console.log("postData" + postData);
    return this.httpClient.delete(url, postData).pipe();
  } */
  
  deleteNynasUpdate(data: any) {
    let url = this.apiUrl + '/DeleteNynasUpdates';
    let httpParams = new HttpParams().set('Date', data['Date']).set('Type', data['Type']).set('CountryCode', data['CountryCode']);
    let options = { params: httpParams };
    return this.httpClient.delete(url, options).pipe();
  }

}