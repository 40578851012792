import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalSearchComponent } from './global-search.component';

@NgModule({
  declarations: [GlobalSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  exports: [
    GlobalSearchComponent
  ],

})
export class GlobalSearchModule { }
