import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import { PendingForecastService } from '../pending-forecast/pending-forecast.component.service';

@Component({
  selector: 'app-pending-forecast-dialog',
  templateUrl: './pending-forecast-dialog.component.html',
  styleUrls: ['./pending-forecast-dialog.component.css']
})
export class PendingForecastDialogComponent implements OnInit {
  /* showFooterButtons: boolean;
  header = 'Title';
  message = 'Are you sure?';
  confirmButtonText = 'Yes';
  cancelButtonText = 'Cancel';
  isSuccess: boolean;
  headerNumber: string;
  messagefield1: string;
  headerNumber1: string;
  messagefield2: string;
  headerNumber4: string;
  headerNumber5: string;
  messagefield5: string;
  emailTo: string;
  messagenumber: string;
  isWarning: boolean;
  extraParams: any; */
  public dataSourceForAdhesivePricesTable = new MatTableDataSource();
  @ViewChild('adhesivePricesTableSort', { static: true }) public adhesivePricesTableSort: MatSort;
  public displayedColumnsForAdhesivePricesTable = ['emailId', 'selection'];
  isAllAdhesiveProductsSelected: boolean;
  emailIdList: any;
  months: any;
  customerName: string;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  monthsList: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  isYearlyForecastReminder: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<PendingForecastDialogComponent>,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private pendingForecastService: PendingForecastService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private sharedService: AppService) {
    if (data) {
      this.emailIdList = data.emailIdList || this.emailIdList;
      this.months = data.months || this.months;
      this.customerName = data.customerName || this.customerName;
      /* this.header = data.header || this.header;
      this.message = data.message || this.message;
      this.isSuccess = data.isSuccess || this.isSuccess;
      this.isWarning = data.isWarning || this.isWarning;
      this.headerNumber = data.headerNumber;
      this.messagefield1 = data.messagefield1;
      this.headerNumber1 = data.headerNumber1;
      this.messagefield2 = data.messagefield2;
      this.headerNumber4 = data.headerNumber4;
      this.headerNumber5 = data.headerNumber5;
      this.messagefield5 = data.messagefield5;
      this.emailTo = data.emailTo;
      this.extraParams = data.extraParams; */
      /* if (data.buttonText) {
        this.showFooterButtons = true;
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      } */
    }
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.getMonthsForYearlyReminder();
  }

  ngOnInit() {
    this.isAllAdhesiveProductsSelected = true;
    /* let selectedEmailIdList = [];
    let emailIdObject = {};
    emailIdObject['emailId'] = 'shweta.kadav@lntinfotech.com';
    emailIdObject['isSelected'] = true;
    selectedEmailIdList.push(emailIdObject);

    let emailIdObject1 = {};
    emailIdObject1['emailId'] = 'sayalia.chavan@lntinfotech.com';
    emailIdObject1['isSelected'] = true;
    selectedEmailIdList.push(emailIdObject1);

    let emailIdObject2 = {};
    emailIdObject2['emailId'] = 'varad.mayekar@lntinfotech.com';
    emailIdObject2['isSelected'] = true;
    selectedEmailIdList.push(emailIdObject2);

    let emailIdObject3 = {};
    emailIdObject3['emailId'] = 'sarang.khadilkar@nynas.com';
    emailIdObject3['isSelected'] = true;
    selectedEmailIdList.push(emailIdObject3);

    let emailIdObject4 = {};
    emailIdObject4['emailId'] = 'kapil.sharma@lntinfotech.com';
    emailIdObject4['isSelected'] = true;
    selectedEmailIdList.push(emailIdObject4); */

    this.dataSourceForAdhesivePricesTable.data = this.emailIdList;
  }


  ngAfterViewInit(): void {
    this.dataSourceForAdhesivePricesTable.sort = this.adhesivePricesTableSort;
    this.cdRef.detectChanges();
    this.dataSourceForAdhesivePricesTable.sortingDataAccessor = (item, property) => {
      return item[property]
    };
  }

  sendEmail(): void {
    let selectedEmailIds = [];
    for (let emailId of this.emailIdList) {
      if (emailId && emailId['isSelected']) {
        selectedEmailIds.push(emailId['emailId']);
      }
    }
    this.sendEmailToUsers(selectedEmailIds);
    // this.dialogRef.close(true);
  }

  /* sendEmailToUsers(selectedEmailIds: any): void {
    let translatedMail: any;
    let wishFrom = "Nynas Oy";
    this.translate.get(['HOME.forecastReminderMail'])
      .subscribe(data => {
        translatedMail = data['HOME.forecastReminderMail']

      });
    let emailTo = (selectedEmailIds && selectedEmailIds.length) ? selectedEmailIds.toString() : '';
    let emailBody = "<html>"
      + "<head>"
      + "<title></title>"
      + "<style type='text/css'></style>"
      + "</head>"
      + "<body aria-readonly='false'>"
      + translatedMail.hello
      + "<br>"
      + "<br>"
      + translatedMail.forecastPending + " "
      + translatedMail.month + "<b>" + this.month + "</b> "
      // + translatedMail.deliverySite + "<b>" + pendingForecast.DeliverySite + "</b>"
      + "<br>"
      + translatedMail.stmt1
      + "<br>"
      + "<br>"
      + translatedMail.stmt1m
      + "<br>"
      + "<br>"
      + translatedMail.stmt2
      + "<br>"
      + "<br>"
      + translatedMail.wish
      + "<br>"
      + "<b>" + wishFrom + "</b>"
      + "<br>"
      + "<br>"
      + "<p style='color:Red'>"
      + "<i>" + translatedMail.sysMail + "</i>"
      + "</p>"
      + "</body>"
      + "</html>";
    let emailSubject = translatedMail.sub + " - " + this.month;
    this.pendingForecastService.sendEmailNotification(emailTo, emailBody, emailSubject)
      .subscribe(
        response => {
          if (response['Status'] && response['Status'].toUpperCase() === 'SUCCESS') {
            this.openDialog("", "", true, 'pending-forecast-success', '');
            this.dialogRef.close(true);
          } else {
            this.openDialog("", "", false, 'pending-forecast-fail', '');
          }
        });
  } */

  sendEmailToUsers(selectedEmailIds: any): void {
    let translatedEmailBodyText: any, translatedMonth: string;

    /* if(this.isYearlyForecastReminder){
      this.translate.get(['HOME.pendingForecastReminderEmailBodyText', 'HOME.' + this.months])
      .subscribe(data => {
        translatedEmailBodyText = data['HOME.pendingForecastReminderEmailBodyText'];
        translatedMonth = data['HOME.' + this.months];
      });
    } else {
      this.translate.get(['HOME.pendingForecastReminderEmailBodyText', 'HOME.' + this.months])
      .subscribe(data => {
        translatedEmailBodyText = data['HOME.pendingForecastReminderEmailBodyText'];
        translatedMonth = data['HOME.' + this.months];
      });
    } */

    /* for (let month of this.months) {
      this.translate.get(['HOME.' + month])
      .subscribe(data => {
        translatedMonth = data['HOME.' + month];
      });
    } */

    this.translate.get(['HOME.pendingForecastReminderEmailBodyText', 'HOME.' + this.months])
      .subscribe(data => {
        translatedEmailBodyText = data['HOME.pendingForecastReminderEmailBodyText'];
      });

    let translatedMonths: string = '';
    this.months.forEach((month, index, array) => {
      this.translate.get(['HOME.' + month])
        .subscribe(data => {
          translatedMonths += data['HOME.' + month];
          if (index !== (array.length - 1)) {
            translatedMonths += ', ';
          }
        });
    });

    let emailTo = (selectedEmailIds && selectedEmailIds.length) ? selectedEmailIds.toString() : '';
    let emailBodyText =
      "<html>"
      + "<head>"
      + "<title></title>"
      + "<style type='text/css'></style>"
      + "</head>"
      + "<body aria-readonly='false'>"
      + translatedEmailBodyText.line1
      + "<br>"
      + "<br>"
      + (this.isYearlyForecastReminder ? translatedEmailBodyText.line2_yearly : translatedEmailBodyText.line2_monthly)
      + "<b>"
      + translatedMonths
      + "</b>"
      + "<br>"
      + translatedEmailBodyText.line3a
      + '<a href="https://bitumen.nynas.com/">https://bitumen.nynas.com/</a>'
      + translatedEmailBodyText.line3b
      + "<br>"
      + "<br>"
      + translatedEmailBodyText.line4a
      + '<a href="mailto:info.suomi@nynas.com">info.suomi@nynas.com</a>'
      + translatedEmailBodyText.line4b
      + "<br>"
      + (this.isYearlyForecastReminder ? translatedEmailBodyText.line5_yearly : translatedEmailBodyText.line5_monthly)
      + "<br>"
      + "<br>"
      + translatedEmailBodyText.line6
      + "<br>"
      + "<br>"
      + translatedEmailBodyText.line7
      + "<br>"
      + translatedEmailBodyText.line8
      + "<br>"
      + "<br>"
      + "<p style='color:Red'>"
      + "<i>" + translatedEmailBodyText.line9 + "</i>"
      + "</p>"
      + "</body>"
      + "</html>";
    let emailSubject = translatedEmailBodyText.subject + translatedMonths;
    this.pendingForecastService.sendEmailNotification(emailTo, emailBodyText, emailSubject)
      .subscribe(
        response => {
          if (response['Status'] && response['Status'].toUpperCase() === 'SUCCESS') {
            this.openDialog("", "", true, 'pending-forecast-success', '');
            this.dialogRef.close(true);
          } else {
            this.openDialog("", "", false, 'pending-forecast-fail', '');
          }
        });
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  selectAllAdhesiveProductsToSave() {
    for (let priceObject of this.dataSourceForAdhesivePricesTable.data) {
      priceObject['isSelected'] = !this.isAllAdhesiveProductsSelected;
    }
  }

  openDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string, emailTo: string, extraParams?: any) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,
        emailTo: emailTo,
        extraParams: extraParams
      },
      // used auto for height, as pop up will display list of email ids vertically
      // height: 'auto',
      // height: '35%',
      // width: (this.mobileQuery.matches ? '80%' : '25%'),
      // width: 'auto',
      height: '35%',
      width: (this.mobileQuery.matches ? '80%' : '25%'),
    });
  }

  getMonthsForYearlyReminder(): void {
    if (this.months && this.months.length && this.months[0] === 'TOTAL') {
      let currentAndFutureMonths = [], includeMonth: boolean, currentMonth = this.getCurrentMonth();
      for (let month of this.monthsList) {
        if (includeMonth || (month === this.monthsList[currentMonth])) {
          includeMonth = true;
          currentAndFutureMonths.push(month);
        }
      }
      // this.months = currentAndFutureMonths.toString();
      this.months = currentAndFutureMonths;

      this.isYearlyForecastReminder = true;
    }
  }

  getCurrentMonth(): number {
    const date = this.sharedService.getCurrentDateTime();
    return ((date && date.getMonth()) ? date.getMonth() : 0);
  }

}
