import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserManagementService {

  private selectedItemSource = new BehaviorSubject('Manage users');
  currentSelectedItem = this.selectedItemSource.asObservable();

  private isMyProfileSource = new BehaviorSubject(false);
  currentIsMyProfile = this.isMyProfileSource.asObservable();

  constructor() { }

  updateSelectedItem(selectedItem: string) {
    this.selectedItemSource.next(selectedItem)
  }

  updateIsMyProfile(isMyProfile: boolean) {
    this.isMyProfileSource.next(isMyProfile)
  }

}