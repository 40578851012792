import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forecastactualfinland',
  templateUrl: './forecastactualfinland.component.html',
  styleUrls: ['./forecastactualfinland.component.css']
})
export class ForecastactualfinlandComponent implements OnInit {

  forecastActualValue: string = 'monthly';
  constructor() { }

  ngOnInit() {
  }

}
