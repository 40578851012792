import {Component, HostBinding, Input, OnInit, Output,EventEmitter} from '@angular/core';
import {NavItem} from '../dashboard-nav/nav-items';
import {NavigationExtras,Router} from '@angular/router';
import {NavService} from '../dashboard-nav/nav-service';
import { AppService } from '../shared-modules/services/shared.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('expand_less', style({transform: 'rotate(0deg)'})),
      state('expand_more', style({transform: 'rotate(180deg)'})),
      transition('expand_less <=> expand_more',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean = false;
  userInfo: any;
  emailId: string;
  selectedLanguage:string;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  @Input() displayAccess: boolean;
  @Output() pageTitle =new EventEmitter();
  customerId: any;
  constructor(public navService: NavService,
              public router: Router,private sharedService: AppService,
              public translate:TranslateService ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.displayAccess=true;
    }
    this.userInfo = this.sharedService.getLoginResponse();
    this.emailId = this.userInfo ? this.userInfo.UserID : '';
    this.customerId = this.userInfo ? this.userInfo.CustomerID : '';
  
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
       //  console.log(`Checking '/${this.item.route}' against '${url}'`);
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
        this.displayAccess=!this.item.disabled;
       // console.log('Display Access',this.item);
        // console.log(`${this.item.route} is expanded: ${this.expanded}`);
      }
    });
  }

  onItemSelected(item: NavItem) {
    
    if (!item.children || !item.children.length) {
     
      if(item.route=='app/my-profile')
      {
        const navigationExtras: NavigationExtras = {
          state: {
            emailId:this.emailId,
            isMyProfile: true,
            customerId: this.customerId
          }
        };
        this.router.navigate([item.route],navigationExtras);
      }
      else{
        this.router.navigate([item.route]);
      }
      //this.navService.closeNav();
      // this.pageTitle.emit(item.displayName);
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
     
    }
    this.pageTitle.emit(this.getDisplayName(item));
      
  }
  displayRoute(data){
    this.pageTitle.emit(data);

  }
  updateLanguage(selectedLanguage: string) {
    this.selectedLanguage = selectedLanguage;
    this.translate.use(selectedLanguage);
  }
  getDisplayName(item: any): string {
    var displayNameKey;
    for (let prop in item) {
      if (prop && prop.toString().startsWith('displayName')) {
         displayNameKey = prop;
         break; 
        }
    }
    return item[displayNameKey];
  }


 
}
