import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { environment } from '../../../environments/environment';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    apiUrl = environment.apiUrl;

    loginResponseSource: any;
    currentLoginResponse: any;
    hideCookie: boolean = false;
    selectedLanguage: string = 'FI';
    currentDateTime: Date;
    finlandHomePageGraphAxisLowerLimit: number;
    finlandHomePageGraphAxisUpperLimit: number;
    conversionRateList: any;
    hsfoUSDPerMTList: any;
    monthlyConversionRateList: any;
    monthlyHSFOUSDPerMTList: any;
    monthlyHSFOEURPerMTList: any;
    isMobileDevice: boolean;
    hideBannerMessage: boolean;
    public static ENGLISH_JSON_FILE_NAME: string = "EN";
    enJSONFileObject: any = {};

    constructor(private http: HttpClient, private translate: TranslateService) {
        this.readDataFromJSONFile(AppService.ENGLISH_JSON_FILE_NAME).subscribe
            (response => {
                if (response && response['HOME']) {
                    this.enJSONFileObject = response['HOME'];
                }
            });
    }

    setLoginResponse(currentLoginResponse: any) {
        this.currentLoginResponse = currentLoginResponse;
    }
    getLoginResponse() {
        return this.currentLoginResponse;
    }

    setCookie(hideCookie: boolean) {
        this.hideCookie = hideCookie;
    }
    getCookie() {
        return this.hideCookie;
    }

    setSelectedLanguage(selectedLanguage: string) {
        this.selectedLanguage = selectedLanguage;
    }
    getSelectedLanguage() {
        return this.selectedLanguage;
    }
    setCurrentDateTime(currentDateTime: Date) {
        this.currentDateTime = (currentDateTime ? currentDateTime : new Date());
    }
    getCurrentDateTime(): Date {
        return (this.currentDateTime ? new Date(this.currentDateTime) : new Date());

        // To test the application for a particular datetime instead of server datetime, pass datetime in mm/dd/yyyy format below
        // return new Date('02/26/2021');
    }

    setFinlandHomePageGraphAxisLowerLimit(finlandHomePageGraphAxisLowerLimit: number) {
        this.finlandHomePageGraphAxisLowerLimit = finlandHomePageGraphAxisLowerLimit;
    }
    getFinlandHomePageGraphAxisLowerLimit() {
        return (this.finlandHomePageGraphAxisLowerLimit ? +this.finlandHomePageGraphAxisLowerLimit : 0);
    }

    setFinlandHomePageGraphAxisUpperLimit(finlandHomePageGraphAxisUpperLimit: number) {
        this.finlandHomePageGraphAxisUpperLimit = finlandHomePageGraphAxisUpperLimit;
    }
    getFinlandHomePageGraphAxisUpperLimit() {
        return (this.finlandHomePageGraphAxisUpperLimit ? +this.finlandHomePageGraphAxisUpperLimit : 0);
    }

    setConversionRateList(conversionRateList: any) {
        this.conversionRateList = conversionRateList;
    }
    getConversionRateList() {
        return (this.conversionRateList ? this.conversionRateList : []);
    }

    setHSFOUSDPerMTList(hsfoUSDPerMTList: any) {
        this.hsfoUSDPerMTList = hsfoUSDPerMTList;
    }
    getHSFOUSDPerMTList() {
        return (this.hsfoUSDPerMTList ? this.hsfoUSDPerMTList : []);
    }

    setMonthlyConversionRateList(monthlyConversionRateList: any) {
        this.monthlyConversionRateList = monthlyConversionRateList;
    }
    getMonthlyConversionRateList() {
        return (this.monthlyConversionRateList ? this.monthlyConversionRateList : []);
    }

    setMonthlyHSFOEURPerMTList(monthlyHSFOEURPerMTList: any) {
        this.monthlyHSFOEURPerMTList = monthlyHSFOEURPerMTList;
    }
    getMonthlyHSFOEURPerMTList() {
        return (this.monthlyHSFOEURPerMTList ? this.monthlyHSFOEURPerMTList : []);
    }

    setMonthlyHSFOUSDPerMTList(monthlyHSFOUSDPerMTList: any) {
        this.monthlyHSFOUSDPerMTList = monthlyHSFOUSDPerMTList;
    }
    getMonthlyHSFOUSDPerMTList() {
        return (this.monthlyHSFOUSDPerMTList ? this.monthlyHSFOUSDPerMTList : []);
    }

    setIsMobileDevice(value: boolean) {
        this.isMobileDevice = value;
    }
    getIsMobileDevice(): boolean {
        return this.isMobileDevice;
    }

    setHideBannerMessage(hideBannerMessage: boolean) {
        this.hideBannerMessage = hideBannerMessage;
    }
    getHideBannerMessage() {
        return this.hideBannerMessage;
    }

    readDataFromJSONFile(fileName: string): Observable<any> {
        return this.http.get("../../../assets/i18n/" + fileName + ".json");
    }

    downloadDataInExcelFile(data: any[], excelFileName: string, headerList: any): void {
        if (data) {
            let translatedHeaderList: any = [], enJSONFileArray: any = [];
            if (this.enJSONFileObject) {

                // Reverse object elements, to match latest records first
                Object.keys(this.enJSONFileObject)
                    .reverse()
                    .forEach(key => {
                        enJSONFileArray.push({
                            'key': key,
                            'value': this.enJSONFileObject[key]
                        })
                    })

                for (let header of headerList) {
                    for (let englishKeyValueObject of enJSONFileArray) {
                        if (header && englishKeyValueObject['value'] && (header.toUpperCase() === englishKeyValueObject['value'].toString().toUpperCase())) {
                            this.translate.get(['HOME.' + englishKeyValueObject['key']])
                                .subscribe(data => {
                                    translatedHeaderList.push((data['HOME.' + englishKeyValueObject['key']]));
                                });
                            break;
                        }
                    }
                }

                // Download data into excel file
                const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header: headerList });
                XLSX.utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: 'A2', dateNF: 'DD-MMM-YY;@', cellDates: true });
                XLSX.utils.sheet_add_aoa(worksheet, [translatedHeaderList]);
                const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
                const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const Report: Blob = new Blob([excelBuffer], {
                    type: EXCEL_TYPE
                });
                FileSaver.saveAs(Report, excelFileName + EXCEL_EXTENSION);
            }
        }
    }

    getTranslatedText(translateObject: any) {
        if (this.enJSONFileObject && translateObject && translateObject['originalText']) {

            let enJSONFileArray: any = [];

            // Reverse object elements, to match latest records first
            Object.keys(this.enJSONFileObject)
                .reverse()
                .forEach(key => {
                    enJSONFileArray.push({
                        'key': key,
                        'value': this.enJSONFileObject[key]
                    })
                })

            for (let englishKeyValueObject of enJSONFileArray) {
                if (translateObject['originalText'] && englishKeyValueObject['value'] && (translateObject['originalText'].toUpperCase() === englishKeyValueObject['value'].toString().toUpperCase())) {
                    this.translate.get(['HOME.' + englishKeyValueObject['key']])
                        .subscribe(data => {
                            translateObject['translatedText'] = (data['HOME.' + englishKeyValueObject['key']]);
                        });
                    break;
                }
            }
        }
    }

}

