
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Customer } from 'src/app/user-management/customer';
import { LoginHistoryService} from './login-history.component.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { CommonService } from '../order-management/order-management/order-management.component.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FormBuilder, AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {formatDate} from '@angular/common';
import { DatePipe } from '@angular/common';
// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'DD-MM-YYYY',
//   },
//   display: {
//     dateInput: 'DD-MM-YYYY',
//     monthYearLabel: 'YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'YYYY'
//   }
// };

@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.css'],
  // providers: [
  //   // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
  //     // application's root module. We provide it at the component level here, due to limitations of
  //     // our example generation script.
  //     {
  //       provide: DateAdapter,
  //       useClass: MomentDateAdapter,
  //       deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  //     },
   
  //     { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  //   ]
  })


export class LoginHistoryComponent implements OnInit {
  

  public displayedColumns :string[]  = [ 'COUNTRY_CODE', 'USER_NAME', 'USER_ID', 'ROLE_ID', 
  'STATUS', 'CUSTOMER_NAME','USER_GROUP_NAME', 'PROFILE_CREATED', 'FIRST_LOGIN',
  'LAST_LOGIN_TIME', 'NO_OF_DAYS_SINCE_LOGIN', 'NO_OF_DAYS_LOGIN', 
  'NO_OF_TIMES_LOGIN', 'MESSAGE',  ];
  dataSource: any;
  
  FromDate:any;
  customerIdUserSelected: string;
  Customers: any;
  fetchedDataForItemArray = [];
  CustomerID: string;
  userInfo : any;
  Country:any;
  date: any;
  countryCode:string;
  loginhistorydata:any;
  loginhistorydataCopy:any;
  displayTable: boolean = true;
  loginhistoryForm : FormGroup;
  dateD:string;
  customerList: Customer[] = [];
  mobileQuery: MediaQueryList;



  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  currentDate : any;
  newDate : any;
  

  constructor(   private dialog: MatDialog, private commonService: CommonService, private formBuilder: FormBuilder,private loginhistoryService: LoginHistoryService,private sharedService: AppService,private datePipe: DatePipe,private dateAdapter: DateAdapter<any>,) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.countryCode = this.userInfo.CountryCode;


    
   }


   

  ngOnInit() {
    
  
  
    this.getLoginHistory();
    this.loginhistoryService.getCustomerList(this.countryCode)
    .subscribe(
      res => {
        if (res["ResponseCode"] == 200) {
          for (let index of res["Customers"]) {
            // this.CustomerList.push(index);
            this.customerList.push(new Customer(index["CustomerID"], index["CustomerName"]));
          }
        }
      });

  }

 

  getLoginHistory() {
    var actualDate: any ;
    var actualMonth: any; 
    var dateString = new Date(this.FromDate).getDate();
    console.log('dateString'+dateString);
    var monthString = new Date(this.FromDate).getMonth()+ 1; 
    console.log('monthString'+monthString);
    var yearString = new Date(this.FromDate).getFullYear();
    console.log('yearString'+yearString);
    
    if(dateString<10) {
      actualDate = '0'+ dateString;
  } 
  else{
    actualDate = dateString; 
  }
  if(monthString<10) {
    actualMonth = '0'+ monthString;
  }
    else{
      actualMonth = monthString;
    }
  
  var formattedDate = actualDate +"-" + actualMonth +"-"+ yearString;
    console.log('formattedDate'+formattedDate);
  //  console.log(this.FromDate);
 
    this.loginhistoryService.getLoginHistory( this.CustomerID,this.countryCode,formattedDate)
      .subscribe(
        res => {
          if (res["ResponseCode"] == "200") {     
            this.displayTable = true;     
                  
            this.loginhistorydata = res['Collection'];
            this.loginhistorydataCopy = this.loginhistorydata;
           
 
            
            this.dataSource = new MatTableDataSource(this.loginhistorydataCopy);
           
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            
          } 
          else
          

            
            if (res['ResponseCode'] === '201' && res['ResponseStatus'] && res['ResponseStatus'] === 'Data not Available') {
              this.openSaveUserDialog('Data Not Available.. Please select valid date', null, 'login-history-fail');
              this.displayTable = false;
          }
          else {
            this.displayTable = false;
            
          }
 
        }
      );
  
  }
 
  openSaveUserDialog(headerTitle: string, message: string, headerNumber:string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        
        headerNumber:headerNumber
      },
			
		
    });
  }
  
  

}





 

