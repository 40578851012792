import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AppService } from '../shared-modules/services/shared.service';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { UpdatePasswordService } from './update-password.service';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {

  message: string = "";
  cancelButtonText = "Cancel";
  form1: FormGroup;
  isFirstLogin: boolean;
  userInfo: any;
	mobileQuery: MediaQueryList;
	private _mobileQueryListener: () => void; 

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<UpdatePasswordComponent>,
    private updatePasswordService: UpdatePasswordService,
    private dialog: MatDialog,
    fb: FormBuilder,
    private router: Router,
		private sharedService: AppService,
		private changeDetectorRef: ChangeDetectorRef, 
		private media: MediaMatcher) {
		this.userInfo = this.sharedService.getLoginResponse();
    this.form1 = fb.group({
      'oldPwd': ['', Validators.required],

      // Commented old regex code, kept for future reference
      // 'newPwd': ['', [Validators.required ,  Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],

      // The given regex will allow the following in password:
      // at least 8 characters
      // at least 1 numeric character
      // at least 1 lowercase letter
      // at least 1 uppercase letter
      // at least 1 special character
      'newPwd': ['', [Validators.required, Validators.pattern('^(?=[^A-Z\\s]*[A-Z])(?=[^a-z\\s]*[a-z])(?=[^\\d\\s]*\\d)(?=\\w*[\\W_])\\S{8,}$')]],

      'confirmPwd': ['', Validators.required]
    });
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
    // this.dialogRef.updateSize('300vw', '350vw');
    this.isFirstLogin = (this.userInfo ? (this.userInfo.FirstLoginFlag === 'True') : false);    
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
  }

	ngOnDestroy(): void {
	  this.mobileQuery.removeListener(this._mobileQueryListener);
	}

  get oldPwd() {
    return (this.form1.get('oldPwd') ? this.form1.get('oldPwd').value : null);
  }

  get newPwd() {
    return (this.form1.get('newPwd') ? this.form1.get('newPwd').value : null);
  }

  get confirmPwd() {
    return (this.form1.get('confirmPwd') ? this.form1.get('confirmPwd').value : null);
  }

  changePassword(): void {
     console.log("tst" + this.form1.valid);
    // // if(this.newPwd.length <= 8 ){
    //   console.log("length of pwd" + this.newPwd.length)
    // // }
    // var test : Boolean = false ;
   
    if (!this.form1.valid){
      if (this.newPwd.length < 8 ){
        //test = this.form1.valid;
        this.openChangePasswordDialog('Password pattern length', '', false,'pwd-length');
      }
      else {
        this.openChangePasswordDialog('Password pattern unmatch', '', false,'pwd-pattern');
      }
     
    }
    else if  (this.form1.valid ){

    
    if (this.newPwd === this.confirmPwd) {
      var userObject = new Object();
      userObject['NewPassword'] = this.newPwd;
      userObject['UserId'] = this.userInfo ? this.userInfo.UserID : '';
      userObject['CurrentPassword'] = this.oldPwd;
      // if(!this.form1.valid){
      //   this.openChangePasswordDialog('Password pattern ', '', false,'test');
      // }
      
      this.updatePasswordService.updatePassword(userObject)
        .subscribe(
          response => {
            this.changePasswordStatusDialog(response);
          });
      this.dialogRef.close(true);
     
  }
  else {
    this.openChangePasswordDialog('Password provided does not match with the confirm password', '', false,'passwords-do-not-match');
  }
}
  // else {
  //  
  // }

  
  }

  changePasswordStatusDialog(response: any) {
    if (response && response['ResponseCode'] === '200' && response['ResponseStatus']  && response['ResponseStatus'].toUpperCase()=== 'SUCCESS' && response['Description'] === "Password changed Successfully") {
      this.openChangePasswordDialog('', ' ', true,'update-password-success');
    } else if (response && response['ResponseCode'] === '201' && response['ResponseStatus']  && response['ResponseStatus'].toUpperCase() === 'ERROR' && response['Description'] === "password not matching with old password") {
      this.openChangePasswordDialog('Failed to Change Password', '', false,'update-password-fail');
    } else {
      this.openChangePasswordDialog('Failed to Change Password', '', false,'update-password-fail');
    }
  }

  openChangePasswordDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        isSuccess: isSuccess,
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        }
      },
      // height: '39%',
      height: 'auto',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  cancel(): void {
    this.dialogRef.close(true);
  }
}
