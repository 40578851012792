import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class AddPriceIndexService {
 
  apiUrl = environment.apiUrl;
 
  constructor(private httpClient: HttpClient) { }
 
  updateIndex(priceIndex: any, toggleButtonMenu: string) {
 
    let url = this.apiUrl + '/UpdateIndex';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };
    let postData: any;
    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'DAILY') {
      postData = JSON.stringify(
        {
          "IndexCollection": [{
            "Type": toggleButtonMenu.toUpperCase(),
            "Date": priceIndex.IndexDate,
            "Rate": priceIndex.Rate,
            "UOM": priceIndex.UOM,
            "Source": priceIndex.Source,
            "PriceNotation": priceIndex.PriceNotation,
            "CREATED_BY": priceIndex.CreatedBy,
            "UPDATED_BY": priceIndex.UpdatedBy,
            "CREATED_ON": priceIndex.CreatedOn,
            "UPDATED_ON": priceIndex.UpdatedOn
          }]
        }
      );
    }
    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'MONTHLY') {
      postData = JSON.stringify(
        {
          "IndexCollection": [{
            "Type": toggleButtonMenu.toUpperCase(),
            "Month": priceIndex.Month,
            "Year": priceIndex.Year,
            "Rate": priceIndex.Rate,
            "UOM": priceIndex.UOM,
            "Source": priceIndex.Source,
            "PriceNotation": priceIndex.PriceNotation,
            "CREATED_BY": priceIndex.CreatedBy,
            "UPDATED_BY": priceIndex.UpdatedBy,
            "CREATED_ON": priceIndex.CreatedOn,
            "UPDATED_ON": priceIndex.UpdatedOn
          }]
        }
      );
    }
    return this.httpClient.post(url, postData, httpOptions).pipe();
  }

  addPriceIndex(priceIndex: any, toggleButtonMenu: string) {

    let url = this.apiUrl + '/AddPriceIndex';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };
    let postData: any;
    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'DAILY') {
      postData = JSON.stringify(
        {
          "IndexCollection": [{
            "Type": toggleButtonMenu.toUpperCase(),
            "Date": priceIndex.IndexDate,
            "Rate": priceIndex.Rate,
            "UOM": priceIndex.UOM,
            "Source": priceIndex.Source,
            "PriceNotation": priceIndex.PriceNotation,
            "CREATED_BY": priceIndex.CreatedBy,
            "UPDATED_BY": priceIndex.UpdatedBy,
            "CREATED_ON": priceIndex.CreatedOn,
            "UPDATED_ON": priceIndex.UpdatedOn
          }]
        }
      );
    }
    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'MONTHLY') {
      postData = JSON.stringify(
        {
          "IndexCollection": [{
            "Type": toggleButtonMenu.toUpperCase(),
            "Month": priceIndex.Month,
            "Year": priceIndex.Year,
            "Rate": priceIndex.Rate,
            "UOM": priceIndex.UOM,
            "Source": priceIndex.Source,
            "PriceNotation": priceIndex.PriceNotation,
            "CREATED_BY": priceIndex.CreatedBy,
            "UPDATED_BY": priceIndex.UpdatedBy,
            "CREATED_ON": priceIndex.CreatedOn,
            "UPDATED_ON": priceIndex.UpdatedOn
          }]
        }
      );
    }
    return this.httpClient.post(url, postData, httpOptions).pipe();
  }
 
  getPriceIndexForTypeMonthly(year: string) {
    let type = 'MONTHLY';
    const url = this.apiUrl + '/GetMonthlyPriceIndex';
    return this.httpClient
      .get(url, {
        params: {
          Type: type,
          Year: year
        }
      });
  }
 
  getPriceIndexForTypeDaily() {
    let type = 'DAILY';
    let records = '365';
    const url = this.apiUrl + '/GetDailyPriceIndex';
    return this.httpClient
      .get(url, {
        params: {
          Type: type,
          Records: records
        }
      });
  }

  getDisplayMessage(type: string) {
      let url = this.apiUrl + '/GetDisplayMessage';
      return this.httpClient.get(url, {
          params: {
              Type: type
          }
      });
  }
 
}