import { MediaMatcher } from '@angular/cdk/layout';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, MAT_DATE_FORMATS } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';
import { ForecastEntryService } from 'src/app/order-management/forecast-entry/forecast-entry.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import * as XLSX from 'xlsx';
import { DialogBodyComponent } from '../../user-management/dialog-body/dialog-body.component';
import { ForecastactualweeklyswedenService } from '../forecastactualweeklysweden/forecastactualweeklysweden.service';
import { DeliveryAnalysisService } from './delivery-analysis.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YY',
  },
  display: {
    dateInput: 'DD-MMM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-delivery-analysis',
  templateUrl: './delivery-analysis.component.html',
  styleUrls: ['./delivery-analysis.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DecimalPipe
  ]
})

export class DeliveryAnalysisComponent implements OnInit {

  dataSource: any;
  isRequestCompleted: boolean;
  countryCode: string;
  userInfo: any;
  Customers = [];
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  loggedInUserName: string;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayTable: boolean;
  customerIdUserSelected: string;
  deliverySiteList: any = [];
  deliverySiteUserSelected: string;
  customerList = [];
  customerId: string;
  customerName: string;
  isLoggedInUserPortalAdmin: boolean;
  isLoggedInUserAdmin: boolean;
  role: string;
  userID: string;
  deliverySiteObjectList: any = [];
  startDate: Date;
  endDate: Date;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumnsDailyReports: any = ['CUSTOMER_PO', 'DEPOT_NAME', 'ACTUAL_SHIP_DATE', 'DELIVERY_SITE',
    'PRODUCT_NAME', 'SHIPPED_QTY', 'TEST', 'RESULT', 'UOM', 'PRODUCT_TYPE_DESC', 'ORDER_NUMBER'];
  @ViewChild('dailyReportTableSort', { static: true }) public dailyReportTableSort: MatSort;
  january012020Date: any = new Date('01/01/2020');
  fromDateIsOlderThan1stJanuary2020: boolean;
  isFindButtonClicked: boolean;
  globalSearchText: string;
  showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;
  selectedLanguage: string;

  constructor(
    private forecastEntryService: ForecastEntryService,
    private deliveryAnalysisService: DeliveryAnalysisService,
    private sharedService: AppService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private forecastactualweeklyswedenService: ForecastactualweeklyswedenService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService,
    private decimalPipe: DecimalPipe,
    private globalSearchService: GlobalSearchService
  ) {
    this.userInfo = this.sharedService.getLoginResponse();
    if (this.userInfo) {
      this.countryCode = (this.userInfo.CountryCode ? this.userInfo.CountryCode : '');
      this.customerId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
      this.customerName = (this.userInfo.CustomerName ? this.userInfo.CustomerName : '');
      this.isLoggedInUserPortalAdmin = (this.userInfo.RoleID === 'PA');
      this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
      this.role = (this.userInfo ? this.userInfo.RoleID : '');
      this.userID = (this.userInfo ? this.userInfo.UserID : '');
      this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => cd.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }
  }

  async ngOnInit() {
    this.translate.get(['HOME.languageCode'])
      .subscribe(data => {
        this.selectedLanguage = data['HOME.languageCode'];
      });
    this.translate.get(['HOME.selectedLanguageForDatePicker'])
      .subscribe(data => {
        this.dateAdapter.setLocale(data['HOME.selectedLanguageForDatePicker']);
      });
    this.isFindButtonClicked = false;
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.getDefaultFromAndToDate();
    try {
      await this.getCustomerList();
    }
    catch (e) {
    }

    try {
      await this.getDeliverySites();
    }
    catch (e) {
    }

    // Load data on load
    this.getDeliveryAnalysisDetails();

    this.subscribeToGlobalSearchServiceSearchTerm();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.globalSearchText = '';
  }

  async getCustomers() {
    this.Customers = [];
    return new Promise<void>((resolve, reject) => {
      this.forecastEntryService.getCustomerNames(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.Customers = res["Customers"]
            if (this.userInfo) {
              this.customerIdUserSelected = (this.userInfo.RoleID === "PA") ? '' : (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
            } else {
              this.customerIdUserSelected = '';
            }
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  async getCustomerList() {
    this.customerList = [];
    return new Promise<void>((resolve, reject) => {
      this.forecastactualweeklyswedenService.getCustomerList(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.customerList = res["Customers"];
            this.customerIdUserSelected = this.customerId;
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  async getDeliverySites() {
    this.deliverySiteList = [];
    this.deliverySiteObjectList = [];
    this.deliverySiteUserSelected = '';
    return new Promise<void>((resolve, reject) => {
      let showUserDataOnly = ((this.countryCode === 'SWE' || this.countryCode === 'NOR' || this.countryCode === 'GBR' || this.countryCode === 'DNK') && (this.userInfo && this.userInfo.RoleID === "U"));
      this.deliveryAnalysisService.fetchDeliverySitesData(this.userID, this.countryCode, "", this.customerIdUserSelected, showUserDataOnly).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            let deliverySiteList = (response["DeliverySiteCollection"] || response["DeliverySites"]);
            for (let deliverySiteObject of deliverySiteList) {
              this.deliverySiteObjectList.push(deliverySiteObject);
              this.deliverySiteList.push(deliverySiteObject["DeliverySite"] || deliverySiteObject["DeliverySiteName"]);
              this.deliverySiteUserSelected = '';
            }
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  getDeliveryAnalysisDetails() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;

    if (!this.validateFromAndToDate()) {
      return;
    }

    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      let showUserDataOnly = ((this.countryCode === 'SWE' || this.countryCode === 'NOR' || this.countryCode === 'GBR' || this.countryCode === 'DNK') && (this.userInfo && this.userInfo.RoleID === "U"));
      this.deliveryAnalysisService.fetchDailyAnalysisDetails(this.countryCode, this.getFormattedDate(this.startDate), this.getFormattedDate(this.endDate), this.userID, this.customerIdUserSelected, jdeAddressNumber, showUserDataOnly).subscribe
        (response => {

          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let dailyReportForAll = [];
            let dailyReportObject = {};
            let customerCollection = response["CustomerCollection"];

            if (customerCollection && customerCollection.length) {
              for (let customerObject of customerCollection) {
                if (customerObject && customerObject['DeliverySiteCollection'] && customerObject['DeliverySiteCollection'].length) {
                  let customerName = customerObject['CUSTOMER_NAME'];
                  for (let deliverySiteObject of customerObject['DeliverySiteCollection']) {
                    if (deliverySiteObject && deliverySiteObject['DetailsCollection'] && deliverySiteObject['DetailsCollection'].length) {
                      let deliverySiteName = deliverySiteObject['DELIVERY_SITE'];
                      for (let detailsObject of deliverySiteObject['DetailsCollection']) {
                        dailyReportObject['CUSTOMER_NAME'] = customerName;
                        dailyReportObject['DELIVERY_SITE'] = deliverySiteName;
                        dailyReportObject['CUSTOMER_PO'] = detailsObject['CUSTOMER_PO'];
                        dailyReportObject['PRODUCT_NAME'] = detailsObject['PRODUCT_NAME'];
                        dailyReportObject['DEPOT_NAME'] = detailsObject['DEPOT_NAME'];
                        dailyReportObject['SHIPPED_QTY'] = detailsObject['SHIPPED_QTY'];
                        dailyReportObject['ACTUAL_SHIP_DATE'] = detailsObject['ACTUAL_SHIP_DATE'];
                        dailyReportObject['ORDER_NUMBER'] = detailsObject['ORDER_NUMBER'];
                        dailyReportObject['TEST'] = detailsObject['TEST'];
                        dailyReportObject['ORDER_NUMBER'] = detailsObject['ORDER_NUMBER'];
                        dailyReportObject['RESULT'] = detailsObject['RESULT'];
                        dailyReportObject['UOM'] = detailsObject['UOM'];
                        dailyReportObject['PRODUCT_TYPE_DESC'] = detailsObject['PRODUCT_TYPE_DESC'];
                        dailyReportForAll.push(dailyReportObject);
                        dailyReportObject = {};
                      }
                    }
                  }
                }
              }
            }
            this.dataSource = new MatTableDataSource(dailyReportForAll);
            this.dataSource.sort = this.dailyReportTableSort;
            this.dataSource.paginator = this.paginator;

            this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
              if (data[sortHeaderId] && sortHeaderId !== 'ACTUAL_SHIP_DATE' && sortHeaderId !== 'INVOICE_DATE' && sortHeaderId !== 'REQUESTED_DATE') {
                return data[sortHeaderId].toLocaleLowerCase();
              } else if (data[sortHeaderId] && (sortHeaderId === 'ACTUAL_SHIP_DATE' || sortHeaderId === 'INVOICE_DATE' || sortHeaderId === 'REQUESTED_DATE')) {
                let date = data[sortHeaderId] ? data[sortHeaderId] : '';
                return new Date(date);
              }
              return data[sortHeaderId];
            };

            this.displayTable = (dailyReportForAll && dailyReportForAll.length && dailyReportForAll.length > 0);
            this.isRequestCompleted = true;
            this.isFindButtonClicked = true;

            resolve();

          } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
          else {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
        })
    });
  }

  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        let deliverySiteObjectName = (deliverySiteObject['DeliverySite'] || deliverySiteObject['DeliverySiteName']);
        if (deliverySiteObject && deliverySiteObjectName && deliverySiteObjectName === deliverySiteName) {
          jdeAddressNumber = (deliverySiteObject['JdeAddressNumber'] || deliverySiteObject['JDE_Address_Number']);
          break;
        }
      }
    }
    return jdeAddressNumber;
  }

  getFormattedDate(date: Date): string {
    if (date) {
      var datePipe = new DatePipe("en-US");
      return datePipe.transform(new Date(date), 'dd-MMM-yy').toLocaleUpperCase();
    }
    return '';
  }

  getDefaultFromAndToDate() {
    let currentDateTime = this.sharedService.getCurrentDateTime();
    let currentYear = currentDateTime.getFullYear();
    let currentMonth = currentDateTime.getMonth() + 1;
    this.startDate = new Date(currentMonth + '/01/' + currentYear); // Get date of 1st January of current year
    this.endDate = currentDateTime;
  }

  // Commented old code, kept for future reference
  /* downloadReportsData(customerId: string) {
    if (!this.validateFromAndToDate()) {
      return;
    }
    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      let showUserDataOnly = ((this.countryCode === 'SWE' || this.countryCode === 'NOR' || this.countryCode === 'GBR' || this.countryCode === 'DNK') && (this.userInfo && this.userInfo.RoleID === "U"));
      this.deliveryAnalysisService.fetchDailyAnalysisDetails(this.countryCode, this.getFormattedDate(this.startDate), this.getFormattedDate(this.endDate), this.userID, customerId, (customerId ? jdeAddressNumber : ''), showUserDataOnly).subscribe
        (response => {
          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let deliveryAnalysisReportForAll = [];
            let deliveryAnalysisReportObject = {};
            let customerCollection = response["CustomerCollection"];
            if (customerCollection && customerCollection.length) {
              for (let customerObject of customerCollection) {
                if (customerObject && customerObject['DeliverySiteCollection'] && customerObject['DeliverySiteCollection'].length) {
                  let customerName = customerObject['CUSTOMER_NAME'];
                  for (let deliverySiteObject of customerObject['DeliverySiteCollection']) {
                    if (deliverySiteObject && deliverySiteObject['DetailsCollection'] && deliverySiteObject['DetailsCollection'].length) {
                      let deliverySiteName = deliverySiteObject['DELIVERY_SITE'];
                      for (let detailsObject of deliverySiteObject['DetailsCollection']) {

                        if (!customerId) {
                          deliveryAnalysisReportObject['Customer name'] = customerName;
                        }

                        if (this.countryCode === 'SWE' || this.countryCode === 'DNK') {
                          deliveryAnalysisReportObject['Delivery ticket number'] = detailsObject['CUSTOMER_PO'];
                          deliveryAnalysisReportObject['Ship date'] = new Date(detailsObject['ACTUAL_SHIP_DATE']);
                        } else {
                          deliveryAnalysisReportObject['Customer PO number'] = detailsObject['CUSTOMER_PO'];
                          deliveryAnalysisReportObject['Actual ship date'] = new Date(detailsObject['ACTUAL_SHIP_DATE']);
                        }
                        deliveryAnalysisReportObject['Loading Point'] = detailsObject['DEPOT_NAME'];
                        deliveryAnalysisReportObject['Delivery place'] = deliverySiteName;
                        deliveryAnalysisReportObject['Product'] = detailsObject['PRODUCT_NAME'];
                        deliveryAnalysisReportObject['Quantity MT'] = detailsObject['SHIPPED_QTY'];
                        deliveryAnalysisReportObject['Test'] = detailsObject['TEST'];
                        deliveryAnalysisReportObject['Result'] = detailsObject['RESULT'];
                        deliveryAnalysisReportObject['UOM'] = detailsObject['UOM'];
                        deliveryAnalysisReportObject['Type of product'] = detailsObject['PRODUCT_TYPE_DESC'];
                        deliveryAnalysisReportObject['Nynas order number'] = detailsObject['ORDER_NUMBER'];

                        deliveryAnalysisReportForAll.push(deliveryAnalysisReportObject);
                        deliveryAnalysisReportObject = {};
                      }
                    }
                  }
                }
              }
            }

            if (deliveryAnalysisReportForAll && deliveryAnalysisReportForAll.length) {
              let headerList = ['Customer PO number', 'Loading Point', 'Actual ship date', 'Delivery place', 'Product', 'Quantity MT',
                'Test', 'Result', 'UOM', 'Type of product', 'Nynas order number'];

              if (this.countryCode === 'SWE' || this.countryCode === 'DNK') {
                headerList = ['Delivery ticket number', 'Loading Point', 'Ship date', 'Delivery place', 'Product', 'Quantity MT',
                  'Test', 'Result', 'UOM', 'Type of product', 'Nynas order number'];
              }

              if (!customerId) {
                headerList.unshift('Customer name');
              }

              this.exportDataInExcelFile(deliveryAnalysisReportForAll, 'Delivery analysis', headerList);
            } else {
              this.showStatusDialog(false, 'no-data-available');
            }
            resolve();
          } else {
            this.showStatusDialog(false, 'Something-went-wrong');
            reject();
          }
        })
    });
  } */

  downloadReportsData(customerId: string) {
    if (!this.validateFromAndToDate()) {
      return;
    }
    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      let showUserDataOnly = ((this.countryCode === 'SWE' || this.countryCode === 'NOR' || this.countryCode === 'GBR' || this.countryCode === 'DNK') && (this.userInfo && this.userInfo.RoleID === "U"));
      this.deliveryAnalysisService.fetchDailyAnalysisDetails(this.countryCode, this.getFormattedDate(this.startDate), this.getFormattedDate(this.endDate), this.userID, customerId, (customerId ? jdeAddressNumber : ''), showUserDataOnly).subscribe
        (async response => {
          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let deliveryAnalysisReportForAll = [];
            let deliveryAnalysisReportObject = {};
            let customerCollection = response["CustomerCollection"];
            if (customerCollection && customerCollection.length) {
              for (let customerObject of customerCollection) {
                if (customerObject && customerObject['DeliverySiteCollection'] && customerObject['DeliverySiteCollection'].length) {
                  let customerName = customerObject['CUSTOMER_NAME'];
                  for (let deliverySiteObject of customerObject['DeliverySiteCollection']) {
                    if (deliverySiteObject && deliverySiteObject['DetailsCollection'] && deliverySiteObject['DetailsCollection'].length) {
                      let deliverySiteName = deliverySiteObject['DELIVERY_SITE'];
                      for (let detailsObject of deliverySiteObject['DetailsCollection']) {

                        if (!customerId) {
                          deliveryAnalysisReportObject['Customer name'] = customerName;
                        }

                        if (this.countryCode === 'SWE' || this.countryCode === 'DNK') {
                          deliveryAnalysisReportObject['Delivery ticket number'] = detailsObject['CUSTOMER_PO'];
                        } else {
                          deliveryAnalysisReportObject['Customer PO number'] = detailsObject['CUSTOMER_PO'];
                        }
                        deliveryAnalysisReportObject['Loading Point'] = detailsObject['DEPOT_NAME'];
                        if (this.countryCode === 'SWE' || this.countryCode === 'DNK') {
                          deliveryAnalysisReportObject['Ship date'] = new Date(detailsObject['ACTUAL_SHIP_DATE']);
                        } else {
                          deliveryAnalysisReportObject['Actual ship date'] = new Date(detailsObject['ACTUAL_SHIP_DATE']);
                        }
                        deliveryAnalysisReportObject['Delivery place'] = deliverySiteName;
                        deliveryAnalysisReportObject['Product'] = detailsObject['PRODUCT_NAME'];
                        deliveryAnalysisReportObject['Quantity MT'] = detailsObject['SHIPPED_QTY'];
                        deliveryAnalysisReportObject['Test'] = detailsObject['TEST'];
                        deliveryAnalysisReportObject['Result'] = detailsObject['RESULT'];
                        deliveryAnalysisReportObject['UOM'] = detailsObject['UOM'];
                        deliveryAnalysisReportObject['Type of product'] = detailsObject['PRODUCT_TYPE_DESC'];
                        deliveryAnalysisReportObject['Nynas order number'] = detailsObject['ORDER_NUMBER'];

                        deliveryAnalysisReportForAll.push(deliveryAnalysisReportObject);
                        deliveryAnalysisReportObject = {};
                      }
                    }
                  }
                }
              }
            }

            if (deliveryAnalysisReportForAll && deliveryAnalysisReportForAll.length) {
              let headerList = ['Customer PO number', 'Loading Point', 'Actual ship date', 'Delivery place', 'Product', 'Quantity MT',
                'Test', 'Result', 'UOM', 'Type of product', 'Nynas order number'];

              if (this.countryCode === 'SWE' || this.countryCode === 'DNK') {
                headerList = ['Delivery ticket number', 'Loading Point', 'Ship date', 'Delivery place', 'Product', 'Quantity MT',
                  'Test', 'Result', 'UOM', 'Type of product', 'Nynas order number'];
              }

              if (!customerId) {
                headerList.unshift('Customer name');
              }

              this.sharedService.downloadDataInExcelFile(deliveryAnalysisReportForAll, 'Delivery analysis', headerList);
            } else {
              this.showStatusDialog(false, 'no-data-available');
            }
            resolve();
          } else {
            this.showStatusDialog(false, 'Something-went-wrong');
            reject();
          }
        })
    });
  }

  // Commented old code, kept for future reference
  /* exportDataInExcelFile(data: any[], excelFileName: string, header: any): void {
    if (data) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header: header, dateNF: 'DD-MMM-YY;@', cellDates: true });
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const Report: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(Report, excelFileName + EXCEL_EXTENSION);
    }
  } */

  exportDataInExcelFile(data: any[], excelFileName: string, header: any, translatedHeaderList: any): void {
    if (data) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header: header, dateNF: 'DD-MMM-YY;@', cellDates: true });
      XLSX.utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, translatedHeaderList);
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const Report: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(Report, excelFileName + EXCEL_EXTENSION);
    }
  }

  showStatusDialog(isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,

      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  validateFromAndToDate(): boolean {
    this.fromDateIsOlderThan1stJanuary2020 = (this.startDate && new Date(this.startDate) < this.january012020Date);
    return ((this.startDate !== null && this.endDate !== null) && !this.fromDateIsOlderThan1stJanuary2020);
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.cd.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.cd.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }

}
