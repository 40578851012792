import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../order-management/order-management/order-management.component.service';
import { AppService } from '../shared-modules/services/shared.service';
import { Router } from '@angular/router';
import { FormControl, Validators, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { ForecastEntryService } from '../order-management/forecast-entry/forecast-entry.service';
import { CustomerContractService } from './customer-contract.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator, MatDialogConfig, DateAdapter } from '@angular/material';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSearchService } from '../global-search/global-search.service';

export interface DummyData {
  Customer: string;
  Depot: string;
  Product: string;
}

@Component({
  selector: 'app-customer-contract',
  templateUrl: './customer-contract.component.html',
  styleUrls: ['./customer-contract.component.css']
})

export class CustomerContractComponent implements OnInit, OnDestroy {

  countrySearch: any;
  countryList: any;

  selectedItem: string = '';

  navbarOpen = false;
  userInfo: any;
  name: String;
  isLoggedInUserAdmin: boolean;
  expandConfigurationFlag: boolean;

  displayTable: boolean = true;
  dataSource: any;


  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = ['CustomerName', 'DepotName', 'ProductName', 'Delete'];


  dropDownYear = [];

  customers: any = [];
  depots: any = [];
  products: any = [];
  customerContract: any = [];
  selectedCustomer: any;
  selectedCustomerId: any;
  selectedDepot: any;
  selectedDepotId: any;
  displayCustomers: boolean;
  displayProducts: boolean;
  displayDepots: boolean;
  selectedProduct: any;
  selectedProductId: any;
  prodList: any = [];
  selectedProducts: string[] = [];
  selectedOptions: any;
  customerContractUpdate: boolean;
  startDateUserSelected: any;
  endDateUserSelected: any;
  submitted: boolean;
  productMapped: boolean;
  customerProductMap: any;
  newProdMappedList: any = [];
  depotDetails: any = [];
  deleteSuccess: boolean;
  country: string;
  countryCode: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;

  constructor(private sharedService: AppService, private dialog: MatDialog,
    private commonService: CommonService, private router: Router,
    private customerContractService: CustomerContractService,
    private cd: ChangeDetectorRef,
    private media: MediaMatcher,
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService,
		private globalSearchService: GlobalSearchService ) {

    this.userInfo = this.sharedService.getLoginResponse();
    this.name = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.countryCode = this.userInfo.CountryCode;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cd.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  async ngOnInit() {


    this.countryList = { fi: 'Finland' };
    this.countrySearch = 'fi';
    this.country = "FIN"

    //get Customers
    this.customers = await this.getCustomerDetails();
    this.selectedCustomer = this.customers[0].CustomerName;
    this.selectedCustomerId = this.customers[0].CustomerID;

    //get Customer Contract
    try {
      this.customerContract = await this.fetchOnClick();
    } catch (e) {
    }
    //console.log(this.customerProductMap)
    //console.log(this.customerContract)


    // getDepots
    this.depots = await this.getDepotDetails();
    this.selectedDepot = this.depots[0].DepotName;
    this.selectedDepotId = this.depots[0].DepotID;

    //get Products 
    this.products = await this.getProductDetails();
    //console.log(this.products)

    this.translate.get(['HOME.selectedLanguageForDatePicker'])
    .subscribe(data => {
      this.dateAdapter.setLocale(data['HOME.selectedLanguageForDatePicker']);
    });
    this.translate.get(['HOME.itemsPerPageLabel'])
      .subscribe(data => {
        this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
      });
        
      this.subscribeToGlobalSearchServiceSearchTerm();

  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.globalSearchText = '';
  }

  updateSelectedItem(selectedItem: string) {
    this.commonService.updateSelectedItem(selectedItem)
  }
  expandConfiguration(expandConfiguration: boolean) {
    this.expandConfigurationFlag = expandConfiguration;
  }
  getCountry(data) {
    return this.countryList
  }

  async getCustomerDetails() {
    return new Promise((resolve, reject) => {
      this.customerContractService.fetchCustomerDetails(this.countryCode)
        .subscribe(
          res => {
            if (res["ResponseCode"] == "200") {
              this.displayCustomers = true
              resolve(res["Customers"])
            } else {
              this.displayCustomers = false
              reject();
            }

          }
        )
    })
  }

  async getDepotDetails() {
    return new Promise((resolve, reject) => {
      let isAvailable: string = '';
      this.customerContractService.fetchDepotDetails(this.countryCode, isAvailable)
        .subscribe(
          res => {
            if (res["ResponseCode"] == "200") {
              this.displayDepots = true
              resolve(res["DepotCollection"])
            } else {
              this.displayDepots = false
              reject();
            }

          }
        )
    })
  }
  getMapValue(prodId, depotId, custId) {
    // console.log(prodId, depotId, custId)

    if (this.customerProductMap && this.customerProductMap.length) {
      for (let j = 0; j < this.customerProductMap.length; j++) {
        if (prodId === this.customerProductMap[j].ProductID && depotId === this.customerProductMap[j].DepotID && custId === this.customerProductMap[j].CustomerID) {
          return true;
        }
      }
    }
  }
  async getProductDetails() {
    // this.products = [];
    return new Promise((resolve, reject) => {
      this.customerContractService.fetchProductDetails(this.selectedDepotId, this.countryCode)
        .subscribe(
          res => {
            if (res["ResponseCode"] == "200") {
              this.displayProducts = true;
              this.newProdMappedList = [];

              let productList = [];
              productList = res["ProductCollection"]
              // console.log(productList)
              for (let i = 0; i < productList.length; i++) {
                // console.log(productList[i].ProductID, this.selectedDepotId, this.selectedCustomerId)
                this.newProdMappedList.push({
                  "ProductID": productList[i].ProductID,
                  "ProductName": productList[i].ProductName,
                  "mapped": this.getMapValue(productList[i].ProductID, this.selectedDepotId, this.selectedCustomerId)
                })
              }
              //console.log(this.newProdMappedList)
              resolve(this.newProdMappedList)
              // resolve(res["ProductCollection"])
            } else {
              this.displayProducts = false
              reject();
            }

          }
        )
    })
  }

  async getCustomerContract() {
    return new Promise((resolve, reject) => {
      this.customerContractService.fetchCustomerContract(this.selectedCustomerId, this.countryCode)
        .subscribe(
          res => {
            this.depotDetails = []
            let arr = [];
            if (res["ResponseCode"] == "200") {
              this.displayTable = true
              this.customerProductMap = res["GetCustomerContractCollection"]
              // console.log(this.customerProductMap)

              // for (let i = 0; i < this.customerProductMap.length; i++) {
              //   this.depotDetails.push({
              //     "Depot_ID": this.customerProductMap[i].DepotID,
              //     "ProductDetails": {
              //       "Product_ID": arr
              //     }
              //   })
              // }
              //console.log(this.depotDetails)
              resolve(res["GetCustomerContractCollection"])
            } else {
              this.displayTable = false
              reject();
            }
          }
        )
    })
  }
  async fetchOnClick() {
    try {
      this.customerContract = [];
      this.customerContract = await this.getCustomerContract();

    } catch (error) {
      // console.log(error)
      // this.customerContract = null;
    }
    this.dataSource = new MatTableDataSource(this.customerContract);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  updateCustomerContract() {
    this.submitted = true;

    let data1 = {
      "Request": {
        "Customer_ID": this.selectedCustomerId,
        "Start_Date": "",
        "End_Date": "",
        "Created_By": this.name,
        "DepotDetails": this.depotDetails,

      }
    }
    //console.log(data1)
    this.customerContractService.updateCustomerContract(data1)
      .subscribe(
        res => {
          if (res["ResponseCode"] == "200") {
            this.customerContractUpdate = true
            this.prodList = [];
            this.depotDetails = [];
            this.fetchOnClick();
            this.openDialog('Mapping added successfully', null, true, 'c-2-p-success');
          } else {
            this.customerContractUpdate = false
          }
        }
      )



  }
  async checkCustomer(customer) {
    this.selectedCustomer = customer.CustomerName;
    this.selectedCustomerId = customer.CustomerID;
    try {
      this.customerContract = await this.fetchOnClick();
    } catch (e) {
      console.log(e)
    }
    this.products = await this.getProductDetails();
  }
  async checkDepot(depot) {
    this.selectedDepot = depot.DepotName;
    this.selectedDepotId = depot.DepotID;
    this.products = await this.getProductDetails();
    this.depotDetails = []
  }
  async checkProduct(product) {
    //console.log(product)
    this.selectedProduct = product.ProductName
    //console.log(this.depotDetails)
    for (let i = 0; i < this.depotDetails.length; i++) {
      if (this.depotDetails[i].Depot_ID == this.selectedDepotId) {
        this.depotDetails = [];
      }
    }
    this.prodList.push(product.ProductID)

    this.prodList = this.prodList.filter((v, i, a) => a.indexOf(v) === i);
    //console.log(this.prodList)

    this.depotDetails.push({
      "Depot_ID": this.selectedDepotId,
      "ProductDetails": {
        "Product_ID": this.prodList
      }
    })
    //console.log(this.depotDetails)
  }
  productGreen(product) {
    this.checkProduct(product)
    for (let i = 0; i < this.prodList.length; i++) {
      if (product.ProductID == this.prodList[i]) {
        return product["isSelected"] = true
      }
    }
  }
  async reset(reset) {
    this.prodList = [];
    this.products = await this.getProductDetails();
  }

  async deleteMapping(row) {
    //console.log(row)
    let data = {
      "CustomerContractCollection": [{
        "CustomerId": row.CustomerID,
        "DepotID": row.DepotID,
        "ProductId": row.ProductID,
        "UpdatedBy": this.name
      }]
    }

    this.customerContractService.deleteCustomerContract(data)
      .subscribe(
        async res => {
          if (res["ResponseCode"] == "200") {
            this.deleteSuccess = true
            this.openDialog('Mapping deleted successfully', null, true, 'c-2-success');
            this.fetchOnClick();
            this.products = await this.getProductDetails();
          }
        }
      )

  }
  openDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber
      },
      height: '39%',
      width: (this.mobileQuery.matches ? '50%' : '25%'),
    });
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.cd.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.cd.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      // this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }
}
