import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ManageUserGroupService {

  apiUrl = environment.apiUrl;
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private httpClient: HttpClient) { }

  getUserGroups(customerId: string, countryCode: string) {
    const loginUrl = this.apiUrl + '/getAllUserGroup';
    if (customerId) {
      return this.httpClient
        .get(loginUrl, {
          params: {
            CustomerID: customerId,
            CountryCode: countryCode
          }
        });
    } else {
      return this.httpClient
        .get(loginUrl, {
          params: {
            CountryCode: countryCode
          }
        });
    }
  }  
 
  deleteUserGroup(userGroup: any) {
    let url = this.apiUrl + '/DeleteUserGroup';
    let httpParams = new HttpParams().set('UserGroupId', userGroup['UserGroupID']).set('CustomerId', userGroup['CustomerID']);
    let options = { params: httpParams };
    return this.httpClient.delete(url, options).pipe();
  }

}
