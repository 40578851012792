import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class ProductDocumentationService {
  apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private httpClient: HttpClient) { }

  // getProductInformation(status: string, country?: string) {
  getProductInformation(CustomerID, countryCode) {
    const serviceUrl = this.apiUrl + '/GetAllProduct';

    return this.httpClient
      .get(serviceUrl, {
        params: {
          Status: 'ACTIVE',
          Country: countryCode,
          CustomerID: CustomerID

        }
      });
  }

  fetchProductDetailsFromSaleforce(countryName: string) {
    const serviceUrl = this.apiUrl + '/FetchProductDetailsFromSaleforce/ProductList';
    return this.httpClient
      .get(serviceUrl, {
        params: {
          Country: countryName
        }
      });
  }

  getProductDropdownData() {
    const serviceUrl = this.apiUrl + '/GetProductTypeSubType';
    return this.httpClient
      .get(serviceUrl, {

      });
  }


  getSharepointDoc(data) {
    let postData = JSON.stringify(data)
    try {
      let productSharepointUrl = this.apiUrl + '/ReadFileDataFromSharepointService';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(productSharepointUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }
  }

  getUserDocumentCountry(userId: string) {
    let url = this.apiUrl + '/FetchCountryAccessForUser/UserDocumentCountry';
    return this.httpClient
      .get(url, {
        params: {
          USERID: userId
        }
      });
  }

  getAllCountries() : Observable<any> {
    let url = this.apiUrl + '/getCountry';
    return this.httpClient.get(url);
  }

  getDisplayMessage(type: string) {
    let url = this.apiUrl + '/GetDisplayMessage';
    return this.httpClient.get(url, {
      params: {
        Type: type
      }
    });
  }

}
