import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddProductPriceService {

  apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  fetchJDEPricesForUser(month: string, year: string, customerId: string, countryCode: string) {
    const url = this.apiUrl + '/FetchJDEPricesForUser';
    return this.httpClient
      .get(url, {
        params: {
          month: month,
          year: year,
          CustomerId: customerId,
          Country: countryCode,
        }
      });
  }

  fetchJDEAdhesivePricesForUser(month: string, year: string, customerId: string, countryCode: string) {
    const url = this.apiUrl + '/FetchJDEAdhesivePricesForUSer';
    return this.httpClient
      .get(url, {
        params: {
          month: month,
          year: year,
          CustomerId: customerId,
          Country: countryCode,
        }
      });
  }

  updateJDEPricesForUser(priceJSONData: any) {
    let url = this.apiUrl + '/UpdateJDEPricesForUser';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };
    return this.httpClient.post(url, priceJSONData, httpOptions).pipe();
  }

  updateAdhesivePricesForUser(priceJSONData: any) {
    let url = this.apiUrl + '/UpdateAdhesivePricesForUser';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };
    return this.httpClient.post(url, priceJSONData, httpOptions).pipe();
  }
}
