import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomerContractService {

  apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient) { }

  fetchCustomerDetails(countryCode: string) {
    let customerUrl = this.apiUrl + '/getCustomer';
    return this.httpClient
      .get(customerUrl, {
        params: {
          CountryCode: countryCode
        }
      });
  }

  fetchDepotDetails(countryCode: string, isAvailable: string) {
    let customerUrl = this.apiUrl + '/GetDepotDetails';
    return this.httpClient
      .get(customerUrl, {
        params: {
          CountryCode: countryCode,
          is_available: isAvailable
        }
      });
  }

  fetchProductDetails(depotId: string, countryCode: string) {
    let customerUrl = this.apiUrl + '/GetProductForDepot';
    return this.httpClient
      .get(customerUrl, {
        params: {
          DepotID: depotId,
          CountryCode: countryCode
        }
      });
  }

  fetchCustomerContract(customerId: string, countryCode: string) {
    let customerUrl = this.apiUrl + '/GetCustomerContract';
    return this.httpClient
      .get(customerUrl, {
        params: {
          CustomerID: customerId,
          Country: countryCode
        }
      });
  }


  updateCustomerContract(data) {
    let postData = JSON.stringify(data)
    try {

      let updateMappingUrl = this.apiUrl + '/UpdateCustomerContract';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(updateMappingUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }
  }

  deleteCustomerContract(data) {
    let postData = JSON.stringify(data)
    try {
      let delMappungUrl = this.apiUrl + '/DeleteCustomerContract';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(delMappungUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }
  }


}
