import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AppService } from '../shared-modules/services/shared.service';
import { AddUserComponentService } from '../user-management/add-user/add-user.component.service';
import { Customer } from '../user-management/customer';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { UserGroup } from '../user-management/user-group';
import { GuestViewService } from './guest-view.service';
import { ConfirmDialogComponent } from '../shared-modules/components/confirm-dialog/confirm-dialog.component';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-guest-view',
  templateUrl: './guest-view.component.html',
  styleUrls: ['./guest-view.component.css']
})
export class GuestViewComponent implements OnInit, OnDestroy {

  Customers = []; //["YIT Kotka IIarila", "Bitumen Sealings Ltd.", "Nordics Waterproofing"];
  customerList: Customer[] = [];
  userGroups: UserGroup[] = [];
  guestViewForm: FormGroup;
  submitted = false;  
  userInfo: any;
  countryCode: string;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(private formBuilder: FormBuilder,
    private guestViewService: GuestViewService,
    private addUserComponentService: AddUserComponentService,
    private sharedService: AppService,
    private router: Router,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private media: MediaMatcher) {
    this.userInfo = this.sharedService.getLoginResponse();
    if (this.userInfo) {
      this.countryCode = this.userInfo.CountryCode;
    }
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cd.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.buildForm();
    this.getCustomerNames();
    // this.getUserGroupsByCustomerId();
    // this.changeCustomerName();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  buildForm() {
    this.guestViewForm = this.formBuilder.group({
      CustomerID: ['', Validators.required],
      UserGroup: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get fields() {
    return this.guestViewForm.controls;
  }

  getCustomerNames() {
    this.guestViewService.getCustomerNames(this.countryCode)
      .subscribe(
        res => {
          if (res["ResponseCode"] == 200) {
            for (let index of res["Customers"]) {
              this.customerList.push(new Customer(index["CustomerID"], index["CustomerName"]));
            }
          }
        });
  }

  changeCustomerName() {
    this.userGroups = [];
    console.log('cust id' + this.guestViewForm.value['CustomerID']);
    if (this.guestViewForm.value['CustomerID'] !== '') {
      this.guestViewService.getUserGroupsByCustomerId(this.guestViewForm.value['CustomerID'], this.countryCode)
        .subscribe(
          res => {
            const usersGroups = 'UsersGroups';
            if (res && res[usersGroups] && res[usersGroups].length) {
              this.userGroups = res[usersGroups];
            }
          });
    }
        return true;
  }

  confirmNavigationToGuestView() {
    this.submitted = true;

    if (!this.guestViewForm.valid) {
      this.showStatusDialog(false, 'Attention');
      return false;
    }

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: {
				headerNumber: 'guest-view-navigation', 
				messageNumber: 'guest-view-message'
			},
			// height: '42%',
      height: 'auto',
			width: (this.mobileQuery.matches ? '60%' : '22%'),	
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			if (dialogResult) {
				this.navigateToGuestView();
			}
		});
	}

  navigateToGuestView() {
    let customerId = this.guestViewForm.value['CustomerID'];
    let userGroupId = this.guestViewForm.value['UserGroup'];
    let currentLoggedInUserLoginResponse = this.sharedService.getLoginResponse();
    this.addUserComponentService.getUserMenuAccess(userGroupId).subscribe(
      response => {
        if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          let guestViewMenuList = [];
          for (let menu of response['Menus']) {
            menu['MenuID'] = menu['MenuId'].toString();
            menu['AccessFlag'] = '';
            guestViewMenuList.push(menu);
          }
          currentLoggedInUserLoginResponse['Menus'] = guestViewMenuList;
          currentLoggedInUserLoginResponse['CustomerID'] = customerId;
          currentLoggedInUserLoginResponse['CustomerName'] = this.getCustomerNameById(customerId);
          currentLoggedInUserLoginResponse['UserGroupID'] = userGroupId;
          currentLoggedInUserLoginResponse['UserGroupName'] = this.getUserGroupNameById(userGroupId);
          currentLoggedInUserLoginResponse['RoleID'] = 'A';
          currentLoggedInUserLoginResponse['RoleName'] = 'Admin';
          this.sharedService.setLoginResponse(currentLoggedInUserLoginResponse);
          this.router.navigateByUrl('/').then(() => this.router.navigateByUrl('/app/home'));
        } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
          this.showStatusDialog(false, 'guest-view-fail');
        } else {
          this.showStatusDialog(false, 'guest-view-fail');
        }
      });
  }

  getCustomerNameById(customerId: string): string {
    for (var customer of this.customerList) {
      if (customer && customer.CustomerID === customerId) {
        return customer.CustomerName;
      }
    }
    return null;
  }

  getUserGroupNameById(userGroupId: string): string {
    for (var userGroup of this.userGroups) {
      if (userGroup && userGroup['UserGroupID'] && userGroup['UserGroupID'].toString() === userGroupId) {
        return userGroup.UserGroupName;
      }
    }
    return null;
  }

  showStatusDialog(isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: '',
        message: '',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber
      },
			height: '37%',
			width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

}
