import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSort, MatTableDataSource, MAT_DATE_FORMATS, MatPaginator } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppService } from '../shared-modules/services/shared.service';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { AddEchangeRateAdminService } from './add-exchange-rate-admin.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YY',
  },
  display: {
    dateInput: 'DD-MMM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

/* export interface DailyTable {
  date: number;
  source: string;
  exchange: number;
  edit: string;
} */

/* export interface MonthlyTable {
  month: number;
  year: number;
  source: string;
  exchange: number;
  edit: string;
} */

/* const ELEMENT_DATA: DailyTable[] = [
  {date: 1, source: 'ECB', exchange: 1.007, edit: 'H'},
  {date: 2, source: 'Nordea', exchange: 4.002, edit: 'He'},
  {date: 3, source: 'ECB', exchange: 6.941, edit: 'Li'},
  {date: 4, source: 'ECB', exchange: 9.012, edit: 'Be'},
  {date: 5, source: 'Nordea', exchange: 8.012, edit: 'Be'}, 
  {date: 5, source: 'Nordea', exchange: 8.012, edit: 'Be'},
  {date: 5, source: 'Nordea', exchange: 8.012, edit: 'Be'},
]; */

/* const ELEMENT_DATA_MONTHLY: MonthlyTable[] = [
  {month: 1, year: 1, source: 'ECB', exchange: 1.007, edit: 'H'},
  {month: 1, year: 1, source: 'Nordea', exchange: 4.002, edit: 'He'},
  {month: 1, year: 1, source: 'ECB', exchange: 6.941, edit: 'Li'},
  {month: 1, year: 1, source: 'ECB', exchange: 9.012, edit: 'Be'},
  {month: 1, year: 1, source: 'Nordea', exchange: 8.012, edit: 'Be'}, 
  {month: 1, year: 1, source: 'Nordea', exchange: 8.012, edit: 'Be'}, 
  {month: 1, year: 1, source: 'Nordea', exchange: 8.012, edit: 'Be'}, 
]; */


@Component({
  selector: 'app-add-exchange-rate-admin',
  templateUrl: './add-exchange-rate-admin.component.html',
  styleUrls: ['./add-exchange-rate-admin.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AddExchangeRateAdminComponent implements OnInit, OnDestroy {

  yearList: number[] = [];
  addExchangeRateForm: FormGroup;
  submitted = false;
  isRequestCompleted: boolean;

  // @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('exchangeRateDailyTableSort', {static: true}) public exchangeRateDailyTableSort: MatSort;
  @ViewChild('exchangeRateMonthlyTableSort', {static: true}) public exchangeRateMonthlyTableSort: MatSort;
  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableTwoPaginator', { static: true }) tableTwoPaginator: MatPaginator;

  type: string;
  records: string;
  public displayedColumnsForDailyTable = ['IndexDate', 'Source', 'Rate', 'Actions'];
  public displayedColumnsForMonthlyTable = ['Month', 'Year', 'Source', 'Rate', 'Actions'];
  public dataSourceForDailyTable = new MatTableDataSource();
  public dataSourceForMonthlyTable = new MatTableDataSource();
  userInfo: any;
  loggedInUserName: string;
  currentToggleMenu: string = 'daily';
  monthList: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  isBitumenIndexFormEdit: boolean = undefined;
  isRateValueInvalid: boolean;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  /*   displayedColumns: string[] = ['date', 'source', 'exchange', 'edit'];
    dataSource = ELEMENT_DATA;
  
    displayedColumnsMonthly: string[] = ['month', 'year', 'source', 'exchange', 'edit'];
    dataSourceMonthly = ELEMENT_DATA_MONTHLY; */

  /*   dropdowns
    status = ['Select', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    statusYear = ['Select', '2020', '2019', '2018', '2017', '2016', '2015', '2014'];
    fromCurrency = ['Euro', 'USD', 'JPY', 'CAD' ];
    toCurrency = ['USD', 'CAD', 'Euro' ]; */

  constructor(private formBuilder: FormBuilder,
    private addEchangeRateAdminService: AddEchangeRateAdminService,
    private sharedService: AppService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cdRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.addExchangeRateForm = this.formBuilder.group({
      IndexDate: ['', Validators.required],
      Month: ['', Validators.required],
      Year: ['', Validators.required],
      Rate: ['', Validators.required],
      Source: ['ECB', Validators.required],
      // FromCurrency: ['USD'],
      // ToCurrency: ['EUR'],
      UpdatedBy: [''],
      CreatedBy: [''],
      UpdatedOn: [''],
      CreatedOn: [''],
      currentIndexDate:  [''] 
    });

    this.getExchangeRate(this.currentToggleMenu);

    /*    this.dataSource.sort = this.sort;
       this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
         if (data[sortHeaderId]) {
           return data[sortHeaderId].toLocaleLowerCase()
         }
         return null;
       }; */

    // this.getCurrentMonthAndYear();    
    // this.priceIndexForm.controls['priceIndexType'].setValue('daily');
    // this.currentToggleMenu = 'daily';

    // Populate year dropdown
    let currentYear: number = this.getCurrentYear();
    this.getYearList(currentYear);  
    this.translate.get(['HOME.selectedLanguageForDatePicker'])
    .subscribe(data => {
      this.dateAdapter.setLocale(data['HOME.selectedLanguageForDatePicker']);
    });
  }

  ngAfterViewInit(): void {
    this.dataSourceForDailyTable.sort = this.exchangeRateDailyTableSort;
    this.dataSourceForMonthlyTable.sort = this.exchangeRateMonthlyTableSort;
    this.cdRef.detectChanges();
    this.dataSourceForDailyTable.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'IndexDate': {
          return new Date(item[property]);
        }
        default: return item[property];
      }
    };
    this.dataSourceForMonthlyTable.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Month': {
          if (item[property] && this.getCurrentDate() && this.getCurrentYear()) {
            let sampleDateFromMonth = this.getCurrentDate().substring(0, 2) + '-' + item[property].toUpperCase().substring(0, 3) + '-' + this.getCurrentYear().toString().substring(0, 2);
            return new Date(sampleDateFromMonth);
          }
        }
        default: return item[property];
      }
    };
    this.dataSourceForDailyTable.paginator = this.tableOnePaginator;
    
    this.translate.get(['HOME.itemsPerPageLabel'])
      .subscribe(data => {
        this.tableOnePaginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
        this.tableTwoPaginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  // convenience getter for easy access to form fields
  get fields() {
    return this.addExchangeRateForm.controls;
  }


  save() {
    this.submitted = true;

    //Set value in hidden fields as per selected toggle menu, to skip required field validation on them
    if (this.currentToggleMenu && this.currentToggleMenu.toUpperCase() === 'DAILY') {
      this.getCurrentMonthAndYear();
      this.addExchangeRateForm.controls['Month'].setValue(this.addExchangeRateForm.value['Month'].substring(0, 3).toUpperCase());
    }
    if (this.currentToggleMenu && this.currentToggleMenu.toUpperCase() === 'MONTHLY') {
      this.getCurrentDate();
    }

    this.isRateValueInvalid = this.isInValidDecimalNumber(this.addExchangeRateForm.value.Rate);
    if (!this.addExchangeRateForm.valid || this.isRateValueInvalid) {
      this.openSaveUserDialog('There are items that require your attention', 'Please complete all fields marked with *', false,'add-exchange-rate-message');
      return false;
    }

    this.presetFormValues();
    this.addExchangeRateForm.controls['Month'].setValue(this.addExchangeRateForm.value['Month'].substring(0, 3).toUpperCase());
    this.addExchangeRateForm.controls['IndexDate'].setValue(this.datePipe.transform(this.addExchangeRateForm.value.IndexDate, 'yyyy-MM-dd'));

    // Check if it is update or add new exchange rate
    if (this.isBitumenIndexFormEdit) {
      this.addEchangeRateAdminService.updateExchangeRate(this.addExchangeRateForm.value, this.currentToggleMenu)
        .subscribe(
          (response: any) => {
            this.saveStatusDialog(response);
          });
    } else {
      this.addEchangeRateAdminService.addExchangeRate(this.addExchangeRateForm.value, this.currentToggleMenu)
        .subscribe(
          (response: any) => {
            this.saveStatusDialog(response);
          });
    }

  }

  reset() {
    this.submitted = false;
    this.isBitumenIndexFormEdit = undefined;
    this.addExchangeRateForm.reset();
    this.presetFormValues();
    this.addExchangeRateForm.controls['Source'].setValue('ECB');
    this.addExchangeRateForm.controls['Month'].setValue('');
    this.addExchangeRateForm.controls['Year'].setValue('');
  }

  presetFormValues() {
    this.addExchangeRateForm.controls['UpdatedBy'].setValue(this.loggedInUserName);
    this.addExchangeRateForm.controls['CreatedBy'].setValue(this.loggedInUserName);
    this.addExchangeRateForm.controls['UpdatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
    this.addExchangeRateForm.controls['CreatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
  }

  // getExchangeRateForTypeDaily() {
  //   this.dataSource.data = [];
  //   this.isRequestCompleted = false;
  //   this.type = 'DAILY';
  //   this.records = '2000';
  //   this.addEchangeRateAdminService.getExchangeRateForTypeDaily(this.type, this.records)
  //     .subscribe(
  //       response => {
  //         if (response) {
  //           if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
  //             const conversionRateCollection = 'ConversionRateCollection';
  //             if (response[conversionRateCollection] && response[conversionRateCollection].length) {
  //               var conversionRateCollectionList = [];
  //               for (var conversionRate of response[conversionRateCollection]) {
  //                 let conversionRateObject = {};
  //                 conversionRateObject['IndexDate'] = conversionRate['IndexDate'];
  //                 conversionRateObject['Source'] = conversionRate['ExchangeRateSource'];
  //                 conversionRateObject['Rate'] = conversionRate['ConversionRate'];
  //                 conversionRateCollectionList.push(conversionRateObject);
  //               }
  //               this.dataSource.data = conversionRateCollectionList;
  //               this.dataSource.sort = this.sort;
  //               this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
  //                 if (data[sortHeaderId]) {
  //                   return data[sortHeaderId].toLocaleLowerCase()
  //                 }
  //                 return null;
  //               };
  //               // this.dataSource = new MatTableDataSource(conversionRateCollectionList);
  //               this.isRequestCompleted = true;
  //             }
  //             this.isRequestCompleted = true;
  //           }
  //           this.isRequestCompleted = true;
  //         }
  //         this.isRequestCompleted = true;
  //       });
  // }

  getExchangeRate(toggleButtonMenu: string) {
    this.isRequestCompleted = false;

    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'DAILY') {
      this.addEchangeRateAdminService.getExchangeRateForTypeDaily()
        .subscribe(
          response => {
            if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
              const conversionRateCollection = 'ConversionRateCollection';
              if (response[conversionRateCollection] && response[conversionRateCollection].length) {
                var conversionRateCollectionList = [];
                for (var conversionRateObject of response[conversionRateCollection]) {
                  let currentConversionRateObject = {};
                  currentConversionRateObject['IndexDate'] = conversionRateObject['IndexDate']; 
                  currentConversionRateObject['Rate'] = conversionRateObject['ConversionRate'];
                  currentConversionRateObject['Source'] = conversionRateObject['ExchangeRateSource'];
                  conversionRateCollectionList.push(currentConversionRateObject);
                }

                // Reset any data already entered in form
                this.reset();
                this.dataSourceForDailyTable.data = conversionRateCollectionList;
                this.dataSourceForDailyTable.paginator =this.tableOnePaginator;
                this.isRequestCompleted = true;
              }
              this.isRequestCompleted = true;
            }
            this.isRequestCompleted = true;
          });
    }

    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'MONTHLY') {
      let currentYear: string = this.getCurrentYear().toString();
      this.addEchangeRateAdminService.getExchangeRateForTypeMonthly(currentYear)
        .subscribe(
          response => {
            if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
              const priceIndexAndConversionRateCollection = 'MonthlyExchangeRateCollection';
              if (response[priceIndexAndConversionRateCollection] && response[priceIndexAndConversionRateCollection].length) {
                var priceIndexCollectionList = [];
                for (var priceIndexObject of response[priceIndexAndConversionRateCollection]) {
                  let currentPriceIndexObject = {};
                  // currentPriceIndexObject['Month'] = this.getFullMonthName(priceIndexObject['Month']);
                  currentPriceIndexObject['Month'] = priceIndexObject['Month'];
                  currentPriceIndexObject['Year'] = priceIndexObject['Year'];  
                  currentPriceIndexObject['Rate'] = priceIndexObject['ConversionRate'];
                  currentPriceIndexObject['Source'] = priceIndexObject['Source'];    
                
                  priceIndexCollectionList.push(currentPriceIndexObject);
                }

                // Reset any data already entered in form
                this.reset();
                this.dataSourceForMonthlyTable.data = priceIndexCollectionList;
                this.dataSourceForMonthlyTable.paginator = this.tableTwoPaginator;
                this.isRequestCompleted = true;
              }
              this.isRequestCompleted = true;
            }
            this.isRequestCompleted = true;
          });
    }
  }

  editExchangeRate(exchangeRate: any) {
    this.isBitumenIndexFormEdit = true;
    // var date = exchangeRate.IndexDate, 
    //     indexDate = this.datePipe.transform(date, 'yyyy-MM-dd');

    // Set Month property in form    
    // exchangeRate['Month'] = this.getFullMonthName(exchangeRate.Month);

    this.addExchangeRateForm.patchValue(exchangeRate);
    if (exchangeRate.IndexDate) {
      this.addExchangeRateForm.controls['IndexDate'].setValue(this.getCorrectDateFormate(exchangeRate.IndexDate));
    }
    this.addExchangeRateForm.controls['currentIndexDate'].setValue(exchangeRate.IndexDate);
  }
 
  getCorrectDateFormate(dateWtihMonth: any) {
    // get year
    var yy = dateWtihMonth.substr(7, 2);
    var fullYear = (yy < 90) ? '20' + yy : '19' + yy;
    var newdate = dateWtihMonth.substr(0, 7) + fullYear;

    // get month
    var actualDate: any = newdate.split("-");
    var months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    for (var j = 0; j < months.length; j++) {
      if (actualDate[1] == months[j]) {
        let monthNumber = (months.indexOf(months[j]) + 1);
        if (monthNumber < 10) {
          actualDate[1] = '0' + monthNumber;
        } else {
          actualDate[1] = monthNumber;
        }
      }
    }
    /* if (actualDate[0] < 10) {
      actualDate[0] = '0' + actualDate[1];
    } */
    var formattedDate = actualDate[2] + '-' + actualDate[1] + '-' + actualDate[0];
    return formattedDate;
  }

  toggleButtonClick(toggleMenu: string) {
    this.reset();
    this.currentToggleMenu = toggleMenu;
    this.getExchangeRate(this.currentToggleMenu); 
  }

  getCurrentMonthAndYear() {
    const date = this.sharedService.getCurrentDateTime();

    // Get current year
    let currentYear = date.getUTCFullYear();

    // Get current month
    var dateArray = date.toISOString().split("-");
    var month_index = parseInt(dateArray[1], 10) - 1;

    this.getYearList(currentYear);
    this.addExchangeRateForm.controls['Month'].setValue(this.monthList[month_index]);
    this.addExchangeRateForm.controls['Year'].setValue(currentYear);
  }

  getCurrentDate(): string {
    let currentDate: any;
    const date = this.sharedService.getCurrentDateTime();  
    currentDate = this.datePipe.transform(date, 'yyyy-MM-dd');      
    this.addExchangeRateForm.controls['IndexDate'].setValue(currentDate);
    return currentDate;
  }

  getCurrentYear(): number {
    const date = this.sharedService.getCurrentDateTime();
    return date.getUTCFullYear();
  }

  getYearList(currentYear: number) {
    this.yearList = [];
    this.yearList.push(currentYear - 5, currentYear - 4, currentYear - 3, currentYear - 2, currentYear - 1, currentYear);
  }

  saveStatusDialog(response: any) {
    /* if (this.addExchangeRateForm.value['Month']) {
      this.addExchangeRateForm.controls['Month'].setValue(this.getFullMonthName(this.addExchangeRateForm.value['Month']));
    } */
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        this.openSaveUserDialog('Exchange Rate Details Saved Successfully', null, true,'add-exchange-rate-success');
        this.getExchangeRate(this.currentToggleMenu);
      } else if (response['Description'] && response['Description'].toUpperCase() === 'DATA ALREADY PRESENT FOR THE GIVEN INPUT.'){
        this.openSaveUserDialog('Failed to Save Exchange Rate Details', (response['Description'] ? response['Description'] : null), false,'add-exchange-rate-fail-exist');
      } else  if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.openSaveUserDialog('Failed to Save Exchange Rate Details', null, false,'add-exchange-rate-fail');
      }
    } else {
      this.openSaveUserDialog('Failed to Save Exchange Rate Details', null, false,'add-exchange-rate-fail');
    }
  }

  openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean,headerNumber:string) {
		this.dialog.open(DialogBodyComponent, {
			data: {
				header: headerTitle,
				message: message ? message : ' ',
				buttonText: {
					ok: 'Ok'
				},
        isSuccess: isSuccess,
        headerNumber:headerNumber
			},
			height: '37%',
			width: (this.mobileQuery.matches ? '50%' : '22%'),
		});
  }

  getFullMonthName(shortMonthName: string): string {
    let fullMonthName: string;    
    for (let month of this.monthList) {
      if (shortMonthName && month && month.toUpperCase().startsWith(shortMonthName.toUpperCase())) {
        fullMonthName = month;
        break;
      }
    }
    return fullMonthName;
  }

  isInValidDecimalNumber(quantity: number): boolean {
    let regex: RegExp = new RegExp(/^\d*\.?\d{0,4}$/g); // regex to check if number contains more than 4 decimal places
    return (quantity && !String(quantity).match(regex))
  }

  getDateObject(date: string): any {
    let dateObject = {};
    if (date) {
      let dateArray = date.split('-');
      if (dateArray && dateArray.length && dateArray[0] && dateArray[1] && dateArray[2]) {
        dateObject['date'] = dateArray[0] ? dateArray[0] : '';
        dateObject['month'] = dateArray[1] ? dateArray[1].toUpperCase() : '';
        dateObject['year'] = dateArray[2] ? dateArray[2] : '';
      }

    }
    return dateObject;
  }



}
