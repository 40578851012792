import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectionListChange, MatStepper } from '@angular/material';
import { AppService } from '../shared-modules/services/shared.service';
import { BannerMessageService } from './banner-message.service';

@Component({
  selector: 'app-banner-message',
  templateUrl: './banner-message.component.html',
  styleUrls: ['./banner-message.component.css']
})
export class BannerMessageComponent implements OnInit {
  
  step = 0;
  isLinear = true;
  formMessageGroup: FormGroup;
  formToGroup: FormGroup;
  formSummaryGroup: FormGroup;
  dateOfToday: Date;
  submittedFlagObject: any;
  countryList: any;
  userGroupList: any;
  @ViewChild('stepper', { static: true }) private stepper: MatStepper;
  customerList:any = [];
  countryCode: string;
  userInfo: any;

  constructor(
    private fb: FormBuilder,
    private sharedService: AppService,
    private bannerMessageService: BannerMessageService) {
    this.createForm();
    this.dateOfToday = this.sharedService.getCurrentDateTime();
    this.userInfo = this.sharedService.getLoginResponse();
    if (this.userInfo) {
      this.countryCode = (this.userInfo.CountryCode ? this.userInfo.CountryCode : '');
    }
  }

  async ngOnInit() {
    try {
      await this.getCountry();
    }
    catch (e) {
    }

    try {
      await this.getCustomerList();
    }
    catch (e) {
    }
  }

  ngAfterViewInit() {
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  createForm() {
    this.formMessageGroup = this.fb.group({
      bannerMessageInEnglish: ['', [Validators.nullValidator, Validators.maxLength(250)]],
      bannerMessageInFinnish: ['', [Validators.nullValidator, Validators.maxLength(250)]],
      bannerMessageInSwedish: ['', [Validators.nullValidator, Validators.maxLength(250)]],
      bannerMessageInNorwegian: ['', [Validators.nullValidator, Validators.maxLength(250)]],
      bannerMessageInDanish: ['', [Validators.nullValidator, Validators.maxLength(250)]],
      language: ['EN', Validators.nullValidator],
      fromDate: ['', Validators.nullValidator],
      toDate: ['', Validators.nullValidator]
    });

    this.formToGroup = this.fb.group({
      selectedCountry: ['', Validators.required],
      selectedCustomer: ['', Validators.required],
      selectedUserGroup: ['', Validators.required]
    });
    this.formSummaryGroup = this.fb.group({
      message: ['', Validators.required]
    });
  }

  async getCountry() {
    this.countryList = [];
    return new Promise<void>((resolve, reject) => {
    this.bannerMessageService.getCountries().subscribe(
      response => {
        if (response["ResponseCode"] === "200") {
          this.countryList = response["Countries"]
        }
      });
    });
  }

  async getCustomerList() {
    this.customerList = [];
    return new Promise<void>((resolve, reject) => {
      this.bannerMessageService.getCustomerList(this.countryCode).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            this.customerList = response["Customers"]
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  async onSelectingCountry(countryCodeSelected: MatSelectionListChange) {
    try {
      await this.getCustomerListByCountryCode(countryCodeSelected['option'].value);
    }
    catch (e) {
    }    
  }

  async onSelectingCustomer(customerIdSelected: MatSelectionListChange) {
    try {
      await this.getUserGroupListByCustomerId(customerIdSelected['option'].value);
    }
    catch (e) {
    }    
  }

  async getCustomerListByCountryCode(countryCode: string) {
    this.customerList = [];
    return new Promise<void>((resolve, reject) => {
      this.bannerMessageService.getCustomerList(countryCode).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            this.customerList = response["Customers"]
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  async getUserGroupListByCustomerId(customerId: string) {
    this.userGroupList = [];
    return new Promise<void>((resolve, reject) => {
      this.bannerMessageService.getUserGroups(customerId, this.countryCode).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            this.userGroupList = response["UsersGroups"]
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

}
