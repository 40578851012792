import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppService } from '../../shared-modules/services/shared.service';

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.css']
})
export class ProductManagementComponent implements OnInit {

  navbarOpen = false;
  selectedItem: String = 'Product information';
  userInfo: any;
  name : String;
  // selectedItem: String = 'Ordering Procedure';
  allowedMenuIdList: string[] = [];

  constructor( private sharedService: AppService ,private router: Router,
    private activatedRoute: ActivatedRoute,private titleService: Title) { 
      this.userInfo = this.sharedService.getLoginResponse();
    this.name =  (this.userInfo ? (this.userInfo.FirstName + " "+ this.userInfo.LastName) : '' );
    this.getAllowedMenuIdList(this.userInfo ? this.userInfo.Menus : []);
    }

  ngOnInit() {
    this.selectedItem= this.getHeadingFromRoute();
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  showSelectedMenuItem(itemName: String) {
    this.selectedItem = itemName;
  }
  getHeadingFromRoute ():string {
    if(this.router.url=='/product-management/product-information')
    return 'Product information';
    // if(this.router.url=='/product-management/product-maintenance')
    // return 'Product maintenance';
    if(this.router.url=='/product-management/distribution-program')
    return 'Distribution programme';
    if(this.router.url=='/product-management/delivery-guide')
    return 'Delivery guide';
    // if(this.router.url=='/product-management/ordering-procedure')
    // return 'Ordering procedure';
  }
  
  validateMenu(menuId: string): boolean {
    return this.allowedMenuIdList.includes(menuId);
  }
  getAllowedMenuIdList(menuList: any) {
    if (menuList && menuList.length) {
      for (var menu of menuList) {
        this.allowedMenuIdList.push(menu.MenuID);
      }
    }
  }
}

