import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as FileSaver from 'file-saver';
import { BaseChartDirective, Label } from 'ng2-charts';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';
import { HistoryService } from 'src/app/order-management/history/history.component.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import * as XLSX from 'xlsx';
import { ForecastSnapshotDetailsComponent } from '../forecast-snapshot-details/forecast-snapshot-details.component';
import { ForecastactualweeklyswedenService } from '../forecastactualweeklysweden/forecastactualweeklysweden.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-weekly-report',
  templateUrl: './weekly-report.component.html',
  styleUrls: ['./weekly-report.component.css']
})
export class WeeklyReportComponent implements OnInit {

  currentToggleMenu: string = 'reports';
  customerIdUserSelected: string;
  deliverySiteList: any = [];
  deliverySiteUserSelected: string;
  userInfo: any;
  countryCode: any;
  customerList = [];
  yearUserSelected: string;
  yearList: any = [];
  fromWeek: string = '';
  toWeek: string = '';
  viewUserSelected: string = 'All';
  lastRowValueObject: any = {
    CustomerName: '',
    DeliverySiteName: '',
    DepotName: '',
    ProductName: ''
  };
  displayTable: boolean;
  displayGraph: boolean;
  isRequestCompleted: boolean;
  dataSource: any;
  displayedColumns: any = ['CustomerName', 'DeliverySiteName', 'DepotName', 'ProductName', 'WeekNumber', 'FIRST_SNAPSHOT_QUANTITY', 'ForecastQuantity', 'ActualQuantity', 'Difference', 'FORECAST_ACCURACY'];
  customerId: string;
  customerName: string;
  isLoggedInUserPortalAdmin: boolean;
  Order_month: any = [];
  Actual_Quantity: any = [];
  Forecast_Quantity: any = [];
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  showDepotNameColumn: boolean;
  showProductNameColumn: boolean;
  showDeliverySiteColumn: boolean;
  @ViewChild('weeklyReportTableSort', { static: true }) public weeklyReportTableSort: MatSort;
  deliverySiteObjectList: any = [];
  role: string;
  userID: string;
  showWeekNumberHyperlink: boolean;
  showForecastFirstSnapshotColumn: boolean;
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;
  showForecastAccuracyColumn: boolean;

  constructor(private forecastactualweeklyswedenService: ForecastactualweeklyswedenService,
    private sharedService: AppService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private media: MediaMatcher,
    private historyService: HistoryService,
		private globalSearchService: GlobalSearchService) {
    this.userInfo = this.sharedService.getLoginResponse();
    if (this.userInfo) {
      this.countryCode = (this.userInfo.CountryCode ? this.userInfo.CountryCode : '');
      this.customerId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
      this.customerName = (this.userInfo.CustomerName ? this.userInfo.CustomerName : '');
      this.isLoggedInUserPortalAdmin = (this.userInfo.RoleID === 'PA');
      this.role = (this.userInfo ? this.userInfo.RoleID : '');
      this.userID = (this.userInfo ? this.userInfo.UserID : '');
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => cd.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }
  }

  async ngOnInit() {
    this.getYearList();

    try {
      await this.getCustomerList();
    }
    catch (e) {
    }

    try {
      await this.getDeliverySites();
    }
    catch (e) {
    }

    try {
      this.getReportsForTable();
    }
    catch (e) {
    }
        
    this.subscribeToGlobalSearchServiceSearchTerm();

  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.globalSearchText = '';
  }

  async toggleButtonClick(toggleMenu: string) {
    this.currentToggleMenu = toggleMenu;

    // get current year by default in dropdown
    this.getYearList();
    this.viewUserSelected = 'All';
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;
    this.showForecastFirstSnapshotColumn = false;
    this.showForecastAccuracyColumn = false;

    if (this.isLoggedInUserPortalAdmin && this.currentToggleMenu === 'reports') {
      this.customerIdUserSelected = '';
    } else {
      this.customerIdUserSelected = this.customerId;
    }

    try {
      await this.getDeliverySites();
    }
    catch (e) {
    }

    if (this.currentToggleMenu === 'reports') {
      this.getReportsForTable();
    } else if (this.currentToggleMenu === 'graph') {
      this.getReportsForGraph();
    }
  }

  // Commented old code to fetch delivery sites, since customer users should access only those delivery sites assigned to them
/* async getDeliverySites() {
  this.deliverySiteList = [];
  this.deliverySiteObjectList = [];
  return new Promise((resolve, reject) => {
    let isWeekly = 'YES'; // Set isWeekly parameter as YES, when delivery sites needed in weekly data
    this.historyService.getHistDeliverySite(this.customerIdUserSelected, this.countryCode, isWeekly).subscribe(
      response => {
        if (response["ResponseCode"] === "200") {
          for (let deliverySiteObject of response["DeliverySiteCollection"]) {
            this.deliverySiteObjectList.push(deliverySiteObject);
            this.deliverySiteList.push(deliverySiteObject["DeliverySite"]);
            if (this.currentToggleMenu === 'reports') {
              this.deliverySiteUserSelected = '';
            } else if (this.currentToggleMenu === 'graph') {
              this.deliverySiteUserSelected = (this.deliverySiteList && this.deliverySiteList.length) ? this.deliverySiteList[0] : '';
            }
          }
          resolve();
        }
        else {
          reject();
        }
      });
  })
} */

  async getDeliverySites() {
    this.deliverySiteList = [];
    this.deliverySiteObjectList = [];
    if (this.countryCode === 'FIN' || (this.role === "A" || this.role === "PA")) {  // For portal admin and customer admins, show all delivery sites in dropdown for countries except for Finland, for Finland show all delivery sites for any role
      return new Promise<void>((resolve, reject) => {
        let isWeekly = 'YES'; // Set isWeekly parameter as YES, when delivery sites needed in weekly data
        this.historyService.getHistDeliverySite(this.customerIdUserSelected, this.countryCode, isWeekly).subscribe(
          response => {
            if (response["ResponseCode"] === "200") {
              for (let deliverySiteObject of response["DeliverySiteCollection"]) {
                this.deliverySiteObjectList.push(deliverySiteObject);
                this.deliverySiteList.push(deliverySiteObject["DeliverySite"]);
                if (this.currentToggleMenu === 'reports') {
                  this.deliverySiteUserSelected = '';
                } else if (this.currentToggleMenu === 'graph') {
                  this.deliverySiteUserSelected = (this.deliverySiteList && this.deliverySiteList.length) ? this.deliverySiteList[0] : '';
                }
              }
              resolve();
            }
            else {
              reject();
            }
          });
      })
    } else {
      return new Promise<void>((resolve, reject) => {
        this.forecastactualweeklyswedenService.getDeliverySiteForUser(this.userID, this.countryCode, "ACTIVE", this.customerIdUserSelected).subscribe(
          response => {
            if (response["ResponseCode"] === "200") {
              for (let deliverySiteObject of response["DeliverySites"]) {
                this.deliverySiteObjectList.push(deliverySiteObject);
                this.deliverySiteList.push(deliverySiteObject["DeliverySiteName"]);
              }
              if (this.currentToggleMenu === 'reports') {
                this.deliverySiteUserSelected = '';
              } else if (this.currentToggleMenu === 'graph') {
                this.deliverySiteUserSelected = (this.deliverySiteList && this.deliverySiteList.length) ? this.deliverySiteList[0] : '';
              }
              resolve();
            }
            else {
              reject();
            }
          });
      })
    }
  }

  async getCustomerList() {
    this.customerList = [];
    return new Promise<void>((resolve, reject) => {
      this.forecastactualweeklyswedenService.getCustomerList(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.customerList = res["Customers"]
            if (this.isLoggedInUserPortalAdmin) {
              this.customerIdUserSelected = '';
            } else {
              this.customerIdUserSelected = this.customerId;
            }
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  getYearList() {
    const date = this.sharedService.getCurrentDateTime();
    let result = this.getWeekNumber(date);
    this.yearUserSelected = result[0];
    this.setFromAndToWeekNumbers(result[1]);
    this.yearList = [];
    this.yearList.push(Number(this.yearUserSelected) - 1, this.yearUserSelected);

    //Remove year 2019 from year dropdown for Finland users, temporary fix until their data is corrected, to be deployed only in Prod
    /* if (this.countryCode === 'FIN') {
      this.yearList = this.yearList.filter(function (year: number) { return year > 2019; });
    } */
  }

  getWeekNumber(date: any) {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    let yearStart: any = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
    return [date.getUTCFullYear(), weekNo];
  }

  getReportsForTable() {
    if (this.viewUserSelected == "All") {
      this.getReportsForTableByViewAll();
    } else if (this.viewUserSelected == "By product") {
      this.getReportsForTableByViewProduct();
    } else if (this.viewUserSelected == "By depot") {
      this.getReportsForTableByViewDepot();
    } else if (this.viewUserSelected == "By product and depot") {
      this.getReportsForTableByViewProductAndDepot();
    }
  }

  getReportsForTableByViewAll() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;
    this.showWeekNumberHyperlink = false;
    this.showForecastFirstSnapshotColumn = false;
    this.showForecastAccuracyColumn = false;

    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      this.forecastactualweeklyswedenService.getWeeklyPlannedVsActualForAllTableFinland(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, this.countryCode, this.fromWeek, this.toWeek, jdeAddressNumber).subscribe
        (res => {

          if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let weekCollection = res["WeeklyPlannedVsActualForAll_Table"][0]["WeekCollection"];
            let weeklyReportAll = [];

            // Reset already present data, used to prevent repeated records displayed in table rows for customer, delivery site, depot and product names.
            this.resetLastRowValueObject();

            for (let weekData of weekCollection) {
              let weekObject = {};
              if (this.lastRowValueObject['CustomerName'] !== weekData['CustomerName']) {
                weekObject['CustomerName'] = weekData['CustomerName'];
                this.lastRowValueObject['CustomerName'] = weekData['CustomerName'];
              }
              weekObject['CustomerNameFromResponse'] = weekData['CustomerName']; // Needed for sorting, not for display
              weekObject['DeliverySiteName'] = weekData['DeliverySiteName'];
              weekObject['ProductName'] = weekData['ProductName'];
              weekObject['DepotName'] = weekData['DepotName'];
              weekObject['WeekNumber'] = weekData['WeekNumber'];
              weekObject['forecastquantity'] = weekData['forecastquantity'];
              weekObject['FIRST_SNAPSHOT_QUANTITY'] = weekData['FIRST_SNAPSHOT_QUANTITY'];
              weekObject['actualquantity'] = weekData['actualquantity'];
              weekObject['difference'] = weekData['difference'];
              weekObject['CustomerID'] = weekData['CustomerID'];
              weekObject['DepoId'] = weekData['DepoId'];
              weekObject['ProductId'] = weekData['ProductId'];
              weekObject['JDE_ADDRESS_NUMBER'] = weekData['JDE_ADDRESS_NUMBER'];
              weekObject['FORECAST_ACCURACY'] = weekData['FORECAST_ACCURACY'];

              // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
              if (weekObject['CustomerName'] && weeklyReportAll && weeklyReportAll.length) {
                weekObject['showRowTopBorder'] = true;
              }

              weeklyReportAll.push(weekObject);
            }
            this.dataSource = new MatTableDataSource(weeklyReportAll);
            this.displayTable = true;
            this.isRequestCompleted = true;
            this.showDepotNameColumn = true;
            this.showProductNameColumn = true;
            this.showDeliverySiteColumn = true;
            this.showWeekNumberHyperlink = true;
            this.showForecastFirstSnapshotColumn = (this.isLoggedInUserPortalAdmin);
            this.showForecastAccuracyColumn = true;

            resolve();

          } else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
          else {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
        })
    });
  }

  getReportsForTableByViewProduct() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;
    this.showWeekNumberHyperlink = false;
    this.showForecastFirstSnapshotColumn = false;
    this.showForecastAccuracyColumn = false;

    let customername = this.getCustomerNamebyId(this.customerIdUserSelected);

    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      this.forecastactualweeklyswedenService.getTableDataForProductFinland(this.yearUserSelected, this.customerIdUserSelected, this.countryCode, this.deliverySiteUserSelected, this.fromWeek, this.toWeek, jdeAddressNumber).subscribe
        (res => {

          if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {

            let productCollection = res["WeeklyPlannedVsActualForProduct"]["ProductCollection"];
            let weeklyReportByProduct = [];

            // Reset already present data, used to prevent repeated records displayed in table rows for customer, delivery site, depot and product names.
            this.resetLastRowValueObject();

            for (let productData of productCollection) {
              let weekCollection = productData['WeekCollection'];
              for (let week of weekCollection) {
                let weekObject = {};
                if (this.lastRowValueObject['ProductName'] !== productData['ProductName']) {
                  weekObject['CustomerName'] = customername;
                  weekObject['ProductName'] = productData['ProductName'];
                  this.lastRowValueObject['ProductName'] = productData['ProductName'];
                }
                weekObject['ProductNameFromResponse'] = productData['ProductName']; // Needed for sorting, not for display
                weekObject['WeekNumber'] = week['WeekNumber'];
                weekObject['forecastquantity'] = week['forecastquantity'];
                weekObject['FIRST_SNAPSHOT_QUANTITY'] = week['FIRST_SNAPSHOT_QUANTITY'];
                weekObject['actualquantity'] = week['actualquantity'];
                weekObject['difference'] = week['difference'];

                // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
                if (weekObject['ProductName'] && weeklyReportByProduct && weeklyReportByProduct.length) {
                  weekObject['showRowTopBorder'] = true;
                }

                weeklyReportByProduct.push(weekObject);
              }
            }
            this.dataSource = new MatTableDataSource(weeklyReportByProduct);
            this.displayTable = true;
            this.isRequestCompleted = true;
            this.showProductNameColumn = true;
            resolve();

          } else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
          else {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
        })
    });
  }

  getReportsForTableByViewDepot() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;
    this.showWeekNumberHyperlink = false;
    this.showForecastFirstSnapshotColumn = false;
    this.showForecastAccuracyColumn = false;

    let customername = this.getCustomerNamebyId(this.customerIdUserSelected);

    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      this.forecastactualweeklyswedenService.getTableDataForDepotFinland(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, this.fromWeek, this.toWeek, this.countryCode, jdeAddressNumber).subscribe
        (res => {

          if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {

            let depotCollection = res["WeeklyPlannedVsActualForDepot"]["DepotCollection"];
            let weeklyReportByDepot = [];

            // Reset already present data, used to prevent repeated records displayed in table rows for customer, delivery site, depot and product names.
            this.resetLastRowValueObject();

            for (let depotData of depotCollection) {
              let weekCollection = depotData['WeekCollection'];
              for (let week of weekCollection) {
                let weekObject = {};
                if (this.lastRowValueObject['DepotName'] !== depotData['DepotName']) {
                  weekObject['CustomerName'] = customername;
                  weekObject['DepotName'] = depotData['DepotName'];
                  this.lastRowValueObject['DepotName'] = depotData['DepotName'];
                }
                weekObject['DepotNameFromResponse'] = depotData['DepotName']; // Needed for sorting, not for display
                weekObject['WeekNumber'] = week['WeekNumber'];
                weekObject['forecastquantity'] = week['forecastquantity'];
                weekObject['FIRST_SNAPSHOT_QUANTITY'] = week['FIRST_SNAPSHOT_QUANTITY'];
                weekObject['actualquantity'] = week['actualquantity'];
                weekObject['difference'] = week['difference'];

                // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
                if (weekObject['DepotName'] && weeklyReportByDepot && weeklyReportByDepot.length) {
                  weekObject['showRowTopBorder'] = true;
                }

                weeklyReportByDepot.push(weekObject);
              }
            }
            this.dataSource = new MatTableDataSource(weeklyReportByDepot);
            this.displayTable = true;
            this.isRequestCompleted = true;
            this.showDepotNameColumn = true;
            resolve();

          } else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
          else {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
        })
    });
  }

  getReportsForTableByViewProductAndDepot() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;
    this.showWeekNumberHyperlink = false;
    this.showForecastFirstSnapshotColumn = false;
    this.showForecastAccuracyColumn = false;

    let customername = this.getCustomerNamebyId(this.customerIdUserSelected);

    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      this.forecastactualweeklyswedenService.getTableDataForDepotAndProductFinland(this.customerIdUserSelected, this.yearUserSelected, this.countryCode, this.deliverySiteUserSelected, this.fromWeek, this.toWeek, jdeAddressNumber).subscribe
        (res => {

          if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {

            let depotCollection = res["DepotCollection"];
            let weeklyReportByProductAndDepot = [];

            // Reset already present data, used to prevent repeated records displayed in table rows for customer, delivery site, depot and product names.
            this.resetLastRowValueObject();

            for (let depotData of depotCollection) {
              let productCollection = depotData['ProductCollection'];
              for (let product of productCollection) {
                let weekCollection = product['WeekCollection'];
                for (let week of weekCollection) {
                  let weekObject = {};
                  if (this.lastRowValueObject['ProductName'] !== product['ProductName'] || this.lastRowValueObject['DepotName'] !== depotData['DepotName']) {
                    weekObject['CustomerName'] = customername;
                    weekObject['DepotName'] = depotData['DepotName'];
                    weekObject['ProductName'] = product['ProductName'];
                    this.lastRowValueObject['ProductName'] = product['ProductName'];
                    this.lastRowValueObject['DepotName'] = depotData['DepotName'];
                  }
                  weekObject['DepotNameFromResponse'] = depotData['DepotName']; // Needed for sorting, not for display
                  weekObject['ProductNameFromResponse'] = product['ProductName']; // Needed for sorting, not for display
                  weekObject['WeekNumber'] = week['WeekNumber'];
                  weekObject['forecastquantity'] = week['forecastquantity'];
                  weekObject['FIRST_SNAPSHOT_QUANTITY'] = week['FIRST_SNAPSHOT_QUANTITY'];
                  weekObject['actualquantity'] = week['actualquantity'];
                  weekObject['difference'] = week['difference'];

                  // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
                  if (weekObject['DepotName'] && weeklyReportByProductAndDepot && weeklyReportByProductAndDepot.length) {
                    weekObject['showRowTopBorder'] = true;
                  }

                  weeklyReportByProductAndDepot.push(weekObject);
                }
              }
            }

            this.dataSource = new MatTableDataSource(weeklyReportByProductAndDepot);
            this.displayTable = true;
            this.isRequestCompleted = true;
            this.showProductNameColumn = true;
            this.showDepotNameColumn = true;
            resolve();

          } else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
          else {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
        })
    });
  }

  resetLastRowValueObject(): void {
    for (let key in this.lastRowValueObject) {
      this.lastRowValueObject[key] = '';
    }
  }

  setFromAndToWeekNumbers(toWeek: string): void {
    this.fromWeek = '1';
    this.toWeek = (toWeek ? toWeek : '1');
  }

  downloadAllReports() {
    let customerId: string = '', deliverySite: string = '', fromWeek = '1', toWeek = '53';
    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(deliverySite);
      this.forecastactualweeklyswedenService.getWeeklyPlannedVsActualForAllTableFinland(this.yearUserSelected, customerId, deliverySite, this.countryCode, fromWeek, toWeek, jdeAddressNumber).subscribe
        (response => {
          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let weekCollection = response["WeeklyPlannedVsActualForAll_Table"][0]["WeekCollection"];
            let excelAllReportsData = [];

            for (let weekData of weekCollection) {
              let weekObject = {};
              weekObject['Customer name'] = weekData['CustomerName'];
              weekObject['Delivery site'] = weekData['DeliverySiteName'];
              weekObject['Depot name'] = weekData['DepotName'];
              weekObject['Product name'] = weekData['ProductName'];

              // weekObject['Week number'] = weekData['WeekNumber'];
              if (weekData['WeekNumber'] || weekData['WeekNumber'] == 0) {
                weekObject['Week number'] = +weekData['WeekNumber'];
              }

              // Display column as Forecast Latest (MT) for Sweden, otherwise Forecast (metric ton) for other countries
              if (this.countryCode === 'SWE') {
                if (weekData['forecastquantity'] || weekData['forecastquantity'] == 0) {
                  weekObject['Forecast Latest (MT)'] = +weekData['forecastquantity'];

                  // Forecast First Snapshot (MT) column, to be shown for Sweden only
                  if (weekData['FIRST_SNAPSHOT_QUANTITY'] || weekData['FIRST_SNAPSHOT_QUANTITY'] == 0) {
                    weekObject['Forecast First Snapshot (MT)'] = +weekData['FIRST_SNAPSHOT_QUANTITY'];
                  }

                  // Display column as Actual (MT) for Sweden, otherwise Actual (metric ton) for other countries
                  if (weekData['actualquantity'] || weekData['actualquantity'] == 0) {
                    weekObject['Actual (MT)'] = +weekData['actualquantity'];
                  }

                  if (weekData['difference'] || weekData['difference'] == 0) {
                    weekObject['Difference Actual v/s Latest (%)'] = +weekData['difference'];
                  }

                }
              } else {

                // Display column as Forecast Latest (MT) for Sweden, otherwise Forecast (metric ton) for other countries
                if (weekData['forecastquantity'] || weekData['forecastquantity'] == 0) {
                  weekObject['Forecast (metric ton)'] = +weekData['forecastquantity'];
                }

                // Display column as Actual (MT) for Sweden, otherwise Actual (metric ton) for other countries
                if (weekData['actualquantity'] || weekData['actualquantity'] == 0) {
                  weekObject['Actual (metric ton)'] = +weekData['actualquantity'];
                }

                if (weekData['difference'] || weekData['difference'] == 0) {
                  weekObject['Difference'] = +weekData['difference'];
                }
              }
              
              weekObject['Forecast accuracy'] = weekData['FORECAST_ACCURACY'];
              excelAllReportsData.push(weekObject);
            }

            let header = ["Customer name", "Delivery site", "Depot name", "Product name", "Week number", "Forecast (metric ton)", "Actual (metric ton)", "Difference", "Forecast accuracy"];

            // Forecast First Snapshot (MT) column, to be shown for Sweden only
            if (this.countryCode === 'SWE') {
              header = ["Customer name", "Delivery site", "Depot name", "Product name", "Week number", "Forecast First Snapshot (MT)", "Forecast Latest (MT)", "Actual (MT)", "Difference Actual v/s Latest (%)", "Forecast accuracy"];
            }
    
            // Commented old code, kept for future reference
            // this.exportDataInExcelFile(excelAllReportsData, 'WeeklyReports', header);
        
            this.sharedService.downloadDataInExcelFile(excelAllReportsData, 'WeeklyReports', header);

            resolve();
          } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
            this.showStatusDialog(false, 'Something-went-wrong');
            reject();
          }
          else {
            this.showStatusDialog(false, 'Something-went-wrong');
            reject();
          }
        })
    });
  }

  downloadReportData() {
    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      this.forecastactualweeklyswedenService.getWeeklyPlannedVsActualForAllTableFinland(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, this.countryCode, this.fromWeek, this.toWeek, jdeAddressNumber).subscribe
        (response => {
          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let weekCollection = response["WeeklyPlannedVsActualForAll_Table"][0]["WeekCollection"];
            let excelAllReportsData = [];

            for (let weekData of weekCollection) {
              let weekObject = {};
              weekObject['Customer name'] = weekData['CustomerName'];
              weekObject['Delivery site'] = weekData['DeliverySiteName'];
              weekObject['Product name'] = weekData['ProductName'];
              weekObject['Depot name'] = weekData['DepotName'];

              if (weekData['WeekNumber'] || weekData['WeekNumber'] == 0) {
                weekObject['Week number'] = +weekData['WeekNumber'];
              }
              if (weekData['forecastquantity'] || weekData['forecastquantity'] == 0) {
                weekObject['Forecast'] = +weekData['forecastquantity'];
              }
              if (weekData['actualquantity'] || weekData['actualquantity'] == 0) {
                weekObject['Actual'] = +weekData['actualquantity'];
              }
              if (weekData['difference'] || weekData['difference'] == 0) {
                weekObject['Difference'] = +weekData['difference'];
              }
              weekObject['Forecast accuracy'] = weekData['FORECAST_ACCURACY'];
              excelAllReportsData.push(weekObject);
            }

            let header = ["Customer name", "Delivery site", "Product name", "Depot name", "Week number", "Forecast", "Actual", "Difference", "Forecast accuracy"];
            this.exportDataInExcelFile(excelAllReportsData, 'WeeklyReports', header);
            resolve();
          } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
            this.showStatusDialog(false, 'Something-went-wrong');
            reject();
          }
          else {
            this.showStatusDialog(false, 'Something-went-wrong');
            reject();
          }
        })
    });
  }

  exportDataInExcelFile(data: any[], excelFileName: string, header: any): void {
    if (data) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((data), { header: header });
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const Report: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(Report, excelFileName + EXCEL_EXTENSION);
    }
  }

  getReports() {
    if (this.currentToggleMenu === 'reports') {
      this.getReportsForTable();
    } else if (this.currentToggleMenu === 'graph') {
      this.getReportsForGraph();
    }
  }

  getReportsForGraph() {
    this.Order_month.splice(0, 52);
    this.Actual_Quantity.splice(0, 52);
    this.Forecast_Quantity.splice(0, 52);
    this.getBarChartData();
  }


  async getBarChartData() {
    this.displayGraph = false;
    this.isRequestCompleted = false;
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastactualweeklyswedenService.getBarChartData(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, this.fromWeek, this.toWeek, jdeAddressNumber).subscribe(
      response => {
        if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          if (this.Order_month.length > 0) {
            this.Order_month = [];
          }
          /* response["Collection"].forEach(x => {
            this.Actual_Quantity.push(parseInt(x.Actual_Quantity));
            this.Forecast_Quantity.push(parseInt(x.Forecast_Quantity));
            this.Order_month.push(x.Forecast_week)
          }); */

          // Show all week numbers from fromWeek to toWeek, on X axis of graph
          let startWeekNumber = (this.fromWeek ? +this.fromWeek : 1), endWeekNumber = (this.toWeek ? +this.toWeek : 53);
          for (let weekNumber = startWeekNumber; weekNumber <= endWeekNumber; weekNumber++) {
            let weeklyDataObject = {};
            this.getWeeklyDataObjectForGraphByWeekNumber(response['Collection'], weeklyDataObject, weekNumber);
            this.Order_month.push(weeklyDataObject['Forecast_week']);
            this.Actual_Quantity.push(weeklyDataObject['Actual_Quantity']);
            this.Forecast_Quantity.push(weeklyDataObject['Forecast_Quantity']);
          }

          this.displayGraph = true;
          this.isRequestCompleted = true;
        }
        else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'NO RECORDS FOUND') {
          this.displayGraph = false;
          this.isRequestCompleted = true;
        }
        else {
          this.displayGraph = false;
          this.isRequestCompleted = true;
          this.showStatusDialog(false, 'Something-went-wrong');
        }
      });
  }

  getTitleBySelectedLanguage(title: string): string {
    let translatedTitle: string;
    let selectedLanguage = this.sharedService.getSelectedLanguage();
    if (title === 'Actual') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Actual';
          break;
        case 'FI':
          translatedTitle = 'Toteuma';
          break;
        case 'SE':
          translatedTitle = 'Faktiskt';
          break;
        case 'NR':
          translatedTitle = 'Salg';
          break;
        default:
          translatedTitle = 'Actual';
      }
    }
    if (title === 'Planned') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Planned';
          break;
        case 'FI':
          translatedTitle = 'Ennuste';
          break;
        case 'SE':
          translatedTitle = 'Prognostiserat';
          break;
        case 'NR':
          translatedTitle = 'Prognose';
          break;
        default:
          translatedTitle = 'Planned';
      }
    }
    return translatedTitle;
  }

  showStatusDialog(isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,

      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  getCustomerNamebyId(id: string) {
    for (let customer of this.customerList) {
      if (id === customer.CustomerID) {
        return customer.CustomerName;
      }
    }
  }

  sortData(sort: Sort) {
    if (this.viewUserSelected == "All") {
      let sortedData = this.dataSource.data.sort((row1: any, row2: any) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active === 'WeekNumber') {
          return compare(row1[sort.active] ? +row1[sort.active] : 0, row2[sort.active] ? +row2[sort.active] : 0, isAsc); // applying sort for numeric rows
        } else if (sort.active === 'CustomerName') {
          return compare(row1['CustomerNameFromResponse'] ? row1['CustomerNameFromResponse'].toUpperCase() : 0, row2['CustomerNameFromResponse'] ? row2['CustomerNameFromResponse'].toUpperCase() : 0, isAsc); // applying sort for customer name rows
        }
        return compare(row1[sort.active] ? row1[sort.active].toUpperCase() : '', row2[sort.active] ? row2[sort.active].toUpperCase() : '', isAsc); // applying sort for other alphabetic rows
      });
      this.displaySortedRecordForViewAll(sortedData);
    } else if (this.viewUserSelected == "By product") {
      let sortedData = this.dataSource.data.sort((row1: any, row2: any) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active === 'WeekNumber') {
          return compare(row1[sort.active] ? +row1[sort.active] : 0, row2[sort.active] ? +row2[sort.active] : 0, isAsc); // applying sort for numeric rows
        } else if (sort.active === 'CustomerName') {
          return compare(row1['CustomerNameFromResponse'] ? row1['CustomerNameFromResponse'].toUpperCase() : 0, row2['CustomerNameFromResponse'] ? row2['CustomerNameFromResponse'].toUpperCase() : 0, isAsc); // applying sort for customer name rows
        } else if (sort.active === 'ProductName') {
          return compare(row1['ProductNameFromResponse'] ? row1['ProductNameFromResponse'].toUpperCase() : 0, row2['ProductNameFromResponse'] ? row2['ProductNameFromResponse'].toUpperCase() : 0, isAsc); // applying sort for product name rows
        }
        return compare(row1[sort.active] ? row1[sort.active].toUpperCase() : '', row2[sort.active] ? row2[sort.active].toUpperCase() : '', isAsc); // applying sort for other alphabetic rows
      });
      this.displaySortedRecordForViewByProduct(sortedData);
    } else if (this.viewUserSelected == "By depot") {
      let sortedData = this.dataSource.data.sort((row1: any, row2: any) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active === 'WeekNumber') {
          return compare(row1[sort.active] ? +row1[sort.active] : 0, row2[sort.active] ? +row2[sort.active] : 0, isAsc); // applying sort for numeric rows
        } else if (sort.active === 'CustomerName') {
          return compare(row1['CustomerNameFromResponse'] ? row1['CustomerNameFromResponse'].toUpperCase() : 0, row2['CustomerNameFromResponse'] ? row2['CustomerNameFromResponse'].toUpperCase() : 0, isAsc); // applying sort for customer name rows
        } else if (sort.active === 'DepotName') {
          return compare(row1['DepotNameFromResponse'] ? row1['DepotNameFromResponse'].toUpperCase() : 0, row2['DepotNameFromResponse'] ? row2['DepotNameFromResponse'].toUpperCase() : 0, isAsc); // applying sort for depot name rows
        }
        return compare(row1[sort.active] ? row1[sort.active].toUpperCase() : '', row2[sort.active] ? row2[sort.active].toUpperCase() : '', isAsc); // applying sort for other alphabetic rows
      });
      this.displaySortedRecordForViewByDepot(sortedData);
    } else if (this.viewUserSelected == "By product and depot") {
      let sortedData = this.dataSource.data.sort((row1: any, row2: any) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active === 'WeekNumber') {
          return compare(row1[sort.active] ? +row1[sort.active] : 0, row2[sort.active] ? +row2[sort.active] : 0, isAsc); // applying sort for numeric rows
        } else if (sort.active === 'CustomerName') {
          return compare(row1['CustomerNameFromResponse'] ? row1['CustomerNameFromResponse'].toUpperCase() : 0, row2['CustomerNameFromResponse'] ? row2['CustomerNameFromResponse'].toUpperCase() : 0, isAsc); // applying sort for customer name rows
        } else if (sort.active === 'ProductName') {
          return compare(row1['ProductNameFromResponse'] ? row1['ProductNameFromResponse'].toUpperCase() : 0, row2['ProductNameFromResponse'] ? row2['ProductNameFromResponse'].toUpperCase() : 0, isAsc); // applying sort for product name rows
        } else if (sort.active === 'DepotName') {
          return compare(row1['DepotNameFromResponse'] ? row1['DepotNameFromResponse'].toUpperCase() : 0, row2['DepotNameFromResponse'] ? row2['DepotNameFromResponse'].toUpperCase() : 0, isAsc); // applying sort for depot name rows
        }
        return compare(row1[sort.active] ? row1[sort.active].toUpperCase() : '', row2[sort.active] ? row2[sort.active].toUpperCase() : '', isAsc); // applying sort for other alphabetic rows
      });
      this.displaySortedRecordForViewByProductAndDepot(sortedData);
    }
  }

  displaySortedRecordForViewAll(sortedData: any) {
    this.dataSource = new MatTableDataSource([]);
    let weeklyReportAll = [];

    // Reset already present data, used to prevent repeated records displayed in table rows for customer, delivery site, depot and product names.
    this.resetLastRowValueObject();

    for (let weekData of sortedData) {
      let weekObject = {};
      if (this.lastRowValueObject['CustomerName'] !== weekData['CustomerNameFromResponse']) {
        weekObject['CustomerName'] = weekData['CustomerNameFromResponse'];
        this.lastRowValueObject['CustomerName'] = weekData['CustomerNameFromResponse'];
      }
      weekObject['CustomerNameFromResponse'] = weekData['CustomerNameFromResponse'];
      weekObject['DeliverySiteName'] = weekData['DeliverySiteName'];
      weekObject['ProductName'] = weekData['ProductName'];
      weekObject['DepotName'] = weekData['DepotName'];
      weekObject['WeekNumber'] = weekData['WeekNumber'];
      weekObject['forecastquantity'] = weekData['forecastquantity'];
      weekObject['FIRST_SNAPSHOT_QUANTITY'] = weekData['FIRST_SNAPSHOT_QUANTITY'];
      weekObject['actualquantity'] = weekData['actualquantity'];
      weekObject['difference'] = weekData['difference'];
      weekObject['CustomerID'] = weekData['CustomerID'];
      weekObject['DepoId'] = weekData['DepoId'];
      weekObject['ProductId'] = weekData['ProductId'];
      weekObject['JDE_ADDRESS_NUMBER'] = weekData['JDE_ADDRESS_NUMBER'];
      weekObject['FORECAST_ACCURACY'] = weekData['FORECAST_ACCURACY'];

      // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
      if (weekObject['CustomerName'] && weeklyReportAll && weeklyReportAll.length) {
        weekObject['showRowTopBorder'] = true;
      }

      weeklyReportAll.push(weekObject);
    }
    this.dataSource = new MatTableDataSource(weeklyReportAll);
  }

  displaySortedRecordForViewByProduct(sortedData: any) {
    let customername = this.getCustomerNamebyId(this.customerIdUserSelected);
    this.dataSource = new MatTableDataSource([]);
    let weeklyReportByProduct = [];

    // Reset already present data, used to prevent repeated records displayed in table rows for customer, delivery site, depot and product names.
    this.resetLastRowValueObject();

    for (let productData of sortedData) {
      let weekObject = {};
      if (this.lastRowValueObject['ProductName'] !== productData['ProductNameFromResponse']) {
        weekObject['CustomerName'] = customername;
        weekObject['ProductName'] = productData['ProductNameFromResponse'];
        this.lastRowValueObject['ProductName'] = productData['ProductNameFromResponse'];
      }
      weekObject['ProductNameFromResponse'] = productData['ProductNameFromResponse'];
      weekObject['WeekNumber'] = productData['WeekNumber'];
      weekObject['forecastquantity'] = productData['forecastquantity'];
      weekObject['FIRST_SNAPSHOT_QUANTITY'] = productData['FIRST_SNAPSHOT_QUANTITY'];
      weekObject['actualquantity'] = productData['actualquantity'];
      weekObject['difference'] = productData['difference'];

      // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
      if (weekObject['ProductName'] && weeklyReportByProduct && weeklyReportByProduct.length) {
        weekObject['showRowTopBorder'] = true;
      }

      weeklyReportByProduct.push(weekObject);
    }

    this.dataSource = new MatTableDataSource(weeklyReportByProduct);
  }

  displaySortedRecordForViewByDepot(sortedData: any) {
    let customername = this.getCustomerNamebyId(this.customerIdUserSelected);
    this.dataSource = new MatTableDataSource([]);
    let weeklyReportByDepot = [];

    // Reset already present data, used to prevent repeated records displayed in table rows for customer, delivery site, depot and product names.
    this.resetLastRowValueObject();

    for (let depotData of sortedData) {
      let weekObject = {};
      if (this.lastRowValueObject['DepotName'] !== depotData['DepotNameFromResponse']) {
        weekObject['CustomerName'] = customername;
        weekObject['DepotName'] = depotData['DepotNameFromResponse'];
        this.lastRowValueObject['DepotName'] = depotData['DepotNameFromResponse'];
      }
      weekObject['DepotNameFromResponse'] = depotData['DepotNameFromResponse'];
      weekObject['WeekNumber'] = depotData['WeekNumber'];
      weekObject['forecastquantity'] = depotData['forecastquantity'];
      weekObject['FIRST_SNAPSHOT_QUANTITY'] = depotData['FIRST_SNAPSHOT_QUANTITY'];
      weekObject['actualquantity'] = depotData['actualquantity'];
      weekObject['difference'] = depotData['difference'];

      // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
      if (weekObject['DepotName'] && weeklyReportByDepot && weeklyReportByDepot.length) {
        weekObject['showRowTopBorder'] = true;
      }

      weeklyReportByDepot.push(weekObject);
    }
    this.dataSource = new MatTableDataSource(weeklyReportByDepot);
  }

  displaySortedRecordForViewByProductAndDepot(sortedData: any) {
    let customername = this.getCustomerNamebyId(this.customerIdUserSelected);
    this.dataSource = new MatTableDataSource([]);
    let weeklyReportByProductAndDepot = [];

    // Reset already present data, used to prevent repeated records displayed in table rows for customer, delivery site, depot and product names.
    this.resetLastRowValueObject();

    for (let data of sortedData) {
      let weekObject = {};
      if (this.lastRowValueObject['ProductName'] !== data['ProductNameFromResponse'] || this.lastRowValueObject['DepotName'] !== data['DepotNameFromResponse']) {
        weekObject['CustomerName'] = customername;
        weekObject['DepotName'] = data['DepotNameFromResponse'];
        weekObject['ProductName'] = data['ProductNameFromResponse'];
        this.lastRowValueObject['ProductName'] = data['ProductNameFromResponse'];
        this.lastRowValueObject['DepotName'] = data['DepotNameFromResponse'];
      }
      weekObject['DepotNameFromResponse'] = data['DepotNameFromResponse'];
      weekObject['ProductNameFromResponse'] = data['ProductNameFromResponse'];
      weekObject['WeekNumber'] = data['WeekNumber'];
      weekObject['forecastquantity'] = data['forecastquantity'];
      weekObject['FIRST_SNAPSHOT_QUANTITY'] = data['FIRST_SNAPSHOT_QUANTITY'];
      weekObject['actualquantity'] = data['actualquantity'];
      weekObject['difference'] = data['difference'];

      // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
      if (weekObject['DepotName'] && weeklyReportByProductAndDepot && weeklyReportByProductAndDepot.length) {
        weekObject['showRowTopBorder'] = true;
      }

      weeklyReportByProductAndDepot.push(weekObject);
    }

    this.dataSource = new MatTableDataSource(weeklyReportByProductAndDepot);
  }

  getWeeklyDataObjectForGraphByWeekNumber(weeklyDataCollection: any, weeklyDataObject: any, weekNumber: number): any {
    weeklyDataObject['Forecast_week'] = (weekNumber ? weekNumber.toString() : '1');
    weeklyDataObject['Actual_Quantity'] = undefined;
    weeklyDataObject['Forecast_Quantity'] = undefined;
    if (weeklyDataCollection && weeklyDataCollection.length) {
      for (let weeklyData of weeklyDataCollection) {
        if (weeklyData && (weekNumber === +weeklyData['Forecast_week'])) {
          weeklyDataObject['Actual_Quantity'] = weeklyData['Actual_Quantity'];
          weeklyDataObject['Forecast_Quantity'] = weeklyData['Forecast_Quantity'];
          break;
        }
      }
    }
    return weeklyDataObject;
  }

  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        let deliverySiteObjectName = (deliverySiteObject['DeliverySite'] || deliverySiteObject['DeliverySiteName']);
        if (deliverySiteObject && deliverySiteObjectName && deliverySiteObjectName === deliverySiteName) {
          jdeAddressNumber = (deliverySiteObject['JDE_ADDRESS_NUMBER'] || deliverySiteObject['JDE_Address_Number']);
          break;
        }
      }
    }
    return jdeAddressNumber;
  }
  
  showSnapshotDetails(weeklyReportRow: any): void {
    this.forecastactualweeklyswedenService.fetchWeeklySnapshotSummary(this.countryCode, weeklyReportRow['CustomerID'], weeklyReportRow['JDE_ADDRESS_NUMBER'], weeklyReportRow['ProductId'], weeklyReportRow['DepoId'], this.yearUserSelected, weeklyReportRow['WeekNumber'], weeklyReportRow['actualquantity']).subscribe(
      response => {
        if (response["ResponseCode"] === "200" && response['Collection'] && response['Collection'].length) {
          this.dialog.open(ForecastSnapshotDetailsComponent, {
            data: {
              snapshotDetails: response['Collection'],
              actualQuantity: weeklyReportRow['actualquantity'],
              countryCode: this.countryCode,
              isLoggedInUserPortalAdmin: this.isLoggedInUserPortalAdmin
            },
            panelClass: 'custom-dialog-container',
            // height: '70%',
            height: 'auto',
            width: (this.mobileQuery.matches ? 'auto' : '90%'),
            // width: 'auto',
            // maxHeight: 'calc(100vh - 90px)',
          });
        } else if (response["ResponseCode"] === "201") {
          this.showStatusDialog(false, "Noforecastsnapshotdetailsfound");
        } else {
          this.showStatusDialog(false, "Something-went-wrong");
        }
      });
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.cd.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.cd.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      // this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }

  //  Changes for barchart start
  public barChartOptions2: ChartOptions = {
    responsive: true,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    tooltips: {
      mode: 'index'
    },
    scales: {

      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "bold"
        }
      }], yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 14,
          fontStyle: "bold"
        }
      }]
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    }
  };
  public barChartLabels2: Label[] = this.Order_month;
  public barChartType2: ChartType = 'bar';
  public barChartLegend2 = true;
  public barChartPlugins2 = [pluginDataLabels];

  public barChartData2: ChartDataSets[] = [
    { data: this.Actual_Quantity, label: this.getTitleBySelectedLanguage('Actual'), pointStyle: "line", backgroundColor: '#FFD500', hoverBackgroundColor: '#FFD500', hoverBorderColor: '#FFD500' },
    { data: this.Forecast_Quantity, label: this.getTitleBySelectedLanguage('Planned'), pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' }
  ];

  // Changes for barchart end

  //bar chart data for customer role: start

  public barChartOptions1: ChartOptions = {
    responsive: true,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    tooltips: {
      mode: 'index'
    },
    scales: {

      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "bold"
        }
      }], yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 14,
          fontStyle: "bold"
        }
      }]
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    }
  };
  public barChartLabels1: Label[] = this.Order_month;
  public barChartType1: ChartType = 'bar';
  public barChartLegend1 = true;
  public barChartPlugins1 = [pluginDataLabels];

  public barChartData1: ChartDataSets[] = [
    { data: this.Forecast_Quantity, label: 'Forecast', pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' }
  ];
  //bar chart data for customer role : end


  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}