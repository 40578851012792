import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import { ForecastEntryService } from '../forecast-entry/forecast-entry.service';
import { CommonService } from '../order-management/order-management.component.service';
import { HistoryService } from './history.component.service';
 
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
// export class HistoryComponent implements OnInit {
 
//   constructor() { }
 
//   ngOnInit() {}
 
export class HistoryComponent implements OnInit {
  displayTable: boolean = false;
  yearUserSelected: any;
  quantityUpdated: any;
  fromWeekUserSelected: any;
  toWeekUserSelected: any;
  customerName: string;
  deliverySites: string;
  delSite = [];
  delSiteUserSelected: string;
  customerUserSelected: string;
  dropDownYear = [];
  historyData: any;
  fetchedDataForItemArray = [];
  filteredArr = []
  itemName: any;
  depot: any;
  
  quantity: any;
  created_BY: any;
  role: any;
  Customers = [];
 
  // Hide Sales Quantity Column for all countries
  // displayedColumns: string[] = ['customerName', 'deliverySite', 'productName', 'depotName', 'quantity', 'salesQuantity', 'week'];
  displayedColumns: string[] = ['customerName', 'deliverySite', 'productName', 'depotName', 'forecastquantity', 'week'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  historyForecastData: any;
  userInfo: any;
  username: string;
  customerID: any;
 
  // Setting maxWeek to 53, since we have only 52 (+ 1) weeks every year
  maxWeek: number = 53;
  minWeek: number;
  historyEntryForm: FormGroup;
  submitted: boolean;
  currYear: any;
  currWeek: any;
  noDisp: boolean;
  customerIdUserSelected: any;
  countryCode: any;
  userId: string;
  deliverySiteObjectList: any = [];
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;
  
  constructor(private commonService: CommonService, 
    private historyService: HistoryService,
    private sharedService: AppService, 
    private formBuilder: FormBuilder, 
    private forecastEntryService: ForecastEntryService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
		private globalSearchService: GlobalSearchService,
    private dialog: MatDialog) {
 
    if (this.sharedService.getLoginResponse() == undefined) {
      this.noDisp = true
    } else {
      this.userInfo = this.sharedService.getLoginResponse();
      this.username = this.userInfo.FirstName + " " + this.userInfo.LastName;
      this.role = this.userInfo.RoleID;
      this.customerID = this.userInfo.CustomerID;
      this.customerName = this.userInfo.CustomerName;
      this.countryCode = this.userInfo.CountryCode
      this.noDisp = false
      this.userId = (this.userInfo ? this.userInfo.UserID : '');
    }
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  get fields() {
    return this.historyEntryForm.controls;
  }
  async ngOnInit() {
    //set curr week n year number
    const date = this.sharedService.getCurrentDateTime();
    var result = this.getWeekNumber(date)
    this.currYear = result[0];
    this.currWeek = result[1];
    let nextWeekNumber: number = this.currWeek + 1;

    if (this.currWeek === 53) {
      nextWeekNumber = 1;
      this.currYear += 1;
    }
 
    //set min and max number of weeks
    this.minWeek = 1;
 
    // Setting maxWeek to 53, since we have only 52 (+ 1) weeks every year
    // this.maxWeek = this.getNumberOfWeeks(this.sharedService.getCurrentDateTime())
 
    // year list
    this.getYearList(this.currYear);
 
    //form control
    let regexNum = "^[0-9]*$";
    this.historyEntryForm = this.formBuilder.group({
      fromWeek: [this.minWeek, [Validators.required, Validators.min(this.minWeek), Validators.max(this.maxWeek), Validators.pattern(regexNum)]],
      toWeek: [nextWeekNumber, [Validators.required, Validators.min(this.minWeek), Validators.max(this.maxWeek), Validators.pattern(regexNum)]],
      year: [this.currYear, Validators.required],
    });
 
    //set week
    this.fromWeekUserSelected = this.historyEntryForm.value.fromWeek;
    this.toWeekUserSelected = this.historyEntryForm.value.toWeek;
 
    //set year 
    this.yearUserSelected = this.historyEntryForm.value.year;
 
    // Set customer name required when logged in user is a customer admin
    this.customerUserSelected = this.customerName;
 
    // get customers names
    await this.getCustomers()
 
    //delivery sites
    await this.getDeliverySites();
    // this.customerUserSelected = this.customerName;
    // this.customerIdUserSelected = this.customerID;
 
 
    // forecast data
    this.fetchOnClick()
 
    if (this.userInfo) {
            this.customerIdUserSelected = (this.userInfo.RoleID === "PA") ? '' : (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
        } else {
            this.customerIdUserSelected = '';    
        }
        
        this.subscribeToGlobalSearchServiceSearchTerm();
        
  }
	  
	ngOnDestroy(): void {
    this.globalSearchText = '';
	}

  changeOnClick() {
    this.fromWeekUserSelected = this.historyEntryForm.value.fromWeek;
    this.toWeekUserSelected = this.historyEntryForm.value.toWeek;
    this.yearUserSelected = this.historyEntryForm.value.year;
  }
  async fetchOnClick() {
    this.submitted = true;
    if (!this.historyEntryForm.valid) {
      return false;
    } else {
      try {
        this.historyForecastData = [];
        this.historyForecastData = await this.fetchData();
      } catch (error) {
        this.historyForecastData = null;
      }
      this.dataSource = new MatTableDataSource(this.historyForecastData);
      this.dataSource.sort = this.sort;
 
    }
  }
 
  async getCustomers() {
    this.Customers = [];
    return new Promise((resolve, reject) => {
      this.forecastEntryService.getCustomerNames(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.Customers = res["Customers"]
            // this.customerUserSelected = this.customerName;
            // this.customerIdUserSelected = this.customerID;
 
            if (this.userInfo) {
              this.customerIdUserSelected = (this.userInfo.RoleID === "PA") ? '' : (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
            } else {
              this.customerIdUserSelected = '';    
            }
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }
  async getDeliverySites() {
    this.delSite = [];
    this.deliverySiteObjectList = [];
    return new Promise((resolve, reject) => {
      let isWeekly = 'YES'; // Set isWeekly parameter as YES, when delivery sites needed in weekly data
      this.historyService.getHistDeliverySite(this.customerIdUserSelected, this.countryCode, isWeekly).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            for (let deliverySiteObject of response["DeliverySiteCollection"]) {
              this.deliverySiteObjectList.push(deliverySiteObject);
              this.delSite.push(deliverySiteObject["DeliverySite"]);
              // this.delSiteUserSelected = (this.userInfo.RoleID === "PA") ? '' : (this.delSite[0] ? this.delSite[0] : '');
              this.delSiteUserSelected = '';
            }
            resolve();
          }
          else {
            reject();
          }
        });
    })
 
 
  }
 
  getYearList(currentYear: number) {
    // this.dropDownYear.push(currYear, currYear + 1)
    // Add previous year and current year in the year drop down
    this.dropDownYear.push(currentYear - 1, currentYear);
  }
 
  getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart
    yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }
 
  getNumberOfWeeks(d) {
    var first;
    first = new Date(d.getFullYear(), 0, 4);
    var dayms = 1000 * 60 * 60 * 24;
    var numday = ((d - first) / dayms)
    var weeks = Math.ceil((numday + first.getDay() + 1) / 7);
    return weeks;
  }
 
  async fetchData() {
    if(this.role == "U" && (this.countryCode == "SWE" || this.countryCode == "NOR")) {
      return new Promise(async (resolve, reject) => {
        this.historyService.fetchWeeklyHistoryDetailsForUser(
          this.customerIdUserSelected, 
          this.delSiteUserSelected, 
          this.fromWeekUserSelected, 
          this.toWeekUserSelected, 
          this.yearUserSelected, 
          this.countryCode, 
          this.userId)
          .subscribe(
            res => {
              if (res["ResponseCode"] === "200") {
                this.historyData = res;
                var historyForecastArr = [];
                var filteredArr = [];
                historyForecastArr = res["ForecastDetails"];
   
                // Filtering the null quantities
                /* for (let i = 0; i < res["ForecastDetails"].length; i++) {
                  if (res["ForecastDetails"][i].quantity !== "") {
                    // this.filteredArr = this.fetchedDataForItemArray
                    filteredArr.push(historyForecastArr[i])
                  }
                } */

                /* for (let data of historyForecastArr) {
                  if (data['forecastquantity']) {
                    data['forecastquantity'] = +data['forecastquantity'];
                  }
                } */

                this.displayTable = true;
                resolve(historyForecastArr)
              }
              else {
                this.displayTable = false;
                reject();
              }
            });
      })
    } else {
      return new Promise(async (resolve, reject) => {
        let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelected);
        this.historyService.fetchHistoryDetails(
          this.customerIdUserSelected, 
          this.delSiteUserSelected, 
          this.fromWeekUserSelected, 
          this.toWeekUserSelected, 
          this.yearUserSelected, 
          this.countryCode,
          jdeAddressNumber)
          .subscribe(
            res => {
              if (res["ResponseCode"] === "200") {
                this.historyData = res;
                var historyForecastArr = [];
                var filteredArr = [];
                historyForecastArr = res["ForecastDetails"];
   
                /* for (let data of historyForecastArr) {
                  if (data['forecastquantity']) {
                    data['forecastquantity'] = +data['forecastquantity'];
                  }
                } */

                this.displayTable = true;
                resolve(historyForecastArr)
              }
              else {
                this.displayTable = false;
                reject();
              }
            });
      })
    }

 
  }
 
  downloadExcelData() {
    if (this.historyForecastData && this.historyForecastData.length) {
      let history = [];
      let cloned = this.historyForecastData.map(x => Object.assign({}, x));
      for (let data of cloned) {
        if (data['forecastquantity']) {
          data['forecastquantity'] = +data['forecastquantity'];
        }
        // if (data['actualquantity']) {
        //   data['actualquantity'] = +data['actualquantity'];
        // }

        // Remove Sales quantity from excel file
        delete data['actualquantity'];

        history.push(data);
      }

      let historyDataObjectList: any = [];
        if (history && history.length) {
          for (let historyDataObject of history) {
            let historyObjectForExcelDownload: any = {};
            historyObjectForExcelDownload['Customer name'] = historyDataObject['customerName'];
            historyObjectForExcelDownload['Delivery site'] = historyDataObject['deliverySite'];
            historyObjectForExcelDownload['JDE address number'] = historyDataObject['JDEAddressNumber'];
            historyObjectForExcelDownload['Product name'] = historyDataObject['productName'];
            historyObjectForExcelDownload['Depot name'] = historyDataObject['depotName'];
            historyObjectForExcelDownload['Quantity (metric ton)'] = historyDataObject['forecastquantity'];
            historyObjectForExcelDownload['Week'] = historyDataObject['week'];
            historyObjectForExcelDownload['Year'] = historyDataObject['year'];
            historyObjectForExcelDownload['Created by'] = historyDataObject['createdBy'];
            historyObjectForExcelDownload['Created on'] = historyDataObject['createdOn'];
            historyObjectForExcelDownload['Updated by'] = historyDataObject['updatedBy'];
            historyObjectForExcelDownload['Updated on'] = historyDataObject['updatedOn'];
            historyDataObjectList.push(historyObjectForExcelDownload);
          }
        }

      // Commented old code, kept for future reference
      // this.historyService.exportAsExcelFile(history, "Forecast History");

      let headerList = ["Customer name", "Delivery site", "JDE address number", "Product name", "Depot name", "Quantity (metric ton)", "Week", "Year", "Created by", "Created on", "Updated by", "Updated on"];

      this.sharedService.downloadDataInExcelFile(historyDataObjectList, 'Forecast History', headerList);
    } else {
      this.showStatusDialog(false, 'no-data-available');
    }
  }

  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        if (deliverySiteObject && deliverySiteObject['DeliverySite'] === deliverySiteName) {
          jdeAddressNumber = deliverySiteObject['JDE_ADDRESS_NUMBER'];
          break;
        }
      }
    }
    return jdeAddressNumber;
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.changeDetectorRef.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.changeDetectorRef.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      // this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }

  showStatusDialog(isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,

      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }
 
}
