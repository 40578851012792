import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { ApplicationPipesModule  } from '../shared-modules/application-pipes.module';
import { CommonModule } from '@angular/common';
import { PriceManagementComponent } from './price-management/price-management.component';
import { BitumenIndexComponent } from './bitumen-index/bitumen-index.component';

import { PriceAuthorsComponent } from './price-authors/price-authors.component';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule ,MatPaginatorModule, MatDatepickerModule, MatNativeDateModule} from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { ChartsModule } from 'ng2-charts';
import { PriceDataComponent } from './price-data/price-data.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { GlobalSearchModule } from 'src/app/global-search/global-search.module';

export function HttpLoaderFactory (http:HttpClient){
  return new  TranslateHttpLoader(http);
}


@NgModule({
  declarations: [PriceManagementComponent, BitumenIndexComponent, PriceAuthorsComponent, PriceDataComponent],
  imports: [
    CommonModule,
    ApplicationPipesModule,
    AppRoutingModule,
    MatButtonToggleModule,
    MatSortModule,
    MatTableModule,
    MatSelectModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule ,
    ChartsModule,
    FormsModule,
    HttpClientModule,   
    ReactiveFormsModule,
    GlobalSearchModule,
    TranslateModule.forRoot({
      loader:{
        provide:TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps:[HttpClient]
      }
    })
  ]
})
export class PriceManagementModule { }
