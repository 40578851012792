import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannerMessageService {

  apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient) {
  }

  getCountries() {
    let url = this.apiUrl + '/getCountry';
    return this.httpClient.get(url);
  }

  getCustomerList(countryCode: string) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }

  getUserGroups(customerId: string, countryCode: string) {
    const loginUrl = this.apiUrl + '/getAllUserGroup';
    if (customerId) {
      return this.httpClient
        .get(loginUrl, {
          params: {
            CustomerID: customerId,
            CountryCode: countryCode
          }
        });
    }
  }
}
