import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class ForecastEntryService {
  private weekUserSelected: any;
  private yearUserSelected: any;
  private customerName: any;
  private delSiteUserSelected: any;
  private currWeek: any;
  private currYear: any;
  private forecastdata: any;

  apiUrl = environment.apiUrl;
  private customerId: any;

  constructor(private httpClient: HttpClient) { }
  setCurrWeek(currWeek) {
    this.currWeek = currWeek;
  }
  getCurrWeek() {
    return this.currWeek;
  }
  setWeek(weekUserSelected) {
    this.weekUserSelected = weekUserSelected;
  }
  getWeek() {
    return this.weekUserSelected;
  }
  setYear(yearUserSelected) {
    this.yearUserSelected = yearUserSelected;
  }
  getYear() {
    return this.yearUserSelected;
  }
  setCurrYear(currYear) {
    this.currYear = currYear;
  }
  getCurrYear() {
    return this.currYear;
  }
  setCustomerName(customerId) {
    this.customerId = customerId;
  }
  getCustomerName() {
    return this.customerId;
  }
  setCustomerId(customerName) {
    this.customerName = customerName;
  }
  getCustomerId() {
    return this.customerName;
  }
  setDeliverySite(delSiteUserSelected) {
    this.delSiteUserSelected = delSiteUserSelected;
  }
  getDeliverySite() {
    return this.delSiteUserSelected;
  }
  setForecast(forecastdata) {
    this.forecastdata = forecastdata;
  }
  getForecast() {
    return this.forecastdata;
  }
  getCustomerNames(countryCode) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }
  getWeeklyForecastDetails(CustomerID: string, DeliverySite: string, Week: any, Year: any, countryCode: string, weeklyAvailable: string, jdeAddressNumber: string) {
    let weekly_url = this.apiUrl + '/FetchWeeklyForecastDetails';
    return this.httpClient
      .get(weekly_url,
        {
          params: {
            CustomerName: CustomerID, //"267359",
            DeliverySite: DeliverySite, //"YIT Kotka Ilarila",
            Week: Week, //"23",
            Year: Year, //"2019"
            CountryCode: countryCode,
            WeeklyAvailable: weeklyAvailable,
            JDEAddressNumber: jdeAddressNumber
          }
        });
  }


  updateWeeklyForecastDetailNew(data) {
    let postData = JSON.stringify(data)
    try {
      let weeklyUrl = this.apiUrl + '/UpdateWeeklyForecastDetailsPOSTService';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(weeklyUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }

  }

  getMonthlyForecastDetails(CustomerId: string, DeliverySite: string, Year: any, CountryCode: any, monthlyAvailable: string, jdeAddressNumber: string) {

    // Commented old service name, kept for future reference
    // let forecast_url = this.apiUrl + '/FetchMonthlyForecast';

    let forecast_url = this.apiUrl + '/FetchMonthlyForecast/FetchMonthlyForecast';

    return this.httpClient
      .get(forecast_url, {
        params: {
          CustomerID: CustomerId,
          Year: Year,
          Country: CountryCode,
          MonthlyAvailable: monthlyAvailable,
          JDE_AddressNumber: jdeAddressNumber,
          DeliverySite: DeliverySite
        }
      });
  }

  getContractualVolumeDetails(CustomerId: string, DeliverySite: string, Year: any, CountryCode: any, monthlyAvailable: string, jdeAddressNumber: string) {
    let forecast_url = this.apiUrl + '/FetchMonthlyForecast/FetchContractualMonthlyForecast';
    return this.httpClient
      .get(forecast_url, {
        params: {
          CustomerID: CustomerId,
          DeliverySite: DeliverySite,
          Year: Year,
          Country: CountryCode,
          MonthlyAvailable: monthlyAvailable,
          JDE_AddressNumber: jdeAddressNumber
        }
      });
  }

  //Only for SWEDEN
  getMonthlyForecastDetailsForSweNor(CustomerId: string, DeliverySite: string, Year: any, CountryCode: any, monthlyAvailable: string, jdeAddressNumber: string) {

    // Commented old service name, kept for future reference
    // let forecast_url = this.apiUrl + '/FetchMonthlyForecast';

    let forecast_url = this.apiUrl + '/FetchMonthlyForecast/FetchMonthlyForecast';

    return this.httpClient
      .get(forecast_url, {
        params: {
          CustomerID: CustomerId,
          DeliverySite: DeliverySite,
          Year: Year,
          Country: CountryCode,
          MonthlyAvailable: monthlyAvailable,
          JDE_AddressNumber: jdeAddressNumber
        }
      });
  }

  getMontlyForecastDetailsForAll(Year: any, countryCode: string, monthlyAvailable: string) {

    // Commented old service name, kept for future reference
    // let url = this.apiUrl + '/FetchMonthlyForecastForAll';

    let url = this.apiUrl + '/FetchMonthlyForecastForAll/FetchForecastForAll';

    return this.httpClient
      .get(url, {
        params: {
          Year: Year,
          CountryCode: countryCode,
          MonthlyAvailable: monthlyAvailable
        }
      });
  }

  fetchMonthlyForecastForCustomerAdmin(customerId: string, deliverySite: string, year: string, countryCode: string, isMonthlyAvailable: string, jdeAddressNumber: string, status: string) {
    let url = this.apiUrl + '/FetchMonthlyForecastForCustomerAdmin';
    return this.httpClient
      .get(url, {
        params: {
          CustomerID: customerId,
          DeliverySite: deliverySite,
          Year: year,
          Country: countryCode,
          MonthlyAvailable: isMonthlyAvailable,
          JDE_AddressNumber: jdeAddressNumber,
          Status: status
        }
      });
  }

  getContractualVolumeDetailsForAll(Year: any, countryCode: string, monthlyAvailable: string) {
    let url = this.apiUrl + '/FetchMonthlyForecastForAll/FetchContractualForecastForAll';
    return this.httpClient
      .get(url, {
        params: {
          Year: Year,
          CountryCode: countryCode,
          MonthlyAvailable: monthlyAvailable
        }
      });
  }

  updateMonthlyForecastDetail(data) {
    let postData = JSON.stringify(data)
    try {

      // Commented old service name, kept for future reference
      // let monthlyUrl = this.apiUrl + '/UpdateMonthlyForecastDetailsPOSTService';

      let monthlyUrl = this.apiUrl + '/UpdateMonthlyForecast/updateMonthlyForecast';

      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(monthlyUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }
  }

  updateContractualVolumeDataDetail(data: any) {
    let postData = JSON.stringify(data)
    try {

      // Commented old service name, kept for future reference
      // let monthlyUrl = this.apiUrl + '/UpdateMonthlyForecastDetailsPOSTService';

      let monthlyUrl = this.apiUrl + '/UpdateMonthlyForecast/updateContractualMonthlyForecast';

      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(monthlyUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'MonthlyForecast': worksheet }, SheetNames: ['MonthlyForecast'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const MonthlyForecast: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(MonthlyForecast, fileName + EXCEL_EXTENSION);
  }



  getRollingWeekForecastDetails(data) {
    let postData = JSON.stringify(data)
    try {
      let rollingWeekUrl = this.apiUrl + '/FetchRollingForecast';
      // let rollingWeekUrl = 'http://nis-dev.nynas.com/FetchRollingForecast';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(rollingWeekUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }
  }

  // Old get method not to be used due issues on Finland
  /* fetchRollingForecastForAll(countryCode: string, year: any, weekstart: string) {
    let url = this.apiUrl + '/FetchRollingForecastForAll';
    return this.httpClient
      .get(url, {
        params: {
          Country: countryCode,
          Year: year,
          weekstart: weekstart
        }
      });
  } */

  downloadAllRollingFourWeekForecastEntries(weekNumberObject: any) {
    let postData = JSON.stringify(weekNumberObject)
    try {
      let fetchRollingForecastForAllUrl = this.apiUrl + '/FetchRollingForecastForAll';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(fetchRollingForecastForAllUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }
  }

  fetchHistoryDetails(customerId: string, deliverySite: string, fromWeek: any, toWeek: any, year: any, countryCode: any, jdeAddressNumber: string) {
    let HistoryUrl = this.apiUrl + '/FetchHistoryForecastDetails';
    return this.httpClient
      .get(HistoryUrl, {
        params: {
          customerId: customerId,
          fromWeek: fromWeek,
          toWeek: toWeek,
          year: year,
          CountryCode: countryCode,
          jdeAddressNumber: jdeAddressNumber
        }
      });
  }
  
}
