import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource, MAT_DATE_FORMATS } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs'; // RxJS 6 syntax
import { LoginService } from 'src/app/login/login.component.service';
import { NynasUpdatesService } from '../nynas-updates/nynas-updates.service';
import { QualityCertificateService } from '../quality-certificate/quality-certificate.service';
import { AppService } from '../shared-modules/services/shared.service';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { Product } from './product';
import { UploadDocumentService } from './upload-document.service';
import * as moment from 'moment';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../shared-modules/components/confirm-dialog/confirm-dialog.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YY',
  },
  display: {
    dateInput: 'DD-MMM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class UploadDocumentComponent implements OnInit, OnDestroy {

  productForm: FormGroup;
  nynasUpdateForm: FormGroup;
  public dataSource = new MatTableDataSource();
  public displayedColumns = ['NYNAS_UPDATE_DATE', 'TYPE', 'TITLE', 'MESSAGE', 'Download', 'Edit', 'Delete'];
  // 'delete'
  currentToggleMenu: string = 'product_document';
  yearList: number[] = [];
  monthList: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  userInfo: any;
  name: String;
  isLoggedInUserAdmin: boolean;
  user: any;
  emailId: string;
  displayTable: boolean = true;
  submitted = false;
  customerID: any;
  productName: any;
  productList: Product[] = [];
  loggedInUserName: string;
  selectedYear: string = "";
  countryCode : any ;
  selectType: any;
  selectTypeToSearch: any;
  isPriceIndexFormEdit: boolean = undefined;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selectStorageType: string[];
	mobileQuery: MediaQueryList;
	private _mobileQueryListener: () => void; 

  constructor(private uploadService: UploadDocumentService,
    private sharedService: AppService, private router: Router, private formBuilder: FormBuilder,
    private loginService: LoginService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private nynasupdate: NynasUpdatesService,
    private qualityCertificateService: QualityCertificateService,
    private nynasUpdateService: NynasUpdatesService,
    private cdRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService) {

    this.userInfo = this.sharedService.getLoginResponse();
    this.customerID = (this.userInfo ? this.userInfo.CustomerID : '');
  
    this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    
    this.countryCode = this.userInfo.CountryCode;
    this.user = [];
    const navigation = this.router.getCurrentNavigation();        
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => cdRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);  
  }

  ngOnInit() {
    this.selectType = ['Distribution', 'Proceedings', 'Nynas Releases'];
    this.selectStorageType = ['Internal', 'External']
    let currentYear: number = this.getCurrentYear();
    this.buildProductForm();
    this.buildNynasUpdateForm();
    this.nynasupdate.getUpdates(this.countryCode);
    this.getYearList(currentYear);
    this.getProductInformation();
    this.getNynasUpdates();
    this.translate.get(['HOME.selectedLanguageForDatePicker'])
    .subscribe(data => {
      this.dateAdapter.setLocale(data['HOME.selectedLanguageForDatePicker']);
    });
 

    this.translate.get(['HOME.itemsPerPageLabel'])
    .subscribe(data => {
      this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
    });
  }

	ngOnDestroy(): void {
	  this.mobileQuery.removeListener(this._mobileQueryListener);
	}

  buildProductForm() {
    this.productForm = this.formBuilder.group({
      PRODUCT_ID: ['', Validators.required],
      PRODUCT_INFO: [''],
      SAFETY_DATA: [''],
      CE_MARK: [''],
      PERFORMANCE_REPORT: [''],
      Year: ['', Validators.required],
      QualityCertificate: ['', Validators.required],
      UpdatedBy: [''],
      CreatedBy: [''],
      UpdatedOn: [''],
      CreatedOn: ['']
    });
  }

  buildNynasUpdateForm() {
    this.nynasUpdateForm = this.formBuilder.group({
      NYNAS_UPDATE_DATE: ['', Validators.required],
      TYPE: ['', Validators.required],
      STORAGE_TYPE: ['Sharepoint', Validators.required],
      TITLE: [''],
      MESSAGE: ['', Validators.required],
      DOUCUMENT_URL: [''],
      UpdatedBy: [''],
      CreatedBy: [''],
      UpdatedOn: [''],
      CreatedOn: [''],
    });
  }

  toggleButtonClick(toggleMenu: string) {
    this.currentToggleMenu = toggleMenu;
    // this.getCurrentMonthAndYear();
    this.resetProduct();
    this.resetNynasUpdate();
    // this.productForm.controls['priceIndexType'].setValue(toggleMenu);
    //this.getPriceIndexList(this.currentToggleMenu);    
  }

  /* getCurrentMonthAndYear() {
    const date = this.sharedService.getCurrentDateTime();

    // Get current year
    let currentYear = date.getUTCFullYear();

    // Get current month
    var dateArray = date.toISOString().split("-");
    var month_index = parseInt(dateArray[1], 10) - 1;

    this.getYearList(currentYear);
    this.productForm.controls['Month'].setValue(this.monthList[month_index]);
    this.productForm.controls['Year'].setValue(currentYear);
  } */

  getCurrentDate(): string {
    let currentDate: any;
    const date = this.sharedService.getCurrentDateTime();
    currentDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.productForm.controls['IndexDate'].setValue(currentDate);
    return currentDate;
  }

  getCurrentYear(): number {
    const date = this.sharedService.getCurrentDateTime();
    return date.getUTCFullYear();
  }

  getYearList(currYear: number) {
    this.yearList = [];
    this.yearList.push(currYear - 5, currYear - 4, currYear - 3, currYear - 2, currYear - 1, currYear);
  }

  get fields() {
    return this.productForm.controls;
  }

  get nynasUpdateFormFields() {
    return this.nynasUpdateForm.controls;
  }

  openSaveUserDialog(headerTitle: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber

      },
      // height: '37%',
      height: 'auto',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  saveStatusDialogProduct(response: any) {
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        this.openSaveUserDialog('Product documents uploaded successfully',true,'product-document-success');
      } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.openSaveUserDialog('Failed to upload product documents', false,'product-document-fail');
      } else {
        this.openSaveUserDialog('Failed to upload product documents',  false,'product-document-fail');
      }
    } else {
      this.openSaveUserDialog('Failed to upload product documents', false,'product-document-fail');
    }
  }

  saveStatusDialogNynasUpdate(response: any) {
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        this.openSaveUserDialog('Nynas update added successfully',  true,'nynas-update-success');
      } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.openSaveUserDialog('Failed to add Nynas update',  false,'nynas-update-fail');
      } else {
        this.openSaveUserDialog('Failed to add Nynas update', false,'nynas-update-fail');
      }
    } else {
      this.openSaveUserDialog('Failed to add Nynas update',  false,'nynas-update-fail');
    }
  }

  deleteStatusDialog(response: any) {
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        this.openSaveUserDialog('Contact deleted successfully', true,'contact-delete-success');
      } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.openSaveUserDialog('Failed to delete contact', false,'contact-delete-fail');
      } else {
        this.openSaveUserDialog('Failed to delete contact',  false,'contact-delete-fail');
      }
    } else {
      this.openSaveUserDialog('Failed to delete contact',  false,'contact-delete-fail');
    }
  }

  saveProduct() {
    this.submitted = true;

    if (!this.productForm.valid) {
			this.openSaveUserDialog('There are items that require your attention ', false,'product-document-message');
			return false;
		}

    this.productForm.controls['UpdatedBy'].setValue(this.loggedInUserName);
    this.productForm.controls['CreatedBy'].setValue(this.loggedInUserName);
    this.productForm.controls['UpdatedOn'].setValue(this.datePipe.transform(this.sharedService.getCurrentDateTime(), 'yyyy-MM-dd'));
    this.productForm.controls['CreatedOn'].setValue(this.datePipe.transform(this.sharedService.getCurrentDateTime(), 'yyyy-MM-dd'));

    const request1 = this.uploadService.addProductMaster(this.productForm.value, this.countryCode);
    const request2 = this.uploadService.addQualityCertificate(this.productForm.value, this.countryCode);
    const requestArray = [];
    requestArray.push(request1);
    requestArray.push(request2);

    forkJoin(requestArray).subscribe(response => {
      // this.response = results;
      if (response && response.length) {
        if (response[0]) {
          // this.customerList = results[0].Customers;
        }
        if (response[1]) {
          this.saveStatusDialogProduct(response[1]);
        }
      }
    });

    this.resetProduct();
  }

  saveNynasUpdates() {
    this.submitted = true;

    if (!this.nynasUpdateForm.valid) {
			this.openSaveUserDialog('There are items that require your attention ', false,'product-document-message');
			return false;
		}

    this.nynasUpdateForm.controls['UpdatedBy'].setValue(this.loggedInUserName);
    this.nynasUpdateForm.controls['CreatedBy'].setValue(this.loggedInUserName);
    this.nynasUpdateForm.controls['UpdatedOn'].setValue(this.datePipe.transform(this.sharedService.getCurrentDateTime(), 'yyyy-MM-dd'));
    this.nynasUpdateForm.controls['CreatedOn'].setValue(this.datePipe.transform(this.sharedService.getCurrentDateTime(), 'yyyy-MM-dd'));
    this.nynasUpdateForm.controls['NYNAS_UPDATE_DATE'].setValue(this.datePipe.transform(this.nynasUpdateForm.value['NYNAS_UPDATE_DATE'], 'yyyy-MM-dd'));



    this.uploadService.addNynasUpdate(this.nynasUpdateForm.value, this.countryCode)
      .subscribe(
        res => {
          this.saveStatusDialogNynasUpdate(res);
          // this.openSaveUserDialog();
          this.getNynasUpdates();
        });

    //this.addUserForm.reset()
    this.resetNynasUpdate();
    this.isPriceIndexFormEdit = undefined;
  }

  getProductInformation() {
    this.uploadService.getProductInformation(this.customerID, this.countryCode)
      .subscribe(
        res => {
          if (res["ResponseCode"] == 200) {
            // this.productName = res['PRODUCT_NAME'];
            this.productList = res['GetAllProductResponses'];
          } else {
            // console.log("Error");
          }
        });
  }

  changeProductName() {
    for (var product of this.productList) {
      if (product.PRODUCT_ID === this.productForm.value['PRODUCT_ID']) {
        this.productForm.controls['PRODUCT_INFO'].setValue(product['PRODUCT_INFO']);
        this.productForm.controls['SAFETY_DATA'].setValue(product['SAFETY_DATA']);
        this.productForm.controls['CE_MARK'].setValue(product['CE_MARK']);
        this.productForm.controls['PERFORMANCE_REPORT'].setValue(product['PERFORMANCE_REPORT']);
      }
    }
    return true;
  }

  changeYear() {
    this.qualityCertificateService.getQualityCertificateUrl(this.productForm.value['PRODUCT_ID'], this.productForm.value['Year'], this.countryCode)
      .subscribe(
        response => {
          this.productForm.controls['QualityCertificate'].setValue(response['QualityCertificate']);

          if (response != '') {
            this.productForm.get('QualityCertificate').setValidators([Validators.required]);
          } else {
            //response.clearValidators();
          }

        });
    return true;
  }

  resetProduct() {
    this.submitted = false;
    this.productForm.reset();
    this.presetProductFormValues();
    // this.addUserForm.controls['Country_Code'].setValue('Country');
    // this.addUserForm.controls['PrimaryContactCountryCode'].setValue('Country Code');
  }

  resetNynasUpdate() {
    this.submitted = false;
    this.isPriceIndexFormEdit = undefined;
    this.nynasUpdateForm.reset();
    this.presetNynasFormValues();
  }

  presetProductFormValues() {
    // this.getCurrentMonthAndYear();
    this.productForm.controls['PRODUCT_ID'].setValue('');
    this.productForm.controls['PRODUCT_INFO'].setValue('');
    this.productForm.controls['SAFETY_DATA'].setValue('');
    this.productForm.controls['CE_MARK'].setValue('');
    this.productForm.controls['PERFORMANCE_REPORT'].setValue('');
    this.productForm.controls['Year'].setValue('');
    this.productForm.controls['QualityCertificate'].setValue('');
    this.productForm.controls['UpdatedBy'].setValue(this.loggedInUserName);
    this.productForm.controls['CreatedBy'].setValue(this.loggedInUserName);
    this.productForm.controls['UpdatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
    this.productForm.controls['CreatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
  }

  presetNynasFormValues() {
    // this.getCurrentMonthAndYear();
    this.nynasUpdateForm.controls['NYNAS_UPDATE_DATE'].setValue('');
    this.nynasUpdateForm.controls['TYPE'].setValue('');
    this.nynasUpdateForm.controls['STORAGE_TYPE'].setValue('Sharepoint');
    this.nynasUpdateForm.controls['TITLE'].setValue('');
    this.nynasUpdateForm.controls['MESSAGE'].setValue('');
    this.nynasUpdateForm.controls['DOUCUMENT_URL'].setValue('');
    this.nynasUpdateForm.controls['UpdatedBy'].setValue(this.loggedInUserName);
    this.nynasUpdateForm.controls['CreatedBy'].setValue(this.loggedInUserName);
    this.nynasUpdateForm.controls['UpdatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
    this.nynasUpdateForm.controls['CreatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
  }

  getNynasUpdates() {
    // this.userList = [];
    this.dataSource.data = [];
    // this.isRequestCompleted = false;
    this.nynasUpdateService.getUpdates(this.countryCode)
      .subscribe(
        res => {
          const users = 'getNynasUpdates';

          if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            // if (false) {
            if (res && res[users] && res[users].length) {
              // this.userList = res[users];
              // this.dataSource.data = res[users];
              // this.isRequestCompleted = true;


              var userList = [];
              for (var nynasUpdate of res[users]) {
                let date = new Date(nynasUpdate['NYNAS_UPDATE_DATE']);
                date.toLocaleDateString("en-GB");
                //nynasUpdate['NYNAS_UPDATE_DATE'] = moment(this.sharedService.getCurrentDateTime()).format('YYYY');
                nynasUpdate['NYNAS_UPDATE_DATE'] = moment(date).format('DD-MMM-YY').toUpperCase();

                userList.push(nynasUpdate);
              }
              this.dataSource.data = userList;
            }

            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.displayTable = true;
            // this.isRequestCompleted = true;
            // this.userListCopy = this.userList; 

          }
          else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
            // else if (true) {
           this.openSaveUserDialog('No Data available',  false,'no-data-available');

          }
          else {
            // this.openSaveUserDialog('No Data available',  false,'no-data-available');

          }
        });
  }
  downloadPdf(pdfUrl, storageType) {
    // let type;
    //console.log(pdfUrl, storageType)
    if (storageType == "Sharepoint") {
      this.downloadPdfSharepoint(pdfUrl)
    } else if (storageType == "External") {
      if (pdfUrl) {
        window.open(pdfUrl, '_blank');
      }
    } else {
      this.openDialog('Sorry! No file available.', null, false,'no-file-available');
    }


  }
  downloadPdfSharepoint(pdfUrl) {
    let pdfData;
    // let filepath = "/Shared%20Documents/General/" + pdfUrl
    let filepath = pdfUrl;

    let sharepointData = {
      "Username": "API.Integration@nynas.onmicrosoft.com",
      "Password": "Nosa9144",
      "Domain": "nynas",
      // "SourceFolderPath": "/sites/GroupCustomerPortal",
      "SourceFolderPath" : "/sites/PortalOperationalDocuments",
      "FilePath": filepath.replace(/ /g, "%20")
    }

    this.nynasUpdateService.getSharepointDoc(sharepointData)
      .subscribe(
        res => {
          if (res["ResponseCode"] == "200") {
            pdfData = res["EncodedFileData"]
            const byteArray = new Uint8Array(atob(pdfData).split('').map(char => char.charCodeAt(0)));
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            let fileUrl = window.URL.createObjectURL(blob);
            window.open(fileUrl);
          } else {
            this.openDialog('Sorry! No file available.', null, false,'no-file-available');
          }
        });
  }

  openDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,
      },
      height: '35%',
      width: (this.mobileQuery.matches ? '50%' : '25%'),
    });
  }

  /* selectRow(row) {
    // this.inputDisabled = true;
    // this.nynasUpdateForm.get('TYPE').disable();
    this.isPriceIndexFormEdit = true;
    var indexDate = this.datePipe.transform(row['NYNAS_UPDATE_DATE'], 'yyyy-MM-dd');
    this.nynasUpdateForm.patchValue(row);
    this.nynasUpdateForm.controls['NYNAS_UPDATE_DATE'].setValue(indexDate);

    // this.addUserForm.controls['Contact_ID'].setValue(row['Contact_ID']);
    // this.addUserForm.controls['PrimaryContactCountryCode'].setValue(row['Country_Code']);

  } */

  selectRow(row) {
    this.isPriceIndexFormEdit = true;
    this.nynasUpdateForm.patchValue(row);
    this.nynasUpdateForm.controls['NYNAS_UPDATE_DATE'].setValue(this.getCorrectDateFormat(row['NYNAS_UPDATE_DATE']));
  }

  getCorrectDateFormat(dateWtihMonth: any) {
    // get year
    var yy = dateWtihMonth.substr(7, 2);
    var fullYear = (yy < 90) ? '20' + yy : '19' + yy;
    var newdate = dateWtihMonth.substr(0, 7) + fullYear;

    // get month
    var actualDate: any = newdate.split("-");
    var months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    for (var j = 0; j < months.length; j++) {
      if (actualDate[1] == months[j]) {
        let monthNumber = (months.indexOf(months[j]) + 1);
        if (monthNumber < 10) {
          actualDate[1] = '0' + monthNumber;
        } else {
          actualDate[1] = monthNumber;
        }
      }
    }
    var formattedDate = actualDate[2] + '-' + actualDate[1] + '-' + actualDate[0];
    return formattedDate;
  }

  deleteNynasUpdate(row) {

    this.showConfirmationPopUp(row, 'delete-nynas-updates-confirmation');

    //console.log(row);
    // console.log(this.datePipe.transform(row.NYNAS_UPDATE_DATE, 'yyyy-MM-dd'))
    /* let data = {
      "Date": this.datePipe.transform(row.NYNAS_UPDATE_DATE, 'yyyy-MM-dd'),
      "Type": row.TYPE,
      "Title": row.TITLE,
      "CountryCode" :  this.countryCode,
      "Message": row.MESSAGE,
      "Link": row.DOUCUMENT_URL,
      "CreatedBy": this.loggedInUserName,
      "CreatedOn": this.loggedInUserName,
      "UpdatedBy": "",
      "UpdatedOn": ""

    }
    //console.log(data)
    this.uploadService.deleteNynasUpdate(data).subscribe(res => {
      if (res["ResponseCode"] === "200") {
        // console.log(res)
        this.openDialog('Deleted successfully', null, true)
        this.getNynasUpdates();
      } else {
        this.openDialog('Failed to delete. Please try again later!', null, false);
      }

    }); */

  }

  showConfirmationPopUp(row: any, headerNumber:string) {
		const dialogData = new ConfirmDialogModel("Delete nynas updates", '', '','',null);
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			// maxWidth: "410px",
			// data: dialogData,
			data: {
				headerNumber:headerNumber ,
				messageNumber: '',
				count: null
			},
			height: '37%',
			width: (this.mobileQuery.matches ? '60%' : '24%'),
			// header: 'Delete user group',
			
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			if (dialogResult) {
				let data = {
          "Date": this.datePipe.transform(row.NYNAS_UPDATE_DATE, 'yyyy-MM-dd'),
          "Type": row.TYPE,
          "Title": row.TITLE,
          "CountryCode" :  this.countryCode,
          "Message": row.MESSAGE,
          "Link": row.DOUCUMENT_URL,
          "CreatedBy": this.loggedInUserName,
          "CreatedOn": this.loggedInUserName,
          "UpdatedBy": "",
          "UpdatedOn": ""
    
        }
        //console.log(data)
        this.uploadService.deleteNynasUpdate(data).subscribe(res => {
          if (res["ResponseCode"] === "200") {
            // console.log(res)
            this.openDialog('Deleted successfully', null, true,'nynas-update-delete-success')
            this.getNynasUpdates();
          } else {
            this.openDialog('Failed to delete. Please try again later!', null, false,'nynas-update-delete-fail');
          }
    
        });
			}
		});
	}

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.cdRef.detectChanges();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'NYNAS_UPDATE_DATE': {
          return new Date(item[property]);
        }
        default: return item[property];
      }
    };
  }

  getDateObject(date: string): any {
    let dateObject = {};
    if (date) {
      let dateArray = date.split('-');
      if (dateArray && dateArray.length && dateArray[0] && dateArray[1] && dateArray[2]) {
        dateObject['date'] = dateArray[0] ? dateArray[0] : '';
        dateObject['month'] = dateArray[1] ? dateArray[1].toUpperCase() : '';
        dateObject['year'] = dateArray[2] ? dateArray[2] : '';
      }

    }
    return dateObject;
  }

  getStringWithoutSpaces(stringWithSpaces: string): string {
    let stringWithoutSpaces = '';
    stringWithoutSpaces = stringWithSpaces.replace(/\s/g, '');
    return stringWithoutSpaces;
  }

}
