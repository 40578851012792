import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesReportService {

  apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getSalesReportLocation(year: string, countryCode: string, customerId: string) {
    const url = this.apiUrl + '/GetSalesReportLocation';
    return this.httpClient.get(url, {
      params: {
        Year: year,
        Country: countryCode,
        CustomerID: customerId,
      }
    });
  }

}
