import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../user';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AddUserGroupService {
  
  apiUrl = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({

      'Content-Type': 'application/json',

    })
  };
  value: any;

  constructor(
    private httpClient: HttpClient) { }

  getUsers(userID: string, country?: string, customerName?: string) {
    const loginUrl = this.apiUrl + '/getAllUser';
    return this.httpClient
      .get(loginUrl, {
        params: {
          UserID: userID,
          Country: country,
          CustomerId: customerName
        }
      });
  }

  getMenu(countryCode: any) {

    // let url = this.apiUrl + '/getMenu';

    let url = 'http://nis-dev.nynas.com:7575' + '/getMenu';

    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }
  
  getCustomerNames(countryCode) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }

  getUserGroupsByGroupId(groupID: string, countryCode: string) {
    const url = this.apiUrl + '/getAllUserGroup';
    if (groupID) {
      return this.httpClient
        .get(url, {
          params: {
            GroupID: groupID,
            CountryCode: countryCode
          }
        });
    } else {
      return this.httpClient
        .get(url, {
          params: {
            CountryCode: countryCode
          }
        });
    }
  }

  createAddUserGroup(userGroup: any, menuList: any) {

    let url = this.apiUrl + '/createUserGroup';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };
    let postData = JSON.stringify({
      "CreatedBy": userGroup['createdBy'],
      "UserGroupName": userGroup['UserGroupName'],
      "CustomerID": userGroup['companyName'],
      "Menus": menuList
    });
    return this.httpClient.post<User>(url, postData, httpOptions).pipe();
  }

  updateUserGroup(userGroup: any, menuList: any) {
    let url = this.apiUrl + '/ManageUserGroup';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };
    /* let postData = JSON.stringify({
      "CreatedBy": userGroup['createdBy'],
      "UserGroupName": userGroup['UserGroupName'],
      "CustomerID": userGroup['companyName'],
      "Menus": menuList,
      "UserGroupID": userGroup['userGroupID']
    }); */
    let postData = '', postDataObject = {}, postDataUserGroupObject = {}, postDataUserGroupObjectArray = [];
    menuList.forEach((menu: any) => {
      postDataUserGroupObject = {}
      postDataUserGroupObject['GroupId'] = userGroup['userGroupID'];
      postDataUserGroupObject['MenuId'] = menu.MenuId;
      postDataUserGroupObject['AccessFlag'] = menu.AccessFlag;
      postDataUserGroupObjectArray.push(postDataUserGroupObject);    
    });
    postDataObject['UserGroups'] = postDataUserGroupObjectArray;
    postData = JSON.stringify(postDataObject);

    return this.httpClient.post<User>(url, postData, httpOptions).pipe();
  }
}
