import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AddDeliverySiteComponentSwedanService {

    apiUrl = environment.apiUrl;

    constructor(private httpClient: HttpClient) { }

    getCountries() {
        let url = this.apiUrl + '/getCountry';
        return this.httpClient.get(url);
    }
    getCustomerNames(countryCode) {
        let url = this.apiUrl + '/getCustomer';
        return this.httpClient.get(url, {
            params: {
              CountryCode: countryCode,
            }
          });
    }
    addDeliverySiteService(deliverySite: any, depotCollectionProduct : any, notifyHaulier: string, primaryContactWithCountryCode: string, sendReminderFlag: string) {
        let url = this.apiUrl + '/AddDeliverySiteForProductMapping';
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            })
        };
        // Old payload before Haulier changes
        /* let postData = JSON.stringify({
            "CustomerId": deliverySite.CustomerId,
            "DeliverySite": deliverySite.DeliverySiteName,
            "DepotCollection":  depotCollectionProduct,
            "SubscriberEmailAddress": deliverySite.SubscriberEmailAddress,
            "DepotAddressNumber": deliverySite.DepotAddressNumber,
            "JdeAddressNumber": deliverySite.JdeAddressNumber,
            "BillingReference": deliverySite.BillingReference,
            "PrimaryContact": deliverySite.PrimaryContact,
            "AddressLine1": deliverySite.AddressLine1,
            "AddressLine2": deliverySite.AddressLine2,
            "DeliveryPostalCode": deliverySite.DeliveryPostalCode,
            "State": null,
            "City": deliverySite.City,
            "CreatedBy": deliverySite.CreatedBy,
            "CountryId": deliverySite.CountryId,
            "Status": deliverySite.Status,
            "ApprovalStage": deliverySite.ApprovalStage,     
            "UpdatedBy": deliverySite.UpdatedBy,
           // "CarrierEmailAddress": deliverySite.CarrierEmailAddress,
           // "CustomerName": deliverySite.CustomerName,
              
        }); */

        // New payload after Haulier changes
        let postData = JSON.stringify({
            "CustomerId": deliverySite.CustomerId,
            "CustomerName": deliverySite.CustomerName,
            "DeliverySite": deliverySite.DeliverySiteName,
            "DepotCollection": depotCollectionProduct,
            "SubscriberEmailAddress": deliverySite.SubscriberEmailAddress,
            "DepotAddressNumber": deliverySite.DepotAddressNumber,
            "JdeAddressNumber": deliverySite.JdeAddressNumber,
            "BillingReference": deliverySite.BillingReference,
            "PrimaryContact": primaryContactWithCountryCode,
            "AddressLine1": deliverySite.AddressLine1,
            "AddressLine2": deliverySite.AddressLine2,
            "DeliveryPostalCode": deliverySite.DeliveryPostalCode,
            "State": null,
            "City": deliverySite.City,
            "CreatedBy": deliverySite.CreatedBy,
            "CountryId": deliverySite.CountryId,
            "Status": deliverySite.Status,
            "ApprovalStage": deliverySite.ApprovalStage,
            "UpdatedBy": deliverySite.UpdatedBy,
            "HaulierEmailId": deliverySite.HaulierEmailId,
            "SecondaryHaulierEmailId": deliverySite.SecondaryHaulierEmailId,
            "NotifyHaulier": notifyHaulier,
            "SendReminderFlag": sendReminderFlag
        });

        return this.httpClient.post(url, postData, httpOptions).pipe();
    }
    updateDeliverySite(deliverySite: any, updateDepotCollection: any, notifyHaulier: string, primaryContactWithCountryCode: string, sendReminderFlag: string) {
        let url = this.apiUrl + '/UpdateDeliverySiteForProductMapping';
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            })
        };

        // Old payload before Haulier changes
        /* let postData = JSON.stringify({
            "CustomerId": deliverySite.CustomerId,
            "DeliverySiteName": deliverySite.DeliverySiteName,
            "DepotCollection": updateDepotCollection,
            "SubscriberEmailAddress": deliverySite.SubscriberEmailAddress,
            "CarrierEmailAddress": deliverySite.CarrierEmailAddress,
            "DepotAddressNumber": deliverySite.DepotAddressNumber,
            "JdeAddressNumber": deliverySite.JdeAddressNumber,
            "BillingReference": deliverySite.BillingReference,
            "PrimaryContact": deliverySite.PrimaryContact,
            "AddressLine1": deliverySite.AddressLine1,
            "AddressLine2": deliverySite.AddressLine2,
            "DeliveryPostalCode": deliverySite.DeliveryPostalCode,
            "State": null,
            "City": deliverySite.City,
            "CreatedBy" : deliverySite.CreatedBy,
            "CountryId": deliverySite.CountryId,
            "Status": deliverySite.Status,
            "ApprovalStage": deliverySite.ApprovalStage,
            "UpdatedBy": deliverySite.UpdatedBy,
            "UpdatedOn": deliverySite.UpdatedOn
        }); */

        // New payload after Haulier changes
        let postData = JSON.stringify({
            "CustomerId": deliverySite.CustomerId,
            "DeliverySiteName": deliverySite.DeliverySiteName,
            "DepotCollection": updateDepotCollection,
            "SubscriberEmailAddress": deliverySite.SubscriberEmailAddress,
            "CarrierEmailAddress": deliverySite.CarrierEmailAddress,
            "DepotAddressNumber": deliverySite.DepotAddressNumber,
            "JdeAddressNumber": deliverySite.JdeAddressNumber,
            "BillingReference": deliverySite.BillingReference,
            "PrimaryContact": primaryContactWithCountryCode,
            "AddressLine1": deliverySite.AddressLine1,
            "AddressLine2": deliverySite.AddressLine2,
            "DeliveryPostalCode": deliverySite.DeliveryPostalCode,
            "State": null,
            "City": deliverySite.City,
            "CreatedBy": deliverySite.CreatedBy,
            "CountryId": deliverySite.CountryId,
            "Status": deliverySite.Status,
            "ApprovalStage": deliverySite.ApprovalStage,
            "UpdatedBy": deliverySite.UpdatedBy,
            "UpdatedOn": deliverySite.UpdatedOn,
            "HaulierEmailId": deliverySite.HaulierEmailId,
            "SecondaryHaulierEmailId": deliverySite.SecondaryHaulierEmailId,
            "NotifyHaulier": notifyHaulier,
            "SendReminderFlag": sendReminderFlag
        });

        // let postData = JSON.stringify(deliverySite);
        return this.httpClient.post(url, postData, httpOptions).pipe();
    }
    updateDepotStatus(deliverySite: any) {
        let url = this.apiUrl + '/UpdateDepotStatus';
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            })
        };
        let postData = JSON.stringify({
            "CustomerId": deliverySite.CustomerId,
            "DeliverySite": deliverySite.DeliverySite,
            "UserStatus": deliverySite.ApprovalStatus,
            "DepotAddressNumber": deliverySite.DepotAddressNumber,
            "Type": deliverySite.Type,
            "CountryCode": deliverySite.CountryId,
            "UpdatedBy": deliverySite.UpdatedBy,
            "UpdatedOn": deliverySite.UpdatedOn
        }
        );
        return this.httpClient.post(url, postData, httpOptions).pipe();
    }
    updateJDEStatus(deliverySite: any) {
        let url = this.apiUrl + '/UpdateJDEStatus';
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            })
        };
        let postData = JSON.stringify({
            "CustomerId": deliverySite.CustomerId,
            "DeliverySite": deliverySite.DeliverySite,
            "UserStatus" :  deliverySite.ApprovalStatus,
            "JdeAddressNumber": deliverySite.JdeAddressNumber,
            "Type": deliverySite.Type,
            "CountryCode": deliverySite.CountryId,
            "UpdatedBy": deliverySite.UpdatedBy,
            "UpdatedOn": deliverySite.UpdatedOn
        }
        );
        return this.httpClient.post(url, postData, httpOptions).pipe();
    }
    updateDeliverySiteStatus(deliverySite: any) {
        let url = this.apiUrl + '/UpdateDeliverySiteStatus';
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            })
        };
        let postData = JSON.stringify({
            "CustomerId": deliverySite.CustomerId,
            "DeliverySite": deliverySite.DeliverySite,
            "CountryCode": deliverySite.CountryId,
            "ApprovalStage": deliverySite.ApprovalStage,
            "UpdatedOn": deliverySite.UpdatedOn,
            "CustomerName": deliverySite.CustomerName
        });
        return this.httpClient.post(url, postData, httpOptions).pipe();
    }

    fetchCustomerContract(customerId: string, countryCode: string) {
        let customerUrl = this.apiUrl + '/GetCustomerContract';
        return this.httpClient
          .get(customerUrl, {
            params: {
              CustomerID: customerId,
              Country: countryCode
            }
          });
      }

      getDeliverySiteProducts(customerId: string,countryCode: string,jdeAddNum :string) {
        const url = this.apiUrl + '/GetDeliverySiteProducts';
        //const url = 'http://nis-dev.nynas.com:7575/GetDeliverySiteProducts?CustomerID=267359&DeliverySiteName=AUTOMAATTINEN%20SIVUSTO&CountryCode=FIN'
        return this.httpClient
          .get(url, {
            params: {
              CustomerID: customerId,
              CountryCode: countryCode,
              JDEAddressNumber:jdeAddNum
            }
          });
      }

      emailService(key: string, countryCode: string, deliverySite: any, primaryContactWithCountryCode: string, fieldsChanged: string, sendReminderFlag: string) {
        let url = this.apiUrl + '/EmailService';
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            })
        };

        let postData = JSON.stringify({
            "Key": key,
            "CountryCode": countryCode,
            "CustomerName": deliverySite.CustomerName,
            "DeliverySite": deliverySite.DeliverySiteName,
            "DepotAddressNumber": deliverySite.DepotAddressNumber,
            "JdeAddressNumber": deliverySite.JdeAddressNumber,
            "PrimaryContact": primaryContactWithCountryCode,
            "SubscriberEmailAddress": deliverySite.SubscriberEmailAddress,
            "CarrierEmailAddress": deliverySite.CarrierEmailAddress,
            "BillingReference": deliverySite.BillingReference,
            "AddressLine1": deliverySite.AddressLine1,
            "AddressLine2": deliverySite.AddressLine2,
            "DeliveryPostalCode": deliverySite.DeliveryPostalCode,
            "City": deliverySite.City,
            "CountryId": deliverySite.CountryId,
            "ZipCode": deliverySite.DeliveryPostalCode,
            "Status": deliverySite.Status,
            "FieldChange": fieldsChanged,
            "SendReminderFlag": sendReminderFlag
        });
        return this.httpClient.post(url, postData, httpOptions).pipe();
    }
}