import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { DistributionDataFilterPipe } from './pipes/distribution-data-filter.pipe';
import { ProductInformationFilterPipe } from './pipes/product-information-filter.pipe';
import { NynasUpdatesFilterPipe } from './pipes/nynas-updates-filter.pipe';
import { ForecastStatusPipe } from './pipes/forecast-status.pipe';
import { ToNumberPipe } from './pipes/price-number.pipe';
@NgModule({
  imports: [
    // dependent modules
  ],
  declarations: [ 
    ProductInformationFilterPipe,
    DistributionDataFilterPipe,
    NynasUpdatesFilterPipe,
    ForecastStatusPipe,
    ToNumberPipe
  ],
  exports: [
    ProductInformationFilterPipe,
    DistributionDataFilterPipe,
    NynasUpdatesFilterPipe,
    ForecastStatusPipe,
    ToNumberPipe
  ],
  providers:[ProductInformationFilterPipe,  DistributionDataFilterPipe, NynasUpdatesFilterPipe, ForecastStatusPipe, ToNumberPipe]
})
export class ApplicationPipesModule {}