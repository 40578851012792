import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class LoginHistoryService {
    apiUrl = environment.apiUrl;
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

    constructor(private httpClient: HttpClient) { }

    getLoginHistory(CustomerID:string, Country:string,FromDate:any){
      const serviceUrl = this.apiUrl + '/FetchLoginHistory';
      return this.httpClient.get(serviceUrl, {
        params: {
            CustomerID:CustomerID,
            Country:Country,
            FromDate:FromDate
            

           
        }
    });
            

        }
        
        getCustomerList(countryCode) {
            let url = this.apiUrl + '/getCustomer';
            return this.httpClient.get(url, {
              params: {
                CountryCode: countryCode,
              }
            });
          }
          }