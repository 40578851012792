import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ManageUsersService } from '../../user-management/manage-users/manage-users.service';
import { MatTableDataSource, MatSort } from '@angular/material';
import { UserGroup } from 'src/app/user-management/user-group';
import { Customer } from 'src/app/user-management/customer';
import { DelSite } from './DelSite';
import { MatPaginator } from '@angular/material/paginator';
import { CompanyDetailsService } from './company-details.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableTwoPaginator', { static: true }) tableTwoPaginator: MatPaginator;
  

  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  AddrLine1: string;
  AddrLine2: string;
  AddrCity: string;
  AddrState: string;
  AddrPin: string;
  AddrCountry: string;

  userID = '';
  country = '';
  customerName = '';

  customerList: Customer[] = [];
  userGroupList: UserGroup[] = [];

  public myFirstTableData: any = new MatTableDataSource();
  public mySecondTableData: any = new MatTableDataSource()

  @ViewChild('firstTableSort', {static: true}) public firstTableSort: MatSort;
  @ViewChild('secondTableSort', {static: true}) public secondTableSort: MatSort;

  dataSource: any;
  public displayedColumns = ['FirstName', 'EmailID', 'PrimaryContact', 'Role', 'UserGroupName', 'UserStatus'];
  public displayedColumnsDelSite = ['DeliverySite', 'Status', 'DeliveryBillRef', 'DS_PrimaryContactNo', 'DeliveryAddress'];

  userData: any;
  isRequestCompleted: boolean;
  dataSourceDelSite: any;
  deliverySiteList: any = [];
  userInfo: any;
  username: string;
  compDetails: any;
  role: any;
  customerID: any;
  displayUserTable: boolean;
  displayDelTable: boolean;
	roleList = [{code: 'U', description: 'User'}, {code: 'A', description: 'Admin'}, {code: 'PA', description: 'Portal Admin'}, {code: 'DA', description: 'Depot Admin'}];
  isLoggedInUserAdmin: boolean;
  countryCode: any;

  constructor
  (
    private manageUsersService: ManageUsersService,
    private companyDetailsService: CompanyDetailsService, 
    private sharedService: AppService,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    this.userInfo = this.sharedService.getLoginResponse();
    // this.username= this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '';
   
    this.username =  (this.userInfo ? (this.userInfo.FirstName + " "+ this.userInfo.LastName) : '' );
   
    this.role = this.userInfo ? this.userInfo.RoleName : '';
    this.customerID = this.userInfo ? this.userInfo.CustomerID : '';    
    this.customerName = this.userInfo ? this.userInfo.CustomerName : ''; 
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.countryCode = this.userInfo.CountryCode
    
    //company details
    this.compDetails = await this.getMyCompanyDetails()
    if (this.compDetails) {
      this.companyName = this.compDetails.CustomerName;
      this.contactName = this.compDetails.PrimaryContactName;
      this.contactEmail = this.compDetails.PrimaryContactEmail;
      this.contactPhone = this.compDetails.PrimaryContactNumber;
      this.AddrLine1 = this.compDetails.AddressLine1;
      this.AddrLine2 = this.compDetails.AddressLine2;
      this.AddrCity = this.compDetails.City;
      // this.AddrState = this.compDetails.State;
      this.AddrPin = this.compDetails.Pin;
      this.AddrCountry = this.compDetails.Country;
      //del site
      try {
        this.deliverySiteList = await this.getDeliverySites();
      } catch (e) {
      }
    }


    this.displayDelTable = true;
    // this.delSiteData = this.getMyCompanyDetails();


    // this.dataSourceDelSite = new MatTableDataSource(this.delSiteData);
    // this.dataSourceDelSite.sort = this.sort;

    this.mySecondTableData = new MatTableDataSource(this.deliverySiteList);
    this.mySecondTableData.sort = this.secondTableSort;
    this.mySecondTableData.paginator = this.tableTwoPaginator;


    // users
    try {
      var userList = await this.getUsers();
    } catch (e) {
    }
    this.userData = userList;
    this.myFirstTableData = new MatTableDataSource(this.userData);
    // this.dataSource.sort = this.sort;
    this.myFirstTableData.sort = this.firstTableSort;
    this.myFirstTableData.paginator = this.tableOnePaginator;
     
  }


  ngAfterViewInit(): void {
    // this.dataSource.sort = this.sort;
    
    this.myFirstTableData.sort = this.firstTableSort;
    this.mySecondTableData.sort = this.secondTableSort;
    this.cdRef.detectChanges();	
		this.myFirstTableData.sortingDataAccessor = (data, sortHeaderId) => {
			if (data[sortHeaderId]) {
				return data[sortHeaderId].toLocaleLowerCase()
			}
			return null;
		};	
		this.mySecondTableData.sortingDataAccessor = (data, sortHeaderId) => {
			if (data[sortHeaderId]) {
				return data[sortHeaderId].toLocaleLowerCase()
			}
			return null;
    };
    
    this.translate.get(['HOME.itemsPerPageLabel'])
      .subscribe(data => {
        this.tableOnePaginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
        this.tableTwoPaginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
      });

	}



  async getMyCompanyDetails() {
    return new Promise((resolve, reject) => {
      this.companyDetailsService.getMyCompanyDetails(this.customerID)
        .subscribe(
          res => {
            if (res["ResponseCode"] == "200") {
              this.displayDelTable = true
              resolve(res)
            } else {
              this.displayDelTable = false
              reject();
            }

          }
        )
    })
  }

  async getUsers() {
    return new Promise((resolve, reject) => {
      this.manageUsersService.getUsers(this.userID, this.countryCode, this.customerID)
        .subscribe(
          response => {
            // const users = 'Users';
            if (response["ResponseCode"] == "200") {
              this.isRequestCompleted = true;
              this.displayUserTable = true;
              if (response['Users'] && response['Users'].length) {
                var userList = [];
                for (var user of response['Users']) {
                  if (user['Role']) {
                    let roleCode = user['Role'],
                      roleObject = this.roleList.filter(role => role.code === roleCode),
                      roleDescription = (roleObject && roleObject.length) ? roleObject[0].description : '';
                    user['Role'] = roleDescription;
                  }

                  // Remove country name short code from phone nunber, instead use area code like +358 for Finland
                  if (user['PrimaryContact']) {
                    user['PrimaryContact'] = user['PrimaryContact'].replace("FIN", "+358 ");
                    user['PrimaryContact'] = user['PrimaryContact'].replace("SWE", "+46 ");
                    user['PrimaryContact'] = user['PrimaryContact'].replace("NOR", "+47 ");
                    user['PrimaryContact'] = user['PrimaryContact'].replace("EST", "+372 ");
                    user['PrimaryContact'] = user['PrimaryContact'].replace("DNK", "+45 ");
                    user['PrimaryContact'] = user['PrimaryContact'].replace("GBR", "+44 ");
                  }

                  userList.push(user);
                }
              }
              resolve(userList);
            } else {
              this.displayUserTable = false;
              reject();
            }
          }
        );
    });
  }

  async getDeliverySites() {
    return new Promise((resolve, reject) => {
      this.companyDetailsService.getDeliverySites(this.customerID, "Inactive", this.countryCode).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {

            // Commented below code, kept for future reference
            // resolve(res["DeliverySiteCollection"]);

            for (let deliverySite of response["DeliverySiteCollection"]) {

              // Remove country name short code from phone nunber, instead use area code like +358 for Finland
              if (deliverySite['PrimaryContact']) {
                deliverySite['PrimaryContact'] = deliverySite['PrimaryContact'].replace("FIN", "+358 ");
                deliverySite['PrimaryContact'] = deliverySite['PrimaryContact'].replace("SWE", "+46 ");
                deliverySite['PrimaryContact'] = deliverySite['PrimaryContact'].replace("NOR", "+47 ");
                deliverySite['PrimaryContact'] = deliverySite['PrimaryContact'].replace("EST", "+372 ");
                deliverySite['PrimaryContact'] = deliverySite['PrimaryContact'].replace("DNK", "+45 ");
                deliverySite['PrimaryContact'] = deliverySite['PrimaryContact'].replace("GBR", "+44 ");
              }
              this.deliverySiteList.push(deliverySite)
            }
            resolve(this.deliverySiteList);
          }
          else {
            reject();
          }
        });
    })
  }

  //  getDelSite() {
  // // async getDelSite() {
  //   // return new Promise((resolve, reject) => {
  //   var fetchDelSite: DelSite[] = [
  //     {
  //       DeliveryNumber: "1", DeliveryProductName: 'Yit Kotka', BillingReference: "201645", DeliveryPostalCode: '54001',
  //       DeliveryPostOffice: 'Oulu', SubscriberEmailAddress: 'hannu@nynas.com', CarrierEmailAddress: 'xyz@carrier.com', Address: 'Saga Matkat Oy, Saga Tours Ltd., Albertinkatu 36 B,  00180 Helinski, Finland'
  //     },
  //     {
  //       DeliveryNumber: "2", DeliveryProductName: 'Yit Bitumen', BillingReference: "201646", DeliveryPostalCode: '54001',
  //       DeliveryPostOffice: 'Malmo', SubscriberEmailAddress: 'hannu@nynas.com', CarrierEmailAddress: 'xyz@carrier.com', Address: 'Saga Matkat Oy, Saga Tours Ltd., Albertinkatu 36 B,  00180 Helinski, Finland'
  //     },
     
  //   ];

  //   return fetchDelSite;
  //   // })
  // }

}


