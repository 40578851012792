import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { ApplicationPipesModule  } from '../shared-modules/application-pipes.module';
import { CommonModule } from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import {ReportsComponent} from './reports.component'
import { ForecastactualComponent } from './forecastactual/forecastactual.component';
import { PortalUsageComponent } from './portal-usage/portal-usage.component';
import { ChartsModule } from 'ng2-charts';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateService} from '@ngx-translate/core';
import { HttpClientModule,HttpClient} from '@angular/common/http';
import {AppComponent} from '../app.component';
import { ForecastactualswedenComponent } from './forecastactualsweden/forecastactualsweden.component';
import { ForecastactualweeklyswedenComponent } from './forecastactualweeklysweden/forecastactualweeklysweden.component';
import { ForecastactualmonthlyswedenComponent } from './forecastactualmonthlysweden/forecastactualmonthlysweden.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ForecastactualfinlandComponent } from './forecastactualfinland/forecastactualfinland.component';
import {ForecastactualweeklyfinlandComponent} from './forecastactualweeklyfinland/forecastactualweeklyfinland.component';
import {ForecastactualmonthlyfinlandComponent} from './forecastactualmonthlyfinland/forecastactualmonthlyfinland.component';
import { SalesReportComponent } from './sales-report/sales-report.component';
import { MatSortModule } from '@angular/material/sort';
import { WeeklyReportComponent } from './weekly-report/weekly-report.component';
import { MonthlyReportComponent } from './monthly-report/monthly-report.component';
import { DailyReportsComponent } from './daily-reports/daily-reports.component';
import { MatDatepickerModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatPaginatorModule } from '@angular/material';
import { ContractualVolumeReportComponent } from './contractual-volume-report/contractual-volume-report.component';
import { ForecastSnapshotDetailsComponent } from './forecast-snapshot-details/forecast-snapshot-details.component';
import { ShippedDeliveriesComponent } from './shipped-deliveries/shipped-deliveries.component';
import { DeliveryAnalysisComponent } from './delivery-analysis/delivery-analysis.component';

import { GlobalSearchModule } from '../global-search/global-search.module';
export function HttpLoaderFactory (http:HttpClient){
  return new  TranslateHttpLoader(http);
}

@NgModule({
  declarations: [ReportsComponent,ForecastactualComponent,PortalUsageComponent, ForecastactualswedenComponent, ForecastactualweeklyswedenComponent, ForecastactualmonthlyswedenComponent,ForecastactualfinlandComponent,ForecastactualmonthlyfinlandComponent,ForecastactualweeklyfinlandComponent, SalesReportComponent, WeeklyReportComponent, MonthlyReportComponent, DailyReportsComponent, ContractualVolumeReportComponent, ForecastSnapshotDetailsComponent, ShippedDeliveriesComponent, DeliveryAnalysisComponent],
  imports: [
    CommonModule,
    ApplicationPipesModule,
    AppRoutingModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    ChartsModule,
     HttpClientModule,
     TranslateModule,
    MatButtonToggleModule,
    MatSortModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatPaginatorModule,
    GlobalSearchModule,
    TranslateModule.forChild({
      loader:{
        provide:TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps:[HttpClient]
      }
    })
  ],
  entryComponents: [ForecastSnapshotDetailsComponent]
})
export class ReportsModule { }
