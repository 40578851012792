import { Pipe, PipeTransform } from '@angular/core';
import { sample } from 'rxjs/operators';

@Pipe({
  name: 'productInformationFilter'
})
export class ProductInformationFilterPipe implements PipeTransform {
  //, productSubtype:string,depot:string Condition &&(!productSubtype)&&(!depot)
  transform(value: any, productType: string, productSubType: string, depotLocation: string): any {
    if ((!productType || productType == 'All' || productType == 'undefined')) {
      
      if ((!productSubType || productSubType == 'All' || productSubType == 'undefined')) {
       
        if ((!depotLocation || depotLocation == 'All' || depotLocation == 'undefined')) {
         
          return value;
        }
        else {
        
          // return value.filter(value => {(value.depotInformation.foreach(element => {
          //   console.log(element.depot);

          // }));


          // });
          return value;
        }
      }
      else {
      
        if ((!depotLocation || depotLocation == 'All' || depotLocation == 'undefined')) {
         
          return value.filter(value => (value.PRODUCT_SUB_TYPE.includes(productSubType ? productSubType.trim() : productSubType)));
        }
        else {
  
          //along with depot Location Filter 
          return value.filter(value => (value.PRODUCT_SUB_TYPE.includes(productSubType ? productSubType.trim() : productSubType)));
        }
      }
    }
    else {
      if ((!productSubType || productSubType == 'All' || productSubType == 'undefined')) {
        if ((!depotLocation || depotLocation == 'All' || depotLocation == 'undefined')) {
         
          return value.filter(value => (value.PRODUCT_TYPE.includes(productType)));
        }
        else {
        
          //return filter on type and depotlocation
          return value.filter(value => (value.PRODUCT_TYPE.includes(productType)));
        }
      }
      else {
        if ((!depotLocation || depotLocation == 'All' || depotLocation == 'undefined')) {
         
          return value.filter(value => (value.PRODUCT_TYPE.includes(productType)) && (value.PRODUCT_SUB_TYPE.includes(productSubType ? productSubType.trim() : productSubType)));
        }
        else {
         
          //add depotlocation as Well
          return value.filter(value => (value.PRODUCT_TYPE.includes(productType)) && (value.PRODUCT_SUB_TYPE.includes(productSubType ? productSubType.trim() : productSubType)));
        }

      }

    }
    // return value.filter(value =>(value.productType.includes(productType))||(value.productSubType.includes(productSubType)));
  }

}
