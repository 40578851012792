import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar, MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { NynasUpdates } from './nynas-updates';
import { AppService } from '../shared-modules/services/shared.service';
import { NynasUpdatesService } from './nynas-updates.service';
import { CommonService } from '../order-management/order-management/order-management.component.service';
import { NynasUpdatesFilterPipe } from '../shared-modules/pipes/nynas-updates-filter.pipe';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MediaMatcher } from '@angular/cdk/layout';


// import { User } from '../user';



@Component({
	selector: 'app-nynas-updates',
	templateUrl: './nynas-updates.component.html',
	styleUrls: ['./nynas-updates.component.css']
})
export class NynasUpdatesComponent implements OnInit, OnDestroy {

	customerName = '';
	Title = '';
	selectTypeToSearch: any;
	selectType: any;
	selectedItem: string = "{{'HOME.NynasUpdates'|translate}}";
	//   users: User[];
	public displayedColumns = ['NYNAS_UPDATE_DATE', 'TYPE', 'TITLE', 'MESSAGE', 'Download'];
	selectedCompanyId: string = "";
	isLoggedInUserPortalAdmin: boolean;
	// userGroupList: UserGroup[] = [];

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	isRequestCompleted: boolean;
	loginResponse: any;
	MessageList = [{ code: 'U', description: 'User' }, { code: 'A', description: 'Admin' }, { code: 'PA', description: 'Portal Admin' }];
	User: any;
	userInfo: any;
	public dataSource = new MatTableDataSource<NynasUpdates>();
	name: string;
	isLoggedInUserAdmin: boolean;
	displayTable: boolean = true;
	userList: any;
	userListCopy: any;
	updatesType = [{ code: 'All', description: 'All' }, { code: 'Distribution', description: 'Distribution' }, { code: 'Proceedings', description: 'Proceedings' }, { code: 'Nynas', description: 'Nynas Releases' }];
	allowedMenuIdList: string[] = [];
	mobileQuery: MediaQueryList;
	private _mobileQueryListener: () => void;

	constructor(
		private nynasUpdateService: NynasUpdatesService,
		private sharedService: AppService,
		private dialog: MatDialog,
		private commonService: CommonService,
		private filterData: NynasUpdatesFilterPipe,
		public translate: TranslateService,
		private changeDetectorRef: ChangeDetectorRef, 
		private media: MediaMatcher
		// public dialogRef: MatDialogRef<DialogBodyComponent>
	) {
		this.userInfo = this.sharedService.getLoginResponse();
		this.name = this.userInfo.FirstName + " " + this.userInfo.LastName;
		this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
		this.getAllowedMenuIdList(this.userInfo ? this.userInfo.Menus : []);
		this.countryCode = this.userInfo.CountryCode;
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}

	ngOnInit() {
		this.selectType = ['All', 'Distribution', 'Proceedings', 'Nynas Releases'];
		this.selectTypeToSearch = this.selectType[0];
		this.getNynasUpdates();



		this.dataSource.sort = this.sort;
		this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
			if (data[sortHeaderId]) {
				return data[sortHeaderId].toLocaleLowerCase()
			}
			return null;
		};

		// Uncomment if services are down to display table data


		const userList: NynasUpdates[] = [

			// {
			// 	Date: this.sharedService.getCurrentDateTime(),
			// 	Type: 'Nynas Releases',
			// 	Message: 'Updated with correct information', 
			// 	Download: 'D',
			// 	Title: 'Tank temperature September 2019'
			// },

			// {
			// 	Date: this.sharedService.getCurrentDateTime(),
			// 	Type: 'Distribution',
			// 	Message: 'Updated Distribution Guide', 
			// 	Download: 'D',
			// 	Title: 'Distribution Guide 2019'
			// },

			// {
			// 	Date: this.sharedService.getCurrentDateTime(),
			// 	Type: 'Proceedings',
			// 	Message: 'Updated Tank temperature', 
			// 	Download: 'D',
			// 	Title: 'Distribution Guide 2019'
			// },

			// {
			// 	Date: this.sharedService.getCurrentDateTime(),
			// 	Type: 'Nynas Releases',
			// 	Message: 'Updated with correct information', 
			// 	Download: 'D',
			// 	Title: 'Tank temperature September 2019'
			// }



		];


		this.translate.get(['HOME.itemsPerPageLabel'])
		.subscribe(data => {
		  this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
		});
	}

	ngOnDestroy(): void {
	  this.mobileQuery.removeListener(this._mobileQueryListener);
	}

	// showSelectedMenuItem(itemName: string) {
	// 	this.selectedItem = itemName;
	// 	this.updateSelectedItem(this.selectedItem);
	//   }
	showSelectedMenuItem(itemName: string) {
		this.selectedItem = itemName;
		this.updateSelectedItem(this.selectedItem);
	}

	updateSelectedItem(selectedItem: string) {
		this.commonService.updateSelectedItem(selectedItem)
	}

	getNynasUpdates() {
		this.userList = [];
		this.dataSource.data = [];
		this.isRequestCompleted = false;
		this.nynasUpdateService.getUpdates(this.countryCode)
			.subscribe(
				res => {
					const users = 'getNynasUpdates';

					if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
						// if (false) {


						if (res && res[users] && res[users].length) {

							var userList = [];
							for (var nynasUpdate of res[users]) {
								let date = new Date(nynasUpdate['NYNAS_UPDATE_DATE']);
								date.toLocaleDateString("en-GB");
								//nynasUpdate['NYNAS_UPDATE_DATE'] = moment(this.sharedService.getCurrentDateTime()).format('YYYY');
								nynasUpdate['NYNAS_UPDATE_DATE'] = moment(date).format('DD-MMM-YY').toUpperCase();

								userList.push(nynasUpdate);
							}


							this.userList = userList;
							this.dataSource.data = this.userList;
							this.isRequestCompleted = true;



						}
						this.dataSource.sort = this.sort;
						this.dataSource.paginator = this.paginator;

						this.displayTable = true;
						this.isRequestCompleted = true;
						this.userListCopy = this.userList;

					}
					else if (res['ResponseCode'] && res['ResponseCode'] === "201" &&
						res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === "DATA NOT FOUND.") {
						this.displayTable = false;
					}
					else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
						// else if (true) {
						this.openSaveUserDialog('No Data available', (res['Description'] ? res['Description'] : null), false,'no-data-available');

					}
					else {
						this.openSaveUserDialog('No Data available', (res['Description'] ? res['Description'] : null), false,'no-data-available');

					}
				});
	}
	countryCode(countryCode: any) {
		throw new Error("Method not implemented.");
	}

	openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean,headerNumber:string) {
		this.dialog.open(DialogBodyComponent, {
			data: {
				header: headerTitle,
				message: message ? message : ' ',
				buttonText: {
					ok: 'Ok'
				},
				isSuccess: isSuccess,
				headerNumber:headerNumber
			},
			height: '40%',
			width: (this.mobileQuery.matches ? '50%' : '25%'),
		});
	}


	filterUpdatess(value, selectTypeToSearch) {

		if (selectTypeToSearch) {
			let type = selectTypeToSearch,
				typeObject = this.updatesType.filter(updateType => updateType.description === type),
				typeCode = (typeObject && typeObject.length) ? typeObject[0].code : '';
			selectTypeToSearch = typeCode;
		}

		this.userListCopy = this.filterData.transform(this.userList, selectTypeToSearch);
		// this.displayIndex=1;

		if (this.userListCopy && this.userListCopy.length) {
			this.dataSource = new MatTableDataSource(this.userListCopy);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.displayTable = true;
		} else {
			this.displayTable = false;
		}		

	}

	validateMenu(menuId: string): boolean {
		return this.allowedMenuIdList.includes(menuId);
	}
	getAllowedMenuIdList(menuList: any) {
		if (menuList && menuList.length) {
			for (var menu of menuList) {
				this.allowedMenuIdList.push(menu.MenuID);
			}
		}
	}

	downloadPdf(pdfUrl, storageType) {
		// let type;
		//console.log(pdfUrl, storageType)
		if (storageType == "Sharepoint") {
			this.downloadPdfSharepoint(pdfUrl)
		} else if (storageType == "External") {
			if (pdfUrl) {
				window.open(pdfUrl, '_blank');
			}
		} else {
			this.openDialog('Sorry! No file available.', null, false,'no-file-available');
		}


	}
	downloadPdfSharepoint(pdfUrl) {
		let pdfData;
		// let filepath = "/Shared%20Documents/General/" + pdfUrl
		let filepath = pdfUrl;

		let sharepointData = {
			"Username": "API.Integration@nynas.onmicrosoft.com",
			"Password": "Nosa9144",
			"Domain": "nynas",
			// "SourceFolderPath": "/sites/GroupCustomerPortal",
			"SourceFolderPath" : "/sites/PortalOperationalDocuments",
			"FilePath": filepath.replace(/ /g, "%20")
		}

		this.nynasUpdateService.getSharepointDoc(sharepointData)
			.subscribe(
				res => {
					if (res["ResponseCode"] == "200") {
						pdfData = res["EncodedFileData"]
						const byteArray = new Uint8Array(atob(pdfData).split('').map(char => char.charCodeAt(0)));
						const blob = new Blob([byteArray], { type: 'application/pdf' });
						let fileUrl = window.URL.createObjectURL(blob);
						window.open(fileUrl);
					} else {
						this.openDialog('Sorry! No file available.', null, false,'no-file-available');
					}
				});
	}

	openDialog(headerTitle: string, message: string, isSuccess: boolean,headerNumber:string) {
		this.dialog.open(DialogBodyComponent, {
			data: {
				header: headerTitle,
				message: message ? message : ' ',
				buttonText: {
					ok: 'Ok'
				},
				isSuccess: isSuccess,
				headerNumber:headerNumber,
			},
			height: '35%',
			width: (this.mobileQuery.matches ? '50%' : '25%'),
		});
	}

	getDateObject(date: string): any {
		let dateObject = {};
		if (date) {
			let dateArray = date.split('-');
			if (dateArray && dateArray.length && dateArray[0] && dateArray[1] && dateArray[2]) {
				dateObject['date'] = dateArray[0] ? dateArray[0] : '';
				dateObject['month'] = dateArray[1] ? dateArray[1].toUpperCase() : '';
				dateObject['year'] = dateArray[2] ? dateArray[2] : '';
			}

		}
		return dateObject;
	}

	getStringWithoutSpaces(stringWithSpaces: string): string {
	  let stringWithoutSpaces = '';
	  stringWithoutSpaces = stringWithSpaces.replace(/\s/g, '');
	  return stringWithoutSpaces;
	}

}