import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class ProductInformationService {
  apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private httpClient: HttpClient) { }

  // getProductInformation(status: string, country?: string) {
  getProductInformation(CustomerID, countryCode) {
    const serviceUrl = this.apiUrl + '/GetAllProduct';

    return this.httpClient
      .get(serviceUrl, {
        params: {
          Status:'ACTIVE',
          Country: countryCode,
          CustomerID: CustomerID
 
        }
      });
  }
  getProductDropdownData() {
    const serviceUrl = this.apiUrl + '/GetProductTypeSubType';
    return this.httpClient
      .get(serviceUrl, {

      });
  }


  getSharepointDoc(data) {
    let postData = JSON.stringify(data)
    try {
      let productSharepointUrl = this.apiUrl + '/ReadFileDataFromSharepointService';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(productSharepointUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }
  }

  // This SOA/OSB service will fetch products documents from Sales force
  getProductList(countryCode: string) {
    // let url = this.apiUrl + '/EmailService';
    let url = 'http://10.0.0.19:7575/soa-infra/resources/bitumen/GetProductListFromSalesforce/GetProductListRestService/GetProductList';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };
    let postData = JSON.stringify({
      "Country": countryCode
    });
    return this.httpClient.post(url, postData, httpOptions).pipe();
  }

}
