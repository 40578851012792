import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  apiUrl = environment.apiUrl;

  constructor(
    private httpClient: HttpClient
  ) { }

  getContactUs(countryCode) {
    const url = this.apiUrl + '/GetContactUs';
    return this.httpClient
      .get(url, {
        params: {
          Country_Code: countryCode
        }
      });
  }

  getCountries() {
    let url = this.apiUrl + '/getCountry';
    return this.httpClient.get(url);
  }
}
