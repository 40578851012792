import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './order-management.component.service';
import { ForecastEntryService } from '../forecast-entry/forecast-entry.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
 
@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.css']
})
export class OrderManagementComponent implements OnInit {
  customerName: string;
  role: any;
  customerUserSelected: string;
  
  currentDate = this.sharedService.getCurrentDateTime();
  navbarOpen = false;
  
  selectedItem: string = 'Forecast entry';
  dataSource: any[];
  forecastValue: string;
  myDate = this.sharedService.getCurrentDateTime();
  currWeek: any;
  userInfo: any;
  name: String;
  allowedMenuIdList: string[] = [];

  constructor(private router: Router,
    private commonService: CommonService, 
    private forecastEntryService: ForecastEntryService, 
    private sharedService: AppService) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.name =  (this.userInfo ? (this.userInfo.FirstName + " "+ this.userInfo.LastName) : '' );
    this.getAllowedMenuIdList(this.userInfo ? this.userInfo.Menus : []);
    }
 
  ngOnInit() {
    
    this.commonService.currentSelectedItem.subscribe(selectedItem => this.selectedItem = selectedItem);
    this.showSelectedMenuItem(this.selectedItem);
    // this.commonService.getSelectedItem.subscribe(selectedItem => this.selectedItem = selectedItem)
    // this.selectedItem = this.getHeadingFromRoute();
  }
 
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  showSelectedMenuItem(itemName: string) {
    this.selectedItem = itemName;
    this.updateSelectedItem(this.selectedItem);
  }

  updateSelectedItem(selectedItem: string) {
		this.commonService.updateSelectedItem(selectedItem)
	}

  getHeadingFromRoute(): string {
    if (this.router.url == '/order-management/forecast-entry')
      return 'Forecast entry';
    if (this.router.url == '/order-management/history')
      return 'Forecast history';
    if (this.router.url == '/order-management/pending-forecast')
      return 'Forecast status';
      if(this.router.url=='/order-management/ordering-procedure')
    return 'Ordering procedure';
  }
  
  validateMenu(menuId: string): boolean {
    return this.allowedMenuIdList.includes(menuId);
  }
  getAllowedMenuIdList(menuList: any) {
    if (menuList && menuList.length) {
      for (var menu of menuList) {
        this.allowedMenuIdList.push(menu.MenuID);
      }
    }
  }

  
}