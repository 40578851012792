import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddEditDeliverySiteService {

  apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient) {
  }

  getCustomerList(countryCode: string) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }

  fetchDepotDetails(countryCode: string, isAvailable: string) {
    let customerUrl = this.apiUrl + '/GetDepotDetails';
    return this.httpClient
      .get(customerUrl, {
        params: {
          CountryCode: countryCode,
          is_available: isAvailable
        }
      });
  }

  getCountries() {
    let url = this.apiUrl + '/getCountry';
    return this.httpClient.get(url);
  }

  fetchCustomerContract(customerId: string, countryCode: string) {
    let customerUrl = this.apiUrl + '/GetCustomerContract';
    return this.httpClient
      .get(customerUrl, {
        params: {
          CustomerID: customerId,
          Country: countryCode
        }
      });
  }

  validatePostalCode(customerId: string, countryCode: string, postalCode: string) {
    let url = this.apiUrl + '/ValidatePostalCode';
    return this.httpClient.get(url, {
      params: {
        CustomerId: customerId,
        CountryCode: countryCode,
        PostalCode: postalCode
      }
    });
  }

  addDeliverySite(deliverySite: any, notifyHaulier: string, sendForecastReminder: string, expectedFirstDeliveryDate: string, contactNumber: string) {
    let url = this.apiUrl + '/AddDeliverySite/AddDeliverySite';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };
    let postData = JSON.stringify({
      "CustomerId": deliverySite.CustomerId,
      "DeliverySite": deliverySite.DeliverySite,
      "CustomerName": deliverySite.CustomerName,
      "BillingReference": deliverySite.BillingReference,
      "PrimaryContactName": deliverySite.PrimaryContactName,
      "PrimaryContactEmailAddress": deliverySite.PrimaryContactEmailAddress,
      "ContactNumber": contactNumber,
      "AddressLine1": deliverySite.AddressLine1,
      "AddressLine2": deliverySite.AddressLine2,
      "DeliveryPostalCode": deliverySite.DeliveryPostalCode,
      "City": deliverySite.City,
      "Country": deliverySite.Country,
      "CreatedBy": deliverySite.CreatedBy,
      "UpdatedBy": deliverySite.UpdatedBy,
      "GPSCoordinates": deliverySite.GPSCoordinates,
      "NotifyHaulier": notifyHaulier,
      "HaulierEmailId": deliverySite.HaulierEmailId,
      "HaulierName": deliverySite.HaulierName,
      "Incoterms": deliverySite.Incoterms,
      "LoadingDepot": deliverySite.LoadingDepot,
      "ExpectedFirstDeliveryDate": expectedFirstDeliveryDate,
      "PlantType": deliverySite.PlantType,
      "CustomerTextInfo": deliverySite.CustomerTextInfo,
      "InternalTextInfo": deliverySite.InternalTextInfo,
      "SendForecastReminder": sendForecastReminder,
      "Mode": deliverySite.Mode
    });
    return this.httpClient.post(url, postData, httpOptions).pipe();
  }

  getPlantTypeDetails(customerId: string, countryCode: string, roleId: string) {
    let url = this.apiUrl + '/GetPlantTypeDetails';
    return this.httpClient
      .get(url, {
        params: {
          CustomerCode: customerId,
          CountryCode: countryCode,
          RoleId: roleId
        }
      });
  }

  fetchFreightHandlingDetails(countryCode: string) {
    let url = this.apiUrl + '/FetchFreightHandlingDetails';
    return this.httpClient
      .get(url, {
        params: {
          CountryCode: countryCode
        }
      });
  }


  // service by harshada

//   UpdateDeliverySiteService:

// URL: http://130.61.54.223/UpdateDeliverySiteService

// Input Payload:

// {
//   "CustomerId" : "23820",
//   "OldDeliverySite" : "NynasTestDeliverySite",
//   "UpdatedDeliverySite" : "NynasTestDelSite",
//   "CustomerName" : "Nynas Oy",
//   "BillingReference" : "10012022183",
//   "PrimaryContactName" : "HElen",
//   "PrimaryContactEmailAddress" : "SAYALIA.CHAVAN@LNTINFOTECH.COM",

//   "ContactNumber" : "FIN+358 9823091288",
//   "AddressLine1" : "ADL1",
//   "AddressLine2" : "ADL2",
//   "DeliveryPostalCode" : "672301",
//   "City" : "SALO",
//   "Country" : "FIN",
//   "CreatedBy" : "ABC",
//   "UpdatedBy" : "ABC",

//   "DepotAddressNumber" : "12345",
//   "JDEAddressNumber" : "318522",
//   "GPSCoordinates" : "59.64266, 17.93718",
//   "NotifyHaulier" : "False",
//   "HaulierEmailId" : "",
//   "HaulierName" : "",
//   "Incoterms" : "EXW",

//   "LoadingDepot" : "",
//   "ExpectedFirstDeliveryDate" : "",
//   "PlantType" : "MP",
//   "CustomerTextInfo" : "",
//   "InternalTextInfo" : "",
//   "SendForecastReminder" : "False,
//   "Mode" : "U"
// }

// URL: http://130.61.54.223/UpdateDeliverySiteService

// service by harshada


// code by divyansh

updateDeliverySite(deliverySite: any, notifyHaulier: string, sendForecastReminder: string, expectedFirstDeliveryDate: string, contactNumber: string, oldDeliverySite: string) {
  let url = this.apiUrl + '/UpdateDeliverySiteService';
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    })
  };
  let postData = JSON.stringify({
    "CustomerId": deliverySite.CustomerId,
    "OldDeliverySite": oldDeliverySite,
    "UpdatedDeliverySite" : deliverySite.DeliverySite,
    "CustomerName": deliverySite.CustomerName,
    "BillingReference": deliverySite.BillingReference,    
    "PrimaryContactName": deliverySite.PrimaryContactName,
    "PrimaryContactEmailAddress": deliverySite.PrimaryContactEmailAddress,
    "ContactNumber": contactNumber,
    "AddressLine1": deliverySite.AddressLine1,
    "AddressLine2": deliverySite.AddressLine2,
    "DeliveryPostalCode": deliverySite.DeliveryPostalCode,
    "City": deliverySite.City,
    "Country": deliverySite.Country,
    "CreatedBy": deliverySite.CreatedBy,
    "UpdatedBy": deliverySite.UpdatedBy,
    "DepotAddressNumber" : deliverySite.DepotAddressNumber, 
    "JDEAddressNumber" : deliverySite.JdeAddressNumber,
    "GPSCoordinates": deliverySite.GPSCoordinates,
    "NotifyHaulier": notifyHaulier,
    "HaulierEmailId": deliverySite.HaulierEmailId,
    "HaulierName": deliverySite.HaulierName,
    "Incoterms": deliverySite.Incoterms,
    "LoadingDepot": deliverySite.LoadingDepot,
    "ExpectedFirstDeliveryDate": expectedFirstDeliveryDate,
    "PlantType": deliverySite.PlantType,
    "CustomerTextInfo": deliverySite.CustomerTextInfo,
    "InternalTextInfo": deliverySite.InternalTextInfo,
    "SendForecastReminder": sendForecastReminder,
    "Mode": deliverySite.Mode
  });
  return this.httpClient.post(url, postData, httpOptions).pipe();
}

// code by divyansh


// code by varad

  // updateDeliverySite(deliverySite: any, notifyHaulier: string, sendForecastReminder: string, expectedFirstDeliveryDate: string, contactNumber: string) {
  //   let url = this.apiUrl + '/UpdateDeliverySite/UpdateDeliverySite';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Access-Control-Allow-Origin': '*',
  //       'Content-Type': 'application/json',
  //     })
  //   };
  //   let postData = JSON.stringify({
  //     "CustomerId": deliverySite.CustomerId,
  //     "DeliverySite": deliverySite.DeliverySite,
  //     "CustomerName": deliverySite.CustomerName,
  //     "BillingReference": deliverySite.BillingReference,
  //     "PrimaryContactName": deliverySite.PrimaryContactName,
  //     "PrimaryContactEmailAddress": deliverySite.PrimaryContactEmailAddress,
  //     "ContactNumber": contactNumber,
  //     "AddressLine1": deliverySite.AddressLine1,
  //     "AddressLine2": deliverySite.AddressLine2,
  //     "DeliveryPostalCode": deliverySite.DeliveryPostalCode,
  //     "City": deliverySite.City,
  //     "Country": deliverySite.Country,
  //     "CreatedBy": deliverySite.CreatedBy,
  //     "UpdatedBy": deliverySite.UpdatedBy,
  //     "GPSCoordinates": deliverySite.GPSCoordinates,
  //     "NotifyHaulier": notifyHaulier,
  //     "HaulierEmailId": deliverySite.HaulierEmailId,
  //     "HaulierName": deliverySite.HaulierName,
  //     "Incoterms": deliverySite.Incoterms,
  //     "LoadingDepot": deliverySite.LoadingDepot,
  //     "ExpectedFirstDeliveryDate": expectedFirstDeliveryDate,
  //     "PlantType": deliverySite.PlantType,
  //     "CustomerTextInfo": deliverySite.CustomerTextInfo,
  //     "InternalTextInfo": deliverySite.InternalTextInfo,
  //     "SendForecastReminder": sendForecastReminder,
  //     "Mode": deliverySite.Mode
  //   });
  //   return this.httpClient.post(url, postData, httpOptions).pipe();
  // }

  // code by varad

  emailService(key: string, countryName: string, deliverySite: any, primaryContactWithCountryCode: string, fieldsChanged: string, sendReminderFlag: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/EmailService';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };

    let postData = JSON.stringify({
      "Key": key,
      "CountryCode": deliverySite.Country,
      "CustomerName": deliverySite.CustomerName,
      "DeliverySite": deliverySite.DeliverySite,
      "DepotAddressNumber": deliverySite.DepotAddressNumber,
      "JdeAddressNumber": jdeAddressNumber,
      "PrimaryContact": primaryContactWithCountryCode,
      "SubscriberEmailAddress": deliverySite.PrimaryContactEmailAddress,
      "CarrierEmailAddress": deliverySite.CarrierEmailAddress,
      "BillingReference": deliverySite.BillingReference,
      "AddressLine1": deliverySite.AddressLine1,
      "AddressLine2": deliverySite.AddressLine2,
      "DeliveryPostalCode": deliverySite.DeliveryPostalCode,
      "City": deliverySite.City,
      "CountryId": countryName,
      "ZipCode": deliverySite.DeliveryPostalCode,
      "Status": deliverySite.Status,
      "FieldChange": fieldsChanged,
      "SendReminderFlag": sendReminderFlag
    });
    return this.httpClient.post(url, postData, httpOptions).pipe();
  }

  validateDeliverySiteName(customerId: string, countryCode: string, deliverySite: string) {
    let url = this.apiUrl + '/ValidateDeliverySiteName';
    return this.httpClient.get(url, {
      params: {
        CustomerId: customerId,
        CountryCode: countryCode,
        DeliverySite: deliverySite
      }
    });
  }

}
