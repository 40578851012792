import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GuestViewService {

  apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getCustomerNames(countryCode: string) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient
      .get(url, {
        params: {
          CountryCode: countryCode,
        }
      });
  }

  getUserGroupsByCustomerId(customerID: string, countryCode: string) {
    const url = this.apiUrl + '/getAllUserGroup';
    if (customerID) {
      return this.httpClient
        .get(url, {
          params: {
            CustomerID: customerID,
            CountryCode: countryCode
          }
        });
    } else {
      return this.httpClient
        .get(url, {
          params: {
            CountryCode: countryCode
          }
        });
    }
  }


}
