import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import { OrderEntryService } from '../order-entry/order-entry.service';
import { OrderTrackingService } from '../order-tracking/order-tracking.service';

@Component({
  selector: 'app-unlock-order',
  templateUrl: './unlock-order.component.html',
  styleUrls: ['./unlock-order.component.css']
})
export class UnlockOrderComponent implements OnInit {

  customerList: any = [];
  customerIdUserSelected: string;
  userInfo: any;
  countryCode: string;
  customerId: string;
  userID: string;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  dataSource: any;
  @ViewChild('lockedOrdersTableSort', { static: true }) public lockedOrdersTableSort: MatSort;
  displayedColumnsLockedOrders: any = ['Reference_ID', 'DeliverySite', 'LockedByUser', 'Lock_Time', 'DeliverySite_Primary_Contact_Number', 'Primary_Contact_Number', 'Action'];
  displayLockedOrdersTable: boolean;
  isFindButtonClicked: boolean;
  showGlobalSearchTextBox: boolean = true;
  globalSearchText: string;
  isFirstOnLoad: boolean = true;

  constructor(private sharedService: AppService, private media: MediaMatcher, private orderEntryService: OrderEntryService, private cd: ChangeDetectorRef, private ordertrackingService: OrderTrackingService, private globalSearchService: GlobalSearchService, private dialog: MatDialog, private ordertrackService: OrderTrackingService) {
    this.userInfo = this.sharedService.getLoginResponse();
    if (this.userInfo) {
      this.countryCode = (this.userInfo.CountryCode ? this.userInfo.CountryCode : '');
      this.customerId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
      this.userID = (this.userInfo ? this.userInfo.UserID : '');
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => cd.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }
  }

  async ngOnInit() {
    try {
      await this.getCustomerList();
    }
    catch (e) {
    }
    try {
      await this.getLockedOrders();
    }
    catch (e) {
    }
    this.subscribeToGlobalSearchServiceSearchTerm();
  }

  async getCustomerList() {
    this.customerList = [];
    return new Promise<void>((resolve, reject) => {
      this.orderEntryService.getCustomerNames(this.countryCode).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            this.customerList = response["Customers"];
            this.customerIdUserSelected = '';
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  async getLockedOrders() {
    this.dataSource = new MatTableDataSource([]);
    this.displayLockedOrdersTable = false;
    this.isFindButtonClicked = false;
    this.reinitializeGlobalSearchComponent();
    let referenceId: string = '', lockedTime: string = '';  // optional parameters

    this.ordertrackingService.getLockedOrders(referenceId, this.customerIdUserSelected, this.countryCode, lockedTime).subscribe(
      response => {
        if (response["ResponseCode"] == 200) {
          let orderCollection = [];

          for (let lockedOrder of response['Collection']) {
            let lockedOrderObject = {};
            lockedOrderObject['Reference_ID'] = lockedOrder['Reference_ID'];
            lockedOrderObject['LockedByUser'] = '(' + lockedOrder['LockedByUser'] + ')';
            lockedOrderObject['UserFullName'] = lockedOrder['User_FirstName'] + ' ' + lockedOrder['User_LastName'];
            lockedOrderObject['Lock_Time'] = lockedOrder['Lock_Time'];
            lockedOrderObject['DeliverySite'] = lockedOrder['DeliverySite'];
            lockedOrderObject['DeliverySite_Primary_Contact_Number'] = this.showPhoneNumberWithCountryCode(lockedOrder['DeliverySite_Primary_Contact_Number']);
            lockedOrderObject['Primary_Contact_Number'] = this.showPhoneNumberWithCountryCode(lockedOrder['Primary_Contact_Number']);
            orderCollection.push(lockedOrderObject);
          }
          this.isFindButtonClicked = true;
          this.displayLockedOrdersTable = (orderCollection && orderCollection.length && orderCollection.length > 0);
          this.dataSource = new MatTableDataSource(orderCollection);
          this.dataSource.sort = this.lockedOrdersTableSort;

          this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            if (data[sortHeaderId] && sortHeaderId !== 'Lock_Time') {
              return data[sortHeaderId].toLocaleLowerCase();
            } else if (data[sortHeaderId] && (sortHeaderId === 'Lock_Time')) {
              let date = data[sortHeaderId] ? data[sortHeaderId] : '';
              return new Date(date);
            }
            return data[sortHeaderId];
          };

        } else if (response["ResponseCode"] === "201") {
          this.isFindButtonClicked = true;
          this.displayLockedOrdersTable = false;
        }
        else {
          this.isFindButtonClicked = true;
          this.displayLockedOrdersTable = false;
        }
      });
  }

  unlockOrder(order: any) {
    let referenceNumbersTobeUnlocked: any = [];
    referenceNumbersTobeUnlocked.push(order['Reference_ID']);
    this.ordertrackService.updateUnlockStatusForOrders(referenceNumbersTobeUnlocked, this.userID).subscribe(
      response => {
        if (response["ResponseCode"] == 200) {
          this.showSuccessPopUpWindow(true, ((order && order['Reference_ID']) ? order['Reference_ID'] : ''));
        } else {
          this.showSuccessPopUpWindow(false, ((order && order['Reference_ID']) ? order['Reference_ID'] : ''));
        }
      });
  }

  showSuccessPopUpWindow(isSuccess: boolean, referenceNumber: string) {
    const dialogRef = this.dialog.open(DialogBodyComponent, {
      data: {
        header: isSuccess ? 'order-unlock-success' : 'order-unlock-fail',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        refNum: referenceNumber
      },
      height: 'auto',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && isSuccess) {
        this.getLockedOrders();
      }
    });
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.cd.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.cd.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      this.displayLockedOrdersTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }

  showConfirmationPopUpToUnlockOrder(order: any) {
    const dialogRef = this.dialog.open(DialogBodyComponent, {
      data: {
        header: 'confirm-order-unlock',
        buttonText: {
          ok: 'Ok'
        },
        isWarning: true,
        refNum: ((order && order['Reference_ID']) ? order['Reference_ID'] : '')
      },
      // height: (this.mobileQuery.matches ? '55%' : '50%'),
      // width: (this.mobileQuery.matches ? '88%' : '27%'),
      height: 'auto',
      width: 'auto',
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.unlockOrder(order);
      }
    });
  }

  showPhoneNumberWithCountryCode(phoneNumber: string): string {
    if (phoneNumber) {
      phoneNumber = phoneNumber.replace("FIN", "+358 ");
      phoneNumber = phoneNumber.replace("SWE", "+46 ");
      phoneNumber = phoneNumber.replace("NOR", "+47 ");
      phoneNumber = phoneNumber.replace("EST", "+372 ");
      phoneNumber = phoneNumber.replace("DNK", "+45 ");
      phoneNumber = phoneNumber.replace("GBR", "+44 ");
    }
    return phoneNumber;
  }

}
