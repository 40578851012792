import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageSelectionService {

  apiUrl = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private httpClient: HttpClient) { }

  fetchProductDetailsFromSaleforce(countryName: string, documentType: string, language: string, productId: string) {
    const serviceUrl = this.apiUrl + '/FetchProductDetailsFromSaleforce/ProductDoc';
    return this.httpClient
      .get(serviceUrl, {
        params: {
          Country: countryName,
          Doc_Type: documentType,
          Language: language,
          Item_ID: productId
        }
      });
  }

  getLanguageForCountry(countryCode: string) {
    const serviceUrl = this.apiUrl + '/GetLangaugeForCountry/FetchLangauge';
    return this.httpClient
      .get(serviceUrl, {
        params: {
          Country: countryCode
        }
      });
  }

}