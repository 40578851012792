import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class NynasUpdatesService {

  apiUrl = environment.apiUrl;
  constructor(
    private httpClient: HttpClient) { }

  getUpdates(countryCode) {
    const url = this.apiUrl + '/GetNynasUpdates';
    return this.httpClient
    .get(url, {
      params: {
        CountryCode: countryCode
      }
    });
  }

  getSharepointDoc(data) {
    let postData = JSON.stringify(data)
    try {
      let productSharepointUrl = this.apiUrl + '/ReadFileDataFromSharepointService';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(productSharepointUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }
  }

  

  
}
