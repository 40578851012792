import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { GuestViewService } from '../guest-view/guest-view.service';
import { DashboardService } from '../home/dashboard.component.service';
import { PriceDataService } from '../price-management/price-authors/price-authors.service';
import { AppService } from '../shared-modules/services/shared.service';
import { AddUserComponentService } from '../user-management/add-user/add-user.component.service';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { SuperUserService } from './super-user.service';

@Component({
  selector: 'app-super-user',
  templateUrl: './super-user.component.html',
  styleUrls: ['./super-user.component.css']
})
export class SuperUserComponent implements OnInit, OnDestroy {

  countryList: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  userInfo: any;
  countryCode: string;
  customerId: string;
  userGroupId: string;
  userId: string;
  customerList: any;
  userGroupList: any;
  selectedCountryCode: string;
  selectedCustomerId: string;
  selectedUserGroupId: string;
  proceedButtonClicked: boolean;
  hsfoUSDPerMTList: any = [];
  conversionRateList: any = [];
  monthlyHSFOUSDPerMTList: any = [];
  monthlyHSFOEURPerMTList: any = [];
  monthlyConversionRateList: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SuperUserComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private sharedService: AppService,
    private router: Router,
    private addUserComponentService: AddUserComponentService,
    private superUserService: SuperUserService,
    private guestViewService: GuestViewService,
    private dialog: MatDialog,
    private dashboardService: DashboardService,
    private exchangeBrentData: PriceDataService) {
    this.userInfo = this.sharedService.getLoginResponse();
    if (this.userInfo) {
      this.userId = (this.userInfo ? this.userInfo.UserID : '');
      this.countryCode = this.userInfo.CountryCode;
      this.customerId = (this.userInfo ? this.userInfo.CustomerID : '');
      this.userGroupId = (this.userInfo ? this.userInfo.UserGroupID : '');
    }

    if (data && data['countryList']) {
      this.countryList = data['countryList'];
      this.selectedCountryCode = this.countryCode;
    }
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.getCustomerListAndUserGroupDetails();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  async getCustomerListForUser() {
    this.customerList = [];
    this.userGroupList = [];
    this.selectedCustomerId = '';
    this.selectedUserGroupId = '';
    this.proceedButtonClicked = false;
    return new Promise<void>((_resolve, _reject) => {
      this.superUserService.getCustomerListForUser(this.userId, this.selectedCountryCode).subscribe(
        response => {
          if (response["RequestCode"] === "200" && response['Collection'] && response['Collection'].length) {
            this.customerList = response["Collection"];
            if (this.selectedCountryCode === this.countryCode) {
              this.selectedCustomerId = this.customerId;
              this.selectedUserGroupId = this.userGroupId;
            } else {
              this.selectedCustomerId = '';
              this.selectedUserGroupId = '';
            }
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

  async getUserGroupListForCustomer() {
    this.userGroupList = [];
    this.selectedUserGroupId = '';
    this.proceedButtonClicked = false;
    if (this.selectedCustomerId) {
      return new Promise<void>((_resolve, _reject) => {
        this.guestViewService.getUserGroupsByCustomerId(this.selectedCustomerId, this.selectedCountryCode).subscribe(
          response => {
            if (response["ResponseCode"] === "200" && response['UsersGroups'] && response['UsersGroups'].length) {
              this.userGroupList = response["UsersGroups"];
              if (this.selectedCountryCode === this.countryCode && this.selectedCustomerId === this.customerId) {
                this.selectedUserGroupId = this.userGroupId;
              } else {
                this.selectedUserGroupId = '';
              }
              _resolve();
            }
            else {
              _reject();
            }
          });
      });
    }
  }

  async getCustomerListAndUserGroupDetails() {
    this.proceedButtonClicked = false;
    try {
      await this.getCustomerListForUser();
    }
    catch (e) {
    }

    // Commented code for user group dropdown, kept for future reference
    /* try {
      await this.getUserGroupListForCustomer();
    }
    catch (e) {
    } */

  }

  getCustomerNameById(customerId: string): string {
    for (let customer of this.customerList) {
      if (customer && customer['CustomerID'] === customerId) {
        return customer['CustomerName'];
      }
    }
    return null;
  }

  getUserGroupNameById(userGroupId: string): string {
    for (let userGroup of this.userGroupList) {
      if (userGroup && userGroup['UserGroupID'] && userGroup['UserGroupID'].toString() === userGroupId) {
        return userGroup['UserGroupName'];
      }
    }
    return null;
  }

  getUserGroupNameByCustomerId(customerId: string): string {
    for (let customer of this.customerList) {
      if (customer && customer['CustomerID'] === customerId) {
        return customer['UserGroupName'];
      }
    }
    return null;
  }

  cancel(): void {
    this.dialogRef.close(true);
  }

  /* proceed(): void {
    this.dialogRef.close(true);
    let customerAndUserGroupObject: any = this.getNynasCustomerAndUserGroupDetailsForSelectedCountry();
    let customerId: string = customerAndUserGroupObject['customerId'];
    let customerName: string = customerAndUserGroupObject['customerName'];
    let userGroupId: string = customerAndUserGroupObject['userGroupId'];
    let userGroupName: string = customerAndUserGroupObject['userGroupName'];
    let currentLoggedInUserLoginResponse: any = this.sharedService.getLoginResponse();
    this.addUserComponentService.getUserMenuAccess(userGroupId).subscribe(
      response => {
        if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          let userGroupMenuList = [];
          for (let menu of response['Menus']) {
            menu['MenuID'] = menu['MenuId'].toString();
            menu['AccessFlag'] = '';
            userGroupMenuList.push(menu);
          }
          currentLoggedInUserLoginResponse['Menus'] = userGroupMenuList;
          currentLoggedInUserLoginResponse['CountryCode'] = this.countryCode;
          currentLoggedInUserLoginResponse['CustomerID'] = customerId;
          currentLoggedInUserLoginResponse['CustomerName'] = customerName;
          currentLoggedInUserLoginResponse['UserGroupID'] = userGroupId;
          currentLoggedInUserLoginResponse['UserGroupName'] = userGroupName;
          this.sharedService.setLoginResponse(currentLoggedInUserLoginResponse);
          this.router.navigate(['/app/home']);
        } else {
          this.showStatusDialog(false, "Something-went-wrong");
        }
      });
  } */

  proceed(): void {
    this.proceedButtonClicked = true;
    if (this.selectedCustomerId && this.selectedUserGroupId) {
      this.dialogRef.close(true);
      let customerName: string = this.getCustomerNameById(this.selectedCustomerId);
      let userGroupName: string = this.getUserGroupNameByCustomerId(this.selectedCustomerId);
      let currentLoggedInUserLoginResponse: any = this.sharedService.getLoginResponse();
      this.addUserComponentService.getUserMenuAccess(this.selectedUserGroupId).subscribe(
        response => {
          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let userGroupMenuList = [];
            for (let menu of response['Menus']) {
              menu['MenuID'] = menu['MenuId'].toString();
              menu['AccessFlag'] = '';
              userGroupMenuList.push(menu);
            }
            currentLoggedInUserLoginResponse['Menus'] = userGroupMenuList;
            currentLoggedInUserLoginResponse['CountryCode'] = this.selectedCountryCode;
            currentLoggedInUserLoginResponse['CustomerID'] = this.selectedCustomerId;
            currentLoggedInUserLoginResponse['CustomerName'] = customerName;
            currentLoggedInUserLoginResponse['UserGroupID'] = this.selectedUserGroupId;
            currentLoggedInUserLoginResponse['UserGroupName'] = userGroupName;
            this.sharedService.setLoginResponse(currentLoggedInUserLoginResponse);
            this.navigateToHomePage(this.selectedCountryCode);
          } else {
            this.showStatusDialog(false, "Something-went-wrong");
          }
        });
    }
  }

  showStatusDialog(isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: '',
        message: '',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber
      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  /* getNynasCustomerAndUserGroupDetailsForSelectedCountry(): any {
    let customerAndUserGroupObject = {};
    switch (this.countryCode) {
      case 'FIN':
        customerAndUserGroupObject['customerId'] = '23820';
        customerAndUserGroupObject['customerName'] = 'Nynas Oy';
        customerAndUserGroupObject['userGroupId'] = '826';
        customerAndUserGroupObject['userGroupName'] = 'MANAGEMENT GROUP';
        break;
      case 'SWE':
        customerAndUserGroupObject['customerId'] = '28719';
        customerAndUserGroupObject['customerName'] = 'Nynas AB';
        customerAndUserGroupObject['userGroupId'] = '827';
        customerAndUserGroupObject['userGroupName'] = 'MANAGEMENT GROUP';
        break;
      case 'NOR':
        customerAndUserGroupObject['customerId'] = '28718';
        customerAndUserGroupObject['customerName'] = 'Nynas AS';
        customerAndUserGroupObject['userGroupId'] = '828';
        customerAndUserGroupObject['userGroupName'] = 'MANAGEMENT GROUP';
        break;
      case 'DNK':
        customerAndUserGroupObject['customerId'] = '118683';
        customerAndUserGroupObject['customerName'] = 'Nynas';
        customerAndUserGroupObject['userGroupId'] = '666';
        customerAndUserGroupObject['userGroupName'] = 'MANAGEMENT GROUP';
        break;
      case 'GBR':
        customerAndUserGroupObject['customerId'] = '204035';
        customerAndUserGroupObject['customerName'] = 'Nynas Eastham Delivery';
        customerAndUserGroupObject['userGroupId'] = '829';
        customerAndUserGroupObject['userGroupName'] = 'MANAGEMENT GROUP';
        break;
      default:
        break;
    }
    return customerAndUserGroupObject;
  } */

  getUserGroupDetailsByCustomerId() {
    this.selectedUserGroupId = '';
    for (let customer of this.customerList) {
      if (customer['CustomerID'] === this.selectedCustomerId) {
        this.selectedUserGroupId = customer['UserGroupID'];
        break;
      }
    }
  }

  async navigateToHomePage(countryCode: string) {
    this.resetExistingGraphParameters();
    if (countryCode === 'FIN') {
      let currentDateTime = this.sharedService.getCurrentDateTime();
      let currentYear = currentDateTime.getFullYear();
      try {
        await this.getHomePageGraphAxisLimits();
      }
      catch (e) {
      }
      try {
        await this.getLineChartData();
      }
      catch (e) {
      }
      try {
        await this.getBrentExchangeData(currentYear - 1);
      }
      catch (e) {
      }
      try {
        await this.getBrentExchangeData(currentYear);
      }
      catch (e) {
      }
      try {
        await this.getExpectedPriceChange();
      }
      catch (e) {
      }
    }
    this.router.navigate(['/app/home']);
  }

  async getHomePageGraphAxisLimits() {
    let key = 'HOME_PAGE_GRAPH_AXIS_LIMITS';
    return new Promise<void>((_resolve, _reject) => {
      this.superUserService.getDisplayMessage(key).subscribe(
        response => {
          if (response && response['ResponseCode'] === '200') {
            if ((response['Message'] && response['Message'].split(',') && response['Message'].split(',').length)) {
              let graphAxisLowerLimit = response['Message'].split(',')[0] ? response['Message'].split(',')[0] : 280;
              let graphAxisUpperLimit = response['Message'].split(',')[1] ? response['Message'].split(',')[1] : 420;
              this.sharedService.setFinlandHomePageGraphAxisLowerLimit(graphAxisLowerLimit);
              this.sharedService.setFinlandHomePageGraphAxisUpperLimit(graphAxisUpperLimit);
            }
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

  async getLineChartData() {
    let uom: string = 'USD/MT';
    return new Promise<void>((_resolve, _reject) => {
      this.dashboardService.getLineChartData(uom).subscribe(
        response => {
          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            if (response["PriceIndexAndConversionRateCollection"] && response["PriceIndexAndConversionRateCollection"].length) {
              response["PriceIndexAndConversionRateCollection"].forEach(priceIndexAndConversionRateObject => {
                this.conversionRateList.push(priceIndexAndConversionRateObject['ConversionRate']);
                priceIndexAndConversionRateObject['PriceNotation'].forEach(priceNotationObject => {
                  if (priceNotationObject['Name'] === 'HSFO' && priceNotationObject['UOM'] === 'USD/MT') {
                    this.hsfoUSDPerMTList.push(priceNotationObject['Rate']);
                  }
                })
              });
            }
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

  getBrentExchangeData(year: number) {
    let type = 'MONTHLY';
    return new Promise<void>((_resolve, _reject) => {
      this.exchangeBrentData.getLineChartExhangeData(type, year).subscribe(
        response => {
          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let priceInformationData = response["PriceIndexAndConversionRateCollection"];
            priceInformationData.forEach(priceObject => {
              this.monthlyConversionRateList.push(priceObject['ConversionRate']);
              priceObject['PriceNotation'].forEach(notation => {
                if (notation.Name === 'HSFO' && notation.UOM === 'USD/MT') {
                  this.monthlyHSFOUSDPerMTList.push(notation['Rate']);
                }
                if (notation.Name === 'HSFO' && notation.UOM === 'EUR/MT') {
                  this.monthlyHSFOEURPerMTList.push(notation['Rate']);
                }
              })
            })
            this.sharedService.setMonthlyConversionRateList(this.monthlyConversionRateList);
            this.sharedService.setMonthlyHSFOUSDPerMTList(this.monthlyHSFOUSDPerMTList);
            this.sharedService.setMonthlyHSFOEURPerMTList(this.monthlyHSFOEURPerMTList);
            _resolve();
          }
          else {
            _reject();
          }
        });
    })
  }

  resetExistingGraphParameters() {
    this.sharedService.setFinlandHomePageGraphAxisLowerLimit(undefined);
    this.sharedService.setFinlandHomePageGraphAxisUpperLimit(undefined);
    this.sharedService.setConversionRateList(undefined);
    this.sharedService.setHSFOUSDPerMTList(undefined);
    this.sharedService.setMonthlyConversionRateList(undefined);
    this.sharedService.setMonthlyHSFOUSDPerMTList(undefined);
    this.sharedService.setMonthlyHSFOEURPerMTList(undefined);
  }

  getExpectedPriceChange() {
    let uom: string = 'USD/MT';
    return new Promise<void>((_resolve, _reject) => {
      this.dashboardService.fetchAveragePriceRate(uom)
        .subscribe(
          response => {
            if (response && response["ResponseCode"] === "200" && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
              let averageCollectionObject = response["AverageCollection"];
              if (averageCollectionObject && averageCollectionObject.length) {
                let averageConversionRate = (averageCollectionObject[0]['CURRENT_MONTH_DAILY_RATE'] ? +(averageCollectionObject[0]['CURRENT_MONTH_DAILY_RATE']) : undefined);
                let averageHSFOEURPerMT = (averageCollectionObject[0]['PAST_MONTH__MONTHLY_HSFO'] ? +(averageCollectionObject[0]['PAST_MONTH__MONTHLY_HSFO']) : undefined);
                this.conversionRateList.push(averageConversionRate);
                this.hsfoUSDPerMTList.push(averageHSFOEURPerMT);
                this.sharedService.setConversionRateList(this.conversionRateList);
                this.sharedService.setHSFOUSDPerMTList(this.hsfoUSDPerMTList);
              }
              _resolve();
            }
            else {
              _reject();
            }
          });
    })
  }

}
