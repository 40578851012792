import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { UserManagementService } from '../user-management.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  navbarOpen = false;
  selectedItem: String = 'Manage users';
  userName: string;
  customerName: string;
  userGroupName: string;
  lastLoginTime: Date;
  userInfo: any;
  name: String;
  isMyProfile: boolean;
  isLoggedInUserAdmin: boolean;
  emailId: string;
  allowedMenuIdList: string[] = [];

  constructor(private router: Router,
    private sharedService: AppService,
    private userManagementService: UserManagementService) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.name = this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '';
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.emailId = this.userInfo ? this.userInfo.EmailID : '';
    this.getAllowedMenuIdList(this.userInfo ? this.userInfo.Menus : []);
  }

  ngOnInit() {
    this.selectedItem = this.getHeadingFromRoute();
    this.userManagementService.currentSelectedItem.subscribe(selectedItem => this.selectedItem = selectedItem);
    this.userManagementService.currentIsMyProfile.subscribe(isMyProfile => this.isMyProfile = isMyProfile);
    if (this.isMyProfile) {
      this.selectedItem = 'My Profile';
    }
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  showSelectedMenuItem(itemName: String) {
    this.selectedItem = itemName;
  }

  getHeadingFromRoute(): string {
    if (this.router.url == '../../app/user-management/manage-users')
      return 'Manage users';
    if (this.router.url == '../../app/user-management/add-user')
      return 'Add user';
    if (this.router.url == '../../app/user-management/manage-user-group')
      return 'Manage user group';
    if (this.router.url == '../../app/user-management/add-user-group')
      return 'Add user group';
  }

  navigateToPage(url: string, itemName: String) {
    this.showSelectedMenuItem(itemName);
    /* var currentUrl = this.router.url;
    var refreshUrl = currentUrl.indexOf(url) > -1 ? '/' : '/user-management/' + url;
    this.router.navigateByUrl(currentUrl).then(() => this.router.navigateByUrl(refreshUrl)); */
    var currentUrl = this.router.url;
    if (currentUrl.indexOf(url) > -1) {
      this.router.navigateByUrl('/').then(() => this.router.navigateByUrl('/user-management/' + url));
    } else {
      this.router.navigate(['/user-management/' + url]);
    }
  }

  openMyProfilePage(url: string, itemName: String) {
		/* const navigationExtras: NavigationExtras = {
			state: {
        emailId: this.emailId,
        isMyProfile: true
			}
		};
    this.router.navigate(["/user-management/add-user"], navigationExtras); */
    this.showSelectedMenuItem(itemName);
    /* var currentUrl = this.router.url;
    var refreshUrl = currentUrl.indexOf(url) > -1 ? '/' : '/user-management/' + url;
    this.router.navigateByUrl(currentUrl).then(() => this.router.navigateByUrl(refreshUrl)); */
    const navigationExtras: NavigationExtras = {
			state: {
        emailId: this.emailId,
        isMyProfile: true
			}
		};
    var currentUrl = this.router.url;
    if (currentUrl.indexOf(url) > -1) {
      this.router.navigateByUrl('/').then(() => this.router.navigateByUrl('/user-management/' + url, navigationExtras));
    } else {
      this.router.navigate(['/user-management/' + url], navigationExtras);
    }
	}

  resetIsMyProfile() {
		this.userManagementService.updateIsMyProfile(false)
	}
  
  validateMenu(menuId: string): boolean {
    return this.allowedMenuIdList.includes(menuId);
  }
  getAllowedMenuIdList(menuList: any) {
    if (menuList && menuList.length) {
      for (var menu of menuList) {
        this.allowedMenuIdList.push(menu.MenuID);
      }
    }
  }

}
