import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { ApplicationPipesModule  } from '../shared-modules/application-pipes.module';
import { CommonModule } from '@angular/common';
import { ProductManagementComponent } from './product-management/product-management.component';
import { DistributionProgramComponent } from './distribution-program/distribution-program.component';

import { ProductInformationComponent } from './product-information/product-information.component';
// import { OrderingProcedureComponent } from './ordering-procedure/ordering-procedure.component';
import {MatSelectModule} from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule ,MatPaginatorModule,MatButtonToggleModule, MatDatepickerModule, MatNativeDateModule, MatIconModule, MatFormFieldModule, MatInputModule} from '@angular/material';
//import { QualityCertificateComponent } from 'src/app/quality-certificate/quality-certificate.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateService} from '@ngx-translate/core';
import { DeliveryGuideComponent } from './delivery-guide/delivery-guide.component';
import { ProductDocumentationComponent } from './product-documentation/product-documentation.component';
import { LanguageSelectionComponent } from './language-selection/language-selection.component';
import { GlobalSearchModule } from '../global-search/global-search.module';

export function HttpLoaderFactory (http:HttpClient){
  return new  TranslateHttpLoader(http);
}

@NgModule({
  declarations: [ProductManagementComponent, 
      ProductInformationComponent, 
      DistributionProgramComponent,
      DeliveryGuideComponent,
      ProductDocumentationComponent,
      LanguageSelectionComponent,
     
      ],
      
  imports: [
    CommonModule,
    ApplicationPipesModule,
    AppRoutingModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    GlobalSearchModule,
    TranslateModule.forRoot({
      loader:{
        provide:TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps:[HttpClient]
      }
    })

  ],
  entryComponents: [LanguageSelectionComponent]
})
export class ProductManagementModule { }
