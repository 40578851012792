import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AppService } from 'src/app/shared-modules/services/shared.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient,
    private sharedService: AppService) { }

  fetchHistoryDetails(customerId: string, deliverySite: string, fromWeek: any, toWeek: any, year: any, countryCode: any, jdeAddressNumber: string) {
    let HistoryUrl = this.apiUrl + '/FetchHistoryForecastDetails';
    return this.httpClient
      .get(HistoryUrl, {
        params: {
          customerId: customerId,
          fromWeek: fromWeek,
          toWeek: toWeek,
          year: year,
          CountryCode: countryCode,
          jdeAddressNumber: jdeAddressNumber
        }
      });
  }
  getHistDeliverySite(customerId: string, countryCode: string, isWeekly: string) {
    let HistoryUrl = this.apiUrl + '/GetForecastHistoryDeliverySite';
    return this.httpClient
      .get(HistoryUrl, {
        params: {
          CustomerId: customerId,
          CountryCode: countryCode,
          IS_WEEKLY: isWeekly
        }
      });
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // tslint:disable:object-literal-key-quotes
    const workbook: XLSX.WorkBook = { Sheets: { 'History': worksheet }, SheetNames: ['History'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const fetchedDataForItemArray: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(fetchedDataForItemArray, fileName + this.sharedService.getCurrentDateTime().getTime() + EXCEL_EXTENSION);
  }
  getCustomerNames(countryCode) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }

  fetchWeeklyHistoryDetailsForUser(customerId: string, deliverySite: string, fromWeek: any, toWeek: any, year: any, countryCode: any, userId: any) {
    let HistoryUrl = this.apiUrl + '/GetWeeklyHistoryForecastDetailsForUser';
    return this.httpClient
      .get(HistoryUrl, {
        params: {
          customerId: customerId,
          deliverySite: deliverySite,
          fromWeek: fromWeek,
          toWeek: toWeek,
          year: year,
          CountryCode: countryCode,
          userId: userId
        }
      });
  }

}
