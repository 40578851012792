import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderEntryService {

  apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

 
  getCustomerNames(countryCode) {
      let url = this.apiUrl + '/getCustomer';
      return this.httpClient.get(url, {
          params: {
            CountryCode: countryCode,
          }
        });
  
}

getDeliverySitesForUSER(USERID : string, customerId: string, countryCode: string) {
  const url = this.apiUrl + '/GetDeliverySiteForUser';
  return this.httpClient
    .get(url, {
      params: {
        UserID: USERID ,
        CountryCode: countryCode,
        MappingStatus: 'ACTIVE',
        CustomerID: customerId
      }
    });
}

getProductInformationFORUSER(CustomerID, countryCode, jdeAddNum) {
  const serviceUrl = this.apiUrl + '/GetDeliverySiteProducts';

  return this.httpClient
    .get(serviceUrl, {
      params: {
        CustomerID: CustomerID,
        CountryCode: countryCode,
        JDEAddressNumber:jdeAddNum
      }
    });
}  

getDeliverySites(customerId: string, countryCode: string) {
  const url = this.apiUrl + '/FetchDeliverySiteService';
  return this.httpClient
    .get(url, {
      params: {
        CustomerId: customerId,
        ApprovalStage: 'Inactive',
        CountryCode: countryCode
      }
    });
}

getProductInformation(CustomerID, countryCode) {
  const serviceUrl = this.apiUrl + '/GetAllProduct';

  return this.httpClient
    .get(serviceUrl, {
      params: {
        Status:'ACTIVE',
        Country: countryCode,
        CustomerID: CustomerID

      }
    });
}

getDepotDetails(countryCode: string) {
  let customerUrl = this.apiUrl + '/GetDepotDetails';
  return this.httpClient
    .get(customerUrl, {
      params: {
        CountryCode: countryCode,
        is_available: ''
      }
    });
}

getPCCode(countryCode: string){
  let customerUrl = this.apiUrl + '/GetPC2Code';
  return this.httpClient
    .get(customerUrl, {
      params: {
        PC2Code:'',
        CountryCode: countryCode,
      }
    });

}

getUOM(countryCode: string){
  let customerUrl = this.apiUrl + '/GetUOM';
  return this.httpClient
    .get(customerUrl, {
      params: {
        UOM:'',
        CountryCode: countryCode,
      }
    });

}

getCustomerPO(jdeAddNum : string){
   let url = this.apiUrl +'/GetCustomerPO';
   return this.httpClient
    .get(url, {
      params: {
        JDEAddressNumber:jdeAddNum
      }
    });

}

fetchOrderType( countryCode , freightCode , depoId){
  let url = this.apiUrl + '/FetchOrderType';
   return this.httpClient
    .get(url, {
      params: {
        CountryCode: countryCode,
        FreightCode: freightCode,
        DepotID:depoId
      }
    });
}

createOrder(UserID:string,addOrderForm: any,NynasItemCollectionList: any ,jdeAddNum: string ) {
  let url = this.apiUrl + '/CreateOrder';
  const httpOptions = {
      headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
      })
  };
  
  let postData = JSON.stringify({
    "UserID": UserID,
    "BillToName": addOrderForm.CustomerId,
    "ShipToID":  jdeAddNum, 
    "CustomerPO":addOrderForm.customerPO,
    "NynasItemCollection": NynasItemCollectionList,
    "Status": "01",
    "ProcessedFlag": "P",
    "CountryCode": addOrderForm.CountryId,
    "CreatedBy": addOrderForm.CreatedBy,
    "UpdatedBy": addOrderForm.UpdatedBy,
    "CreatedOn": addOrderForm.CreatedOn,
    "UpdatedOn": addOrderForm.UpdatedOn
  });
  
  // let postData = JSON.stringify(deliverySite);
  return this.httpClient.post(url, postData, httpOptions).pipe();
}

approveOrder(addOrderForm: any,NynasItemCollectionList: any ,orderStatus: string ,jdeAddNum: string) {
  let url = this.apiUrl + '/UpdateOrder';
  const httpOptions = {
      headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
      })
  };
  
  let postData = JSON.stringify({
    "OrderNumber" : addOrderForm.orderJDE,
    "ReferenceNumber" : addOrderForm.portalRefNumber,
    "BillToName": addOrderForm.CustomerId,
    "ShipToID":  jdeAddNum, 
    "CustomerPO":addOrderForm.customerPO,
    "FreightCode" : addOrderForm.frCode,
     "PC2Code" : "",
     "NynasItemCollection": NynasItemCollectionList,
     "Status": orderStatus ,
     "DocumentType" : addOrderForm.orderType,
    "OrderFlag": addOrderForm.reqStatus,
     "CountryCode": addOrderForm.CountryId,
    "CreatedBy": addOrderForm.CreatedBy,
    "UpdatedBy": addOrderForm.UpdatedBy,
    "CreatedOn": addOrderForm.CreatedOn,
    "UpdatedOn": addOrderForm.UpdatedOn
  });
  

  return this.httpClient.post(url, postData, httpOptions).pipe();
}

orderAmendement(addOrderForm: any,NynasItemCollectionList: any ,orderStatus: string ,jdeAddNum: string,UserID:string){
  let url = this.apiUrl + '/UpdateJDEOrder';
  const httpOptions = {
      headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
      })
  };
  
  let postData = JSON.stringify({
    "UserID":UserID,
    "OrderNumber" : addOrderForm.orderJDE,
    "ReferenceNumber" : addOrderForm.portalRefNumber,
    "BillToName": addOrderForm.CustomerId,
    "ShipToID":  jdeAddNum, 
    "CustomerPO":addOrderForm.customerPO,
    "FreightCode" : addOrderForm.frCode,
     "PC2Code" : "",
     "NynasItemCollection": NynasItemCollectionList,
     "Status": "03" ,
     "DocumentType" : addOrderForm.orderType,
    "OrderFlag": addOrderForm.reqStatus,
     "CountryCode": addOrderForm.CountryId,
    "CreatedBy": addOrderForm.CreatedBy,
    "UpdatedBy": addOrderForm.UpdatedBy,
    "CreatedOn": addOrderForm.CreatedOn,
    "UpdatedOn": addOrderForm.UpdatedOn
  });
  

  return this.httpClient.post(url, postData, httpOptions).pipe();

}


rejectOrder(addOrderForm: any,NynasItemCollectionList: any ,orderStatus: string ,jdeAddNum: string) {
  let url = this.apiUrl + '/UpdateOrder';
  const httpOptions = {
      headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
      })
  };
  
  let postData = JSON.stringify({
    "OrderNumber" : addOrderForm.orderJDE,
    "ReferenceNumber" : addOrderForm.portalRefNumber,
    "BillToName": addOrderForm.CustomerId,
    "ShipToID":  jdeAddNum, 
    "CustomerPO":addOrderForm.customerPO,
    "FreightCode" : addOrderForm.frCode,
     "PC2Code" : "",
     "NynasItemCollection": NynasItemCollectionList,
     "Status": orderStatus ,
     "DocumentType" : addOrderForm.orderType,
    "OrderFlag": addOrderForm.reqStatus,
     "CountryCode": addOrderForm.CountryId,
    "CreatedBy": addOrderForm.CreatedBy,
    "UpdatedBy": addOrderForm.UpdatedBy,
    "CreatedOn": addOrderForm.CreatedOn,
    "UpdatedOn": addOrderForm.UpdatedOn
  });
  

  return this.httpClient.post(url, postData, httpOptions).pipe();
}

  getDisplayMessage(type: string) {
    let url = this.apiUrl + '/GetDisplayMessage';
    return this.httpClient.get(url, {
      params: {
        Type: type
      }
    });
  }

  validateTime(input: string, zone: string, requestedDate: string, countryCode: string) {
    let url = this.apiUrl + '/ValidateTime';
    return this.httpClient.get(url, {
      params: {
        Input: input,
        Zone: zone,
        Requested_Date: requestedDate,
        Country_Code: countryCode
      }
    });
  }

}
