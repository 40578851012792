import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { OrderingProcedureService } from './ordering-procedure.component.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';

@Component({
  selector: 'app-ordering-procedure',
  templateUrl: './ordering-procedure.component.html',
  styleUrls: ['./ordering-procedure.component.css']
})
export class OrderingProcedureComponent implements OnInit {
  //To be fetched using company service call
  //To be fetched using company service call
  companyName: any;
  userGroupForm: FormGroup;
  menuAccessArray: FormArray;
  wareHouseData: any;
  countrySearch: any;
  countryList: any;
  selectedItem: String = 'Ordering Procedure';
  orderingProcedureCollection: any;
  generalInstructions: any;
  userInfo: any;
  countryCode: any;
  loggedInUsercountryCode: any;
  dispTable: boolean;

  constructor(private formBuilder: FormBuilder, private orderprocedure: OrderingProcedureService,
    private sharedService: AppService) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.countryCode = this.userInfo.CountryCode;
    this.loggedInUsercountryCode = this.userInfo.CountryCode; // Used to hide contact number and email for Finland logged in users
  }



  ngOnInit() {

    this.getOrderingProcedure();
    //this.countryList = [{ id: 'no', name: 'Norway' }, { id: 'dk', name: 'Denmark' }, { id: 'se', name: 'Sweden' },{ id: 'fi', name: 'Finland' }];
    // this.countryList = this.orderprocedure.getCountries();
    this.getCountry()
   
    this.countrySearch = 'fi';
    // this.wareHouseData = {
    //   countryCode: 'fi',
    //   wareHouseDetails: [
    //     {
    //       depot: "All",

    //       orderingInstructions: "Outage disruptions by product and terminal are reported via SMS." +
    //         "<br>" +
    //         "Transportation is ordered from your own carrier. The minimum size of deliveries is knob load." +
    //         "<br>" +
    //         "The vehicles and drivers of the transport companies must comply with the fleet and driver requirements set by Neste Corporation and / or Baltic Tank Oy ." + "<br>" +
    //         " The carrier gives information about the vehicles and drivers they use to the terminal." + "<br>" + "<br>" + "<br>" + "Weekly Subscription:" + "<br>" +
    //         "A weekly order for all products applies throughout the distribution period." +
    //         "<br>" +
    //         "Weekly orders must be announced by Thursday of the week prior to shipping via Bitumi Extranet at https://www.nynas.fi ." +
    //         "<br>"
    //     }],
    //     wareHouseDetails1: [
    //     {
    //       depot: "Naantali",
    //       addressLine1: "Neste Corporation ,",
    //       addressLine2: "Naantali Refinery distribution Terminal",
    //       state: 'Naantali',
    //       pinCode: '21100',
    //       primaryContact: { label: 'Prepration of Master', contactNumber: '+358 50   458 6348' },
    //       secondaryContact: { label: 'Load Manager', contactNumber: '+358 50   458 6248' },
    //       email: "",
    //       orderingInstructions1: "Naantali's delivery time is Sunday from 12.00 to Friday at 15.00."
    //     }],
    //     wareHouseDetails2: [
    //     {
    //       depot: "Hamina",
    //       addressLine1: "Baltic Tank Oy Terminal",
    //       addressLine2: " ",
    //       state: 'Hamina',
    //       pinCode: '21100',
    //       primaryContact: { label: ' ', contactNumber: '+358 50   458 6348' },
    //       secondaryContact: { label: ' ', contactNumber: ' ' },
    //       email: "hamina@baltictank.fi",
    //       orderingInstructions: "In Hamina, distribution operates on a 24/7 basis with the exceptions below."
    //         + "<br>" + "(1) During the time between the receipt and approval of the ship dismantling and subsequent analysis data, the distribution shall be suspended as appropriate ."

    //         + "<br>" + "(2) The Seller shall have the right to suspend distribution for the duration of maintenance or similar operations at a 24-hour notice ." +
    //         "<br>"
    //     }],
    //     wareHouseDetails3: [

    //     {
    //       depot: "Rauma",
    //       addressLine1: "Baltic Tank Oy Terminal",
    //       addressLine2: " ",
    //       state: 'Rauma',
    //       pinCode: '21100',
    //       primaryContact: { label: ' ', contactNumber: '+358 50   458 6348' },
    //       secondaryContact: { label: ' ', contactNumber: ' ' },
    //       email: "avi.rauma@baltictank.fi",
    //       orderingInstructions: "In Rauma, distribution operates on a 24/7 basis with the exceptions below."
    //         + "<br>" + "(1) During the time between the receipt and approval of the ship dismantling and subsequent analysis data, the distribution shall be suspended as appropriate ."

    //         + "<br>" + "(2) The Seller shall have the right to suspend distribution for the duration of maintenance or similar operations at a 24-hour notice ." +
    //         "<br>"
    //     }]
    // };

    // this.companyName = ['Ab Asphalt AX', 'AB Rafael', 'Baltic Tank Oy', 'Neste Corporation', 'Super Asphalt Oy'];


  }
  // getCountry(data) {
  //   return this.countryList
  // }
  getCountry() {
    this.countryList = [];
    this.orderprocedure.getCountries().subscribe(
      res => {
        if (res["ResponseCode"] === "200") {
          this.countryList = res["Countries"]
        }
      });

  }
  showSelectedMenuItem(itemName: String) {
    this.selectedItem = itemName;
  }

  getOrderingProcedure() {
    this.orderprocedure.getOrderingProcedure(this.countryCode).subscribe(

      res => {
        if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          // console.log(res);
          this.wareHouseData = res;

          // Commented old code to get ordering procedure, kept for future reference
          // this.orderingProcedureCollection = res['OrderingProcedureCollection'];

          let orderingProcedureList = [];
          if (res['OrderingProcedureCollection'] && res['OrderingProcedureCollection'].length) {
            for (let orderingProcedure of res['OrderingProcedureCollection']) {
              if (orderingProcedure) {

                if (this.countryCode === 'SWE') {
                  // Fields of AddressLine2, CONTACT_PERSON and CONTACT_NUMBER contains comma separated values for Sweden
                  if (orderingProcedure['AddressLine2'] && orderingProcedure['AddressLine2'].split(',') && orderingProcedure['AddressLine2'].split(',').length) {
                    orderingProcedure['AddressLine2Details'] = orderingProcedure['AddressLine2'].split(',');
                  }
                  if (orderingProcedure['CONTACT_PERSON'] && orderingProcedure['CONTACT_PERSON'].split(',') && orderingProcedure['CONTACT_PERSON'].split(',').length) {
                    orderingProcedure['contactPersons'] = orderingProcedure['CONTACT_PERSON'].split(',');
                  }
                  if (orderingProcedure['CONTACT_NUMBER'] && orderingProcedure['CONTACT_NUMBER'].split(',') && orderingProcedure['CONTACT_NUMBER'].split(',').length) {
                    orderingProcedure['contactNumbers'] = orderingProcedure['CONTACT_NUMBER'].split(',');
                  }
                }

                if (this.countryCode === 'NOR') {
                  // Fields of AddressLine2, CONTACT_PERSON and CONTACT_NUMBER contains semi-colon separated values for Norway
                  if (orderingProcedure['AddressLine2'] && orderingProcedure['AddressLine2'].split(';') && orderingProcedure['AddressLine2'].split(';').length) {
                    orderingProcedure['AddressLine2Details'] = orderingProcedure['AddressLine2'].split(';');
                  }
                  if (orderingProcedure['CONTACT_PERSON'] && orderingProcedure['CONTACT_PERSON'].split(';') && orderingProcedure['CONTACT_PERSON'].split(';').length) {
                    orderingProcedure['contactPersons'] = orderingProcedure['CONTACT_PERSON'].split(';');
                  }
                  if (orderingProcedure['CONTACT_NUMBER'] && orderingProcedure['CONTACT_NUMBER'].split(';') && orderingProcedure['CONTACT_NUMBER'].split(';').length) {
                    orderingProcedure['contactNumbers'] = orderingProcedure['CONTACT_NUMBER'].split(';');
                  }
                  if (orderingProcedure['Email'] && orderingProcedure['Email'].split(';') && orderingProcedure['Email'].split(';').length) {
                    let semiColonSeparatedEmailList = orderingProcedure['Email'].split(';');
                    orderingProcedure['Email'] = semiColonSeparatedEmailList[0];
                    let contactPersonEmails = [], index = 0;
                    for (let email of semiColonSeparatedEmailList) {
                      index++;
                      if (index > 1) {
                        contactPersonEmails.push(email);
                      }
                    }
                    orderingProcedure['contactPersonEmails'] = contactPersonEmails;
                  }
                }

                if (this.countryCode === 'DNK') {
                  if (orderingProcedure['CONTACT_PERSON'] && orderingProcedure['CONTACT_PERSON'].split(';') && orderingProcedure['CONTACT_PERSON'].split(';').length) {
                    orderingProcedure['depotChef'] = orderingProcedure['CONTACT_PERSON'].split(';')[0];
                    orderingProcedure['depotTechnician'] = orderingProcedure['CONTACT_PERSON'].split(';')[1];
                  }
                }
              }
              orderingProcedureList.push(orderingProcedure);
            }
          }
          this.orderingProcedureCollection = orderingProcedureList;

          this.generalInstructions = res['GeneralInstruction']
          this.dispTable = true
        }
        else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
          (res['Description'] ? res['Description'] : null);
          //this.errorDesc = res['Description'];
          this.dispTable = false
        }
        else {
          (res['Description'] ? res['Description'] : null)
          //this.errorDesc = res['Description'];
          this.dispTable = false

        }

      });
  }


}

