import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../user-management/user';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordComponentService {

    apiUrl = environment.apiUrl;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        })
    };

    constructor(private httpClient: HttpClient) { }

    resetPassword(user: any): Observable<User> {
        let resetPasswordUrl = this.apiUrl + '/SetPassword';
        return this.httpClient.post<User>(resetPasswordUrl, JSON.stringify(user), this.httpOptions).pipe();
    }

}