import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { NavigationExtras, Router } from '@angular/router';
import { forkJoin } from 'rxjs'; // RxJS 6 syntax
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../shared-modules/components/confirm-dialog/confirm-dialog.component';
import { AddUserComponentService } from '../add-user/add-user.component.service';
import { Customer } from '../customer';
import { DialogBodyComponent } from '../dialog-body/dialog-body.component';
import { UserGroup } from '../user-group';
import { UserManagementService } from '../user-management.service';
import { ManageUserGroupService } from './manage-user-group.service';
import { AddUserGroupService } from '../add-user-group/add-user-group.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';

@Component({
  selector: 'app-manage-user-group',
  templateUrl: './manage-user-group.component.html',
  styleUrls: ['./manage-user-group.component.css']
})
export class ManageUserGroupComponent implements OnInit, AfterViewInit, OnDestroy {

	customerId = '';

	public displayedColumns = ['UserGroupName', 'customerName', 'LastModifiedDate', 'actions'];
	public dataSource = new MatTableDataSource <UserGroup> ();

	selectedCompanyName: string = "";
	selectedCompanyId: string = "";
	isLoggedInUserPortalAdmin: boolean;

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	customerList: Customer[] = [];
	isRequestCompleted: boolean;
	userInfo: any;
	selectedItem: string;
	countryCode: any;
	mobileQuery: MediaQueryList;
	private _mobileQueryListener: () => void; 
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
	isFirstOnLoad: boolean = true;

	constructor(private manageUserGroupService: ManageUserGroupService,
		private addUserComponentService: AddUserComponentService,
		private router: Router,
		private sharedService: AppService,
		private userManagementService: UserManagementService,
		private dialog: MatDialog,
		private addUserGroupService: AddUserGroupService,
		private cd: ChangeDetectorRef,
		private translate: TranslateService,
		private media: MediaMatcher,
		private globalSearchService: GlobalSearchService) {
			this.userInfo = this.sharedService.getLoginResponse();
			this.countryCode = this.userInfo.CountryCode;		            
			this.mobileQuery = media.matchMedia('(max-width: 600px)');
			this._mobileQueryListener = () => cd.detectChanges();
			this.mobileQuery.addListener(this._mobileQueryListener); 
		}

	ngOnInit() {
		this.userManagementService.currentSelectedItem.subscribe(selectedItem => this.selectedItem = selectedItem);
		this.selectedCompanyName = this.userInfo ? this.userInfo.CustomerName : '';
		if (this.userInfo) {
			this.selectedCompanyId = (this.userInfo.RoleID === "PA") ? '' : (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
		} else {
			this.selectedCompanyId = '';
		}
		this.isLoggedInUserPortalAdmin = (this.userInfo ? (this.userInfo.RoleID === 'PA') : false);

		this.getUserGroupDetails();

		// Commented old code, kept for future reference
		/* if (!this.isLoggedInUserPortalAdmin) {
			this.getUserGroupDetails();
		} else {
			this.addUserComponentService.getCustomerNames(this.countryCode).subscribe(
				response => {
								if (response && response['Customers'] && response['Customers'].length) {
									this.customerList = response['Customers'];
								}
							}
			);
		} */

		this.translate.get(['HOME.itemsPerPageLabel'])
			.subscribe(data => {
				this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
			});

		// Commented old code, kept for future reference
		// Load data on load
		// this.changeCustomerName();

		this.subscribeToGlobalSearchServiceSearchTerm();

	}

	ngAfterViewInit(): void {
		this.dataSource.sort = this.sort;	
		this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
			if (data[sortHeaderId]) {
				return data[sortHeaderId].toLocaleLowerCase()
			}
			return null;
		};
	}
  
	ngOnDestroy(): void {
	  this.mobileQuery.removeListener(this._mobileQueryListener);
	  this.globalSearchText = '';
	}

	getCustomerNameById(customerId: string): string {
		for (var customer of this.customerList) {
			if (customerId && customer && customer.CustomerID && customer.CustomerID.toString() === customerId.toString()) {
				return customer.CustomerName;
			}
    	}
    return null;
	}

	getUserGroupDetails() {
		this.dataSource.data = [];
		const request1 = this.addUserComponentService.getCustomerNames(this.countryCode);
		const request2 = this.manageUserGroupService.getUserGroups(this.selectedCompanyId, this.countryCode);

		const requestArray = [];
		requestArray.push(request1);
		requestArray.push(request2);

		forkJoin(requestArray).subscribe(results => {
			if (results && results.length) {
				if (results[0] && results[0]['Customers'] && results[0]['Customers'].length) {
					this.customerList = results[0]['Customers'];
				}
				if (results[1] && results[1]['UsersGroups'] && results[1]['UsersGroups'].length) {
					// this.dataSource.data = results[1].UsersGroups;
					var userGroupList = [];
					for (var userGroup of results[1]['UsersGroups']) {
						userGroup['customerName'] = this.getCustomerNameById(userGroup['CustomerID']);
						userGroupList.push(userGroup);
					}
					this.dataSource.data = userGroupList;
					this.dataSource.paginator = this.paginator;
					this.isRequestCompleted = true;
				}
			}
		});
	}

	// Choose Customer Names using select dropdown
	changeCustomerName() {
		this.dataSource.data = [];
		this.isRequestCompleted = false;
		this.manageUserGroupService.getUserGroups(this.selectedCompanyId, this.countryCode)
			.subscribe(
				res => {
					const usersGroups = 'UsersGroups';
					if (res && res[usersGroups] && res[usersGroups].length) {
						// this.dataSource.data = res[usersGroups];
						var userGroupList = [];
						for (var userGroup of res[usersGroups]) {
							userGroup['customerName'] = this.getCustomerNameById(userGroup['CustomerID']);
							userGroupList.push(userGroup);
						}
						this.dataSource.data = userGroupList;
						this.dataSource.paginator = this.paginator;
						this.isRequestCompleted = true;
					}
					this.isRequestCompleted = true;
				});
	}

	editUserGroup(userGroup: UserGroup) {
		this.updateSelectedItem('Add user group');
		const navigationExtras: NavigationExtras = {
			state: {
				userGroupID: userGroup.UserGroupID,
				customerID: userGroup.CustomerID
			}
		};
		this.router.navigate(["../../app/user-management/add-user-group"], navigationExtras);
	}

	updateSelectedItem(selectedItem: string) {
		this.userManagementService.updateSelectedItem(selectedItem)
	}

	deleteUserGroup(userGroup: UserGroup) {
		this.manageUserGroupService.deleteUserGroup(userGroup).subscribe(res => {
			this.deleteStatusDialog(res);
			this.getUserGroupList();
		});
	}

	deleteStatusDialog(response: any) {
		if (response) {
			if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
				this.openSaveUserDialog('User group deleted successfully', null, true,'user-group-success');
			} else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
				this.openSaveUserDialog('Failed to delete user group', '', false,'user-group-fail');
			} else {
				this.openSaveUserDialog('Failed to delete user group', null, false,'user-group-fail');
			}
		} else {
			this.openSaveUserDialog('Failed to delete user group', null, false,"user-group-fail");
		}
	}

	openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean,headerNumber:string) {
		this.dialog.open(DialogBodyComponent, {
			data: {
				header: headerTitle,
				message: message ? message : ' ',
				buttonText: {
					ok: 'Ok'
				},
				isSuccess: isSuccess,
				headerNumber:headerNumber
			},
			// height: '37%',
			height: 'auto',
			width: (this.mobileQuery.matches ? '50%' : '22%'),
		});
	}

	getUserGroupList() {
		this.dataSource.data = [];
		this.isRequestCompleted = false;
		this.manageUserGroupService.getUserGroups(this.selectedCompanyId, this.countryCode).subscribe(
			response => {
				if (response && response['UsersGroups'] && response['UsersGroups'].length) {
					var userGroupList = [];
					for (var userGroup of response['UsersGroups']) {
						userGroup['customerName'] = this.getCustomerNameById(userGroup['CustomerID']);
						userGroupList.push(userGroup);
					}
					this.dataSource.data = userGroupList;
					this.dataSource.paginator = this.paginator;
					this.isRequestCompleted = true;
				}
				this.isRequestCompleted = true;
			});
	}

	showDeleteUserGroupPopUp(userGroup: UserGroup): void {
		if (userGroup && userGroup['UserGroupID']) {
			this.addUserGroupService.getUserGroupsByGroupId(userGroup['UserGroupID'].toString(), this.countryCode).subscribe(
				response => {
					if (response && response['UsersGroups'].length && response['UsersGroups'][0] &&
						response['UsersGroups'][0]['Users'] && response['UsersGroups'][0]['Users'].length) {
						let userCount: number = 0;
						userCount = response['UsersGroups'][0]['Users'].length;
						const message = userCount === 1 ? 'There is 1 user assigned to this user group. ' : 'There are ' + userCount + ' users assigned to this user group. ';
						this.showConfirmationPopUp(userGroup, message, userCount, 'user-present-in-group' ,'delete-user-group-popup');
					} else {
						this.showConfirmationPopUp(userGroup, '', null,'alert-delete-message','delete-user-group-popup');
					}
				});
		}
	}

	showConfirmationPopUp(userGroup: any, message: string,userCount:number,messageNumber:string,headerNumber:string) {
		message += 'Are you sure you want to delete this group?';
		const dialogData = new ConfirmDialogModel("Delete user group", '', messageNumber,headerNumber,userCount);
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			// maxWidth: "410px",
			// data: dialogData,
			data: {
				headerNumber:headerNumber ,
				messageNumber: messageNumber,
				count: userCount
			},
			// height: '37%',
			height: 'auto',
			width: (this.mobileQuery.matches ? '60%' : '24%'),
			// header: 'Delete user group',
			
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			if (dialogResult) {
				this.deleteUserGroup(userGroup);
			}
		});
	}

	reinitializeGlobalSearchComponent() {
	  this.globalSearchText = '';
	  this.showGlobalSearchTextBox = false;
	  this.cd.detectChanges();
	  this.showGlobalSearchTextBox = true;
	  this.cd.detectChanges();
	}
  
	subscribeToGlobalSearchServiceSearchTerm() {
	  // this listens to the input value from the service and does something on change.
	  this.globalSearchService.searchTerm.subscribe((newValue: string) => {
		// this is where you would apply your existing filtering.
		this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
	  });
	}
  
	applyFilter(filterValue: string) {
		if (filterValue || filterValue === "") {
			filterValue = filterValue.trim(); // Remove whitespace
			filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
			this.dataSource.filter = filterValue;
			// this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
		}
	}

}