import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../user';
import { environment } from '../../../environments/environment';
@Injectable({ providedIn: 'root' })
export class BitumenIndexService {
  apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({ 
      // 'Access-Control-Allow-Origin': '*', 
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*' ,
      // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization',
      // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    })
  };

  constructor(
    private httpClient: HttpClient) { }

    // getBitumenIndex(month: string, country?: string, Year?: string) {
      getBitumenIndex() {
        const serviceUrl = this.apiUrl + '/GetBituminIndex';
        return this.httpClient
            .get(serviceUrl, {
                params: {
                  // month: userID,
                  // Country: country,
                  // Year: Year
                }
            });
    }
}
