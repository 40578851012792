import { Component, Input, OnInit } from '@angular/core';
import { GlobalSearchService } from './global-search.service';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css']
})
export class GlobalSearchComponent implements OnInit {

  @Input() public searchText: string;
  globalSearchText: string;

  constructor(private globalSearchService: GlobalSearchService) {
    this.globalSearchText = '';
  }

  ngOnInit() {
    this.globalSearchText = '';
  }

  public applyFilter(value: any) {
    // this pushes the input value into the service's Observable.
    // this.globalSearchService.searchTerm.next(event.target.value);
    this.globalSearchService.searchTerm.next(value);
  }

}
