import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateDeliverySiteProductsService {

  apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient) {
  }

  getDeliverySiteProducts(customerId: string, countryCode: string, JDEAddressNumber: string) {
    const url = this.apiUrl + '/GetDeliverySiteProducts';
    return this.httpClient
      .get(url, {
        params: {
          CustomerID: customerId,
          CountryCode: countryCode,
          JDEAddressNumber: JDEAddressNumber
        }
      });
  }

  updateDeliverySite(deliverySite: any, updateDepotCollection: any, notifyHaulier: string, primaryContactWithCountryCode: string, sendReminderFlag: string) {
    let url = this.apiUrl + '/UpdateDeliverySiteForProductMapping';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };

    let postData = JSON.stringify({
      "CustomerId": deliverySite.CustomerId,
      "DeliverySiteName": deliverySite.DeliverySite,
      "DepotCollection": updateDepotCollection,
      "SubscriberEmailAddress": deliverySite.SubscriberEmailAddress,
      "CarrierEmailAddress": deliverySite.CarrierEmailAddress,
      "DepotAddressNumber": deliverySite.DepotAddressNumber,
      "JdeAddressNumber": deliverySite.JdeAddressNumber,
      "BillingReference": deliverySite.BillingReference,
      "PrimaryContact": primaryContactWithCountryCode,
      "AddressLine1": deliverySite.AddressLine1,
      "AddressLine2": deliverySite.AddressLine2,
      "DeliveryPostalCode": deliverySite.DeliveryPostalCode,
      "State": null,
      "City": deliverySite.City,
      "CreatedBy": deliverySite.CreatedBy,
      "CountryId": deliverySite.CountryId,
      "Status": deliverySite.Status,
      "ApprovalStage": deliverySite.ApprovalStage,
      "UpdatedBy": deliverySite.UpdatedBy,
      "UpdatedOn": deliverySite.UpdatedOn,
      "HaulierEmailId": deliverySite.HaulierEmailId,
      "SecondaryHaulierEmailId": deliverySite.SecondaryHaulierEmailId,
      "NotifyHaulier": notifyHaulier,
      "SendReminderFlag": sendReminderFlag
    });

    return this.httpClient.post(url, postData, httpOptions).pipe();
  }

}
