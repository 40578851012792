import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppService} from '../app/shared-modules/services/shared.service';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardNavComponent }   from './dashboard-nav/dashboard-nav.component'
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { UserManagementModule } from './user-management/user-management.module';
import { ProductManagementModule } from './product-management/product-management.module';
import { PriceManagementModule } from './price-management/price-management.module';
import { OrderManagementModule } from './order-management/order-management/order-management.module';
import { NgbModule, NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule, MatMenuModule, MatToolbarModule, MatIconModule, MatCardModule, MatDatepickerModule, MatNativeDateModule,MatSidenavModule,MatListModule, MatAutocomplete, MatAutocompleteModule, MatTabsModule, MatTooltip, MatTooltipModule, MatStepperModule, MatExpansionModule } from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule,MatPaginatorModule  } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material';
import {MatSelectModule} from '@angular/material/select';
import { MyCompanyModule } from './my-company/my-company.module';
import { HelpComponent } from './help/help.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ReportsModule } from './reports/reports.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { QualityCertificateComponent } from './quality-certificate/quality-certificate.component';
import { NynasUpdatesComponent } from './nynas-updates/nynas-updates.component';

import { UdspMappingComponent } from './udsp-mapping/udsp-mapping.component';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DeliveryGuideAdminComponent } from './delivery-guide-admin/delivery-guide-admin.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader.service';
import { HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { LoaderInterceptor } from './loader.interceptor';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AuthGuard } from './guards/auth-guard.service';
import { RoleGuard } from './guards/role-guard.service';
import { AuthService } from './guards/auth.service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateService} from '@ngx-translate/core';
import { HomeComponent } from './home/home.component';
import {MenuListItemComponent} from './menu-list-item/menu-list-item.component';
import { NavService } from './dashboard-nav/nav-service';
import { OrderManagementComponent } from './order-management/order-management/order-management.component';
import { ReportsComponent } from './reports/reports.component';
import {NgcCookieConsentModule , NgcCookieConsentConfig, NgcInitializeEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
 registerLocaleData(localeFr);

import {MatButtonToggleModule} from '@angular/material/button-toggle';

import { CustomerContractComponent } from './customer-contract/customer-contract.component';
import { AddPriceIndexComponent } from './add-price-index/add-price-index.component';
import {MatFormFieldModule} from'@angular/material/form-field';
import {MatInputModule} from'@angular/material/input';
import {MAT_DATE_LOCALE} from '@angular/material';
import {ContactUsAdminComponent} from './contact-us-admin/contact-us-admin.component';
import { BitumenIndexAdminComponent } from './bitumen-index-admin/bitumen-index-admin.component';

import { PriceNotationAdminComponent } from './price-notation-admin/price-notation-admin.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { environment } from 'src/environments/environment';
import { UploadDocumentComponent } from './upload-document/upload-document.component';

import { AddExchangeRateAdminComponent } from './add-exchange-rate-admin/add-exchange-rate-admin.component';
import {MatRadioModule} from '@angular/material/radio';
// import { GuestViewComponent } from './guest-view/guest-view.component';

import * as moment from 'moment'
// import * as moment from 'moment';
import { ConfirmDialogComponent } from '../app/shared-modules/components/confirm-dialog/confirm-dialog.component';
import { GuestViewComponent } from './guest-view/guest-view.component';
import { AddProductPriceComponent } from './add-product-price/add-product-price.component';
// import { AddDeliverySiteSwedenComponent } from './add-delivery-site-sweden/add-delivery-site-sweden.component';
import { LoginHistoryComponent } from './login-history/login-history.component';
import { OrderEntryComponent } from './order-management/order-entry/order-entry.component';
import { OrderTrackingComponent } from './order-management/order-tracking/order-tracking.component';
import { SuperUserComponent } from './super-user/super-user.component';
import { GlobalSearchModule } from './global-search/global-search.module';
import { OrderEntryValidationsComponent } from './order-management/order-entry/order-entry-validations/order-entry-validations.component';
import { UnlockOrderComponent } from './order-management/unlock-order/unlock-order.component';
import { SettingsComponent } from './settings/settings.component';
import { AlertModule } from './alert/alert.module';
import { BannerMessageComponent } from './banner-message/banner-message.component';

// Commented code for session management, kept for future reference
// import { BnNgIdleService } from 'bn-ng-idle';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#18426a'
    },
    button: {
      background: '#FFD500'
    }
  },
  // theme: "classic",
  theme: "edgeless",
  type: 'opt-out',
  // type: 'info',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{header}}{{messagelink}}'
  },
  elements: {
    'header': `
    <div class="cc-compliance cc-highlight">
      <a aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny">{{deny}}</a>
      <a aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow">{{allow}}</a>
    </div>
    `,
    messagelink: `
    <div id="cookieconsent:desc" class="cc-message">
      {{messagecookie}}
    </div>
    <div class="cc-message">
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">
        {{link}}
      </a>
    </div>
    `
  },
  content:{
    messagecookie: 'This website uses cookies to give you a better experience of the site. By continuing to use the site, you agree to our use of cookies. Please also find information about Nynas processing of personal data in the Privacy Statement.',
    
    link: 'Information about how we use cookies',
     deny: "Decline",
     allow: 'Allow cookies',
    // cookiePolicyLink: 'Information about how we use cookies >',
    cookiePolicyHref: 'https://www.nynas.com/en/about-cookies/'
 
  }
};
export function HttpLoaderFactory (http:HttpClient){
  return new  TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    
    AppComponent,
    FooterComponent,
    HomeComponent,
    DashboardNavComponent,
    LoginComponent,
    HelpComponent,
    ContactUsComponent,
    ResetPasswordComponent,
    UpdatePasswordComponent,
    QualityCertificateComponent,
    NynasUpdatesComponent,

    UdspMappingComponent,

    PageNotFoundComponent,
    DeliveryGuideAdminComponent,
    LoaderComponent,
    MenuListItemComponent,
    CustomerContractComponent,
    AddPriceIndexComponent,
    AddExchangeRateAdminComponent,
    ContactUsAdminComponent,
    PriceNotationAdminComponent,
    AddPriceIndexComponent,
    UploadDocumentComponent,
    BitumenIndexAdminComponent,
    ConfirmDialogComponent,
    GuestViewComponent,
    AddProductPriceComponent,
    LoginHistoryComponent,
    SuperUserComponent,
    UdspMappingComponent,
    OrderEntryValidationsComponent,
    UnlockOrderComponent,
    SettingsComponent,
    BannerMessageComponent
  ],
  imports: [
    
    BrowserModule,
    ChartsModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    UserManagementModule,
    ProductManagementModule,
    OrderManagementModule,
    MyCompanyModule,
    PriceManagementModule,
    OrderManagementModule,
    ReportsModule,
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule ,
    MatDialogModule,
    MatSnackBarModule,           
    MatDatepickerModule,       
    MatNativeDateModule,    
    MatSidenavModule,  
    MatToolbarModule,
    ReportsModule,
    MatListModule,    
    NgSelectModule,
    MatRadioModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatTooltipModule,
    GlobalSearchModule,
    MatStepperModule,
    AlertModule,
    MatExpansionModule,
    TranslateModule.forRoot({
      loader:{
        provide:TranslateLoader,
        useFactory: HttpLoaderFactory,                
        deps:[HttpClient]                
      }
    }),
    MatButtonToggleModule,
    MatInputModule,
    MatFormFieldModule,
    
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  exports: [
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
  ],
  entryComponents: [ResetPasswordComponent, UpdatePasswordComponent,QualityCertificateComponent, ConfirmDialogComponent, SuperUserComponent],
  providers: [
    AppService,
    NavService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR '},
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    AuthGuard,
    RoleGuard,

    // Commented code for session management, kept for future reference
    // BnNgIdleService
    
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
