import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import * as XLSX from 'xlsx';
import { WeeklyForecast } from '../forecast';
import { CommonService } from '../order-management/order-management.component.service';
import { PendingForecastService } from '../pending-forecast/pending-forecast.component.service';
import { ForecastEntryService } from './forecast-entry.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-forecast-entry',
  templateUrl: './forecast-entry.component.html',
  styleUrls: ['./forecast-entry.component.css']
})

export class ForecastEntryComponent implements OnInit, OnDestroy {
  forecastEntryForm: FormGroup;
  dataSource: any;
  displayTable: boolean = false;
  yearUserSelected: any;
  quantityUpdated: any;
  customerName: string;
  deliverySites: string;
  delSites = [];
  Customers = [];
  delSiteUserSelected: string;
  customerUserSelected: string;
  weekUserSelected: any;
  dropDownYear = [];
  weeklyData: any;
  monthlyData: any;
  fetchedDataForItemArray = [];
  itemName: any;
  depot: any;
  quantity: any;
  role: any;
  username: any;
  isWeeklyForecast: boolean = true;
  forecastValue: any = "rollingWeek";
  dataUpdatedWeek: boolean = false;
  dataUpdatedMonth: boolean = false;
  @ViewChild('monthSort', { static: true }) monthSort: MatSort;
  @ViewChild('weekSort', { static: true }) weekSort: MatSort;
  @ViewChild('rollingWeekSort', { static: true }) rollingWeekSort: MatSort;
  @ViewChild('contractualVolumeSort', { static: true }) contractualVolumeSort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild('WeekPaginator', { static: true }) weekPaginator: MatPaginator;
  // @ViewChild('MonthPaginator', { static: true }) monthPaginator: MatPaginator;
  forecastData: any;
  pendingRowSelectData: any;
  yearUserSelected1: any;
  isRequestCompleted: boolean = false;
  currWeek: number;
  currYear: number;
  userInfo: any;
  customerID: any;
  selectedLanguage: string;

  // Setting maxWeek to 53, since we have only 52 (+ 1) weeks every year
  maxWeek: number = 53;
  submitted: boolean;
  minWeek: number;
  isLoggedInUserAdmin: boolean;
  isLoggedInUserCustomerAdmin: boolean;
  noDisp: boolean;
  customerIdUserSelected: any;
  weekly = WeeklyForecast;
  selectedRow: WeeklyForecast;
  monthlyDataForecastDataforALL: any;


  displayedColumnsWeek: string[] = ['ItemName', 'Depot', 'Quantity'];
  displayedColumnsMonth: string[] = ['DepotName', 'ProductName', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total'];
  displayedColumnsContractualVolume: string[] = ['DepotName', 'ProductName', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total'];
  displayedColumnsRollingWeek: string[] = ['DepotName', 'ProductName'];

  yearlyData: any;

  created_BY: any;

  weeklyForecast: any;

  rowSelect: boolean;
  selectedRowIndex: any;

  week: any;
  updateWeekData = [];
  resData: any;

  forecastLock: boolean;

  tableData: any;
  dataSourceWeekly: MatTableDataSource<unknown>;
  dataSourceMonthly: any;
  displayTableW: boolean;
  displayTableM: boolean;
  updateMonthData: any;
  contractualVolumeDataToBeUpdated: any;
  arrMonth = [];
  pendData: any;
  monthlyForecastArr: any;
  arrWeek = [];
  selectedRowIndexMonth: any;
  countryCode: any;
  dataSourceRolling: any;
  displayTableR: boolean;
  excelData: any;
  selectedRowIndexRollingWeek: any;
  arrRollingWeek = [];
  updateRollingWeekData: any[];
  weekArray = [];
  rollingForecastArr: any;
  fetchRollingWeekForecastArr: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  totalRolling = 0;
  inputRollingWeek: any;
  inputRollingWeekArr: any;
  rollingData: any;
  UserID: any;
  delSiteUserSelectedMonth: string;
  isLoggedInUserPortalAdmin: boolean;
  selectedRollingWeekDetails: any = {};
  showMonthlyForecastEntryTab: boolean;
  showRollingFourWeekForecastEntryTab: boolean;
  customerIdUserSelectedForWeekly: string;
  customerIdUserSelectedForMonthly: string;
  deliverySiteObjectList: any = [];
  yearUserSelectedForWeekly: string;
  yearUserSelectedForMonthly: string;
  showContractualVolumeTab: boolean;
  displayTableContractualVolume: boolean;
  dataSourceContractualVolume: any;

  constructor(public dialog: MatDialog, private commonService: CommonService,
    private forecastEntryService: ForecastEntryService,
    private pendingForecastService: PendingForecastService, private sharedService: AppService,
    private formBuilder: FormBuilder, public translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher) {
    //   translate.addLangs(['EN','FI']);
    // translate.setDefaultLang('EN');
    // let browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/EN|FI/)? browserLang :"EN");
    this.dataSourceRolling = new MatTableDataSource();
    if (this.sharedService.getLoginResponse() == undefined) {
      this.noDisp = true;
    } else {
      this.userInfo = this.sharedService.getLoginResponse();
      this.username = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
      this.UserID = (this.userInfo ? this.userInfo.UserID : '');
      this.role = (this.userInfo ? this.userInfo.RoleID : '');
      this.customerID = (this.userInfo ? this.userInfo.CustomerID : '');
      this.customerName = (this.userInfo ? this.userInfo.CustomerName : '');
      this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
      this.isLoggedInUserCustomerAdmin = (this.userInfo ? (this.userInfo.RoleID === "A") : false);
      this.isLoggedInUserPortalAdmin = (this.userInfo ? (this.userInfo.RoleID === 'PA') : false);
      this.countryCode = this.userInfo.CountryCode;
      this.noDisp = false;
      this.checkTabsToBeDisplayedDependingUponPermissions();
    }
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // Show rolling weekly forecast by default on load of Forecast entry page for all countries
    /* if (this.countryCode == 'FIN') {
      this.forecastValue = "weekly"
    } else {
      this.forecastValue = "rollingWeek"
    } */

    // Below logic is moved to new method of checkIfMonthlyAndRollingWeeklyForecastTabIsToBeDisplayed
    // For Finland logged in customer admin users, check if menu id 42 monthly forecast entry permission is given to its user group
    // this.showMonthlyForecastEntryTab = (this.isLoggedInUserPortalAdmin || (this.isLoggedInUserAdmin && ((this.countryCode === 'NOR' || this.countryCode === 'SWE' || this.countryCode === 'DNK') || this.hasPermissionForMonthlyForecastEntry())));
  }
  get fields() {
    return this.forecastEntryForm.controls;
  }
  async ngOnInit() {
    //set curr week n year number
    const date = this.sharedService.getCurrentDateTime();
    var result = this.getWeekNumber(date)
    this.currYear = result[0];
    this.currWeek = result[1];
    let nextWeekNumber: number = this.currWeek + 1;

    if (this.currWeek === 53) {
      nextWeekNumber = 1;
      this.currYear += 1;
    }

    //set min and max number of weeks
    this.minWeek = 1;

    // Setting maxWeek to 53, since we have only 52 (+ 1) weeks every year
    // this.maxWeek = this.getNumberOfWeeks(this.sharedService.getCurrentDateTime())

    // year list
    this.getYearList(this.currYear);

    // form control
    let regexNum = "^[0-9]*$"
    this.forecastEntryForm = this.formBuilder.group({
      week: [nextWeekNumber, [Validators.required, Validators.min(this.minWeek), Validators.max(this.maxWeek), Validators.pattern(regexNum)]],
      year: [this.currYear, Validators.required],
    });

    //set week in service
    this.weekUserSelected = this.pendingForecastService.getPWeek() ? this.pendingForecastService.getPWeek() : this.forecastEntryForm.value.week;
    this.forecastEntryService.setWeek(this.weekUserSelected)
    this.forecastEntryService.setCurrWeek(this.currWeek)

    //set year in service
    this.yearUserSelected = this.forecastEntryForm.value.year;
    this.forecastEntryService.setYear(this.yearUserSelected);
    this.forecastEntryService.setCurrYear(this.currYear);
    this.pendData = this.pendingForecastService.getPData()
    if (this.pendData) {
      this.customerUserSelected = this.pendData.CustomerName
      this.customerIdUserSelected = this.pendData.CustomerId
      this.delSiteUserSelected = this.pendData.DeliverySite
      this.week = this.forecastEntryForm.controls['week'].setValue(this.pendData.Week);

    } else {
      this.customerUserSelected = this.customerName;
      this.customerIdUserSelected = this.customerID;
    }
    //set customer name
    // this.customerUserSelected = this.pendData.CustomerName ? this.pendData.CustomerName : this.customerName;
    // this.customerIdUserSelected = this.pendData.CustomerId ? this.pendData.CustomerId : this.customerID;

    //set customer name
    // this.customerUserSelected = this.pendingForecastService.getPCustomerName() ? this.pendingForecastService.getPCustomerName() : this.customerName;
    // this.customerIdUserSelected = this.pendingForecastService.getPCustomerId() ? this.pendingForecastService.getPCustomerId() : this.customerID;

    // this.customerUserSelected = this.forecastEntryService.getCustomerName() ? this.forecastEntryService.getCustomerName() : this.customerName;
    // this.customerIdUserSelected = this.forecastEntryService.getCustomerId() ? this.forecastEntryService.getCustomerId() : this.customerID;
    this.forecastEntryService.setCustomerName(this.customerUserSelected)
    this.forecastEntryService.setCustomerId(this.customerIdUserSelected)

    // get customers names
    try {
      await this.getCustomers()
    } catch (e) {
    }


    //delivery sites
    try {
      await this.getDeliverySites();
    } catch (e) {
    }


    // forecast data

    // Do not load any data without Find button click
    /* try {
      this.forecastData = await this.fetchData();
    } catch (error) {
      this.forecastData = null;
    } */

    // this.forecastEntryService.setForecast(this.forecastData)
    // this.dataSource = new MatTableDataSource(this.forecastData);
    // this.dataSource.sort = this.weekSort;

    // Get Delivery site and Week from service, when user enters forecast from Forecast Status page
    // if (this.forecastEntryService.getDeliverySite()) {
    //   this.delSiteUserSelected = this.forecastEntryService.getDeliverySite();
    // }
    // if (this.forecastEntryService.getWeek()) {
    //   this.forecastEntryForm.controls['week'].setValue(this.forecastEntryService.getWeek());
    // }
    // this.getDummyData()
  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.pendingForecastService.setPData(undefined)
  }

  getYearList(currentYear) {
    this.dropDownYear.push(currentYear - 1, currentYear);  // Show only last and current years in the forecast entry dropdown for Mothly forecast entry
  }

  getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart
    yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }
  weeksInYear(year) {
    var month = 11, day = 31, week;
    // Find week that 31 Dec is in. If is first week, reduce date until
    // get previous week.
    do {
      let d = new Date(year, month, day--);
      week = this.getWeekNumber(d)[1];
    } while (week == 1);
    return week;
  }
  getNumberOfWeeks(d) {
    var first;
    first = new Date(d.getFullYear(), 0, 4);
    var dayms = 1000 * 60 * 60 * 24;
    var numday = ((d - first) / dayms)
    var weeks = Math.ceil((numday + first.getDay() + 1) / 7);
    return weeks;
  }

  async   getDeliverySites() {

    // Hide previous customer forecast data table, on change of customer, in weekly, monthly and rolling forecast entry pages
    this.displayTableW = false;
    this.displayTableM = false;
    this.displayTableR = false;
    this.displayTableContractualVolume = false;

    this.delSites = [];
    this.deliverySiteObjectList = [];

    // this.delSiteUserSelected = undefined;
    // Set delivery site to blank in order to show select in dropdown
    this.delSiteUserSelected = '';

    this.forecastEntryService.setDeliverySite(this.delSiteUserSelected)
    if (this.countryCode == "SWE" || this.countryCode == "NOR" || this.countryCode == "DNK" ) {
      if (this.role == "PA" || this.role == "A") {
        return new Promise<void>((resolve, reject) => {
          this.commonService.getDeliverySite(this.customerIdUserSelected, "Inactive", this.countryCode).subscribe(
            response => {
              if (response["ResponseCode"] === "200") {
                for (let deliverySiteObject of response["DeliverySiteCollection"]) {
                  this.deliverySiteObjectList.push(deliverySiteObject);
                  this.delSites.push(deliverySiteObject["DeliverySite"]);
                  if (this.pendData == undefined)
                  //   this.delSiteUserSelected = this.delSites[0]
                  // this.delSiteUserSelected = this.pendingForecastService.getPDelSite() ? this.pendingForecastService.getPDelSite() : this.delSites[0];
                  // Set delivery site to blank in order to show select in dropdown
                  this.delSiteUserSelected = '';
                }
                this.forecastEntryService.setDeliverySite(this.delSiteUserSelected)
                resolve();
              }
              else {
                reject();
              }
            });
        })
      } else {
        return new Promise<void>((resolve, reject) => {
          this.commonService.getDeliverySiteForUser(this.UserID, this.countryCode, "ACTIVE", this.customerIdUserSelected).subscribe(
            response => {
              if (response["ResponseCode"] === "200") {
                for (let deliverySiteObject of response["DeliverySites"]) {
                  this.deliverySiteObjectList.push(deliverySiteObject);
                  this.delSites.push(deliverySiteObject["DeliverySiteName"]);
                  if (this.pendData == undefined)
                    // this.delSiteUserSelected = this.delSites[0]
                  // this.delSiteUserSelected = this.pendingForecastService.getPDelSite() ? this.pendingForecastService.getPDelSite() : this.delSites[0];
                  // Set delivery site to blank in order to show select in dropdown
                  this.delSiteUserSelected = '';
                }
                this.forecastEntryService.setDeliverySite(this.delSiteUserSelected)
                resolve();
              }
              else {
                reject();
              }
            });
        })
      }


    } else {
      return new Promise<void>((resolve, reject) => {
        this.commonService.getDeliverySite(this.customerIdUserSelected, "Inactive", this.countryCode).subscribe(
          response => {
            if (response["ResponseCode"] === "200") {
              for (let deliverySiteObject of response["DeliverySiteCollection"]) {
                this.deliverySiteObjectList.push(deliverySiteObject);
                this.delSites.push(deliverySiteObject["DeliverySite"]);
                if (this.pendData == undefined)
                  // this.delSiteUserSelected = this.delSites[0]
                // this.delSiteUserSelected = this.pendingForecastService.getPDelSite() ? this.pendingForecastService.getPDelSite() : this.delSites[0];
                  // Set delivery site to blank in order to show select in dropdown
                  this.delSiteUserSelected = '';
              }
              this.forecastEntryService.setDeliverySite(this.delSiteUserSelected)
              resolve();
            }
            else {
              reject();
            }
          });
      })

    }


  }

  async getCustomers() {
    this.Customers = [];
    return new Promise<void>((resolve, reject) => {

      this.forecastEntryService.getCustomerNames(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {

            this.Customers = res["Customers"]
            if (this.pendData == undefined) {
              this.customerUserSelected = this.customerName;
              this.customerIdUserSelected = this.customerID;

            }
            // this.customerUserSelected = this.pendingForecastService.getPCustomerName() ? this.pendingForecastService.getPCustomerName() : this.customerName;
            // this.customerIdUserSelected = this.pendingForecastService.getPCustomerId() ? this.pendingForecastService.getPCustomerId() : this.customerID;

            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  changeOnClick() {
    // Set value selected by user in week and year
    this.weekUserSelected = this.forecastEntryForm.value.week;
    this.yearUserSelected = this.forecastEntryForm.value.year;

    // Moved to fetchOnClick method called on Find button click
    // this.forecastEntryService.setWeek(this.forecastEntryForm.value.week)
    // this.forecastEntryService.setYear(parseInt(this.forecastEntryForm.value.year))

    this.forecastEntryService.setDeliverySite(this.delSiteUserSelected);
    this.forecastEntryService.setCustomerName(this.customerUserSelected)
    this.forecastEntryService.setCustomerId(this.customerIdUserSelected)

    // Hide previous customer forecast data table, on change of delivery site, week, year; in weekly, monthly and rolling forecast entry pages
    this.displayTableW = false;
    this.displayTableM = false;
    this.displayTableR = false;
    this.displayTableContractualVolume = false;

  }

  async fetchOnClick(isFindButtonClick: boolean) {
    if (isFindButtonClick && !this.delSiteUserSelected && !((this.countryCode === 'FIN' || this.countryCode === 'SWE') && (this.forecastValue == "monthly" || this.forecastValue == "contractualVolume"))) {
      this.openDialog('', '', false, 'please-select-delivery-site');
    } else {
      this.displayedColumnsRollingWeek = ['DepotName', 'ProductName']
      this.forecastEntryService.setWeek(this.forecastEntryForm.value.week);
      this.forecastEntryService.setYear(parseInt(this.forecastEntryForm.value.year));
      this.forecastEntryService.setCustomerName(this.customerUserSelected)
      this.forecastEntryService.setCustomerId(this.customerIdUserSelected)

      this.submitted = true;
      // if (!this.forecastEntryForm.valid) {
      //   return false;
      // } else {
      //   var data = this.fetchData();
      // }
      var data = this.fetchData();

      // Reset selectedRollingWeekDetails when new forecast data is loaded
      this.selectedRollingWeekDetails = {};
    }
  }

  async fetchData() {
    return new Promise<void>(async (resolve, reject) => {
      let currentYear = this.sharedService.getCurrentDateTime().getFullYear();
      if (this.forecastValue == "weekly") {
        try {
          var weeklyForecastArr: any;
          try {
            weeklyForecastArr = await this.fetchWeeklyData();
          } catch (e) {
          }

          this.dataSourceWeekly = new MatTableDataSource(weeklyForecastArr);
          this.dataSourceWeekly.sort = this.weekSort;
          // this.dataSourceWeekly.paginator = this.weekPaginator;
          // resolve(weeklyForecastArr);
          resolve()
        } catch (error) {
          reject();
        }
      } else if (this.forecastValue == "monthly") {
        try {
          var monthlyForecastEntryDataList: any;
          try {
            monthlyForecastEntryDataList = await this.fetchMonthlyData()
          } catch (e) {
          }

          if (monthlyForecastEntryDataList && monthlyForecastEntryDataList.length) {
            if (this.countryCode === 'FIN') {
              let yearSelectedInDropdown = this.yearUserSelected ? +this.yearUserSelected : 0;
              if (yearSelectedInDropdown >= currentYear) {

                // Commented old logic, in case needed for future reference
                /* let currentYearDateFor1stJanuary: any = new Date(1 + '/' + 1 + '/' + currentYear);  // Current year date for 1st January
                contractualVolumeDataList = contractualVolumeDataList.filter((element: any) => {
                  let fromDate = element['FROM_DATE'];                      // 2019-07-26T00:00:00.000+00:00
                  let fromDateDate = new Date(fromDate).getDate();          // 26
                  let fromDateMonth = (new Date(fromDate).getMonth() + 1);  // 7
                  let fromDateYear = new Date(fromDate).getFullYear();      // 2019
                  let fromDateObject = new Date(fromDateMonth + '/' + fromDateDate + '/' + fromDateYear);

                  let toDate = element['TO_DATE'];                          // 2021-04-05T00:00:00.000+00:00
                  let toDateDate = new Date(toDate).getDate();              // 5
                  let toDateMonth = (new Date(toDate).getMonth() + 1);      // 4
                  let toDateYear = new Date(toDate).getFullYear();          // 2021
                  let toDateObject = new Date(toDateMonth + '/' + toDateDate + '/' + toDateYear);
                  return ((element['IS_CC_AVAILABLE'] === 'TRUE') && (fromDateObject <= currentYearDateFor1stJanuary && currentYearDateFor1stJanuary <= toDateObject));
                }) */

                monthlyForecastEntryDataList = monthlyForecastEntryDataList.filter((element: any) => {
                  let fromDate = element['FROM_DATE'];                      // 2020-01-01T00:00:00.000+00:00
                  let fromDateYear = new Date(fromDate).getFullYear();      // 2020

                  let toDate = element['TO_DATE'];                          // 2021-03-31T00:00:00.000+00:00
                  let toDateYear = new Date(toDate).getFullYear();          // 2021
                  return ((element['IS_CC_AVAILABLE'] === 'TRUE') && (fromDateYear <= yearSelectedInDropdown && yearSelectedInDropdown <= toDateYear));
                })

              } else {

                // Commented logic to filter records as per delivery guide for previous year
                /* monthlyForecastEntryDataList = monthlyForecastEntryDataList.filter((element: any) => {
                  let fromDate = element['FROM_DATE'];                      // 2020-01-01T00:00:00.000+00:00
                  let fromDateYear = new Date(fromDate).getFullYear();      // 2020

                  let toDate = element['TO_DATE'];                          // 2021-03-31T00:00:00.000+00:00
                  let toDateYear = new Date(toDate).getFullYear();          // 2021
                  return (fromDateYear <= yearSelectedInDropdown && yearSelectedInDropdown <= toDateYear);
                }) */

              }
            } else {
              monthlyForecastEntryDataList = monthlyForecastEntryDataList.filter((element: any) => {
                return (element['IS_CC_AVAILABLE'] === 'TRUE');
              })
            }
          }

          this.displayTableM = (monthlyForecastEntryDataList && monthlyForecastEntryDataList.length);
          this.dataSourceMonthly = new MatTableDataSource(monthlyForecastEntryDataList);
          this.dataSourceMonthly.sort = this.monthSort;
          // this.dataSourceMonthly.paginator = this.monthPaginator;
          // resolve(monthlyForecastArr);
          resolve()
        } catch (error) {
          reject(error);
        }

      } else if (this.forecastValue == "rollingWeek") {
        this.displayedColumnsRollingWeek = ['DepotName', 'ProductName']
        try {
          var rollingForecastData: any;
          try {
            rollingForecastData = await this.fetchRollingWeekData()
          } catch (e) {
          }

          // Commented old code to filter records as per delivery guide, in case needed for future reference
          /* if (this.countryCode === 'FIN') {
            rollingForecastData = rollingForecastData.filter((element: any) => {
              return (element['IS_CC_AVAILABLE_FOR_ALL_FIVE_WEEKS'] === 'TRUE' && element['IS_AVAILABLE_FOR_ALL_FIVE_WEEKS'] === 'TRUE');
            })
          } else {
            rollingForecastData = rollingForecastData.filter((element: any) => {
              return (element['IS_CC_AVAILABLE_FOR_ALL_FIVE_WEEKS'] === 'TRUE');
            })
          } */

          if (rollingForecastData && rollingForecastData.length) {
            // Show only records that are available with C2P mappings only for all countries
            rollingForecastData = rollingForecastData.filter((element: any) => {
              return (element['IS_CC_AVAILABLE_FOR_ALL_FIVE_WEEKS'] === 'TRUE');
            })
          }

          this.displayTableR = (rollingForecastData && rollingForecastData.length);
          this.dataSourceRolling = new MatTableDataSource(rollingForecastData);
          this.dataSourceRolling.sort = this.rollingWeekSort;
          // this.dataSourceMonthly.paginator = this.monthPaginator;
          // resolve(monthlyForecastArr);
          resolve()
        } catch (error) {
          reject(error);
        }

      } else if (this.forecastValue == "contractualVolume") {
        try {
          var contractualVolumeDataList: any;
          try {
            contractualVolumeDataList = await this.fetchContractualVolumeData()
          } catch (e) {
          }

          if (contractualVolumeDataList && contractualVolumeDataList.length) {
            if (this.countryCode === 'FIN') {
              let yearSelectedInDropdown = this.yearUserSelected ? +this.yearUserSelected : 0;
              if (parseInt(this.yearUserSelected) >= currentYear) {
                contractualVolumeDataList = contractualVolumeDataList.filter((element: any) => {
                  let fromDate = element['FROM_DATE'];                      // 2020-01-01T00:00:00.000+00:00
                  let fromDateYear = new Date(fromDate).getFullYear();      // 2020

                  let toDate = element['TO_DATE'];                          // 2021-03-31T00:00:00.000+00:00
                  let toDateYear = new Date(toDate).getFullYear();          // 2021
                  return ((element['IS_CC_AVAILABLE'] === 'TRUE') && (fromDateYear <= yearSelectedInDropdown && yearSelectedInDropdown <= toDateYear));
                })
              }
            } else {
              contractualVolumeDataList = contractualVolumeDataList.filter((element: any) => {
                return (element['IS_CC_AVAILABLE'] === 'TRUE');
              })
            }
          }

          this.displayTableContractualVolume = (contractualVolumeDataList && contractualVolumeDataList.length);
          this.dataSourceContractualVolume = new MatTableDataSource(contractualVolumeDataList);
          this.dataSourceContractualVolume.sort = this.contractualVolumeSort;
          resolve()
        } catch (error) {
          reject(error);
        }
      }
    })

  }


  async fetchRollingWeekData() {
    let rollingForecast = []
    this.inputRollingWeek = []
    this.inputRollingWeekArr = []
    for (let k = 0; k <= 4; k++) {
      let current = this.forecastEntryService.getCurrWeek()
      // let current = 51;
      let week = current + k;
      let year = this.forecastEntryService.getCurrYear();
      let maxiWeek = this.weeksInYear(year)
      if (maxiWeek < week) {
        week = week - maxiWeek;
        year = year + 1;
      } else {
        week = week;
        year = year;
      }

      this.displayedColumnsRollingWeek.push("Week " + week)

      this.inputRollingWeekArr.push({
        "CustomerId": this.customerIdUserSelected,
        "DeliverySite": this.delSiteUserSelected,
        "Year": year,
        "Week": week,
        "CountryCode": this.countryCode,
        "JDEAddressNumber" : this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelected)
      })
    }
    this.displayedColumnsRollingWeek.push("Total")
    this.inputRollingWeek = {
      "WeekCollection": this.inputRollingWeekArr
    }

    if (this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelected)) {
      return new Promise(async (resolve, reject) => {
        await this.forecastEntryService.getRollingWeekForecastDetails(this.inputRollingWeek)
          .subscribe(
            res => {
              if (res["ResponseCode"] === "200") {
                this.rollingData = res;
                this.rollingForecastArr = [];
                rollingForecast = this.rollingData["RollingForecastDetails"];
                for (let i = 0; i < rollingForecast.length; i++) {
                  this.rollingForecastArr.push({
                    "RowID": rollingForecast[i].RowID,
                    "CustomerId": this.rollingData["CustomerId"],
                    "DeliverySite": this.rollingData["DeliverySite"],
                    "DepotID": rollingForecast[i].DepotID,
                    "DepotName": rollingForecast[i].DepotName,
                    "ProductID": rollingForecast[i].ProductID,
                    "ProductName": rollingForecast[i].ProductName,
                  })
                }
                for (let i = 0; i < rollingForecast.length; i++) {
                  for (let j = 0; j < rollingForecast[i]["WeekCollection"].length; j++) {
                    // if ((this.forecastEntryService.getCurrWeek() + j) == rollingForecast[i]["WeekCollection"][j].WeekNumber) {
                    this.rollingForecastArr[i]['Week ' + rollingForecast[i]["WeekCollection"][j].WeekNumber] = rollingForecast[i]["WeekCollection"][j].Quantity;
                    this.rollingForecastArr[i]['Year ' + rollingForecast[i]["WeekCollection"][j].WeekNumber] = rollingForecast[i]["WeekCollection"][j].Year;
  
                    // Set IS_AVAILABLE flag along with week number to disable forecast entry textboxes for customer admin login as per delivery guide, for Finland only
                    this.rollingForecastArr[i]['IS_AVAILABLE ' + rollingForecast[i]["WeekCollection"][j].WeekNumber] = rollingForecast[i]["WeekCollection"][j].IS_AVAILABLE;
  
  
                    // Set IS_CC_AVAILABLE flag along with week number to disable forecast entry textboxes when C 2 P mapping is removed for a product and depot, for all countries, applicable only for past years
                    this.rollingForecastArr[i]['IS_CC_AVAILABLE ' + rollingForecast[i]["WeekCollection"][j].WeekNumber] = rollingForecast[i]["WeekCollection"][j].IS_CC_AVAILABLE;
  
                    // Set IS_CC_AVAILABLE_FOR_ALL_FIVE_WEEKS flag to filter out records when C 2 P mapping is removed for a product and depot, for all countries, applicable only for current year
                    this.rollingForecastArr[i]['IS_CC_AVAILABLE_FOR_ALL_FIVE_WEEKS'] = rollingForecast[i]["WeekCollection"][j].IS_CC_AVAILABLE;
  
                    // Set IS_AVAILABLE_FOR_ALL_FIVE_WEEKS flag to filter out records when a product is not available in the current year, for all countries
                    this.rollingForecastArr[i]['IS_AVAILABLE_FOR_ALL_FIVE_WEEKS'] = rollingForecast[i]["WeekCollection"][j].IS_AVAILABLE;
  
                    // }
                  }
                }
                this.displayTableR = true
                resolve(this.rollingForecastArr)
              } else {
                this.displayTableR = false
                reject()
              }
            })
      })
    }


  }
  // getAllMonthTotal(data) {
  //   let totalMonth = 0;
  //   if (this.dataSourceMonthly) {
  //     let data = this.dataSourceMonthly.filteredData;
  //     console.log(this.dataSourceMonthly.filteredData)
  //     for (let i = 0; i < data.length; i++) {
  //       totalMonth = this.getRollingWeekTotal(data[i]) + totalMonth
  //     }
  //   }
  //   console.log(totalMonth)
  //   return totalMonth;
  // }

  getRollingTotal() {
    let totalRoll = 0;
    if (this.dataSourceRolling) {
      let data = this.dataSourceRolling.filteredData;
      for (let i = 0; i < data.length; i++) {
        totalRoll = this.getRollingWeekTotal(data[i]) + totalRoll
      }
    }
    return totalRoll;
  }
  getRollingWeekTotal(element) {
    let weekArrData = []
    weekArrData = JSON.parse(JSON.stringify(this.displayedColumnsRollingWeek))
    weekArrData.splice(0, 2);
    weekArrData.pop()

    let totalWeek = 0;
    for (let i = 0; i < weekArrData.length; i++) {
      totalWeek = totalWeek + parseInt((element[weekArrData[i]] == "" || element[weekArrData[i]] == null) ? 0 : element[weekArrData[i]])
    }
    return totalWeek;
  }
  disableRollingWeekForecast(disCol, fetchedDataForItemArray) {
    // let weekNum = disCol.split("Week")

    // Initialise the value of forecastLock to false at first
    this.forecastLock = false;

    if (this.userInfo.RoleID == 'PA') {
      this.forecastLock = false
    } else {

      // Commented old logic to disable weekly forecast entry, since it did not work after year change
      /* if (this.forecastEntryService.getCurrYear() <= fetchedDataForItemArray["Year" + weekNum[1]]) { //2020<2021 2020=2020
        if (this.forecastEntryService.getCurrYear() == fetchedDataForItemArray["Year" + weekNum[1]]) { //2020=2020
          if (this.forecastEntryService.getCurrWeek() + 1 <= weekNum[1]) { //12<13 12=12
            if (this.forecastEntryService.getCurrWeek() + 1 == weekNum[1]) { //12=12
              if (this.sharedService.getCurrentDateTime().getDay() > 4) { //5>4 fri>thur
                this.forecastLock = true
              } else {
                this.forecastLock = false
              }
            } else {
              this.forecastLock = false
            }
          } else { //12<11 previous week
            this.forecastLock = true
          }
        } else {
          this.forecastLock = false
        }
      } else { //2020<2019 previous year
        this.forecastLock = true
      }
      // if (this.sharedService.getCurrentDateTime().getDay() > 4) {
      //   this.forecastLock = this.forecastEntryService.getCurrWeek() + 1 >= this.forecastEntryService.getWeek() || this.forecastEntryService.getCurrYear() > this.forecastEntryService.getYear()
      // } else {
      //   this.forecastLock = this.forecastEntryService.getCurrWeek() >= this.forecastEntryService.getWeek() || this.forecastEntryService.getCurrYear() > this.forecastEntryService.getYear()
      // } */

      let weekNumber = (disCol && disCol.replace("Week ", "") ? Number.parseInt(disCol.replace("Week ", "")) : 0);
      let yearForSelectedWeek = ((weekNumber && fetchedDataForItemArray && fetchedDataForItemArray["Year " + weekNumber]) ? +fetchedDataForItemArray["Year " + weekNumber] : 0); // Get year from week number
      this.forecastLock = !this.validateIfWeekNumberIsAllowedForForecastEntry(weekNumber, yearForSelectedWeek);

      // Disable forecast entry textbox if C2P mapping is removed for a product and depot, applicable for all countries and only for past years
      if (!this.forecastLock) {
        this.forecastLock = (fetchedDataForItemArray['IS_CC_AVAILABLE' + ' ' + weekNumber] === 'FALSE')
      }

    }

    // If forecast entry is allowed for Finland customer admin depending upon delivery guide
    if (this.countryCode === 'FIN' && !this.isLoggedInUserPortalAdmin && !this.forecastLock) {
      let weekNumber = (disCol ? disCol.replace('Week ', '') : 0);  // disCol = Week 51, so extract week number
      this.forecastLock = this.forecastLock || (fetchedDataForItemArray['IS_AVAILABLE' + ' ' + weekNumber] === 'FALSE');
    }

    return this.forecastLock;
  }
  onSelectRollingWeek(fetchedDataForItemArray: any): void {
    this.rowSelect = true;
    this.selectedRowIndexRollingWeek = fetchedDataForItemArray.RowID;
    this.arrRollingWeek.push(fetchedDataForItemArray);
    this.arrRollingWeek = this.arrRollingWeek.filter((v, i, a) => a.indexOf(v) === i);

  }

  async updateRollingWeekForecastNew() {
    let rollingWeek;

    this.dataUpdatedWeek = true;
    rollingWeek = this.arrRollingWeek

    this.weekArray = JSON.parse(JSON.stringify(this.displayedColumnsRollingWeek))
    this.weekArray.splice(0, 2);
    this.weekArray.pop()


    this.updateRollingWeekData = [];
    for (let i = 0; i < rollingWeek.length; i++) {
      for (let j = 0; j < this.weekArray.length; j++) {
        let weekNum = this.weekArray[j].split("Week ")

        this.updateRollingWeekData.push({
          "CustomerId": this.forecastEntryService.getCustomerId(),
          "DeliverySite": this.forecastEntryService.getDeliverySite(),
          "Week": weekNum[1],
          "Year": rollingWeek[i]["Year " + weekNum[1]],
          "ProductId": rollingWeek[i].ProductID,
          "Depot": rollingWeek[i].DepotID,
          "Quantity": (rollingWeek[i][this.weekArray[j]] == null) ? "" : rollingWeek[i][this.weekArray[j]],
          "Created_By": this.username,
          "Updated_By": this.username,
          "Created_On": this.sharedService.getCurrentDateTime().toISOString(),
          "Updated_On": this.sharedService.getCurrentDateTime().toISOString(),
          "JDEAddressNumber" : this.getJDEAddressNumberByDeliverySiteName(this.forecastEntryService.getDeliverySite())
        })
      }
    }
    let isQuantityInvalid: boolean;
    isQuantityInvalid = false
    for (let weekRow of this.updateRollingWeekData) {
      if (this.isInValidDecimalNumber(weekRow.Quantity)) {
        isQuantityInvalid = true;
        break;
      }
    }

    /* if (!this.updateRollingWeekData || !this.updateRollingWeekData.length) {
      this.openDialog('', '', false, 'forecast-message');
    } */
    if (this.selectedRollingWeekDetails && Object.keys(this.selectedRollingWeekDetails).length === 0) {
      this.openDialog('', '', false, 'forecast-message');
    }
    else {
      if (!isQuantityInvalid) {
        let data1 = { "WeeklyForecastDetails": this.getPayloadForActualForecastEntered() };
        this.forecastEntryService.updateWeeklyForecastDetailNew(data1)
          .subscribe(
            res => {
              if (res["ResponseCode"] === "200") {
                this.openDialog('Forecast saved succcessfully!', null, true, 'forecast-entry-success');
                this.arrWeek = []
                // this.updateSuccess();

                // Reset selectedRollingWeekDetails when new forecast data is loaded
                this.selectedRollingWeekDetails = {};
              } else {
                this.dataUpdatedWeek = false;
                this.openDialog('Forecast data not saved. Please try again later!', null, false, 'forecast-entry-fail');
                // this.updateFailure();
              }
            });
      }
    }
  }


  //WEEKLY
  async fetchWeeklyData() {
    this.forecastEntryService.setWeek(this.weekUserSelected)
    return new Promise((resolve, reject) => {
      let weeklyAvailable = 'YES'; // Set weeklyAvailable as YES, to get data of all depots in weekly forecast page
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelected);
      this.forecastEntryService.getWeeklyForecastDetails(this.customerIdUserSelected, this.delSiteUserSelected, this.weekUserSelected, this.yearUserSelected, this.countryCode, weeklyAvailable, jdeAddressNumber).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.weeklyData = res;
            var fetchWeeklyForecastArr = [];
            if (!this.forecastEntryForm.valid) {
              this.displayTableW = false;
              reject();
            } else {
              fetchWeeklyForecastArr = this.weeklyData["ForecastData"];
              this.excelData = fetchWeeklyForecastArr;
              this.isRequestCompleted = true
              this.displayTableW = true;
              resolve(fetchWeeklyForecastArr);
            }
          }
          else {
            this.displayTableW = false;
            reject();
          }
        });

    })
  }

  disableWeekForecast(rowData: any) {
    if (this.userInfo.RoleID == 'PA') {
      this.forecastLock = false
    } else {

      // Commented old logic to disable weekly forecast entry, since it did not work after year change
      /* if (this.forecastEntryService.getCurrYear() <= this.forecastEntryService.getYear()) { //2020<2021 2020=2020
        if (this.forecastEntryService.getCurrYear() == this.forecastEntryService.getYear()) { //2020=2020
          if (this.forecastEntryService.getCurrWeek() + 1 <= this.forecastEntryService.getWeek()) { //8<9 8=8
            if (this.forecastEntryService.getCurrWeek() + 1 == this.forecastEntryService.getWeek()) { //8=8
              if (this.sharedService.getCurrentDateTime().getDay() > 4) { //5>4 fri>thur
                this.forecastLock = true
              } else {
                this.forecastLock = false
              }
            } else {
              this.forecastLock = false
            }
          } else { //8<7 previous week
            this.forecastLock = true
          }
        } else {
          this.forecastLock = false

        }

      } else { //2020<2019 previous year
        this.forecastLock = true
      }
      // if (this.sharedService.getCurrentDateTime().getDay() > 4) {
      //   this.forecastLock = this.forecastEntryService.getCurrWeek() + 1 >= this.forecastEntryService.getWeek() || this.forecastEntryService.getCurrYear() > this.forecastEntryService.getYear()
      // } else {
      //   this.forecastLock = this.forecastEntryService.getCurrWeek() >= this.forecastEntryService.getWeek() || this.forecastEntryService.getCurrYear() > this.forecastEntryService.getYear()
      // } */

      this.forecastLock = !this.validateIfWeekNumberIsAllowedForForecastEntry(this.weekUserSelected, this.yearUserSelected);

      // Disable forecast entry textbox if C2P mapping is removed for a product and depot, applicable for all countries and only for past years
      if (!this.forecastLock) {
        this.forecastLock = (rowData['IS_CC_AVAILABLE'] === 'FALSE');
      }

    }

    // If forecast entry is allowed for Finland customer admin depending upon delivery guide
    if (this.countryCode === 'FIN' && !this.isLoggedInUserPortalAdmin && !this.forecastLock) {
      if (!(this.customerID == '267359' && rowData['DepotID'] === '23522' && (this.yearUserSelected && this.yearUserSelected.toString() === '2020'))) {  // Allow Peab customer to enter forecast for Hamina depot throught out year of 2020
        this.forecastLock = this.forecastLock || (rowData['IS_AVAILABLE'] === 'FALSE');
      }
    }

    return this.forecastLock;
  }

  onSelectWeek(fetchedDataForItemArray: any): void {
    this.rowSelect = true;
    this.selectedRowIndex = fetchedDataForItemArray.RowID;
    this.arrWeek.push(fetchedDataForItemArray);

    this.arrWeek = this.arrWeek.filter((v, i, a) => a.indexOf(v) === i);
  }

  async updateWeeklyForecastNew(updateForecastData: any) {
    // let updateWeekly = updateForecastData.filteredData

    let updateWeekly = JSON.parse(JSON.stringify(updateForecastData.filteredData));
    this.dataUpdatedWeek = true;
    updateWeekly = this.arrWeek
    // for (let i = 0; i < updateWeekly.length; i++) {
    //   if (updateWeekly[i].Quantity === "") {
    //     updateWeekly.splice(i, 1)
    //     i--;
    //   }
    // }
    this.updateWeekData = [];
    for (let i = 0; i < updateWeekly.length; i++) {
      this.updateWeekData.push({
        "CustomerId": this.forecastEntryService.getCustomerId(),
        "DeliverySite": this.forecastEntryService.getDeliverySite(),
        "Week": this.forecastEntryService.getWeek(),
        "Year": this.forecastEntryService.getYear(),
        "ProductId": updateWeekly[i].ItemID,
        "Depot": updateWeekly[i].DepotID,
        "Quantity": (updateWeekly[i].Quantity == null) ? "" : updateWeekly[i].Quantity,
        "Created_By": this.username,
        "Updated_By": this.username,
        "Created_On": this.sharedService.getCurrentDateTime().toISOString(),
        "Updated_On": this.sharedService.getCurrentDateTime().toISOString(),
        "JDEAddressNumber" : this.getJDEAddressNumberByDeliverySiteName(this.forecastEntryService.getDeliverySite())
      })
    }

    let isQuantityInvalid: boolean;
    for (let weekRow of this.updateWeekData) {
      if (this.isInValidDecimalNumber(weekRow.Quantity)) {
        isQuantityInvalid = true;
        break;
      }
    }

    if (!this.updateWeekData || !this.updateWeekData.length) {
      this.openDialog('', '', false, 'forecast-message');
    } else if (!this.delSiteUserSelected) {
      this.openDialog('', '', false, 'select-delivery-site-to-save');
    }
    else {

      //   for (let weekRow of this.updateWeekData) {
      //     if (weekRow.Quantity === "") {
      //       this.openDialog('Kindly insert valid value.', null, false);
      //       isQuantityInvalid = true;
      //       break;
      //     }
      //   }

      if (!isQuantityInvalid) {
        let data1 = { "WeeklyForecastDetails": this.updateWeekData };
        this.forecastEntryService.updateWeeklyForecastDetailNew(data1)
          .subscribe(
            res => {
              if (res["ResponseCode"] === "200") {
                this.openDialog('Forecast saved succcessfully!', null, true, 'forecast-entry-success');
                this.arrWeek = []
                // this.updateSuccess();
              } else {
                this.dataUpdatedWeek = false;
                this.openDialog('Forecast data not saved. Please try again later!', null, false, 'forecast-entry-fail');
                // this.updateFailure();
              }
            });
      }
    }
  }

  //MONTHLY
  async fetchMonthlyData() {
    if (this.countryCode == "FIN" || this.countryCode == "SWE") {
      this.delSiteUserSelectedMonth = "DefaultDelSite"
    } else {
      this.delSiteUserSelectedMonth = this.delSiteUserSelected;
    }
    return new Promise((resolve, reject) => {
      let monthlyAvailable = 'YES'; // Set monthlyAvailable as YES, to get data of all depots in monthly forecast page
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelectedMonth);
      this.forecastEntryService.getMonthlyForecastDetails(this.customerIdUserSelected, this.delSiteUserSelectedMonth, this.yearUserSelected, this.countryCode, monthlyAvailable, jdeAddressNumber)
        .subscribe(
          response => {
            if (response["ResponseCode"] === "200") {
              this.monthlyData = response;
              var fetchMonthlyForecastArr = this.monthlyData["MonthlyForecastDetails"];
              this.monthlyForecastArr = fetchMonthlyForecastArr;
              this.excelData = this.monthlyForecastArr;
              this.displayTableM = true;
              resolve(fetchMonthlyForecastArr);
            }
            else {
              this.displayTableM = false;
              reject();
            }
          });
    })



  }

  async fetchContractualVolumeData() {
    if (this.countryCode == "FIN" || this.countryCode == "SWE") {
      this.delSiteUserSelectedMonth = "DefaultDelSite"
    } else {
      this.delSiteUserSelectedMonth = this.delSiteUserSelected;
    }
    return new Promise((resolve, reject) => {
      let monthlyAvailable = 'YES'; // Set monthlyAvailable as YES, to get data of all depots in contractual volume page
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelectedMonth);
      this.forecastEntryService.getContractualVolumeDetails(this.customerIdUserSelected, this.delSiteUserSelectedMonth, this.yearUserSelected, this.countryCode, monthlyAvailable, jdeAddressNumber)
        .subscribe(
          res => {
            if (res["ResponseCode"] === "200") {
              this.monthlyData = res;
              var contractualVolumeDataList = this.monthlyData["MonthlyForecastDetails"];
              this.excelData = contractualVolumeDataList;
              this.displayTableM = true;
              resolve(contractualVolumeDataList);
            }
            else {
              this.displayTableContractualVolume = false;
              reject();
            }
          });
    })
  }

  disableMonthForecast(columnMonth: any, rowData: any) {
    // Initialise the value of forecastLock to false at first
    this.forecastLock = false;

    if (this.userInfo.RoleID == 'PA') { // Do not disable any textboxes on Monhtly forecast page for Portal admin role
      this.forecastLock = false;
    } else {

      // Commented old logic, kept for future reference
      /* if (this.forecastEntryService.getCurrYear() <= this.forecastEntryService.getYear()) { //2020<2021 2020=2020
        if (this.forecastEntryService.getCurrYear() == this.forecastEntryService.getYear() && this.sharedService.getCurrentDateTime().getMonth() + 2 >= columnMonth) { //Jan 2020
          if (this.sharedService.getCurrentDateTime().getMonth() + 2 > columnMonth || this.sharedService.getCurrentDateTime().getDate() > 15) {
            this.forecastLock = true
          } else { //1 to 4 Jan 2020 OR 1 to 4 Jan 2019
            this.forecastLock = false
          }
        } else { // Feb 2020
          this.forecastLock = false
        }
      } else { //2020<2019
        this.forecastLock = true
      } */

      // If we are entering forecast for current year
      let currentMonthNumber = (this.sharedService.getCurrentDateTime().getMonth() + 1);
      if (this.yearUserSelected && ((+this.yearUserSelected) === this.sharedService.getCurrentDateTime().getFullYear())) {
        if ((currentMonthNumber) >= (Number(columnMonth))) { // If today is 23rd November, 2020 and column month is January, to disable monthly forecast entry for current and past months
          this.forecastLock = true;
        } else if ((currentMonthNumber + 1) === (Number(columnMonth)) && (this.sharedService.getCurrentDateTime().getDate() > 15)) { // If today is 23rd November, 2020 and column month is December, to disable monthly forecast entry for next month after date 15th of current month
          this.forecastLock = true;
        } else {
          this.forecastLock = false;
        }
      } else if (this.yearUserSelected && ((+this.yearUserSelected) > this.sharedService.getCurrentDateTime().getFullYear())) { // If we are entering forecast for next year
        if ((currentMonthNumber === 12) && (Number(columnMonth) === 1) && (this.sharedService.getCurrentDateTime().getDate() > 15)) { // If today is 23rd December, 2020 and column month is January of year 2021, to disable monthly forecast entry for next month after date 15th of current month
          this.forecastLock = true;
        } else {
          this.forecastLock = false;
        }
      } else if (this.yearUserSelected && ((+this.yearUserSelected) < this.sharedService.getCurrentDateTime().getFullYear())) { // If we are entering forecast for previous year
        this.forecastLock = true;
      }

      // Logic moved to Contractual volume page
      // Sweden - enable monthly forecast for customer admins till 31st March of every year, after that only Nynas admins can enter monthly forecast
      /* if (this.countryCode === 'SWE' && !this.isLoggedInUserPortalAdmin) {
        if (this.yearUserSelected == this.sharedService.getCurrentDateTime().getFullYear()) {
          let currentDateTime = (this.sharedService.getCurrentDateTime() ? new Date(this.sharedService.getCurrentDateTime()) : undefined);
          let currentYear = this.sharedService.getCurrentDateTime().getFullYear();
          let dateOn31stMarch2021 = new Date('03/31/' + currentYear);
          if (currentDateTime <= dateOn31stMarch2021) {
            this.forecastLock = false;
          } else {
            this.forecastLock = true;
          }
        } else {
          this.forecastLock = true; // disable forecast entry for past years
        }
      } */

      // Disable forecast entry textbox if C2P mapping is removed for a product and depot, applicable for all countries and only for past years
      if (!this.forecastLock) {
        this.forecastLock = (rowData['IS_CC_AVAILABLE'] === 'FALSE');
      }

    }

    // If forecast entry is allowed for Finland customer admin depending upon delivery guide
    if (this.countryCode === 'FIN' && !this.isLoggedInUserPortalAdmin && !this.forecastLock) {
      if (!(this.customerID == '267359' && rowData['DepotID'] === '23522' && (this.yearUserSelected && this.yearUserSelected.toString() === '2020'))) {  // Allow Peab customer to enter forecast for Hamina depot throught out year of 2020
        this.forecastLock = this.forecastLock || !(this.checkIfProductIsAvailableInSelectedMonth(rowData, columnMonth));
      }
    }

    return this.forecastLock;
  }

  disableForecastEntryTextBoxForContractualVolume(columnMonth: any, rowData: any) {
    // Initialise the value of forecastLock to false at first
    this.forecastLock = false;

    if (this.userInfo.RoleID == 'PA') { // Do not disable any textboxes on Contractual volume tab for Nynas admin role
      this.forecastLock = false;
    } else {

      // Enable contractual volume for customer admins till 31st March of every year, after that only Nynas admins can enter monthly forecast
      if (this.yearUserSelected == this.sharedService.getCurrentDateTime().getFullYear()) {
        let currentDateTime = (this.sharedService.getCurrentDateTime() ? new Date(this.sharedService.getCurrentDateTime()) : undefined);
        let currentYear = this.sharedService.getCurrentDateTime().getFullYear();
        let dateOn31stMarch2021 = new Date('03/31/' + currentYear);
        if (currentDateTime <= dateOn31stMarch2021) {
          this.forecastLock = false;
        } else {
          this.forecastLock = true;
        }
      } else {
        this.forecastLock = true; // disable contractual volume for past years
      }

    }

    return this.forecastLock;
  }

  getTotal(jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec) {
    return parseInt(!jan || jan === "null" ? 0 : jan) + parseInt(!feb || feb === "null" ? 0 : feb) + parseInt(!mar || mar === "null" ? 0 : mar) + parseInt(!apr || apr === "null" ? 0 : apr) + parseInt(!may || may === "null" ? 0 : may) +
      parseInt(!jun || jun === "null" ? 0 : jun) + parseInt(!jul || jul === "null" ? 0 : jul) + parseInt(!aug || aug === "null" ? 0 : aug) + parseInt(!sep || sep === "null" ? 0 : sep) + parseInt(!oct || oct === "null" ? 0 : oct) + parseInt(!nov || nov === "null" ? 0 : nov) +
      parseInt(!dec || dec === "null" ? 0 : dec);

  }
  onSelectMonth(fetchedDataForItemArray: any): void {
    // this.test = true;
    this.rowSelect = true;
    this.selectedRowIndexMonth = fetchedDataForItemArray.RowID;
    this.arrMonth.push(fetchedDataForItemArray);
    this.arrMonth = this.arrMonth.filter((v, i, a) => a.indexOf(v) === i);

  }
  updateMonthlyForecast(updateMonthlyData: any) {
    //let updateMonthly = updateMonthlyData.filteredData
    let updateMonthly = this.arrMonth
    this.dataUpdatedMonth = true;

    this.updateMonthData = [];
    for (let i = 0; i < updateMonthly.length; i++) {
      if (this.countryCode == "FIN" || this.countryCode == "SWE") {
        this.delSiteUserSelected = "DefaultDelSite"
      }
      this.updateMonthData.push({
        "CustomerId": this.forecastEntryService.getCustomerId(),
        "DeliverySite": this.delSiteUserSelected,
        "Year": this.yearUserSelected,
        "CreatedBy": this.username,
        "UpdatedBy": this.username,
        "CreatedOn": this.sharedService.getCurrentDateTime(),
        "UpdatedOn": this.sharedService.getCurrentDateTime(),
        "ProductId": updateMonthly[i].ProductID,
        "DepotId": updateMonthly[i].DepotID,
        "Jan": (updateMonthly[i].Jan == null) ? "" : updateMonthly[i].Jan,
        "Feb": (updateMonthly[i].Feb == null) ? "" : updateMonthly[i].Feb,
        "Mar": (updateMonthly[i].Mar == null) ? "" : updateMonthly[i].Mar,
        "Apr": (updateMonthly[i].Apr == null) ? "" : updateMonthly[i].Apr,
        "May": (updateMonthly[i].May == null) ? "" : updateMonthly[i].May,
        "Jun": (updateMonthly[i].Jun == null) ? "" : updateMonthly[i].Jun,
        "Jul": (updateMonthly[i].Jul == null) ? "" : updateMonthly[i].Jul,
        "Aug": (updateMonthly[i].Aug == null) ? "" : updateMonthly[i].Aug,
        "Sep": (updateMonthly[i].Sep == null) ? "" : updateMonthly[i].Sep,
        "Oct": (updateMonthly[i].Oct == null) ? "" : updateMonthly[i].Oct,
        "Nov": (updateMonthly[i].Nov == null) ? "" : updateMonthly[i].Nov,
        "Dec": (updateMonthly[i].Dec == null) ? "" : updateMonthly[i].Dec,
        "Total": this.getTotal(updateMonthly[i].Jan, updateMonthly[i].Feb, updateMonthly[i].Mar,
          updateMonthly[i].Apr, updateMonthly[i].May, updateMonthly[i].Jun,
          updateMonthly[i].Jul, updateMonthly[i].Aug, updateMonthly[i].Sep,
          updateMonthly[i].Oct, updateMonthly[i].Nov, updateMonthly[i].Dec),
        "JDEAddressNumber" : this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelected)
      })
    }

    let isQuantityInvalid: boolean;
    for (let monthRow of this.updateMonthData) {
      if (this.isInValidDecimalNumber(monthRow.Jan) || this.isInValidDecimalNumber(monthRow.Feb) || this.isInValidDecimalNumber(monthRow.Mar)
        || this.isInValidDecimalNumber(monthRow.Apr) || this.isInValidDecimalNumber(monthRow.May) || this.isInValidDecimalNumber(monthRow.Jun)
        || this.isInValidDecimalNumber(monthRow.Jul) || this.isInValidDecimalNumber(monthRow.Aug) || this.isInValidDecimalNumber(monthRow.Sep)
        || this.isInValidDecimalNumber(monthRow.Oct) || this.isInValidDecimalNumber(monthRow.Nov) || this.isInValidDecimalNumber(monthRow.Dec)) {
        isQuantityInvalid = true;
        break;
      }
    }
    if (!isQuantityInvalid) {
      // this.updateMonthData = this.updateMonthData.filter((v, i, a) => a.indexOf(v) === i);

      if (!this.updateMonthData || !this.updateMonthData.length) {
        this.openDialog('', '', false, 'forecast-message');
      } else {
        //check blank value.

        //   for (let monthRow of this.updateMonthData) {
        //     if( (monthRow.Jan === "") || (monthRow.Feb === "") || (monthRow.Mar === "")
        //       || (monthRow.Apr === "") ||(monthRow.May === "") || (monthRow.Jun === "")
        //       || (monthRow.Jul === "") || (monthRow.Aug === "") || (monthRow.Sep === "")
        //       ||(monthRow.Oct === "") || (monthRow.Nov === "") || (monthRow.Dec === "")) {
        //       isQuantityInvalid = true;
        //       break;
        //     }
        //   }
        //   if (isQuantityInvalid) {
        //   this.openDialog('Kindly insert valid value.', null, false);
        // }
        // else{
        let data1 = { "ForeCastDetails": this.updateMonthData }
        this.forecastEntryService.updateMonthlyForecastDetail(data1)
          .subscribe(
            res => {
              if (res["ResponseCode"] === "200") {
                this.openDialog('Forecast saved succcessfully!', null, true, 'forecast-detail-success');
                this.updateMonthData = []
                this.arrMonth = []
                // this.updateSuccess();
              } else {
                this.dataUpdatedMonth = false;
                this.openDialog('Forecast data not saved. Please try again later!', null, false, 'forecast-detail-fail');
                // this.updateFailure();
              }
            });

      }
    }
  }

  updateContractualVolumeDataList() {
    let updateContractualVolumeData = this.arrMonth
    this.dataUpdatedMonth = true;
    this.contractualVolumeDataToBeUpdated = [];
    for (let i = 0; i < updateContractualVolumeData.length; i++) {
      if (this.countryCode == "FIN" || this.countryCode == "SWE") {
        this.delSiteUserSelected = "DefaultDelSite"
      }
      this.contractualVolumeDataToBeUpdated.push({
        "CustomerId": this.forecastEntryService.getCustomerId(),
        "DeliverySite": this.delSiteUserSelected,
        "Year": this.yearUserSelected,
        "CreatedBy": this.username,
        "UpdatedBy": this.username,
        "CreatedOn": this.sharedService.getCurrentDateTime(),
        "UpdatedOn": this.sharedService.getCurrentDateTime(),
        "ProductId": updateContractualVolumeData[i].ProductID,
        "DepotId": updateContractualVolumeData[i].DepotID,
        "Jan": (updateContractualVolumeData[i].Jan == null) ? "" : updateContractualVolumeData[i].Jan,
        "Feb": (updateContractualVolumeData[i].Feb == null) ? "" : updateContractualVolumeData[i].Feb,
        "Mar": (updateContractualVolumeData[i].Mar == null) ? "" : updateContractualVolumeData[i].Mar,
        "Apr": (updateContractualVolumeData[i].Apr == null) ? "" : updateContractualVolumeData[i].Apr,
        "May": (updateContractualVolumeData[i].May == null) ? "" : updateContractualVolumeData[i].May,
        "Jun": (updateContractualVolumeData[i].Jun == null) ? "" : updateContractualVolumeData[i].Jun,
        "Jul": (updateContractualVolumeData[i].Jul == null) ? "" : updateContractualVolumeData[i].Jul,
        "Aug": (updateContractualVolumeData[i].Aug == null) ? "" : updateContractualVolumeData[i].Aug,
        "Sep": (updateContractualVolumeData[i].Sep == null) ? "" : updateContractualVolumeData[i].Sep,
        "Oct": (updateContractualVolumeData[i].Oct == null) ? "" : updateContractualVolumeData[i].Oct,
        "Nov": (updateContractualVolumeData[i].Nov == null) ? "" : updateContractualVolumeData[i].Nov,
        "Dec": (updateContractualVolumeData[i].Dec == null) ? "" : updateContractualVolumeData[i].Dec,
        "Total": this.getTotal(updateContractualVolumeData[i].Jan, updateContractualVolumeData[i].Feb, updateContractualVolumeData[i].Mar,
          updateContractualVolumeData[i].Apr, updateContractualVolumeData[i].May, updateContractualVolumeData[i].Jun,
          updateContractualVolumeData[i].Jul, updateContractualVolumeData[i].Aug, updateContractualVolumeData[i].Sep,
          updateContractualVolumeData[i].Oct, updateContractualVolumeData[i].Nov, updateContractualVolumeData[i].Dec),
        "JDEAddressNumber": this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelected)
      })
    }

    let isQuantityInvalid: boolean;
    for (let monthRow of this.contractualVolumeDataToBeUpdated) {
      if (this.isInValidDecimalNumber(monthRow.Jan) || this.isInValidDecimalNumber(monthRow.Feb) || this.isInValidDecimalNumber(monthRow.Mar)
        || this.isInValidDecimalNumber(monthRow.Apr) || this.isInValidDecimalNumber(monthRow.May) || this.isInValidDecimalNumber(monthRow.Jun)
        || this.isInValidDecimalNumber(monthRow.Jul) || this.isInValidDecimalNumber(monthRow.Aug) || this.isInValidDecimalNumber(monthRow.Sep)
        || this.isInValidDecimalNumber(monthRow.Oct) || this.isInValidDecimalNumber(monthRow.Nov) || this.isInValidDecimalNumber(monthRow.Dec)) {
        isQuantityInvalid = true;
        break;
      }
    }
    if (!isQuantityInvalid) {
      if (!this.contractualVolumeDataToBeUpdated || !this.contractualVolumeDataToBeUpdated.length) {
        this.openDialog('', '', false, 'forecast-message');
      } else {
        let data1 = { "ForeCastDetails": this.contractualVolumeDataToBeUpdated }
        this.forecastEntryService.updateContractualVolumeDataDetail(data1)
          .subscribe(
            res => {
              if (res["ResponseCode"] === "200") {
                this.openDialog('Contractual volume details saved successfully', null, true, 'contractual-volume-details-saved');
                this.contractualVolumeDataToBeUpdated = []
                this.arrMonth = []
              } else {
                this.dataUpdatedMonth = false;
                this.openDialog('Contractual volume details not saved. Please try again later', null, false, 'contractual-volume-details-not-saved');
              }
            });
      }
    }
  }

  downloadDefaulterList(groupValue: string) {
    let montlyFrArr = [];
    let cloned = this.excelData.map(x => Object.assign({}, x));
    for (let data of cloned) {
      if (data['Jan']) {
        data['Jan'] = +data['Jan'];
      }
      if (data['Feb']) {
        data['Feb'] = +data['Feb'];
      }
      if (data['Mar']) {
        data['Mar'] = +data['Mar'];
      }
      if (data['Apr']) {
        data['Apr'] = +data['Apr'];
      }
      if (data['May']) {
        data['May'] = +data['May'];
      }
      if (data['Jun']) {
        data['Jun'] = +data['Jun'];
      }
      if (data['Jul']) {
        data['Jul'] = +data['Jul'];
      }
      if (data['Aug']) {
        data['Aug'] = +data['Aug'];
      }
      if (data['Sep']) {
        data['Sep'] = +data['Sep'];
      }
      if (data['Oct']) {
        data['Oct'] = +data['Oct'];
      }
      if (data['Nov']) {
        data['Nov'] = +data['Nov'];
      }
      if (data['Dec']) {
        data['Dec'] = +data['Dec'];
      }
      data["Total"] = parseInt(!data["Jan"] ? 0 : data["Jan"])
      + parseInt(!data["Feb"] ? 0 : data["Feb"])
      + parseInt(!data["Mar"] ? 0 : data["Mar"])
      + parseInt(!data["Apr"] ? 0 : data["Apr"])
      + parseInt(!data["May"] ? 0 : data["May"])
      + parseInt(!data["Jun"] ? 0 : data["Jun"])
      + parseInt(!data["Jul"] ? 0 : data["Jul"])
      + parseInt(!data["Aug"] ? 0 : data["Aug"])
      + parseInt(!data["Sep"] ? 0 : data["Sep"])
      + parseInt(!data["Oct"] ? 0 : data["Oct"])
      + parseInt(!data["Nov"] ? 0 : data["Nov"])
      + parseInt(!data["Dec"] ? 0 : data["Dec"]);

      montlyFrArr.push(data);
    }
    let excelFileName = (groupValue === "weekly") ? "Weekly Forecast": "Monthly Forecast";
    this.forecastEntryService.exportAsExcelFile(montlyFrArr, excelFileName);
  }
  //montlyforecastdownload

  // Old methods of monthlyForecastDownloadforAll and downloadMontlyForecast, were used for download of all monthly excel data, not used now, so commented
  /* monthlyForecastDownloadforAll() {
    return new Promise((resolve, reject) => {
      this.forecastEntryService.getMontlyForecastDetailsForAll(this.yearUserSelected, this.countryCode)
        .subscribe(
          res => {
            if (res["ResponseCode"] === "200") {
              this.monthlyDataForecastDataforALL = res['FetchMonthlyForecastDetailsAll'];
              this.downloadMontlyForecast();
              resolve();
            }
            else {
              this.openDialog('Unable to download file. Please try again later!', null, false, '');
              // reject();
            }
          });
    })

  }
  downloadMontlyForecast() {
    //this.monthlyForecastDownloadforAll();
    let montlyFrAll = [];
    let cloned = this.monthlyDataForecastDataforALL.map(x => Object.assign({}, x));
    for (let data of cloned) {
      if (data['Jan']) {
        data['Jan'] = +data['Jan'];
      }
      if (data['Feb']) {
        data['Feb'] = +data['Feb'];
      }
      if (data['Mar']) {
        data['Mar'] = +data['Mar'];
      }
      if (data['Apr']) {
        data['Apr'] = +data['Apr'];
      }
      if (data['May']) {
        data['May'] = +data['May'];
      }
      if (data['Jun']) {
        data['Jun'] = +data['Jun'];
      }
      if (data['Jul']) {
        data['Jul'] = +data['Jul'];
      }
      if (data['Aug']) {
        data['Aug'] = +data['Aug'];
      }
      if (data['Sep']) {
        data['Sep'] = +data['Sep'];
      }
      if (data['Oct']) {
        data['Oct'] = +data['Oct'];
      }
      if (data['Nov']) {
        data['Nov'] = +data['Nov'];
      }
      if (data['Dec']) {
        data['Dec'] = +data['Dec'];
      }
      // data["Total"] = data["Jan"] + data['Feb'] + data['Mar'] + data['Apr'] + data['May'] + data['Jun'] + data['Jul'] + data['Aug'] + data['Sep'] + data['Oct'] + data['Nov'] + data['Dec'];
      data["Total"] = parseInt(!data["Jan"] ? 0 : data["Jan"])
      + parseInt(!data["Feb"] ? 0 : data["Feb"])
      + parseInt(!data["Mar"] ? 0 : data["Mar"])
      + parseInt(!data["Apr"] ? 0 : data["Apr"])
      + parseInt(!data["May"] ? 0 : data["May"])
      + parseInt(!data["Jun"] ? 0 : data["Jun"])
      + parseInt(!data["Jul"] ? 0 : data["Jul"])
      + parseInt(!data["Aug"] ? 0 : data["Aug"])
      + parseInt(!data["Sep"] ? 0 : data["Sep"])
      + parseInt(!data["Oct"] ? 0 : data["Oct"])
      + parseInt(!data["Nov"] ? 0 : data["Nov"])
      + parseInt(!data["Dec"] ? 0 : data["Dec"]);
      montlyFrAll.push(data);
    }
    this.forecastEntryService.exportAsExcelFile(montlyFrAll, "MonthlyForecastAll");
    //this.forecastEntryService.exportAsExcelFile(this.monthlyDataForecastDataforALL, "MonthlyForecastAll");
  } */

  getMonthTotal(data) {
    let totalMonth = 0;
    if (this.dataSourceMonthly) {
      let element = this.dataSourceMonthly.filteredData;

      let monthArrData = []
      monthArrData = JSON.parse(JSON.stringify(this.displayedColumnsMonth))
      monthArrData.splice(0, 2);
      // monthArrData.pop()

      for (let j = 0; j < element.length; j++) {
        if (data == 13) {
          totalMonth = totalMonth + this.getTotal(element[j].Jan, element[j].Feb, element[j].Mar, element[j].Apr, element[j].May, element[j].Jun, element[j].Jul, element[j].Aug, element[j].Sep, element[j].Oct, element[j].Nov, element[j].Dec)
        } else {
          totalMonth = totalMonth + parseInt((element[j][monthArrData[data - 1]] == "" || element[j][monthArrData[data - 1]] == null) ? 0 : element[j][monthArrData[data - 1]])
        }
      }
      return totalMonth;
    }
  }

  getMonthTotalForContractualVolumeTableData(data) {
    let totalMonth = 0;
    if (this.dataSourceContractualVolume) {
      let element = this.dataSourceContractualVolume.filteredData;
      let contractualVolumeDataList = []
      contractualVolumeDataList = JSON.parse(JSON.stringify(this.displayedColumnsMonth))
      contractualVolumeDataList.splice(0, 2);
      for (let j = 0; j < element.length; j++) {
        if (data == 13) {
          totalMonth = totalMonth + this.getTotal(element[j].Jan, element[j].Feb, element[j].Mar, element[j].Apr, element[j].May, element[j].Jun, element[j].Jul, element[j].Aug, element[j].Sep, element[j].Oct, element[j].Nov, element[j].Dec)
        } else {
          totalMonth = totalMonth + parseInt((element[j][contractualVolumeDataList[data - 1]] == "" || element[j][contractualVolumeDataList[data - 1]] == null) ? 0 : element[j][contractualVolumeDataList[data - 1]])
        }
      }
      return totalMonth;
    }
  }

  //COMMON
  openDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber
      },
      height: '35%',
      width: (this.mobileQuery.matches ? '50%' : '25%'),
    });
  }
  updateLanguage(selectedLanguage: string) {
    this.selectedLanguage = selectedLanguage;
    this.translate.use(selectedLanguage);
  }
  isInValidDecimalNumber(quantity: number): boolean {
    if (quantity === null || quantity === undefined || quantity.toString() === "null" || quantity.toString() === "") {
      return false;
    } else {
      let regex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g); // regex to check if number contains more than 2 decimal places
      return (quantity && !String(quantity).match(regex))
    }
  }
  isInValidNumber(quantity: number): boolean {
    if (quantity === null || quantity === undefined || quantity.toString() === "null" || quantity.toString() === "") {
      return false;
    } else {
      let regex: RegExp = new RegExp("^[0-9]*$"); // regex to check if number contains more than 2 decimal places
      return (quantity && !String(quantity).match(regex))
    }
  }

  clickSort(e) {
    // e.sort = this.sort;
    if (this.forecastValue == "weekly") {
      e.sort = this.weekSort;
    } else if (this.forecastValue == "monthly") {
      e.sort = this.monthSort;
    } else if (this.forecastValue == "contractualVolume") {
      e.sort = this.contractualVolumeSort;
    }
  }

  // Old method not to be used due issues on Finland
  /* fetchRollingForecastForAll() {
    this.forecastEntryService.fetchRollingForecastForAll(this.countryCode, this.currYear, (this.currWeek ? this.currWeek.toString() : '0'))
      .subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            let rollingForecastOverview = [];
            for (let forecastDetail of response['ForecastDetails']) {
              if (forecastDetail['forecastquantity']) {
                forecastDetail['forecastquantity'] = +forecastDetail['forecastquantity'];
              } else {
                forecastDetail['forecastquantity'] = 0;
              }
              rollingForecastOverview.push(forecastDetail);
            }
            this.downloadRollingForecastForAll(rollingForecastOverview);
          }
          else {
            this.openDialog('Something went wrong', '', false, 'Something-went-wrong');
          }
        });
  } */

  fetchRollingForecastForAll() {
    let weekCollectionList = [];
    let currentDateTime = this.sharedService.getCurrentDateTime();
    let currentYear = this.sharedService.getCurrentDateTime().getFullYear();  // 2020
    let maximumWeekNumberForCurrentYear = this.weeksInYear(currentYear);  // 53
    let currentWeekNumber = ((this.getWeekNumber(currentDateTime) && this.getWeekNumber(currentDateTime).length && this.getWeekNumber(currentDateTime)[1]) ? this.getWeekNumber(currentDateTime)[1] : 1); // 51
    for (let weeknumber = 0; weeknumber <= 4; weeknumber++) {  // Payload to contain current week number and next 4 weeks
      let week = currentWeekNumber + weeknumber;
      let year = currentYear;
      if (maximumWeekNumberForCurrentYear < week) {
        week = week - maximumWeekNumberForCurrentYear;
        year = currentYear + 1;
      } else {
        week = week;
        year = currentYear;
      }
      weekCollectionList.push({
        "Country": this.countryCode,
        "week": week,
        "Year": year
      })
    }
    let weekCollectionObject: any = {
      "WeekCollection": weekCollectionList
    }

    this.forecastEntryService.downloadAllRollingFourWeekForecastEntries(weekCollectionObject)
      .subscribe(
        response => {
          if (response && response["ResponseCode"] === "200") {
            let rollingForecastOverview = [];
            let rollingForecastOverviewObject: any = {};
            for (let forecastDetail of response['ForecastDetails']) {
              rollingForecastOverviewObject = {};
              if (forecastDetail['forecastquantity']) {
                forecastDetail['forecastquantity'] = +forecastDetail['forecastquantity'];
              } else {
                forecastDetail['forecastquantity'] = 0;
              }

              rollingForecastOverviewObject['Customer name'] = forecastDetail['customerName'];
              rollingForecastOverviewObject['Delivery site'] = forecastDetail['deliverySite'];
              rollingForecastOverviewObject['Product name'] = forecastDetail['productName'];
              rollingForecastOverviewObject['Depot name'] = forecastDetail['depotName'];
              rollingForecastOverviewObject['Forecast quantity'] = (forecastDetail['forecastquantity']);
              rollingForecastOverviewObject['Week'] = forecastDetail['week'];
              rollingForecastOverviewObject['Year'] = forecastDetail['year'];
              rollingForecastOverviewObject['Created by'] = forecastDetail['createdBy'];
              rollingForecastOverviewObject['Created on'] = forecastDetail['CREATED_ON'];
              rollingForecastOverviewObject['Updated by'] = forecastDetail['UPDATED_BY'];
              rollingForecastOverviewObject['Updated on'] = forecastDetail['UPDATED_ON'];

              rollingForecastOverview.push(rollingForecastOverviewObject);
            }

            // Commented old code, kept for future reference
            // this.downloadRollingForecastForAll(rollingForecastOverview);

            let headerList = ['Customer name', 'Delivery site', 'Product name', 'Depot name', 'Forecast quantity', 'Week',
              'Year', 'Created by', 'Created on', 'Updated by', 'Updated on'];

            this.sharedService.downloadDataInExcelFile(rollingForecastOverview, 'RollingForecastAll', headerList);

          } else if (response["ResponseCode"] && response["ResponseCode"] === '201') {
            this.openDialog('No data available!', '', false, 'no-data-available');
          } else {
            this.openDialog('Something went wrong', '', false, 'Something-went-wrong');
          }
        });
  }

  downloadRollingForecastForAll(rollingForecastForAll: any) {
    if (rollingForecastForAll) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rollingForecastForAll);
      const workbook: XLSX.WorkBook = { Sheets: { 'RollingForecastAll': worksheet }, SheetNames: ['RollingForecastAll'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const MonthlyForecast: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(MonthlyForecast, "RollingForecastAll" + EXCEL_EXTENSION);
    }
  }

  changeRollingWeekForecastDetail(disCol: any, fetchedDataForItemArray: any): void {
    if (disCol && fetchedDataForItemArray) {
      let depotId = fetchedDataForItemArray["DepotID"] ? fetchedDataForItemArray["DepotID"] : 0;
      let productId = fetchedDataForItemArray["ProductID"] ? fetchedDataForItemArray["ProductID"] : 0;
      let weekNumber = disCol ? (disCol.replace('Week ', '')) : 0;
      let year = (weekNumber && fetchedDataForItemArray['Year ' + weekNumber]) ? fetchedDataForItemArray['Year ' + weekNumber] : 0;
      let objectKey = depotId + '_' + productId + '_' + weekNumber + '_' + year;
      let actualForecastDetail = {
        "CustomerId": this.forecastEntryService.getCustomerId(),
        "DeliverySite": this.forecastEntryService.getDeliverySite(),
        "Week": weekNumber,
        "Year": year,
        "ProductId": productId,
        "Depot": depotId,
        "Quantity": fetchedDataForItemArray[disCol],
        "Created_By": this.username,
        "Updated_By": this.username,
        "Created_On": this.sharedService.getCurrentDateTime().toISOString(),
        "Updated_On": this.sharedService.getCurrentDateTime().toISOString(),
        "JDEAddressNumber" : this.getJDEAddressNumberByDeliverySiteName(this.forecastEntryService.getDeliverySite())
      }
      this.selectedRollingWeekDetails[objectKey] = actualForecastDetail;
    }
  }

  getPayloadForActualForecastEntered(): any {
    let actualPayloadToSaveForecastDetails = [];
    for (let rollingWeekDetailKey in this.selectedRollingWeekDetails) {
      if (rollingWeekDetailKey && this.selectedRollingWeekDetails[rollingWeekDetailKey]) {
        actualPayloadToSaveForecastDetails.push(this.selectedRollingWeekDetails[rollingWeekDetailKey]);
      }
    }
    return actualPayloadToSaveForecastDetails;
  }

  checkIfProductIsAvailableInSelectedMonth(rowData: any, columnMonth: number): boolean {
    let isAvailable = false;
    if (rowData['FROM_DATE'] && rowData['TO_DATE']) {
      let fromDate = rowData['FROM_DATE'];                      // 2019-07-26T00:00:00.000+00:00
      let fromDateDate = new Date(fromDate).getDate();          // 26
      let fromDateMonth = (new Date(fromDate).getMonth() + 1);  // 7
      let fromDateYear = new Date(fromDate).getFullYear();      // 2019
      let fromDateObject = new Date(fromDateMonth + '/' + fromDateDate + '/' + fromDateYear);

      let toDate = rowData['TO_DATE'];                          // 2021-04-05T00:00:00.000+00:00
      let toDateDate = new Date(toDate).getDate();              // 5
      let toDateMonth = (new Date(toDate).getMonth() + 1);      // 4
      let toDateYear = new Date(toDate).getFullYear();          // 2021
      let toDateObject = new Date(toDateMonth + '/' + toDateDate + '/' + toDateYear);

      let columnMonthFromDateObject = new Date(columnMonth + '/' + fromDateDate + '/' + this.yearUserSelected);
      let columnMonthToDateObject = new Date(columnMonth + '/' + toDateDate + '/' + this.yearUserSelected);

      if (fromDateObject <= columnMonthFromDateObject && columnMonthToDateObject <= toDateObject) {
        isAvailable = true;
      } else {
        isAvailable = false;
      }

    }
    return isAvailable;
  }

  // Commented the old method to disable monthly forecast entry page textboxes, since it did not work on year change
  /* checkIfProductIsAvailableInSelectedMonth(rowData: any, columnMonth: number): boolean {
    let isAvailable = false;
    if (rowData['FROM_DATE'] && rowData['TO_DATE']) {
      let currentDate = this.sharedService.getCurrentDateTime().getDate();                   // 11
      let currentMonth = (this.sharedService.getCurrentDateTime().getMonth() + 1);           // 12
      let currentYear = this.sharedService.getCurrentDateTime().getFullYear();               // 2020

      let fromDate = rowData['FROM_DATE'];                      // 2019-07-26T00:00:00.000+00:00
      let fromDateDate = new Date(fromDate).getDate();          // 26
      let fromDateYear = new Date(fromDate).getFullYear();      // 2019
      let fromDateMonth = (new Date(fromDate).getMonth() + 1);  // 7

      let toDate = rowData['TO_DATE'];                          // 2021-04-05T00:00:00.000+00:00
      let toDateDate = new Date(toDate).getDate();              // 5
      let toDateYear = new Date(toDate).getFullYear();          // 2021
      let toDateMonth = (new Date(toDate).getMonth() + 1);      // 4

      if (fromDateYear < currentYear) {                         // 2019 < 2020
        fromDateMonth = 1;                                      // Set fromDateMonth to starting month of the year
      }

      if (toDateYear > currentYear) {                           // 2021 > 2020
        toDateMonth = 12;                                       // Set toDateMonth to last month of the year
      }

      if ((Number(fromDateMonth) <= Number(columnMonth)) && (Number(columnMonth) <= Number(toDateMonth))) {     // 1 <= 1 && 1 <= 8 evaluates to true
        isAvailable = true;

        // When current month, column month and from date month are equal
        if ((Number(fromDateMonth) === Number(columnMonth)) && (Number(columnMonth) === Number(currentMonth))) {  // fromDateMonth= 8, columnMonth=8, currentMonth=8
          if (fromDateDate <= currentDate) {
            isAvailable = isAvailable && true;
          } else {
            isAvailable = isAvailable && false;
          }
        }

        // When current month, column month and to date month are equal
        if ((Number(toDateMonth) === Number(columnMonth)) && (Number(columnMonth) === Number(currentMonth))) {  // toDateMonth= 8, columnMonth=8, currentMonth=8
          if (toDateDate >= currentDate) {
            isAvailable = isAvailable && true;
          } else {
            isAvailable = isAvailable && false;
          }
        }
      }

    }
    return isAvailable;
  } */

  download() {
    if (this.forecastValue == "weekly") {
      return new Promise<void>((resolve, reject) => {
        let weeklyAvailable = 'YES'; // Set weeklyAvailable as YES, to get data of all depots in weekly forecast details excel
        let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelected);
        this.forecastEntryService.getWeeklyForecastDetails(this.customerIdUserSelected, this.delSiteUserSelected, this.weekUserSelected,
          this.yearUserSelected, this.countryCode, weeklyAvailable, jdeAddressNumber).subscribe(
            response => {
              if (response["ResponseCode"] === "200") {
                if (response["ForecastData"] && response["ForecastData"].length) {
                  let headerList = ["Product name", "Depot", "Quantity (metric ton)"];
                  let weeklyForecastDetailsToDownloadExcel = [];
                  for (let monthlyRowData of response["ForecastData"]) {
                    let rowObject = {};
                    for (let header of headerList) {
                      if (header === "Product name") {
                        rowObject[header] = monthlyRowData['ItemName'];
                      } else if (header === "Depot") {
                        rowObject[header] = monthlyRowData['Depot'];
                      } else if (header === "Quantity (metric ton)") {
                        rowObject[header] = monthlyRowData['Quantity'];
                      }
                    }
                    weeklyForecastDetailsToDownloadExcel.push(rowObject);
                  }
                  this.downloadExcel(weeklyForecastDetailsToDownloadExcel, headerList, "Weekly forecast");
                } else {
                  this.openDialog('No data available', '', false, 'no-data-available');
                }
                resolve();
              } else {
                this.openDialog('Something went wrong', '', false, 'Something-went-wrong');
                reject();
              }
            });
      })
    } else if (this.forecastValue == "monthly") {
      return new Promise<void>((resolve, reject) => {
        let monthlyAvailable = 'YES'; // Set monthlyAvailable as YES, to get data of all depots in monthly forecast page
        let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelectedMonth);
        this.forecastEntryService.getMonthlyForecastDetails(this.customerIdUserSelected, this.delSiteUserSelectedMonth,
          this.yearUserSelected, this.countryCode, monthlyAvailable, jdeAddressNumber).subscribe(
            response => {
              if (response["ResponseCode"] === "200") {
                if (response["MonthlyForecastDetails"] && response["MonthlyForecastDetails"].length) {
                  let headerList = ["Depot", "Product name", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total", "Created by", "Created on", "Updated by", "Updated on"];
                  let monthlyForecastDetailsToDownloadExcel = [];
                  let monthlyForecastEntryDataList = response["MonthlyForecastDetails"];
                  let currentYear = this.sharedService.getCurrentDateTime().getFullYear();

                  if (this.countryCode === 'FIN') {
                    let yearSelectedInDropdown = this.yearUserSelected ? +this.yearUserSelected : 0;
                    if (yearSelectedInDropdown >= currentYear) {
                      monthlyForecastEntryDataList = monthlyForecastEntryDataList.filter((element: any) => {
                        let fromDate = element['FROM_DATE'];                      // 2020-01-01T00:00:00.000+00:00
                        let fromDateYear = new Date(fromDate).getFullYear();      // 2020

                        let toDate = element['TO_DATE'];                          // 2021-03-31T00:00:00.000+00:00
                        let toDateYear = new Date(toDate).getFullYear();          // 2021
                        return ((element['IS_CC_AVAILABLE'] === 'TRUE') && (fromDateYear <= yearSelectedInDropdown && yearSelectedInDropdown <= toDateYear));
                      })
                    }
                  } else {
                    monthlyForecastEntryDataList = monthlyForecastEntryDataList.filter((element: any) => {
                      return (element['IS_CC_AVAILABLE'] === 'TRUE');
                    })
                  }

                  for (let monthlyRowData of monthlyForecastEntryDataList) {
                    let rowObject = {};
                    // rowObject['Total'] = 0;

                    // Commented old code, kept for future reference
                    /* for (let header of headerList) {
                      if (header === "Product name") {
                        rowObject[header] = monthlyRowData['ProductName'];
                      } else if (header === "Depot") {
                        rowObject[header] = monthlyRowData['DepotName'];
                      } else if (header === "Created by") {
                        rowObject[header] = monthlyRowData['CreatedBy'];
                      } else if (header === "Created on") {
                        rowObject[header] = monthlyRowData['CreatedOn'];
                      } else if (header === "Updated by") {
                        rowObject[header] = monthlyRowData['UpdatedBY'];
                      } else if (header === "Updated on") {
                        rowObject[header] = monthlyRowData['UpdatedOn'];
                      } else if (monthlyRowData[header]) {
                        rowObject[header] = +monthlyRowData[header];
                        rowObject['Total'] += rowObject[header];
                      }
                    } */                    

                    rowObject['Depot'] = monthlyRowData['DepotName'];
                    rowObject['Product name'] = monthlyRowData['ProductName'];
                    rowObject['Jan'] = (monthlyRowData['Jan'] ? +monthlyRowData['Jan'] : '');
                    rowObject['Feb'] = (monthlyRowData['Feb'] ? +monthlyRowData['Feb'] : '');
                    rowObject['Mar'] = (monthlyRowData['Mar'] ? +monthlyRowData['Mar'] : '');
                    rowObject['Apr'] = (monthlyRowData['Apr'] ? +monthlyRowData['Apr'] : '');
                    rowObject['May'] = (monthlyRowData['May'] ? +monthlyRowData['May'] : '');
                    rowObject['Jun'] = (monthlyRowData['Jun'] ? +monthlyRowData['Jun'] : '');
                    rowObject['Jul'] = (monthlyRowData['Jul'] ? +monthlyRowData['Jul'] : '');
                    rowObject['Aug'] = (monthlyRowData['Aug'] ? +monthlyRowData['Aug'] : '');
                    rowObject['Sep'] = (monthlyRowData['Sep'] ? +monthlyRowData['Sep'] : '');
                    rowObject['Oct'] = (monthlyRowData['Oct'] ? +monthlyRowData['Oct'] : '');
                    rowObject['Nov'] = (monthlyRowData['Nov'] ? +monthlyRowData['Nov'] : '');
                    rowObject['Dec'] = (monthlyRowData['Dec'] ? +monthlyRowData['Dec'] : '');
                    rowObject['Total'] = (+monthlyRowData['Jan'] + +monthlyRowData['Feb'] + +monthlyRowData['Mar'] + +monthlyRowData['Apr'] + +monthlyRowData['May'] + +monthlyRowData['Jun'] + +monthlyRowData['Jul'] + +monthlyRowData['Aug'] + +monthlyRowData['Sep'] + +monthlyRowData['Oct'] + +monthlyRowData['Nov'] + +monthlyRowData['Dec']);
                    rowObject['Created by'] = monthlyRowData['CreatedBy'];
                    rowObject['Created on'] = monthlyRowData['CreatedOn'];
                    rowObject['Updated by'] = monthlyRowData['UpdatedBY'];
                    rowObject['Updated on'] = monthlyRowData['UpdatedOn'];

                    monthlyForecastDetailsToDownloadExcel.push(rowObject);
                  }

                  // Commented old code, kept for future reference
                  // this.downloadExcel(monthlyForecastDetailsToDownloadExcel, headerList, "Monthly forecast");
                  
                  this.sharedService.downloadDataInExcelFile(monthlyForecastDetailsToDownloadExcel, 'Monthly forecast', headerList);

                } else {
                  this.openDialog('No data available', '', false, 'no-data-available');
                }
                resolve();
              } else {
                this.openDialog('Something went wrong', '', false, 'Something-went-wrong');
                reject();
              }
            });
      })
    } else if (this.forecastValue == "contractualVolume") {
      return new Promise<void>((resolve, reject) => {
        let monthlyAvailable = 'YES'; // Set monthlyAvailable as YES, to get data of all depots in contractual volume page
        let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelectedMonth);
        this.forecastEntryService.getContractualVolumeDetails(this.customerIdUserSelected, this.delSiteUserSelectedMonth,
          this.yearUserSelected, this.countryCode, monthlyAvailable, jdeAddressNumber).subscribe(
            response => {
              if (response["ResponseCode"] === "200") {
                if (response["MonthlyForecastDetails"] && response["MonthlyForecastDetails"].length) {
                  let headerList = ["Depot", "Product name", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total", "Created by", "Created on", "Updated by", "Updated on"];
                  let contractualVolumeDetailsToDownloadExcel = [];
                  let contractualVolumeDataList = response["MonthlyForecastDetails"];
                  let currentYear = this.sharedService.getCurrentDateTime().getFullYear();
                  if (this.countryCode === 'FIN') {
                    let yearSelectedInDropdown = this.yearUserSelected ? +this.yearUserSelected : 0;
                    if (yearSelectedInDropdown >= currentYear) {
                      contractualVolumeDataList = contractualVolumeDataList.filter((element: any) => {
                        let fromDate = element['FROM_DATE'];                      // 2020-01-01T00:00:00.000+00:00
                        let fromDateYear = new Date(fromDate).getFullYear();      // 2020

                        let toDate = element['TO_DATE'];                          // 2021-03-31T00:00:00.000+00:00
                        let toDateYear = new Date(toDate).getFullYear();          // 2021
                        return ((element['IS_CC_AVAILABLE'] === 'TRUE') && (fromDateYear <= yearSelectedInDropdown && yearSelectedInDropdown <= toDateYear));
                      })
                    }
                  } else {
                    contractualVolumeDataList = contractualVolumeDataList.filter((element: any) => {
                      return (element['IS_CC_AVAILABLE'] === 'TRUE');
                    })
                  }

                  /* for (let contractualVolumeRowData of contractualVolumeDataList) {
                    let rowObject = {};
                    rowObject['Total'] = 0;
                    for (let header of headerList) {
                      if (header === "Product name") {
                        rowObject[header] = contractualVolumeRowData['ProductName'];
                      } else if (header === "Depot") {
                        rowObject[header] = contractualVolumeRowData['DepotName'];
                      } else if (contractualVolumeRowData[header]) {
                        rowObject[header] = +contractualVolumeRowData[header];
                        rowObject['Total'] += rowObject[header];
                      }
                    }
                    contractualVolumeDetailsToDownloadExcel.push(rowObject);
                  } */

                  for (let contractualVolumeRowData of contractualVolumeDataList) {
                    let rowObject = {};

                    rowObject['Depot'] = contractualVolumeRowData['DepotName'];
                    rowObject['Product name'] = contractualVolumeRowData['ProductName'];
                    rowObject['Jan'] = (contractualVolumeRowData['Jan'] ? +contractualVolumeRowData['Jan'] : '');
                    rowObject['Feb'] = (contractualVolumeRowData['Feb'] ? +contractualVolumeRowData['Feb'] : '');
                    rowObject['Mar'] = (contractualVolumeRowData['Mar'] ? +contractualVolumeRowData['Mar'] : '');
                    rowObject['Apr'] = (contractualVolumeRowData['Apr'] ? +contractualVolumeRowData['Apr'] : '');
                    rowObject['May'] = (contractualVolumeRowData['May'] ? +contractualVolumeRowData['May'] : '');
                    rowObject['Jun'] = (contractualVolumeRowData['Jun'] ? +contractualVolumeRowData['Jun'] : '');
                    rowObject['Jul'] = (contractualVolumeRowData['Jul'] ? +contractualVolumeRowData['Jul'] : '');
                    rowObject['Aug'] = (contractualVolumeRowData['Aug'] ? +contractualVolumeRowData['Aug'] : '');
                    rowObject['Sep'] = (contractualVolumeRowData['Sep'] ? +contractualVolumeRowData['Sep'] : '');
                    rowObject['Oct'] = (contractualVolumeRowData['Oct'] ? +contractualVolumeRowData['Oct'] : '');
                    rowObject['Nov'] = (contractualVolumeRowData['Nov'] ? +contractualVolumeRowData['Nov'] : '');
                    rowObject['Dec'] = (contractualVolumeRowData['Dec'] ? +contractualVolumeRowData['Dec'] : '');
                    rowObject['Total'] = (+contractualVolumeRowData['Jan'] + +contractualVolumeRowData['Feb'] + +contractualVolumeRowData['Mar'] + +contractualVolumeRowData['Apr'] + +contractualVolumeRowData['May'] + +contractualVolumeRowData['Jun'] + +contractualVolumeRowData['Jul'] + +contractualVolumeRowData['Aug'] + +contractualVolumeRowData['Sep'] + +contractualVolumeRowData['Oct'] + +contractualVolumeRowData['Nov'] + +contractualVolumeRowData['Dec']);
                    rowObject['Created by'] = contractualVolumeRowData['CreatedBy'];
                    rowObject['Created on'] = contractualVolumeRowData['CreatedOn'];
                    rowObject['Updated by'] = contractualVolumeRowData['UpdatedBY'];
                    rowObject['Updated on'] = contractualVolumeRowData['UpdatedOn'];

                    contractualVolumeDetailsToDownloadExcel.push(rowObject);
                  }

                  // Commented old code, kept for future reference
                  // this.downloadExcel(contractualVolumeDetailsToDownloadExcel, headerList, "Contractual volume");
                  
                  this.sharedService.downloadDataInExcelFile(contractualVolumeDetailsToDownloadExcel, 'Contractual volume', headerList);
                  
                } else {
                  this.openDialog('No data available', '', false, 'no-data-available');
                }
                resolve();
              } else {
                this.openDialog('Something went wrong', '', false, 'Something-went-wrong');
                reject();
              }
            });
      })
    }
  }

  downloadExcel(forecastEntryDetails: any, header: any, sheetName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((forecastEntryDetails), { header: header });
    const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const forecastEntryDetailsBlob: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(forecastEntryDetailsBlob, sheetName + EXCEL_EXTENSION);
  }

  downloadAllForecastDetails() {
    return new Promise<void>((resolve, reject) => {
      let monthlyAvailable = 'YES'; // Set monthlyAvailable as YES, to get data of all depots in monthly forecast all details excel
      this.forecastEntryService.getMontlyForecastDetailsForAll(this.yearUserSelected, this.countryCode, monthlyAvailable)
        .subscribe(
          response => {
            if (response && response["ResponseCode"] === "200") {
              let isLoggedInUserOfNorway = (this.countryCode === 'NOR');  // Check for Norway user, since they need delivery site as well to be displayed in excel
              let excelMonthlyForecastDetailsForAll = [];
              let headerList = isLoggedInUserOfNorway ? (["Customer name", "Depot name", "Product name", "Delivery site", "Month", "Quantity", "Created by", "Created on", "Updated by", "Updated on"]) :
                (["Customer name", "Depot name", "Product name", "Month", "Quantity", "Created by", "Created on", "Updated by", "Updated on"]);
              let monthlyForecastDetailsForAll = response['FetchMonthlyForecastDetailsAll'];
              if (monthlyForecastDetailsForAll && monthlyForecastDetailsForAll.length) {
                for (let monthlyForecastDetail of monthlyForecastDetailsForAll) {
                  if (monthlyForecastDetail && monthlyForecastDetail['FetchMonthlyCollection'].length) {
                    for (let monthlyDetail of monthlyForecastDetail['FetchMonthlyCollection']) {
                      let monthlyDetailObject = {};
                      monthlyDetailObject['Customer name'] = monthlyForecastDetail['CustomerName'];
                      monthlyDetailObject['Depot name'] = monthlyForecastDetail['DepotName'];
                      monthlyDetailObject['Product name'] = monthlyForecastDetail['ProductName'];
                      if (isLoggedInUserOfNorway) {
                        monthlyDetailObject['Delivery site'] = monthlyForecastDetail['DeliverySite'];
                      }
                      monthlyDetailObject['Month'] = (monthlyDetail['MONTH'] ? +monthlyDetail['MONTH'] : '');
                      monthlyDetailObject['Quantity'] = (monthlyDetail['PLANNED_QUANTITY'] ? +monthlyDetail['PLANNED_QUANTITY'] : '');
                      monthlyDetailObject['Created by'] = monthlyDetail['CreatedBy'];
                      monthlyDetailObject['Created on'] = monthlyDetail['CreatedOn'];
                      monthlyDetailObject['Updated by'] = monthlyDetail['UpdatedBY'];
                      monthlyDetailObject['Updated on'] = monthlyDetail['UpdatedOn'];
                      excelMonthlyForecastDetailsForAll.push(monthlyDetailObject);
                    }
                  }
                }

                // Commented old code, kept for future reference
                // this.downloadExcel(excelMonthlyForecastDetailsForAll, headerList, "MonthlyForecastAll");

                this.sharedService.downloadDataInExcelFile(excelMonthlyForecastDetailsForAll, 'MonthlyForecastAll', headerList);

              } else {
                this.openDialog('No data available!', '', false, 'no-data-available');
              }
              resolve();
            }
            else {
              this.openDialog('Something went wrong', '', false, 'Something-went-wrong');
              reject();
            }
          });
    })
  }

  downloadAllContractualVolumeDetails() {
    return new Promise<void>((resolve, reject) => {
      let monthlyAvailable = 'YES'; // Set monthlyAvailable as YES, to get data of all depots in contractual volume download all details excel
      this.forecastEntryService.getContractualVolumeDetailsForAll(this.yearUserSelected, this.countryCode, monthlyAvailable)
        .subscribe(
          response => {
            if (response && response["ResponseCode"] === "200") {
              let isLoggedInUserOfNorway = (this.countryCode === 'NOR');  // Check for Norway user, since they need delivery site as well to be displayed in excel
              let excelContractualVolumeDetailsForAll = [];
              let headerList = isLoggedInUserOfNorway ? (["Customer name", "Depot name", "Product name", "Delivery site", "Month", "Quantity"]) :
                (["Customer name", "Depot name", "Product name", "Month", "Quantity"]);
              let contractualVolumeDetailsForAll = response['FetchMonthlyForecastDetailsAll'];
              if (contractualVolumeDetailsForAll && contractualVolumeDetailsForAll.length) {
                for (let contractualVolumeDetail of contractualVolumeDetailsForAll) {
                  if (contractualVolumeDetail && contractualVolumeDetail['FetchMonthlyCollection'].length) {
                    for (let contVolumeDetail of contractualVolumeDetail['FetchMonthlyCollection']) {
                      let contractualVolumeDetailObject = {};
                      contractualVolumeDetailObject['Customer name'] = contractualVolumeDetail['CustomerName'];
                      contractualVolumeDetailObject['Depot name'] = contractualVolumeDetail['DepotName'];
                      contractualVolumeDetailObject['Product name'] = contractualVolumeDetail['ProductName'];
                      if (isLoggedInUserOfNorway) {
                        contractualVolumeDetailObject['Delivery site'] = contractualVolumeDetail['DeliverySite'];
                      }
                      contractualVolumeDetailObject['Month'] = (contVolumeDetail['MONTH'] ? +contVolumeDetail['MONTH'] : '');
                      contractualVolumeDetailObject['Quantity'] = (contVolumeDetail['PLANNED_QUANTITY'] ? +contVolumeDetail['PLANNED_QUANTITY'] : '');
                      excelContractualVolumeDetailsForAll.push(contractualVolumeDetailObject);
                    }
                  }
                }

                // Commented old code, kept for future reference
                // this.downloadExcel(excelContractualVolumeDetailsForAll, headerList, "ContractualVolumeAll");

                this.sharedService.downloadDataInExcelFile(excelContractualVolumeDetailsForAll, 'ContractualVolumeAll', headerList);

              } else {
                this.openDialog('No data available!', '', false, 'no-data-available');
              }
              resolve();
            }
            else if (response["ResponseCode"] && response["ResponseCode"] === '201') {
              this.openDialog('No data available!', '', false, 'no-data-available');
            }
            else {
              this.openDialog('Something went wrong', '', false, 'Something-went-wrong');
              reject();
            }
          });
    })
  }

  // Below logic is moved to new method of checkIfMonthlyAndRollingWeeklyForecastTabIsToBeDisplayed
  /* hasPermissionForMonthlyForecastEntry(): boolean {
    let menuAccessList = [], menuId = '42'; // '42' is the menu id for 'Monthly forecast entry' menu, saved in database
    if (this.userInfo && this.userInfo.Menus && this.userInfo.Menus.length) {
      for (let menu of this.userInfo.Menus) {
        menuAccessList.push(menu.MenuID);
      }
    }
    return (menuAccessList.includes(menuId));
  } */

  async toggleButtonClick() {
    /* if (this.isLoggedInUserPortalAdmin) {
      if (this.countryCode == 'FIN') {
        if (this.forecastValue == "monthly") {
          this.customerIdUserSelectedForWeekly = this.customerIdUserSelected;
          this.customerIdUserSelected = this.customerIdUserSelectedForMonthly ? this.customerIdUserSelectedForMonthly : this.customerID;
        } else if (this.forecastValue == "rollingWeek") {
          this.customerIdUserSelectedForMonthly = this.customerIdUserSelected;
          this.customerIdUserSelected = this.customerIdUserSelectedForWeekly ? this.customerIdUserSelectedForWeekly : this.customerID;
        }
      } else if (this.countryCode == 'NOR' || this.countryCode == 'SWE' || this.countryCode == 'DNK') {
        if (this.forecastValue == "monthly") {
          this.customerIdUserSelectedForWeekly = this.customerIdUserSelected;
          this.customerIdUserSelected = this.customerIdUserSelectedForMonthly ? this.customerIdUserSelectedForMonthly : this.customerID;
        } else if (this.forecastValue == "rollingWeek") {
          this.customerIdUserSelectedForMonthly = this.customerIdUserSelected;
          this.customerIdUserSelected = this.customerIdUserSelectedForWeekly ? this.customerIdUserSelectedForWeekly : this.customerID;
        }
      }
    }

    // Save the year selected by user on Weekly, Rolling weekly and Monthly forecast pages, in temporary variable and use it on navigating back to that page
    if (this.countryCode) {
      if (this.forecastValue == "monthly") {
        this.yearUserSelectedForWeekly = this.yearUserSelected;
        this.yearUserSelected = this.yearUserSelectedForMonthly ? this.yearUserSelectedForMonthly : this.sharedService.getCurrentDateTime().getFullYear();
      } else if (this.forecastValue == "rollingWeek") {
        this.yearUserSelectedForMonthly = this.yearUserSelected;
        this.yearUserSelected = this.yearUserSelectedForWeekly ? this.yearUserSelectedForWeekly : this.sharedService.getCurrentDateTime().getFullYear();
      }
    } */

    // Save year and customer already selected on monthly and rolling 4 week forecast entry tab
    if (this.forecastValue == "monthly") {
      this.customerIdUserSelectedForWeekly = this.customerIdUserSelected;
      this.customerIdUserSelected = this.customerIdUserSelectedForMonthly ? this.customerIdUserSelectedForMonthly : this.customerID;
      this.yearUserSelectedForWeekly = this.yearUserSelected;
      this.yearUserSelected = this.yearUserSelectedForMonthly ? this.yearUserSelectedForMonthly : this.sharedService.getCurrentDateTime().getFullYear();
    } else if (this.forecastValue == "rollingWeek") {
      this.customerIdUserSelectedForMonthly = this.customerIdUserSelected;
      this.customerIdUserSelected = this.customerIdUserSelectedForWeekly ? this.customerIdUserSelectedForWeekly : this.customerID;
      this.yearUserSelectedForMonthly = this.yearUserSelected;
      this.yearUserSelected = this.yearUserSelectedForWeekly ? this.yearUserSelectedForWeekly : this.sharedService.getCurrentDateTime().getFullYear();
    } else if (this.forecastValue == "contractualVolume") {
      this.customerIdUserSelectedForWeekly = this.customerIdUserSelected;
      this.customerIdUserSelected = this.customerIdUserSelectedForMonthly ? this.customerIdUserSelectedForMonthly : this.customerID;
      this.yearUserSelectedForWeekly = this.yearUserSelected;
      this.yearUserSelected = this.yearUserSelectedForMonthly ? this.yearUserSelectedForMonthly : this.sharedService.getCurrentDateTime().getFullYear();
    }

    //reload delivery sites in dropdown
    try {
      await this.getDeliverySites();
    } catch (e) {
    }
    this.fetchOnClick(false);
  }

  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (deliverySiteName === 'DefaultDelSite') {
      jdeAddressNumber = 'NA';
    } else if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        if (deliverySiteObject) {
          let currentDeliverySiteName = (deliverySiteObject['DeliverySite'] || deliverySiteObject['DeliverySiteName']);
          if (currentDeliverySiteName === deliverySiteName) {
            jdeAddressNumber = (deliverySiteObject['JDE_Address_Number'] || deliverySiteObject['JdeAddressNumber']);
            break;
          }
        }
      }
    }
    return jdeAddressNumber;
  }

  checkTabsToBeDisplayedDependingUponPermissions() {
    if (this.countryCode) {
      this.showMonthlyForecastEntryTab = this.isLoggedInUserAdmin;
      this.showContractualVolumeTab = this.checkIfMenuIdIsAllowedToLoggedInUser('49'); // Menu id 49 is Contractual volume, for all countries
      this.showRollingFourWeekForecastEntryTab = true;
      switch (this.countryCode) {
        case 'FIN':
          this.showMonthlyForecastEntryTab = this.checkIfMenuIdIsAllowedToLoggedInUser('42'); // For Finland, menu id 42 is Monthly forecast entry
          break;
        case 'SWE':
          this.showMonthlyForecastEntryTab = this.checkIfMenuIdIsAllowedToLoggedInUser('46'); // For Sweden, menu id 46 is Monthly forecast entry
          this.showRollingFourWeekForecastEntryTab = this.checkIfMenuIdIsAllowedToLoggedInUser('45'); // For Sweden, menu id 45 is Rolling 4 week forecast entry

          if (!this.showRollingFourWeekForecastEntryTab && this.showMonthlyForecastEntryTab) {
            this.forecastValue = 'monthly';
          }

          break;
        case 'NOR':
          this.showMonthlyForecastEntryTab = (this.isLoggedInUserAdmin || this.checkIfMenuIdIsAllowedToLoggedInUser('46')); // For Norway, menu id 46 is Monthly forecast entry
          break;
        default:
      }
    }
  }

  checkIfMenuIdIsAllowedToLoggedInUser(menuId: string): boolean {
    let menuAccessList = [];
    if (this.userInfo && this.userInfo.Menus && this.userInfo.Menus.length) {
      for (let menu of this.userInfo.Menus) {
        menuAccessList.push(menu.MenuID);
      }
    }
    return (menuAccessList.includes(menuId));
  }

  // Commented old code to disable rolling 4 week forecast entry textboxes, kept for future reference
  /* validateIfWeekNumberIsAllowedForForecastEntry(weekNumber: number): boolean {
    let isValidWeekNumberForForecastEntry: boolean;
    let currentWeekNumber = this.currWeek;  // 53
    let currentYear = this.sharedService.getCurrentDateTime().getFullYear();  // 2020

    if (currentYear === this.yearUserSelected) {  // currentYear = 2020, yearUserSelected = 2020
      if ((currentWeekNumber + 1) < weekNumber) { // currentWeekNumber = 5, weekNumber = 7
        isValidWeekNumberForForecastEntry = true;
      } else if (((currentWeekNumber + 1) === weekNumber) && this.sharedService.getCurrentDateTime().getDay() < 5) { // currentWeekNumber = 5, weekNumber = 6, today is Sunday, Monday, Tuesday, Wednesday or Thursday
        isValidWeekNumberForForecastEntry = true;
      } else {
        isValidWeekNumberForForecastEntry = false; // currentWeekNumber = 5, weekNumber = 6, today is Friday or Saturday
      }
    } else if (currentYear < this.yearUserSelected) { // 2020 < 2021
      if (currentWeekNumber === 53 && weekNumber === 1 && this.sharedService.getCurrentDateTime().getDay() > 4) { // currentWeekNumber = 53, weekNumber = 1, today is fridayy or saturday
        isValidWeekNumberForForecastEntry = false;
      } else {
        isValidWeekNumberForForecastEntry = true;
      }
    }
    return isValidWeekNumberForForecastEntry;
  } */

  validateIfWeekNumberIsAllowedForForecastEntry(weekNumber: number, yearForSelectedWeek: number): boolean {
    let isValidWeekNumberForForecastEntry: boolean;
    let currentWeekNumber = this.currWeek;  // 6
    let currentYear = this.sharedService.getCurrentDateTime().getFullYear();  // 2021
    let dayNumberForFriday: number = 5;

    if (currentYear === yearForSelectedWeek) {  // currentYear = 2021, yearForSelectedWeek = 2021
      if ((currentWeekNumber + 1) < weekNumber) { // currentWeekNumber = 6, weekNumber = 8
        isValidWeekNumberForForecastEntry = true;
      } else if (((currentWeekNumber + 1) === weekNumber)) { // currentWeekNumber = 6, weekNumber = 7
        if (this.countryCode === 'NOR' && this.customerID === '32807') {  // Forecast of next week to be freezed for Norway - Veidekke Industri AS on 12.00 hours on Fridays
          if ((this.sharedService.getCurrentDateTime().getDay() < dayNumberForFriday) || (this.sharedService.getCurrentDateTime().getDay() === dayNumberForFriday && this.sharedService.getCurrentDateTime().getUTCHours() < 12)) {  // If day is Sunday, Monday, Tuesday, Wednesday, Thursday or Friday but time is less than 12 hours. Use getUTCHours to take UTC time
            isValidWeekNumberForForecastEntry = true;
          } else {
            isValidWeekNumberForForecastEntry = false;
          }
        } else if (this.sharedService.getCurrentDateTime().getDay() < dayNumberForFriday) {  // Forecast of next week to be freezed for others on 00.00 hours on Fridays
          isValidWeekNumberForForecastEntry = true;
        } else {
          isValidWeekNumberForForecastEntry = false;
        }
      } else {
        isValidWeekNumberForForecastEntry = false; // currentWeekNumber = 6, weekNumber = 6, today is Friday or Saturday
      }
    } else if (currentYear < yearForSelectedWeek) { // 2020 < 2021
      if (currentWeekNumber === 53 && weekNumber === 1 && this.sharedService.getCurrentDateTime().getDay() > 4) { // currentWeekNumber = 53, weekNumber = 1, today is fridayy or saturday
        isValidWeekNumberForForecastEntry = false;
      } else {
        isValidWeekNumberForForecastEntry = true;
      }
    }
    return isValidWeekNumberForForecastEntry;
  }

  fetchAllRollingForecastDataForCustomerAdmin() {
    let weekCollectionList = [];
    let currentDateTime = this.sharedService.getCurrentDateTime();
    let currentYear = this.sharedService.getCurrentDateTime().getFullYear();  // 2020
    let maximumWeekNumberForCurrentYear = this.weeksInYear(currentYear);  // 53
    let currentWeekNumber = ((this.getWeekNumber(currentDateTime) && this.getWeekNumber(currentDateTime).length && this.getWeekNumber(currentDateTime)[1]) ? this.getWeekNumber(currentDateTime)[1] : 1); // 51
    for (let weeknumber = 0; weeknumber <= 4; weeknumber++) {  // Payload to contain current week number and next 4 weeks
      let week = currentWeekNumber + weeknumber;
      let year = currentYear;
      if (maximumWeekNumberForCurrentYear < week) {
        week = week - maximumWeekNumberForCurrentYear;
        year = currentYear + 1;
      } else {
        week = week;
        year = currentYear;
      }
      weekCollectionList.push({
        "Country": this.countryCode,
        "week": week,
        "Year": year
      })
    }

    let fromWeek: string = weekCollectionList[0]['week']; // 17
    let toWeek: string = weekCollectionList[4]['week']; // 17 + 4 = 21
    let deliverySite: string = '';  // Pass delivery site parameter as blank to fetch all data
    let jdeAddressNumber: string = '';  // Pass jde address number parameter as blank to fetch all data
    this.forecastEntryService.fetchHistoryDetails(
      this.customerIdUserSelected,
      deliverySite,
      fromWeek,
      toWeek,
      this.yearUserSelected,
      this.countryCode,
      jdeAddressNumber)
      .subscribe(
        response => {
          if (response && response["ResponseCode"] === "200") {

            // Commented old code, kept for future reference
            /* let forecastDetails: any = [];

            for (let forecastDetail of response["ForecastDetails"]) {
              if (forecastDetail['forecastquantity']) {
                forecastDetail['forecastquantity'] = +forecastDetail['forecastquantity'];
              } else {
                forecastDetail['forecastquantity'] = 0;
              }

              // Remove JDEAddressNumber and actualquantity from excel file
              delete forecastDetail['JDEAddressNumber'];
              delete forecastDetail['actualquantity'];

              forecastDetails.push(forecastDetail);
            } */

            let rollingForecastOverview = [];
            let rollingForecastOverviewObject: any = {};
            for (let forecastDetail of response['ForecastDetails']) {
              rollingForecastOverviewObject = {};
              if (forecastDetail['forecastquantity']) {
                forecastDetail['forecastquantity'] = +forecastDetail['forecastquantity'];
              } else {
                forecastDetail['forecastquantity'] = 0;
              }

              rollingForecastOverviewObject['Customer name'] = forecastDetail['customerName'];
              rollingForecastOverviewObject['Delivery site name'] = forecastDetail['deliverySite'];
              rollingForecastOverviewObject['Product name'] = forecastDetail['productName'];
              rollingForecastOverviewObject['Depot name'] = forecastDetail['depotName'];
              rollingForecastOverviewObject['Forecast quantity'] = (forecastDetail['forecastquantity']);
              rollingForecastOverviewObject['Week'] = forecastDetail['week'];
              rollingForecastOverviewObject['Year'] = forecastDetail['year'];
              rollingForecastOverviewObject['Created by'] = forecastDetail['createdBy'];
              rollingForecastOverviewObject['Created on'] = forecastDetail['createdOn'];
              rollingForecastOverviewObject['Updated by'] = forecastDetail['updatedBy'];
              rollingForecastOverviewObject['Updated on'] = forecastDetail['updatedOn'];

              rollingForecastOverview.push(rollingForecastOverviewObject);
            }

            // Commented old code, kept for future reference
            // this.downloadRollingForecastForAll(forecastDetails);

            let headerList = ['Customer name',	'Delivery site name',	'Product name',	'Depot name',	'Forecast quantity',	'Week', 
            'Year',	'Created by',	'Created on',	'Updated by',	'Updated on'];

            this.sharedService.downloadDataInExcelFile(rollingForecastOverview, 'RollingForecastAll', headerList);

          } else if (response["ResponseCode"] && response["ResponseCode"] === '201') {
            this.openDialog('No data available!', '', false, 'no-data-available');
          } else {
            this.openDialog('Something went wrong', '', false, 'Something-went-wrong');
          }
        });
  }

  fetchAllMonthlyForecastDataForCustomerAdmin() {
    return new Promise<void>((resolve, reject) => {
      let deliverySite: string = '';
      let isMonthlyAvailable = 'YES'; // Set isMonthlyAvailable as YES, to get data of all the depots in monthly forecast details excel file
      let jdeAddressNumber: string = '';
      let status: string = 'ACTIVE';  // Set status as acive

      this.forecastEntryService.fetchMonthlyForecastForCustomerAdmin(this.customerIdUserSelected, deliverySite,
        this.yearUserSelected, this.countryCode, isMonthlyAvailable, jdeAddressNumber, status).subscribe(
          response => {
            if (response["ReponseCode"] === "200") {
              if (response["MonthlyForecastDetailsForCustomerAdmin"] && response["MonthlyForecastDetailsForCustomerAdmin"].length) {
                let headerList = ["Delivery site", "Depot", "Product name", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total", "Created by", "Created on", "Updated by", "Updated on"];
                let monthlyForecastDetailsToDownloadExcel = [];
                let monthlyForecastDetailsForCustomerAdmin = response["MonthlyForecastDetailsForCustomerAdmin"];

                for (let monthlyForecastData of monthlyForecastDetailsForCustomerAdmin) {
                  let monthlyForecastDetails = monthlyForecastData["MonthlyForecastDetails"];
                  let deliverySiteName: string = monthlyForecastData["DeliverySite"];
                  let depotName: string = monthlyForecastData["DepotName"];
                  let productName: string = monthlyForecastData["ProductName"];
                  if (monthlyForecastDetails && monthlyForecastDetails.length) {
                    let rowObject = {};

                    rowObject['Delivery site'] = deliverySiteName;
                    rowObject['Depot'] = depotName;
                    rowObject['Product name'] = productName;
                    rowObject['Jan'] = '';
                    rowObject['Feb'] = '';
                    rowObject['Mar'] = '';
                    rowObject['Apr'] = '';
                    rowObject['May'] = '';
                    rowObject['Jun'] = '';
                    rowObject['Jul'] = '';
                    rowObject['Aug'] = '';
                    rowObject['Sep'] = '';
                    rowObject['Oct'] = '';
                    rowObject['Nov'] = '';
                    rowObject['Dec'] = '';
                    rowObject['Total'] = '';
                    rowObject['Created by'] = '';
                    rowObject['Created on'] = '';
                    rowObject['Updated by'] = '';
                    rowObject['Updated on'] = '';

                    for (let monthlyRowData of monthlyForecastDetails) {

                      if (monthlyRowData['Jan']) {
                        rowObject['Jan'] = +monthlyRowData['Jan'];
                      }
                      if (monthlyRowData['Feb']) {
                        rowObject['Feb'] = +monthlyRowData['Feb'];
                      }
                      if (monthlyRowData['Mar']) {
                        rowObject['Mar'] = +monthlyRowData['Mar'];
                      }
                      if (monthlyRowData['Apr']) {
                        rowObject['Apr'] = +monthlyRowData['Apr'];
                      }
                      if (monthlyRowData['May']) {
                        rowObject['May'] = +monthlyRowData['May'];
                      }
                      if (monthlyRowData['Jun']) {
                        rowObject['Jun'] = +monthlyRowData['Jun'];
                      }
                      if (monthlyRowData['Jul']) {
                        rowObject['Jul'] = +monthlyRowData['Jul'];
                      }
                      if (monthlyRowData['Aug']) {
                        rowObject['Aug'] = +monthlyRowData['Aug'];
                      }
                      if (monthlyRowData['Sep']) {
                        rowObject['Sep'] = +monthlyRowData['Sep'];
                      }
                      if (monthlyRowData['Oct']) {
                        rowObject['Oct'] = +monthlyRowData['Oct'];
                      }
                      if (monthlyRowData['Nov']) {
                        rowObject['Nov'] = +monthlyRowData['Nov'];
                      }
                      if (monthlyRowData['Dec']) {
                        rowObject['Dec'] = +monthlyRowData['Dec'];
                      }
                      if (monthlyRowData['CreatedBy']) {
                        rowObject['Created by'] = monthlyRowData['CreatedBy'];
                      }
                      if (monthlyRowData['CreatedOn']) {
                        rowObject['Created on'] = monthlyRowData['CreatedOn'];
                      }
                      if (monthlyRowData['UpdatedBY']) {
                        rowObject['Updated by'] = monthlyRowData['UpdatedBY'];
                      }
                      if (monthlyRowData['UpdatedOn']) {
                        rowObject['Updated on'] = monthlyRowData['UpdatedOn'];
                      }
                    }
                    rowObject['Total'] = (
                      (rowObject['Jan'] ? +rowObject['Jan'] : 0) +
                      (rowObject['Feb'] ? +rowObject['Feb'] : 0) +
                      (rowObject['Mar'] ? +rowObject['Mar'] : 0) +
                      (rowObject['Apr'] ? +rowObject['Apr'] : 0) +
                      (rowObject['May'] ? +rowObject['May'] : 0) +
                      (rowObject['Jun'] ? +rowObject['Jun'] : 0) +
                      (rowObject['Jul'] ? +rowObject['Jul'] : 0) +
                      (rowObject['Aug'] ? +rowObject['Aug'] : 0) +
                      (rowObject['Sep'] ? +rowObject['Sep'] : 0) +
                      (rowObject['Oct'] ? +rowObject['Oct'] : 0) +
                      (rowObject['Nov'] ? +rowObject['Nov'] : 0) +
                      (rowObject['Dec'] ? +rowObject['Dec'] : 0)
                    );
                    monthlyForecastDetailsToDownloadExcel.push(rowObject);
                  }
                }

                this.sharedService.downloadDataInExcelFile(monthlyForecastDetailsToDownloadExcel, 'Monthly forecast all', headerList);

              } else {
                this.openDialog('No data available', '', false, 'no-data-available');
              }
              resolve();
            } else {
              this.openDialog('Something went wrong', '', false, 'Something-went-wrong');
              reject();
            }
          });
    })
  }

}
