import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { ForecastActual } from '../reports';
import { MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { CommonService } from 'src/app/order-management/order-management/order-management.component.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import * as moment from 'moment'
import { Customer } from 'src/app/user-management/customer';
import { TranslateService } from '@ngx-translate/core';
import { ForecastActualMonthlySwedenService } from './forecastactualmonthlysweden.service'

@Component({
  selector: 'app-forecastactualmonthlysweden',
  templateUrl: './forecastactualmonthlysweden.component.html',
  styleUrls: ['./forecastactualmonthlysweden.component.css']
})
export class ForecastactualmonthlyswedenComponent implements OnInit {
  public displayedColumns = ['Type', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total'];
  public displayedColumnfordepot = ['Depot name', 'Type', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total'];
  public displayedColumnforproduct = ['Product name', 'Type', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total'];
  public displayedColumnfordelsite = ['Delivery site', 'Type', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total'];
  public displayedColumnforproductanddepot = ['Product name', 'Depot name', 'Type', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total'];
  navbarOpen = false;
  year: string;
  custId: string;
  excelDataForALL: any;
  selectedCustomerIdForGraph: string;
  selectedCustomerIdForTable: string;
  selectedDeliverySiteForGraph: string;
  selectedDeliverySiteForTable: string;
  Actual_Quantity: any = [];
  Forecast_Quantity: any = [];
  Order_month: any = [];
  deliverySiteList: any = [];
  selectedItem: String = 'Reports - Sweden';
  forecastActualData: ForecastActual[];
  dataSource: any;
  dataSource1: any;
  dataSource2: any;
  dataSource3: any;
  displayTable: boolean;
  userInfo: any;
  isLoggedInUserPortalAdmin: boolean;
  dataToSearch: any;
  filterParam: any;
  errorDesc: string = '';
  displayErrorMsg: boolean = true;
  displayErrorMsgTable: boolean = false;
  years: any;
  yearToSearch: any;
  yearsForTable: any;
  yearToSearchForTable: any;
  BarChartDataInfo: any;
  viewBy: any;
  // Year = this.sharedService.getCurrentDateTime().getFullYear();
  customerList: Customer[] = [];
  approvalStage: "";
  countryCode: any;
  delSites = [];
  delSitesTable=[];
  UserID: any;
  pendData: any;
  customerIdUserSelected: any;
  delSiteUserSelected: any;
  role: string;
  DepotDataType: any;
  deliverySiteObjectList: any = [];


  constructor(private translate: TranslateService, 
    private sharedService: AppService, 
    private myService: ForecastActualMonthlySwedenService, 
    private dialog: MatDialog, 
    private commonService: CommonService) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.custId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
    this.selectedCustomerIdForGraph = this.custId;
    this.selectedCustomerIdForTable = this.custId;
    // this.selectedDeliverySiteForGraph = this.selectedDeliverySiteForGraph
    this.isLoggedInUserPortalAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA") : false);
    this.countryCode = this.userInfo.CountryCode;
    this.UserID = (this.userInfo ? this.userInfo.UserID : '');
    this.role = (this.userInfo ? this.userInfo.RoleID : '');
  }

  async ngOnInit() {
    // Populate year dropdown
    let currentYear: number = this.getCurrentYear();
    this.getYearList(currentYear);

    // this.year = moment(this.sharedService.getCurrentDateTime()).format('YYYY');
    // this.years = ['2020'];
    this.yearToSearch = this.years[1];
    // this.yearsForTable = ['2020'];
    this.yearToSearchForTable = this.years[1];
    this.filterParam = ['All', 'By product', 'By depot', 'By delivery site', 'By product and depot'];
    this.dataToSearch = this.filterParam[0];

    //get customer list
    this.getCustomerList();

    //get del sites
    try {
    await this.getDeliverySitesForGraph();
    }
    catch (e) {
    }

    try {
    await this.getDeliverySitesForTable();
    }
    catch (e){
    }

    this.getBarChartData();
    this.getForecastActualTable();
    this.filterIndexForTable(this.yearToSearch,'All');
  }
   
  getYearList(currYear: number) {
    this.years = [];
    this.years.push(currYear - 1, currYear);
    this.yearsForTable = [];
    this.yearsForTable.push(currYear - 1, currYear);
  }

  getCurrentYear(): number {
    const date = this.sharedService.getCurrentDateTime();
    return date.getUTCFullYear();
  }

  filterIndex(value) {
    this.yearToSearch = value;
    this.Actual_Quantity.splice(0, 12);
    this.Forecast_Quantity.splice(0, 12);
    this.Order_month.splice(0, 12);
    this.getBarChartData();
    this.getForecastActualTable();
  }
  filterIndexForTable(year, searchParam) {
    this.yearToSearchForTable = year;
    this.viewBy = searchParam;
    if (this.viewBy == "All") {
      this.getForecastActualTable();
    }
    if (this.viewBy == "By depot") {
      this.getforecatActualTableByDepot();
    }
    if (this.viewBy == "By product") {
      this.getforecatActualTableByProduct();
    }
    if (this.viewBy == "By delivery site") {
      this.getforecatActualTableByDelSite();
    }
    if (this.viewBy == "By product and depot") {
      this.getforecastActualTableByProductandDepot();
    }
  }

  getBarChartData() {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.selectedDeliverySiteForGraph);
    this.myService.getBarChartData(this.yearToSearch, this.selectedCustomerIdForGraph, this.selectedDeliverySiteForGraph, jdeAddressNumber).subscribe(

      res => {
        if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          if (this.Order_month.length > 0) {
            this.Order_month = [];
          }

          res["Collection"].forEach(x => {
            this.Actual_Quantity.push(parseInt(x.Actual_Quantity));
            this.Forecast_Quantity.push(parseInt(x.Forecast_Quantity));
            //this.Order_month.push(x.Forecast_month);
            this.translate.get(['HOME.' + x.Forecast_month])
              .subscribe(data => {
                this.Order_month.push(data['HOME.' + x.Forecast_month]);
              });
            this.displayErrorMsg = false;
          });

        }
        else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
          (res['Description'] ? res['Description'] : null);
          this.errorDesc = res['Description'];
          this.displayErrorMsg = true;
        }
        else {
          (res['Description'] ? res['Description'] : null);
          this.errorDesc = res['Description'];
          this.displayErrorMsg = true;
        }
      });

  }
  openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess
      },
      height: '37%',
      width: '22%',
    });
  }

  //  Changes for barchart start
  public barChartOptions2: ChartOptions = {
    responsive: true,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    tooltips: {
      mode: 'index'
    },
    scales: {

      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "bold"
        }
      }], yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 14,
          fontStyle: "bold"
        }
      }]
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    }
  };
  public barChartLabels2: Label[] = this.Order_month;
  public barChartType2: ChartType = 'bar';
  public barChartLegend2 = true;
  public barChartPlugins2 = [pluginDataLabels];

  public barChartData2: ChartDataSets[] = [
    /* { data: this.Actual_Quantity, label: 'Actual', pointStyle: "line", backgroundColor: '#FFD500', hoverBackgroundColor: '#FFD500', hoverBorderColor: '#FFD500' },
    { data: this.Forecast_Quantity, label: 'Planned', pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' } */

    { data: this.Actual_Quantity, label: this.getTitleBySelectedLanguage('Actual'), pointStyle: "line", backgroundColor: '#FFD500', hoverBackgroundColor: '#FFD500', hoverBorderColor: '#FFD500' },
    { data: this.Forecast_Quantity, label: this.getTitleBySelectedLanguage('Planned'), pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' }
  ];

  // Changes for barchart end

  //bar chart data for customer role: start

  public barChartOptions1: ChartOptions = {
    responsive: true,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    tooltips: {
      mode: 'index'
    },
    scales: {

      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "bold"
        }
      }], yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 14,
          fontStyle: "bold"
        }
      }]
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    }
  };
  public barChartLabels1: Label[] = this.Order_month;
  public barChartType1: ChartType = 'bar';
  public barChartLegend1 = true;
  public barChartPlugins1 = [pluginDataLabels];

  public barChartData1: ChartDataSets[] = [
    { data: this.Forecast_Quantity, label: 'Forecast', pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' }
  ];
  //bar chart data for customer role : end


  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  showSelectedMenuItem(itemName: String) {
    this.selectedItem = itemName;
  }

  getForecastActualTable() {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.selectedDeliverySiteForTable);
    this.myService.getTableDataForAll(this.yearToSearchForTable, this.selectedCustomerIdForTable, this.selectedDeliverySiteForTable, jdeAddressNumber).subscribe(res => {
      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var test = this;
        res["ActualVsPlannedByAll"].forEach(function (arrayItem) {
          var x = arrayItem;
          test.forecastActualData = [];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast']['Jan'], x['TypeCollectionForecast']['Feb'], x['TypeCollectionForecast']['Mar'], x['TypeCollectionForecast']['Apr'], x['TypeCollectionForecast']['May'], x['TypeCollectionForecast']['Jun'], x['TypeCollectionForecast']['Jul'], x['TypeCollectionForecast']['Aug'], x['TypeCollectionForecast']['Sep'], x['TypeCollectionForecast']['Oct'], x['TypeCollectionForecast']['Nov'], x['TypeCollectionForecast']['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual']['Jan'], x['TypeCollectionActual']['Feb'], x['TypeCollectionActual']['Mar'], x['TypeCollectionActual']['Apr'], x['TypeCollectionActual']['May'], x['TypeCollectionActual']['Jun'], x['TypeCollectionActual']['Jul'], x['TypeCollectionActual']['Aug'], x['TypeCollectionActual']['Sep'], x['TypeCollectionActual']['Oct'], x['TypeCollectionActual']['Nov'], x['TypeCollectionActual']['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          x['TypeCollectionForecast']['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          x['TypeCollectionActual']['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          x['TypeCollectionDiffernce']['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0):0;;
          test.forecastActualData.push(x['TypeCollectionForecast']);
          test.forecastActualData.push(x['TypeCollectionActual']);
          test.forecastActualData.push(x['TypeCollectionDiffernce']);
          test.excelDataForALL = test.forecastActualData;
          test.dataSource = new MatTableDataSource(test.forecastActualData);
          test.dataSource.sort = test.sort;
          test.displayTable = true
        });

      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        (res['Description'] ? res['Description'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }
      else {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }

    });



  }

  getTotal(jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec) {
    var totalValue = parseInt(jan) + parseInt(feb) + parseInt(mar) + parseInt(apr) + parseInt(may) +
      parseInt(jun) + parseInt(jul) + parseInt(aug) + parseInt(sep) + parseInt(oct) + parseInt(nov) +
      parseInt(dec);
    return totalValue;
  }

  getforecatActualTableByDepot() {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.selectedDeliverySiteForTable);
    this.myService.getTableDataForDepot(this.yearToSearchForTable, this.selectedCustomerIdForTable, this.selectedDeliverySiteForTable, jdeAddressNumber).subscribe(res => {
      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = res["ActualVsPlannedByDepot"]["DepotCollection"];
        var test = this;
        var forecastActualData = [];
        productData.forEach(function (arrayItem) {
          var x = arrayItem;
          let array1 = x['TypeCollectionForecast'][0];
          let array2 = x['TypeCollectionActual'][0];
          let array3 = x['TypeCollectionDifference'][0];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          array1['DepotName'] = x.DepotName;
          // array2['DepotName']= x.DepotName;
          // array3['DepotName']= x.DepotName; 
          array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0):0;;
          forecastActualData.push(array1);
          forecastActualData.push(array2);
          forecastActualData.push(array3);

        });
        this.dataSource1 = new MatTableDataSource(forecastActualData);
        this.excelDataForALL = forecastActualData;
        this.dataSource1.sort = this.sort;
        this.displayTable = true;
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }
      else {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }

    });
  }

  getforecatActualTableByProduct() {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.selectedDeliverySiteForTable);
    this.myService.getTableDataForProduct(this.yearToSearchForTable, this.selectedCustomerIdForTable, this.countryCode, this.selectedDeliverySiteForTable, jdeAddressNumber).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = res["ActualVsPlannedByProduct"]["ProductCollection"];
        var test = this;
        var forecastActualData = [];
        productData.forEach(function (arrayItem) {
          var x = arrayItem;
          let array1 = x['TypeCollectionForecast'][0];
          let array2 = x['TypeCollectionActual'][0];
          let array3 = x['TypeCollectionDifference'][0];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          array1['ProductName'] = x.ProductName;
          // array2['DepotName']= x.DepotName;
          // array3['DepotName']= x.DepotName; 
          array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0):0;;
          forecastActualData.push(array1);
          forecastActualData.push(array2);
          forecastActualData.push(array3);
        });

        this.dataSource2 = new MatTableDataSource(forecastActualData);
        this.excelDataForALL = forecastActualData;
        this.dataSource2.sort = this.sort;
        this.displayTable = true;
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }
      else {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }

    });
  }

  getforecatActualTableByDelSite() {

    this.myService.getTableDataForDelSite(this.yearToSearchForTable, this.selectedCustomerIdForTable).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = res["ActualVsPlannedByDeliverySite"]["DeliverySiteCollection"];
        var test = this;
        var forecastActualData = [];
        /* productData.forEach(function (arrayItem) {
          var x = arrayItem;
          let array1 = x['TypeCollectionActual'][0];

          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
          array1['DeliverySite'] = x.DeliverySite;
          array1['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          forecastActualData.push(array1);
        }); */

        // Added filter for delivery site selected in dropdown temporarily, until delivery site parameter is passed to service
        productData.forEach(function (arrayItem) {
          if (test.selectedDeliverySiteForTable === arrayItem['DeliverySite']) {
            var x = arrayItem;
            let array1 = x['TypeCollectionActual'][0];
            let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
            array1['DeliverySite'] = x.DeliverySite;
            array1['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
            forecastActualData.push(array1);
          }
        });

        this.dataSource3 = new MatTableDataSource(forecastActualData);
        this.excelDataForALL = forecastActualData;
        this.dataSource3.sort = this.sort;

        // this.displayTable = true;
        this.displayTable = (forecastActualData && forecastActualData.length > 0);
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }
      else {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }

    });

  }

  downloadData() {
      let excelReportData = [];
      let cloned = this.excelDataForALL.map(x => Object.assign({}, x));

       for (let data of cloned) {
        if (data['Jan']) {
          data['Jan'] = +data['Jan'];
        }
        if (data['Feb']) {
          data['Feb'] = +data['Feb'];
        }
        if (data['Mar']) {
          data['Mar'] = +data['Mar'];
        }
        if (data['Apr']) {
          data['Apr'] = +data['Apr'];
        }
        if (data['May']) {
          data['May'] = +data['May'];
        }
        if (data['Jun']) {
          data['Jun'] = +data['Jun'];
        }
        if (data['Jul']) {
          data['Jul'] = +data['Jul'];
        }
        if (data['Aug']) {
          data['Aug'] = +data['Aug'];
        }
        if (data['Sep']) {
          data['Sep'] = +data['Sep'];
        }
        if (data['Oct']) {
          data['Oct'] = +data['Oct'];
        }
        if (data['Nov']) {
          data['Nov'] = +data['Nov'];
        }
        if (data['Dec']) {
          data['Dec'] = +data['Dec'];
        }
        excelReportData.push(data);
      }

    if (this.viewBy == "All") {
      this.myService.exportAsExcelFileForAll(excelReportData, "Monthly_Reports_by_All");
    }

    if (this.viewBy == "By product") {
      this.myService.exportAsExcelFileForProduct(excelReportData, "Monthly_Reports_by_Product");
    }

    if (this.viewBy == "By depot") {
      this.myService.exportAsExcelFileForDepot(excelReportData, "Monthly_Reports_by_Depot");
    }

    if (this.viewBy == "By delivery site") {
      this.myService.exportAsExcelFileForDeliverySite(excelReportData, "Monthly_Reports_by_DeliverySite");
    }

    if (this.viewBy == "By product and depot") {
      this.myService.exportAsExcelFileForProductAndDepot(excelReportData, "Monthly_Reports_by_ProductAndDepot");
    }
  }


  getCustomerList() {
    this.myService.getCustomerList(this.countryCode)
      .subscribe(
        response => {
          if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            if (response["Customers"] && response["Customers"].length) {
              for (let customer of response["Customers"]) {
                this.customerList.push(new Customer(customer["CustomerID"], customer["CustomerName"]));
              }
            }
          }
        });
  }

  async getDeliverySitesForTable() {
    this.delSitesTable = [];
    if (this.role == "A" || this.role == "PA") {
      return new Promise((resolve, reject) => {
        this.myService.getDeliverySite(this.selectedCustomerIdForTable, "Inactive", this.countryCode).subscribe(
          res => {
            if (res["ResponseCode"] === "200") {
              for (let index of res["DeliverySiteCollection"]) {
                this.delSitesTable.push(index["DeliverySite"])
              }
              this.selectedDeliverySiteForTable = this.delSitesTable[0]
              resolve();
            }
            else {
              reject();
            }
          });
      })
    } else {
      return new Promise((resolve, reject) => {
        this.myService.getDeliverySiteForUser(this.UserID, this.countryCode, "ACTIVE", this.selectedCustomerIdForTable).subscribe(
          response => {
            if (response["ResponseCode"] === "200") {
              for (let deliverySiteObject of response["DeliverySites"]) {
                this.deliverySiteObjectList.push(deliverySiteObject);
                this.delSitesTable.push(deliverySiteObject["DeliverySiteName"])
              }
              this.selectedDeliverySiteForTable = this.delSitesTable[0]
              resolve();
            }
            else {
              reject();
            }
          });
      })

    }
  }

  async getDeliverySitesForGraph() {
    this.delSites = [];
    if (this.role == "A" || this.role == "PA") {
      return new Promise((resolve, reject) => {
        this.myService.getDeliverySite(this.selectedCustomerIdForGraph, "Inactive", this.countryCode).subscribe(
          res => {
            if (res["ResponseCode"] === "200") {
              for (let index of res["DeliverySiteCollection"]) {
                this.delSites.push(index["DeliverySite"])
              }
              this.selectedDeliverySiteForGraph = this.delSites[0]
              resolve();
            }
            else {
              reject();
            }
          });
      })

    } else {
      return new Promise((resolve, reject) => {
        this.myService.getDeliverySiteForUser(this.UserID, this.countryCode, "ACTIVE", this.selectedCustomerIdForGraph).subscribe(
          response => {
            if (response["ResponseCode"] === "200") {
              for (let deliverySiteObject of response["DeliverySites"]) {
                this.deliverySiteObjectList.push(deliverySiteObject);
                this.delSites.push(deliverySiteObject["DeliverySiteName"])
              }
              this.selectedDeliverySiteForGraph = this.delSites[0]
              resolve();
            }
            else {
              reject();
            }
          });
      })
    }
  }

  // countryCode(countryCode: any) {
  //   throw new Error("Method not implemented.");
  // }

  getStringWithoutSpaces(stringWithSpaces: string): string {
    let stringWithoutSpaces = '';
    stringWithoutSpaces = stringWithSpaces.replace(/\s/g, '');
    return stringWithoutSpaces;
  }

  getforecastActualTableByProductandDepot() {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.selectedDeliverySiteForTable);
    this.myService.getTableDataForDepotAndProduct(this.selectedCustomerIdForTable, this.yearToSearchForTable, this.countryCode, this.selectedDeliverySiteForTable, jdeAddressNumber).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = res["DepotCollection"][0]["ProductCollection"];
        var depotData = res["DepotCollection"];
        var test = this;
        var forecastActualData = [];

        depotData.forEach(function (depotItem) {
          var productData = depotItem["ProductCollection"];
          productData.forEach(function (arrayItem) {
            var x = arrayItem;

            let array1 = x['TypeCollectionForecast'][0];
            let array2 = x['TypeCollectionActual'][0];
            let array3 = x['TypeCollectionDifference'][0];
            let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
            let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
            let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);

            array1['ProductName'] = x.ProductName;
            array1['DepotName'] = depotItem.DepotName;
            // array3['DepotName']= x.DepotName;

            array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
            array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
            array3['Total'] = diffenceTotalPer ? diffenceTotalPer.toString() : 0;
            forecastActualData.push(array1);
            forecastActualData.push(array2);
            forecastActualData.push(array3);
          })
        });
   
        // depotData.forEach(function (arrayItem) {
        //   var x = arrayItem;
        //   let array1= x['TypeCollectionForecast'][0];
        //   let array2= x['TypeCollectionActual'][0];
        //   let array3= x['TypeCollectionDifference'][0];
        //   let TypeCollectionForecastTotal  = test.getTotal(x['TypeCollectionForecast'][0]['Jan'],x['TypeCollectionForecast'][0]['Feb'],  x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'],x['TypeCollectionForecast'][0]['Jul'],x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'],x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'],x['TypeCollectionForecast'][0]['Dec']);
        //   let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'],x['TypeCollectionActual'][0]['Feb'],  x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'],x['TypeCollectionActual'][0]['Jul'],x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'],x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'],x['TypeCollectionActual'][0]['Dec']);
        //   let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal )/ TypeCollectionForecastTotal * 100) : 0) ;
        //   array1['DepotName']= x.DepotName;
        //   // array1['DepotName']= x.DepotName;
        //   // array3['DepotName']= x.DepotName; 
        //   array1['Total']= TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
        //   array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
        //   array3['Total'] = diffenceTotalPer ? diffenceTotalPer.toString() : 0;
        //   forecastActualData.push(array1);
        //   forecastActualData.push(array2);
        //   forecastActualData.push(array3);
        //  });


        this.dataSource2 = new MatTableDataSource(forecastActualData);
        this.excelDataForALL = forecastActualData;
        this.dataSource2.sort = this.sort;
        this.displayTable = true;
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }
      else {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }

    });
  }  

  changeOnClick() {
    // added this function to fix ng build prod errors
  }

  

  getTitleBySelectedLanguage(title: string): string {
    let translatedTitle: string;
    let selectedLanguage = this.sharedService.getSelectedLanguage();
    if (title === 'Actual') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Actual';
          break;
        case 'FI':
          translatedTitle = 'Toteuma';
          break;
        case 'SE':
          translatedTitle = 'Faktiskt';
          break;
        case 'NR':
          translatedTitle = 'Salg';
          break;
        default:
          translatedTitle = 'Actual';
      }
    }
    if (title === 'Planned') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Planned';
          break;
        case 'FI':
          translatedTitle = 'Ennuste';
          break;
        case 'SE':
          translatedTitle = 'Prognostiserat';
          break;
        case 'NR':
          translatedTitle = 'Prognose';
          break;
        default:
          translatedTitle = 'Planned';
      }
    }
    return translatedTitle;
  }
  
  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        if (deliverySiteObject && deliverySiteObject['DeliverySite'] === deliverySiteName) {
          jdeAddressNumber = deliverySiteObject['JdeAddressNumber'];
          break;
        }
      }
    }
    return jdeAddressNumber;
  }




}
